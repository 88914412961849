<form class="consignment-form py-4 px-2 px-sm-3 px-md-5 w-100" *ngIf="serviceRequest && serviceRequest.consecutive">
    <div class="row">
        <h4 class="col-12 mt-3 mb-5 text-bold text-center">Edición Remesa <span
                class="color-secondary-dark">{{getConsignmentToEdit}}</span></h4>
    </div>

    <div permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.allowToEditGeneralDataModule">
        <div class="row">
            <div class="col-md-12">
                <span class="label-field">Propietario de la Remesa</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-general-data-cargo class="col-md-12" [cargo]="serviceRequest"
                    (emitToParent)="onEmitToParent($event)" (cleanCargo)="onChangeCleanCargo($event)"
                    [hiddenFields]="hiddenFieldsGeneralDataCargo" [defaultEnabled]="true">
                </app-general-data-cargo>
            </div>
        </div>
        <hr>
    </div>

    <div permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.editServiceTypeModule">
        <div class="row">
            <div class="col-md-12">
                <span class="label-field">Tipo de Servicio</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-cargo-type [cargo]="serviceRequest" (emitToParent)="onEmitToParent($event)"
                    (cleanCargo)="onChangeCleanCargo($event)" [hiddenFields]="hiddenFieldsCargoType"></app-cargo-type>
            </div>
        </div>
        <hr>
    </div>

    <div permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editServiceCharacteristicsModule">
        <div class="row">
            <div class="col-md-12">
                <span class="label-field">Caracteríasticas del Servicio</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <app-cargo-characteristic [cargo]="serviceRequest" (emitToParent)="onEmitToParent($event)"
                    [hiddenFields]="hiddenFieldsCargoCharacteristic" [forceEnabledTypeMerchandise]="true">
                </app-cargo-characteristic>
            </div>
        </div>
        <hr>
    </div>


    <div permission-display [module]="permission.cargo.module"
        [functionality]="permission.cargo.editLoadDownloadModule">
        <div class="row">
            <div class="col-md-12">
                <app-load-download-cargo [cargo]="serviceRequest" (emitToParent)="onEmitToParent($event)"
                    [hiddenFields]="hiddenFieldsLoadDownloadCargo" [hideOptionsAddCity]="true"
                    [hideOptionsAddAddress]="true">
                </app-load-download-cargo>
            </div>
        </div>
    </div>

    <div class="row">
        <!-- Submit button -->
        <div class="button-container my-3  col-12">
            <button type="button" mat-raised-button color="primary" class="primary-secondary bg-color-primary"
                (click)="goBack()">Cancelar</button>
            <button mat-raised-button color="primary" type="button" (click)="onSubmit()"
                class="bg-color-primary">Guardar</button>
        </div>
    </div>

    <!--[cleanCargo]="onChangeCleanCargo"<div class="row" *ngIf="reactiveBasicConsignmentForm && reactiveBasicConsignmentForm.form">
        <div class="col-sm-6" *ngIf="reactiveBasicConsignmentForm && reactiveBasicConsignmentForm.form">
            <app-trip-type-field
                [inputFormControl]="reactiveBasicConsignmentForm.form.get('tripType.name')"></app-trip-type-field>
        </div>
        <div class="col-sm-6">
            <app-cargo-type-field></app-cargo-type-field>
        </div>
        <div class="col-sm-6">
            <app-service-type-field></app-service-type-field>
        </div>
        <div class="col-sm-6">
            <app-packaging-type-field
                [inputFormGroup]="reactiveBasicConsignmentForm.form.get('packagingType')"></app-packaging-type-field>
        </div>
        <div class="col-sm-6">
            <app-product-type-field></app-product-type-field>
            <app-cargo-type-merchandise class="col-md-12" [inputFormControl]="reactiveBasicConsignmentForm.form.get('tripType.name')"
              [options]="optionsTypeMerchandise" [validate]="validate">
            </app-cargo-type-merchandise>
        </div>
        <div class="col-sm-6">
            <app-vehicle-type-field></app-vehicle-type-field>
        </div>
        <div class="col-sm-6">
            <app-bodywork-type-field></app-bodywork-type-field>
        </div>
        <div class="col-sm-6">
            <app-third-party-consignment-field></app-third-party-consignment-field>
        </div>
        <div class="col-sm-6">
            <app-origin-upload-field></app-origin-upload-field>
        </div>
        <div class="col-sm-6">
            <app-third-party-consignment-field></app-third-party-consignment-field>
        </div>
        <div class="col-sm-6">
            <app-date-field></app-date-field>
        </div>
        <div class="col-sm-6">
            <app-time-field></app-time-field>
        </div>
        <div class="col-sm-6">
            <app-list-hours-field></app-list-hours-field>
        </div>
        <div class="col-sm-6">
            <app-list-minutes-field></app-list-minutes-field>
        </div>
        <div class="col-sm-6">
            <app-list-download-destinations-field></app-list-download-destinations-field>
        </div>
        <div class="col-sm-6">
            <app-destination-download-field></app-destination-download-field>
        </div>
        <div class="col-sm-6">
            <app-third-party-consignment-field></app-third-party-consignment-field>
        </div>
        <div class="col-sm-6">
            <app-date-field></app-date-field>
        </div>
        <div class="col-sm-6">
            <app-time-field></app-time-field>
        </div>
        <div class="col-sm-6">
            <app-list-hours-field></app-list-hours-field>
        </div>
        <div class="col-sm-6">
            <app-list-minutes-field></app-list-minutes-field>
        </div>
    </div>-->
</form>