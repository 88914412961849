export enum VehicleMessages {

  DAYS_TO_ALERT_EXPIRATION_DEFAULT = 30,
  DAYS_TO_BLOCK_DEFAULT = 7,
  DAYS_TO_DEACTIVE_VEHICLES_DEFAULT = 60,

  RTM_NO_INFORMATION = 'No hay información de la RTM.',
  RTM_NO_EXPIRATION = 'No hay información de la fecha de vencimiento de la RTM.',
  RTM_EXONERATED = 'El vehículo se encuentra exonerado de la RTM por ser nuevo',
  RTM_POSSIBLE_EXONERATED = 'El vehículo se encuentra en su último año de exoneración de la RTM',
  RTM_VALID = 'RTM válida.',
  RTM_MORE_N_DAYS = 'RTM válida y con vigencia mayor a %s días desde la fecha de llegada.',
  RTM_LESS_ALERT_N_DAYS = 'La RTM expirará en menos de %s días desde la fecha de llegada.',
  RTM_LESS_BLOCK_N_DAYS = '¡La RTM expirará en menos de %s días desde la fecha de llegada!',
  RTM_EXPIRATED = 'La RTM estará vencida para la fecha de llegada.',
  SOAT_NO_INFORMATION = 'No hay información del SOAT.',
  SOAT_NO_EXPIRATION = 'No hay información de la fecha de vencimiento del SOAT.',
  SOAT_VALID = 'SOAT válido.',
  SOAT_MORE_N_DAYS = 'SOAT válido y con vigencia mayor a %s días desde la fecha de llegada.',
  SOAT_LESS_ALERT_N_DAYS = 'El SOAT expirará en menos de %s días desde la fecha de llegada.',
  SOAT_LESS_BLOCK_N_DAYS = '¡El SOAT expirará en menos de %s días desde la fecha de llegada!',
  SOAT_EXPIRATED = 'El SOAT estará vencido para la fecha de llegada.',

  NO_VEHICLE_TYPE_NAME = "El vehículo seleccionado no tiene un tipo asignado",
  NO_CARGO_VEHICLE_NAME = "El servicio no tiene un tipo de vehículo asignado",
  STATE_INACTIVE = "El vehículo seleccionado no esta activo",
  DRIVER_STATE_INACTIVE = "El conductor de este vehículo no está activo",
  OWNER_STATE_INACTIVE = "El propietario de este vehículo no está activo",
  ADMIN_STATE_INACTIVE = "El administrador de este vehículo no está activo",
  VEHICLE_TYPE_DIFF = "El vehículo seleccionado es de tipo %s, el servicio permite vehículos tipo %s",
  VEHICLE_DOCUMENTATION_INCOMPLETE = "El vehículo no tiene cargada la documentación obligatoria. Falta(n):",
  VEHICLE_DOCUMENTATION_UNACCEPTED = "Hay documentación obligatoria que no ha sido validada por tráfico. Falta(n):",
  VEHICLE_REFERENCES_UNACCEPTED = "Hay referencias y contactos que no han sido validados por tráfico. Falta(n):",
  NOT_VEHICLE_SELECTED = "No se ha seleccionado un vehículo"
}
