import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Utils } from 'src/app/core/resources/utils';
import { CompaniesService } from '../list-companies.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import { Company } from 'src/app/core/interfaces/company';
import { Fmt } from 'src/app/core/messages/fmt';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';

@Component({
  selector: 'app-cost-center-form',
  templateUrl: './cost-center-form.component.html',
  styleUrls: ['./cost-center-form.component.scss']
})
export class CostCenterFormComponent implements OnInit {

  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<CostCenterFormComponent>,
    public utils: Utils,
    private companiesService: CompaniesService,
    private snackBarService: SnackBarService,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      nitCompany;
      nameCompany;
    }
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      active: new FormControl(true),
      code: new FormControl('', Validators.required),
      name: new FormControl(this.data && this.data.nameCompany ? this.data.nameCompany : '', Validators.required)
    })
  }

  onSubmit() {
    if (this.utils.errorMessagesCustomized(this.form.get('code'), 'código')) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre del centro')) return;
    else this.associateCostCenter(this.form.value);
  }

  private associateCostCenter(costCenter: CostCenter): void {
    this.spinner.show();
    this.companiesService.associateCostCenter(costCenter, this.data.nitCompany).toPromise()
      .then((success: Company) => {
        if (success && (success.message === "Update" || success.message === "Actualizado") && success.costCenter && success.costCenter.length) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.STATE_COST_CENTER, 'asociado'));
          this.dialogRef.close({ state: true, data: success.costCenter.find(costCenterResponse => costCenterResponse.code === costCenter.code) });
        } else if (success.message) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(success.message, undefined, 'error');
          this.dialogRef.close();
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error asociando el Centro de costo', undefined, 'error');
          this.dialogRef.close();
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
        this.dialogRef.close();
      });
  }



}
