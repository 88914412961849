import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContainerClassType, ContainerInspect } from 'src/app/core/interfaces/container-inspect';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogSurveyComponent } from 'src/app/shared/dialog-survey/dialog-survey.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import { CargoItemService } from '../cargo-item/cargo-item.service';
import ContentTypes from 'src/app/core/resources/content-type-ext.json';
import { CheckListQuestion, CheckLists } from 'src/app/core/interfaces/checkListsInspectVehicle';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-container-checklist',
  templateUrl: './container-checklist.component.html',
  styleUrls: ['./container-checklist.component.scss']
})
export class ContainerChecklistComponent implements OnInit {

  public options = {
    "B": "Bueno",
    "M": "Malo",
    "N/A": "No Aplica",
  };

  public user: string;


  public questions = {
    "IDP": "Tiene placa de identificacíon",
    "IPA": "La placa de identificación presenta alteraciones",
    "LNM": "Los números laterales coinciden con los de la placa",
    "LCN": "El dado izquierdo tiene número",
    "CPM": "El número de dado coincide con la placa",
    "CNC": "El número del contenedor presenta inconsistencias en alguna de las marcas internas o externas",
    "RNS": "Su número presenta remarcación",
    "CHP": "Sus letras y números son parejos",
    "MFR": "Su marcación presenta acabados deformes",

    "HRS": "Bisagras con soldaduras recientes",
    "LBS": "Barra de cierre con soldaduras",
    "SYI": "Tuercas internas con siliconas",
    "PBO": "Se ven las tuercas de los canales inferiores y superiores",
    "BHA": "Remaches, bisagras y alrededores con golpes, ralladuras o pintura nueva",
    "SPA": "La platina de seguridad muestra dobleces, ralladuras o pintura nueva",
    "REA": "Retenedores presenta soldadura y/o pintura fresca",
    "CBA": "Los sujetadores de barra de cierre presentan inconsistencias en sus tornillos",
    "LHA": "Los tornillos de la manija de cierre presentan inconsistencias o alteraciones",
    "DLA": "Los tornillos del pistillo de puerta presentan inconsistencias",

    "LRA": "El número de costillas es igual interna y externamente",
    "LRF": "Presenta señales de reparaciones recientes",
    "LFF": "Los ventiladores estan libres",
    "LCA": "El dado presenta alguna inconsistencia ",
    "LCS": "El dado al golpearlo su sonido es sólido ",
    "LCY": "El dado es igual en su forma y tamaño al del otro lado",
    "LPU": "La pintura del dado es uniforme con la del contenedor",
    "LAP": "Se observa alguna pieza adicional en la pared del contenedor",

    "RRA": "El número de costillas es igual interna y externamente",
    "RRF": "Presenta señales de reparaciones recientes",
    "RFF": "Los ventiladores estan libres",
    "RCA": "El dado presenta alguna inconsistencia",
    "RCS": "El dado al golpearlo su sonido es sólido",
    "RCY": "El dado es igual en su forma y tamaño al del otro lado",
    "RPU": "La pintura del dado es uniforme con la del contenedor",
    "RAP": "Se observa alguna pieza adicional en la pared del contenedor",

    "RUS": "Presenta desnivel en su estructura",
    "RRS": "Señales o muestras de reparación",
    "IER": "Se ven interna y externamente las mismas señales de golpes o reparaciones",
    "IMD": "La altura entre piso y techo al medirlo interna y externamente presenta diferencia de más de 1cm.",
    "RHC": "Los dados se encontraban ocultos.",

    "IPF": "Presenta reparaciones con malos acabados",
    "IUS": "Tiene desnivele en su estructura",
    "IDL": "Presenta un escalón o sobre sale en alguna de sus partes",
    "ISD": "El sonido es parejo en todas sus partes",
    "IBS": "Tiene tornillos que presentan inconsistencias",

    "EVW": "Se ve la madera del piso",
    "EVB": "Se ven las vigas",

    "FHS": "Su sonido es hueco",
    "FRD": "La distancia al ventilador es de una costilla",
    "RRU": "Se ve la unión de refuerzo de la pared lateral derecha",
    "LRU": "Se ve la unión de refuerzo de la pared lateral izquierda",
    "RBE": "Las reparaciones o golpes se ven en ambos lados"
  };

  public checkLists: Array<CheckLists> = [
    { question: CheckListQuestion.IDP, response: 'B' },
    { question: CheckListQuestion.IPA, response: 'B' },
    { question: CheckListQuestion.LNM, response: 'B' },
    { question: CheckListQuestion.LCN, response: 'B' },
    { question: CheckListQuestion.CPM, response: 'B' },
    { question: CheckListQuestion.CNC, response: 'B' },
    { question: CheckListQuestion.RNS, response: 'B' },
    { question: CheckListQuestion.CHP, response: 'B' },
    { question: CheckListQuestion.MFR, response: 'B' },
    { question: CheckListQuestion.HRS, response: 'B' },
    { question: CheckListQuestion.LBS, response: 'B' },
    { question: CheckListQuestion.SYI, response: 'B' },
    { question: CheckListQuestion.PBO, response: 'B' },
    { question: CheckListQuestion.BHA, response: 'B' },
    { question: CheckListQuestion.SPA, response: 'B' },
    { question: CheckListQuestion.REA, response: 'B' },
    { question: CheckListQuestion.CBA, response: 'B' },
    { question: CheckListQuestion.LHA, response: 'B' },
    { question: CheckListQuestion.DLA, response: 'B' },
    { question: CheckListQuestion.LRA, response: 'B' },
    { question: CheckListQuestion.LRF, response: 'B' },
    { question: CheckListQuestion.LFF, response: 'B' },
    { question: CheckListQuestion.LCA, response: 'B' },
    { question: CheckListQuestion.LCS, response: 'B' },
    { question: CheckListQuestion.LCY, response: 'B' },
    { question: CheckListQuestion.LPU, response: 'B' },
    { question: CheckListQuestion.LAP, response: 'B' },
    { question: CheckListQuestion.RRA, response: 'B' },
    { question: CheckListQuestion.RRF, response: 'B' },
    { question: CheckListQuestion.RFF, response: 'B' },
    { question: CheckListQuestion.RCA, response: 'B' },
    { question: CheckListQuestion.RCS, response: 'B' },
    { question: CheckListQuestion.RCY, response: 'B' },
    { question: CheckListQuestion.RPU, response: 'B' },
    { question: CheckListQuestion.RAP, response: 'B' },
    { question: CheckListQuestion.RUS, response: 'B' },
    { question: CheckListQuestion.RRS, response: 'B' },
    { question: CheckListQuestion.IER, response: 'B' },
    { question: CheckListQuestion.IMD, response: 'B' },
    { question: CheckListQuestion.RHC, response: 'B' },
    { question: CheckListQuestion.IPF, response: 'B' },
    { question: CheckListQuestion.IUS, response: 'B' },
    { question: CheckListQuestion.IDL, response: 'B' },
    { question: CheckListQuestion.ISD, response: 'B' },
    { question: CheckListQuestion.IBS, response: 'B' },
    { question: CheckListQuestion.EVW, response: 'B' },
    { question: CheckListQuestion.EVB, response: 'B' },
    { question: CheckListQuestion.FHS, response: 'B' },
    { question: CheckListQuestion.FRD, response: 'B' },
    { question: CheckListQuestion.RRU, response: 'B' },
    { question: CheckListQuestion.LRU, response: 'B' },
    { question: CheckListQuestion.RBE, response: 'B' }
  ];
  public containerLocationDamageOptions: Array<string> = [
    'Techo',
    'Lateral Derecho',
    'Lateral Izquierdo',
    'Piso',
    'Puerta',
    'Sujetador',
    'Cantonera',
    'Gozne Central',
    'Gancho de Cierre',
    'Frisa'
  ];
  public damageTypeOptions: Array<object> = [
    { value: 'DO', label: 'Doblado' },
    { value: 'OX', label: 'Oxidado' },
    { value: 'SU', label: 'Sumido' },
    { value: 'SC', label: 'Sucio' },
    { value: 'CO', label: 'Cortado' },
    { value: 'FA', label: 'Falta' },
    { value: 'RO', label: 'Roto' },
    { value: 'ZA', label: 'Zafo' },
    { value: 'PM', label: 'Manchado' },
  ]

  public classTypes = {
    'SD': 'SD',
    'HC': 'HC',
    'OP': 'OPEN TOP',
    'FR': 'FLAT RACK',
    'RE': 'REEFER',
    'OT': 'OTRO'
  }

  public containerStatus = [];
  public vehicle: Vehicle;
  public size: number = 20;
  public tmpsize: 20 | 40 | -1 = 20;
  public classType: ContainerClassType = ContainerClassType.SD;

  form = new FormGroup({
    container: new FormControl('', [Validators.required, Validators.minLength(3)]),
    indicativeSeal: new FormControl('', [Validators.required, Validators.minLength(2)]),
    safetySeal: new FormControl('', [Validators.required, Validators.minLength(2)]),
    shippingCompany: new FormControl('', [Validators.required, Validators.minLength(2)]),
    courtyard: new FormControl('', [Validators.required, Validators.minLength(2)]),
    evaluatorObservation: new FormControl('', [Validators.required, Validators.minLength(8)]),
    classType: new FormControl(ContainerClassType.SD, [Validators.required]),
    size: new FormControl(20, [Validators.required]),
    altered: new FormControl(false, [Validators.required])
  });

  damageForm = new FormGroup({
    containerLocation: new FormControl('', [Validators.required]),
    damageType: new FormControl('', [Validators.required]),
    damageDimension: new FormControl('', [Validators.required]),
    damageObs: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });


  constructor(
    public dialogRef: MatDialogRef<DialogSurveyComponent>,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private cargoItemService: CargoItemService,
    public dialog: MatDialog,
    private vehiclesService: VehiclesService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user,
      licensePlate: string,
      inspect,
      disabled,
      cargoId: string,
      cargoContainer: string,
      title?: string
    },
  ) {
    if (this.data.cargoContainer && !this.data.inspect) {
      this.form
        .get('container')
        .setValue(this.data.cargoContainer);
      this.form
        .get('container')
        .disable();
    }
  }
  public userInformation: string;

  ngOnInit() {
    if (this.data.inspect && this.data.inspect.id) {
      this.form.patchValue(this.data.inspect);

      this.size = this.data.inspect.size;
      this.tmpsize = this.size as any;
      this.classType = this.data.inspect.classType;
      this.checkLists = this.data.inspect.checkLists;

      if (this.data.inspect.approval) {
        this.userInformation = this.data.inspect.confirmedInspectionFingerPrint.userName;
      } else {
        this.userInformation = this.data.inspect.evaluatorFingerPrint.userName;
      }
      this.form.disable();
    } else {
      this.userInformation = this.data.user.name;
    }
    this.getVehicle(this.data.licensePlate);
  }

  public getVehicle(id: string) {
    this.vehiclesService.getVehicle(id).subscribe(
      (success: any) => {
        if (success) {
          this.vehicle = success;
        }
      }
    );
  }

  public confirmRejection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `¿Estás seguro que deseas rechazar la inspección preoperacional del vehiculo?`,
    };
    dialogConfig.height = 'auto';
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result && result.state)
        this.approve(false);
    });
  }

  public approve(approval: boolean) {
    let obj = {
      inspectionId: this.data.inspect.id,
      fingerprint: {
        userId: this.data.user.document,
        userName: this.data.user.name,
        date: DateManager.dateToString(new Date(), 'yyyy-MM-DD HH:mm ZZ')
      }
    };
    this.cargoItemService.approveInspection(obj, approval, "container").subscribe(success => {
      approval ?
        this.snackBarService.openSnackBar('Inspección aprobada correctamente', undefined, "success") :
        this.snackBarService.openSnackBar('Inspección rechazada correctamente', undefined, "success");
      this.dialogRef.close();
    },
      (error: HttpErrorResponse) => {
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al aprobar inspección', undefined, 'error');
        }
      }
    );
  }

  onChangeDatePickerCorrective($event) {
    this.form.get("dateMaintenanceCorrective").patchValue(DateManager.dateToString($event.value, 'YYYY-MM-DD'));
  }

  onChangeDatePickerPreventive($event) {
    this.form.get("dateMaintenancePreventive").patchValue(DateManager.dateToString($event.value, 'YYYY-MM-DD'));
  }

  saveInspection() {
    this.form.markAllAsTouched();
    this.tmpsize !== -1 && this.form.get('size').setValue(this.tmpsize);
    if (this.form.valid) {
      let Inspection: ContainerInspect =
      {
        version: 2,
        licensePlate: this.vehicle.id,
        evaluatorFingerPrint: {
          userId: this.data.user.document,
          userName: this.data.user.name,
          date: DateManager.dateToString(new Date(), 'yyyy-MM-DD HH:mm ZZ'),
        },
        checkLists: this.checkLists,
        approval: false,
        container: this.form.get('container').value,
        size: this.form.get('size').value,
        classType: this.form.get('classType').value,
        safetySeal: this.form.get('safetySeal').value,
        indicativeSeal: this.form.get('indicativeSeal').value,
        shippingCompany: this.form.get('shippingCompany').value,
        courtyard: this.form.get('courtyard').value,
        containerStatus: this.containerStatus,
        evaluatorObservation: this.form.get('evaluatorObservation').value,
        altered: this.form.get('altered').value
      };
      if (this.data && this.data.inspect && this.data.inspect.id) Inspection['id'] = this.data.inspect.id;
      if (this.someFieldIsFilled()) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          title: `Tiene algunos campos de daños llenos pero no ha guardado el daño en la inspección, ¿Desea descartarlos y continuar?`,
        };
        dialogConfig.width = ModalEnum.SMALL_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.autoFocus = false;
        this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe(result => {
          if (result && result.state)
            this.save(Inspection);
        });
      } else {
        this.save(Inspection);
      }
    } else {
      if (this.utils.errorMessagesCustomized(this.form.get('container'), 'número del contenedor', 3)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('indicativeSeal'), 'sello indicativo', 2)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('safetySeal'), 'sello de seguridad', 2)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('courtyard'), 'patio', 2)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('shippingCompany'), 'naviera', 2)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('size'), 'tamaño')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('classType'), 'classType')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('evaluatorObservation'), 'observación', 8)) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
      }
    }
  }

  someFieldIsFilled(): boolean {
    return Object.keys(this.damageForm.controls).some(controlName => {
      const control = this.damageForm.get(controlName);
      return control && control.dirty && control.value !== '';
    });
  }

  public save(Inspection: ContainerInspect) {
    this.spinner.show();
    this.cargoItemService.sendInspection(Inspection, "CONTAINER", this.data.cargoId).subscribe(
      (success) => {
        if (success) {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Inspección guardada correctamente');
          this.dialogRef.close();
        } else {
          this.snackBarService.openSnackBar('Existio un error al guardar la inspección', undefined, 'error');
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (!this.utils.isEmpty(error) && !this.utils.isEmpty(error.error) && !this.utils.isEmpty(error.error.message)) {
          this.snackBarService.openSnackBar(error.error.message, undefined, 'alert');
        } else {
          this.snackBarService.openSnackBar('Ocurrió un error al enviar inspeción', undefined, 'error');
        }
      }
    );
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  public AttachDamangeObs(formDirective: FormGroupDirective) {
    if (this.damageForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.damageForm.get('containerLocation'), 'ubicación')) return;
      else if (this.utils.errorMessagesCustomized(this.damageForm.get('damageType'), 'tipo de daño')) return;
      else if (this.utils.errorMessagesCustomized(this.damageForm.get('damageDimension'), 'dimensiones')) return;
      else if (this.utils.errorMessagesCustomized(this.damageForm.get('damageObs'), 'observaciones del daño', 8)) return;
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
      return;
    }
    this.containerStatus.push(this.damageForm.value);
    this.snackBarService.openSnackBar('Daño agregado exitosamente')
    this.damageForm.reset();
    formDirective.resetForm();
  }

  public downloadInspection() {
    this.spinner.show();
    this.cargoItemService.getInspectionPDF(this.data.cargoId, this.data.licensePlate, "container").subscribe(
      (response) => {
        this.spinner.hide();
        var file = new Blob([response], { type: ContentTypes.pdf });
        var downloadURL = window.URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `<${this.data.licensePlate}>${this.data.cargoContainer ? '(' + this.data.cargoContainer + ')' : ''} inspección-a-las-unidades-de-carga.pdf`;
        link.click();
      }, (error: HttpErrorResponse) => {
        this.spinner.hide()
        this.snackBarService.openSnackBar('Ocurrió un error al descargar la inspección de las unidades de carga', undefined, 'error');
      })
  }
}
