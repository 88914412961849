import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleMessages } from 'src/app/core/messages/vehicle-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Company } from 'src/app/core/interfaces/company';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { AccountService } from 'src/app/modules/account/account.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { AuthService } from 'src/app/core/services/authentication.service';
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { VehicleDocumentCatalog, VehicleDocumentRequired } from 'src/app/core/interfaces/vehicleDocuments';
import { Global } from 'src/app/core/resources/global';
import { Roles } from 'src/app/core/enums/roles.enum';
import { Companies } from 'src/app/core/resources/companies';
import { SecurityRules, ValidationRule } from 'src/app/core/interfaces/validation-rule';
import { Setting } from 'src/app/core/interfaces/saas-config';

@Component({
  selector: 'app-company-config',
  templateUrl: './company-config.component.html',
  styleUrls: ['./company-config.component.scss']
})
export class CompanyConfigComponent implements OnInit {

  @Input() company: Company;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  formSoatRtm: FormGroup;
  formVehicle: FormGroup;
  formResume: FormGroup;
  formIntegration: FormGroup;
  formSecurityPolicies: FormGroup;
  formFees: FormGroup;
  formVehicleDocuments: FormArray;
  listTypeDocuments: VehicleDocumentCatalog[] = [];
  listCurrentDocuments: VehicleDocumentRequired[] = [];
  entitiesFields = ['Admin', 'Driver', 'Owner'];
  entitiesDict = {
    Admin: "Administrador",
    Driver: "Conductor",
    Owner: "Propietario"
  }
  stateFields = ['arl', 'eps', 'driverLicense'];
  stateDict = {
    arl: "ARL",
    eps: "EPS",
    driverLicense: "Licencia de conducción",
    operationalAccreditation: "Acreditación de personal operativo"
  }
  securityFields = ['truora'];
  securityDict = {
    truora: "Estudio de seguridad"
  }
  rules: ValidationRule[] = [];
  listCompanies: BasicCompany[] = [];
  vehicleMessages = VehicleMessages;
  automaticConsignmentGeneration: FormControl = new FormControl();
  automaticManifestGeneration: FormControl = new FormControl();
  settingsToSend: Setting;
  formManifest: FormGroup;
  formConsignment: FormGroup;
  constructor(
    public utils: Utils,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    private companyService: CompaniesService,
    private accountService: AccountService,
    private dialog: MatDialog,
    private global: Global,
    private authService: AuthService,
    private permissionRole: PermissionRole,
    private vehicleService: VehiclesService,
  ) { }

  ngOnInit() {
    this.listCompanies = this.accountService.listRegisterCompanies();
    if (!this.company) this.company = this.authService.getCompany();
    this.initCompanyConfig();
  }

  getSaasSettings(){
    let settings = this.authService.getSettingsSaasCompany();
    if (settings && settings.length) {
      let automaticConsignment = settings.find(setting => setting.id === 'automaticConsignmentGeneration');
      let automaticManifest = settings.find(setting => setting.id === 'automaticManifestGeneration');

      if (automaticConsignment && this.utils.isDefined(automaticConsignment.active)) {
        this.formConsignment = new FormGroup({
          id: new FormControl(automaticConsignment.id),
          description: new FormControl(automaticConsignment.description),
          active: new FormControl(automaticConsignment.active),
        });
      }
      if (automaticManifest && this.utils.isDefined(automaticManifest.active)) {
        this.formManifest = new FormGroup({
          id: new FormControl(automaticManifest.id),
          description: new FormControl(automaticManifest.description),
          active: new FormControl(automaticManifest.active),
        });
      }
      
    }
  }

  private initCompanyConfig() {
    this.initForms();
    if (this.utils.isDefined(this.company.rtmAlertsAndBlocksEnabled) && !this.company.rtmAlertsAndBlocksEnabled) {
      this.formSoatRtm.get('daysToAlertExpirationRTM').disable();
      this.formSoatRtm.get('daysToBlockRTM').disable();
    }
    if (this.utils.isDefined(this.company.soatAlertsAndBlocksEnabled) && !this.company.soatAlertsAndBlocksEnabled) {
      this.formSoatRtm.get('daysToAlertExpirationSOAT').disable();
      this.formSoatRtm.get('daysToBlockSOAT').disable();
    }
  }

  private async initForms() {
    this.getSaasSettings();
    this.formSoatRtm = new FormGroup({
      daysToAlertExpirationSOAT: new FormControl(
        this.company && this.company.daysToAlertExpirationSOAT ? this.company.daysToAlertExpirationSOAT : null,
        [Validators.required, Validators.min(1)]
      ),
      daysToAlertExpirationRTM: new FormControl(
        this.company && this.company.daysToAlertExpirationRTM ? this.company.daysToAlertExpirationRTM : null,
        [Validators.required, Validators.min(1)]
      ),
      daysToBlockSOAT: new FormControl(
        this.company && this.company.daysToBlockSOAT ? this.company.daysToBlockSOAT : null,
        [Validators.required, Validators.min(1)]
      ),
      daysToBlockRTM: new FormControl(
        this.company && this.company.daysToBlockRTM ? this.company.daysToBlockRTM : null,
        [Validators.required, Validators.min(1)]
      ),
      soatAlertsAndBlocksEnabled: new FormControl(
        this.company && this.utils.isDefined(this.company.soatAlertsAndBlocksEnabled) ? this.company.soatAlertsAndBlocksEnabled : true
      ),
      rtmAlertsAndBlocksEnabled: new FormControl(
        this.company && this.utils.isDefined(this.company.rtmAlertsAndBlocksEnabled) ? this.company.rtmAlertsAndBlocksEnabled : true
      ),
    });
    this.formVehicle = new FormGroup({
      daysToDeactivateVehicle: new FormControl(this.company && this.utils.isDefined(this.company.daysToDeactivateVehicle) ? this.company.daysToDeactivateVehicle : '', [Validators.required, Validators.min(1)])
    });
    this.formResume = new FormGroup({
      allowCreatingWithoutTruora: new FormControl(this.company && this.utils.isDefined(this.company.allowCreatingWithoutTruora) ? !this.company.allowCreatingWithoutTruora : false, [])
    })
    this.formIntegration = new FormGroup({
      monitor: new FormControl(this.company && this.company.gpsConfiguration && this.company.gpsConfiguration.monitor && this.company && this.company.gpsConfiguration && this.company.gpsConfiguration.monitor && this.company.gpsConfiguration.monitor.active)
    });

    this.formFees = new FormGroup({
      feeToOwnedFleet: new FormControl(this.company && this.utils.isDefined(this.company.chargeOwnFleet) ? this.company.chargeOwnFleet : false)
    });
    if (this.hasPermissionEditVehicleDocuments) {
      await this.getDocumentTypes();
      await this.getCompanyDocumentTypes();
      this.formVehicleDocuments = new FormArray([]);
      this.listTypeDocuments.forEach((document) => {
        const currentDocument: VehicleDocumentRequired = this.listCurrentDocuments.find(doc => doc && (doc.id === document.name || doc.id === document.id));
        this.formVehicleDocuments.push(new FormGroup({
          id: new FormControl(document.id ? document.id : document.name),
          active: new FormControl(!!(currentDocument && currentDocument.active)),
          mandatory: new FormControl({ value: !!(currentDocument && currentDocument.mandatory), disabled: !(currentDocument && currentDocument.active) }),
          name: new FormControl(this.vehicleService.getVehicleDocumentName(document.name))
        }));
      });
    }
    this.initSecurityPolicyForm();
  }

  private async getDocumentTypes() {
    try {
      const types = await this.vehicleService.getDocumentTypes().toPromise();
      if (types && types.catalog && types.catalog.length)
        this.listTypeDocuments = [...this.global.listTypeDocuments, ...types.catalog];
      else
        this.listTypeDocuments = [...this.global.listTypeDocuments];
    } catch (error) {
      this.listTypeDocuments = [...this.global.listTypeDocuments];
    }
  }

  private async getCompanyDocumentTypes() {
    try {
      const types = await this.vehicleService.getCompanyDocumentTypes().toPromise();
      if (types && types.length)
        this.listCurrentDocuments = types;
      else
        this.listCurrentDocuments = [];
    } catch (e) {
      this.listCurrentDocuments = [];
    }
  }

  private async getValidationRules() {
    try { this.rules = await this.vehicleService.getValidationRules().toPromise() }
    catch (error) { this.rules = [] }
  }

  private async initSecurityPolicyForm() {
    await this.getValidationRules();
    if (this.hasEscortServicesCompany && !this.stateFields.includes("operationalAccreditation"))
      this.stateFields.push('operationalAccreditation');

    this.formSecurityPolicies = new FormGroup({});
    this.entitiesFields.forEach((entity: Roles) => {
      const stateControl = new FormGroup({});
      this.stateFields.forEach((state) => {
        const stateRule: ValidationRule = this.rules.find(rule => rule && rule.roleName === entity && rule.ruleType === 'state' && rule.ruleName === state);
        if (stateRule && stateRule.id)
          stateControl.addControl(state, new FormControl(!!stateRule.active));
      })
      const securityControl = new FormGroup({});
      this.securityFields.forEach((security) => {
        const securityRule: ValidationRule = this.rules.find(rule => rule && rule.roleName === entity && rule.ruleType === 'security' && rule.ruleName === security);
        if (securityRule && securityRule.id)
          securityControl.addControl(security, new FormControl(!!securityRule.active));
      });
      const entityControl = new FormGroup({});
      entityControl.addControl("state", stateControl);
      entityControl.addControl('security', securityControl);
      this.formSecurityPolicies.addControl(entity, entityControl);
    });
  }

  onChangeEnable($event, type: 'RTM' | 'SOAT') {
    if (type === 'RTM') {
      $event.value ? this.formSoatRtm.get('daysToAlertExpirationRTM').enable() : this.formSoatRtm.get('daysToAlertExpirationRTM').disable();
      $event.value ? this.formSoatRtm.get('daysToBlockRTM').enable() : this.formSoatRtm.get('daysToBlockRTM').disable();
    } else {
      $event.value ? this.formSoatRtm.get('daysToAlertExpirationSOAT').enable() : this.formSoatRtm.get('daysToAlertExpirationSOAT').disable();
      $event.value ? this.formSoatRtm.get('daysToBlockSOAT').enable() : this.formSoatRtm.get('daysToBlockSOAT').disable();
    }
  }

  onSubmitSoatRtm() {
    this.formSoatRtm.markAllAsTouched();
    if (this.formSoatRtm.invalid) {
      if (this.utils.errorMessagesCustomized(this.formSoatRtm.get('daysToAlertExpirationSOAT'), 'periodo de advertencia del SOAT', null, null, 1)) return;
      else if (this.utils.errorMessagesCustomized(this.formSoatRtm.get('daysToBlockSOAT'), 'periodo de bloqueo del SOAT', null, null, 1)) return;
      else if (this.utils.errorMessagesCustomized(this.formSoatRtm.get('daysToAlertExpirationRTM'), 'periodo de advertencia de la RTM', null, null, 1)) return;
      else if (this.utils.errorMessagesCustomized(this.formSoatRtm.get('daysToBlockRTM'), 'periodo de bloqueo de la RTM', null, null, 1)) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT);
        return;
      }
    }
    const body = this.formSoatRtm.getRawValue();
    body.daysToAlertExpirationSOAT = parseInt(body.daysToAlertExpirationSOAT);
    body.daysToBlockSOAT = parseInt(body.daysToBlockSOAT);
    body.daysToAlertExpirationRTM = parseInt(body.daysToAlertExpirationRTM);
    body.daysToBlockRTM = parseInt(body.daysToBlockRTM);

    if (body.soatAlertsAndBlocksEnabled && body.daysToAlertExpirationSOAT < body.daysToBlockSOAT) {
      this.snackBarService.openSnackBar("El periodo de advertencia debe ser mayor al periodo de bloqueo para el SOAT", undefined, 'alert');
      return
    }
    if (body.rtmAlertsAndBlocksEnabled && body.daysToAlertExpirationRTM < body.daysToBlockRTM) {
      this.snackBarService.openSnackBar("El periodo de advertencia debe ser mayor al periodo de bloqueo para la RTM", undefined, 'alert');
      return
    }
    this.updateAlertsAndBlocks(body);
  }

  updateAlertsAndBlocks(body) {
    this.spinner.show();
    this.companyService.updateAlertsAndBlocks(body, this.company.companyId).subscribe(
      (result: any) => {
        this.spinner.hide();
        this.refreshList.emit();
        this.snackBarService.openSnackBar(result && result.message ? result.message : 'Se actualizaron las alertas y bloqueos exitosamente');
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )
  }

  onSubmitVehicle() {
    this.formVehicle.markAllAsTouched();
    if (this.formVehicle.invalid) {
      if (this.utils.errorMessagesCustomized(this.formVehicle.get('daysToDeactivateVehicle'), 'máximo de días de inactividad', null, null, 1)) return;
      else {
        this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT);
        return;
      }
    }
    const body = {
      companyId: this.company.companyId,
      daysToDeactivateVehicle: Number(this.formVehicle.value.daysToDeactivateVehicle)
    };
    const deactivateVehicleObserver = {
      next: () => {
        this.spinner.hide();
        this.refreshList.emit();
        this.snackBarService.openSnackBar(FormMessages.DEACTIVATE_VEHICLE_SUCCESS);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.companyService.setDaysToDeactivateVehicle(body).subscribe(deactivateVehicleObserver);
  }

  onSubmitFees() {
    this.formFees.markAllAsTouched();
    const chargeOwnFleetObs = {
      next: () => {
        this.spinner.hide();
        this.refreshList.emit();
        this.snackBarService.openSnackBar(FormMessages.CHARGE_OWN_FLEET_SUCCESS);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.companyService.updateChargeOwnFleet(this.company.companyId, this.formFees.get('feeToOwnedFleet').value).subscribe(chargeOwnFleetObs);
  }

  get isPrincipalCompany(): boolean {
    return !!(this.listCompanies.length && this.company && this.company.companyId && this.listCompanies.some(company => company.nit === this.company.companyId));
  }

  onSubmitResume() {
    this.formResume.markAllAsTouched();
    const deactivateResumeObserver = {
      next: () => {
        this.spinner.hide();
        this.refreshList.emit();
        if (this.formResume && this.formResume.value && this.formResume.value.allowCreatingWithoutTruora)
          this.snackBarService.openSnackBar("Se activó el estudio de seguridad correctamente.");
        else
          this.snackBarService.openSnackBar("Se desactivó el estudio de seguridad correctamente.");
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      complete: () => {
        this.spinner.hide();
      }
    };
    this.spinner.show();
    this.companyService.setResume(this.company.companyId, !this.formResume.value.allowCreatingWithoutTruora).subscribe(deactivateResumeObserver);
  }

  onSubmitIntegration() {
    const value = this.formIntegration.get('monitor').value;

    const req = { companyId: this.company.companyId, status: (value ? 'ACTIVE' : 'INACTIVE') }

    this.companyService.setMonitorState(req).subscribe(
      () => {
        this.snackBarService.openSnackBar("Cambio guardado correctamente.", "Aceptar");
        this.refreshList.emit();
      },
      () => this.snackBarService.openSnackBar("Ha ocurrido un error.", "Aceptar", "error")

    );
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  hasValidationRules(role: Roles): boolean {
    return this.rules && this.rules.length && this.rules.some(rule => rule && rule.roleName === role);
  }

  onSubmitSecurityPolicies() {
    const policies: { [key in Roles.ADMIN | Roles.DRIVER | Roles.OWNER]: SecurityRules } = this.formSecurityPolicies.value;
    let body: ValidationRule[] = [];
    this.entitiesFields.forEach((entity: Roles) => {
      this.stateFields.forEach((state) => {
        if (policies[entity] && policies[entity] && this.utils.isDefined(policies[entity]['state'][state])) {
          const stateRule: ValidationRule = this.rules.find(rule => rule && rule.roleName === entity && rule.ruleType === 'state' && rule.ruleName === state);
          if (stateRule && stateRule.id) {
            stateRule['active'] = policies[entity]['state'][state];
            body.push(stateRule);
          }
        }
      })
      this.securityFields.forEach((security) => {
        if (policies[entity] && policies[entity] && this.utils.isDefined(policies[entity]['security'][security])) {
          const securityRule: ValidationRule = this.rules.find(rule => rule && rule.roleName === entity && rule.ruleType === 'security' && rule.ruleName === security);
          if (securityRule && securityRule.id) {
            securityRule['active'] = policies[entity]['security'][security];
            body.push(securityRule);
          }
        }
      })
    });
    this.spinner.show();
    this.companyService.updatePolicies(body).subscribe(() => {
      this.spinner.hide();
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_SUCCESS, 'x', 'success')
      this.initSecurityPolicyForm();
    }, () => {
      this.spinner.hide();
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error')
    });
  }


  onChangeMonitor(active: boolean) {
    if (active) {
      const config = new MatDialogConfig();
      config.data = {
        title: `¿Seguro desea activar la trazabilidad con múltiples GPS?`,
        description: `Recuerde que este servicio tiene costo adicional.`,
        labelButton1: 'Cancelar',
        labelButton2: 'Activar',
        hideBtnCancel: true,
        hideBtnConfirm: true,
      };
      config.maxHeight = ModalEnum.MAX_HEIGHT;
      config.width = ModalEnum.SMALL_WIDTH;
      config.maxWidth = ModalEnum.MAX_WIDTH;
      config.autoFocus = false;
      this.dialog
        .open(DialogComponent, config)
        .afterClosed()
        .subscribe(
          (result) => {
            if (result.refuse !== 'Activar') {
              this.formIntegration.get('monitor').setValue(false);
            }
          }
        )
    }
  }

  onChangeActiveDocument(document: FormGroup) {
    if (document && document.get('active') && document.get('mandatory')) {
      if (!document.get('active').value) {
        document.get('mandatory').setValue(false);
        document.get('mandatory').disable();
      } else document.get('mandatory').enable();
    }

  }

  onSubmitVehicleDocuments() {
    this.spinner.show();
    this.companyService.setRequiredVehicleDocuments(this.formVehicleDocuments.getRawValue()).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_UPDATE_SUCCESS);
        this.refreshList.emit();
      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error")
      }
    );
  }

  async onSubmitConsignmentAndManifestGeneration(type: 'automaticConsignmentGeneration' | 'automaticManifestGeneration') {
    let setting: Setting;
    if (type === 'automaticConsignmentGeneration') setting = this.formConsignment.getRawValue();
    else setting = this.formManifest.getRawValue();
    const changeSettingSaasCompanyObserver = {
      next: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_UPDATE_SUCCESS, undefined, 'success');
        this.authService.setSettingsSaasCompany();
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
      }
    }
    this.spinner.show();
    this.authService.changeSettingsSaasCompany(setting).subscribe(changeSettingSaasCompanyObserver)
  }

  get hasPermissionToIntegrateMonitor(): boolean {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.setupMonitorIntegration
    );
  }

  get hasPermissionToTruora(): boolean {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.truoraConfiguration
    );
  }

  get hasPermissionEditVehicleDocuments(): boolean {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.vehicleDocumentsConfiguration
    )
  }

  get hasPermissionToChangeFeeToOwnedFleet(): boolean {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.changeFeeToOwnedFleet
    );
  }

  get hasPermissionValidationRule(): boolean {
    return this.permissionRole.hasPermission(
      Permission.administration.module,
      Permission.administration.changeValidationRules
    )
  }
}
