import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Inject
} from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { CargoDetailService } from "./cargo-detail.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { NgxSpinnerService } from "ngx-spinner";
declare var google: any;
import 'firebase/database';
import 'firebase/storage';
import { Utils } from "src/app/core/resources/utils";
import { AccountService } from "../../account/account.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { GoogleService } from "src/app/core/services/google.service";
import { RegisterCargoPaymentComponent } from "../register-cargo-payment/register-cargo-payment.component";
import { ListCargoPaymentsComponent } from "../list-cargo-payments/list-cargo-payments.component";
import { Cargo } from "src/app/core/interfaces/cargo";
import { User } from "src/app/core/interfaces/user";
import { Company } from "src/app/core/interfaces/company";
import { Titles } from "src/app/core/resources/titles";
import { ModalComponent } from "src/app/shared/modal/modal.component";
import { PermissionRole } from "src/app/core/resources/permission-role";
import { RoleService } from "src/app/core/services/role.service";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { ShipingCostComponent } from "./components/shipping-cost/shipping-cost.component";
import { AdminUsersService } from "../../administration/admin-users/admin-users.service";
import { CargoItemService } from "../cargo-item/cargo-item.service";
import { CargoEvidenceComponent } from "../cargo-evidence/cargo-evidence.component";
import { VehiclesService } from "../../administration/vehicles/list-vehicles.service";
import { Vehicle } from "src/app/core/interfaces/vehicle";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { Permission } from "src/app/core/resources/permission";
import { OpenImgComponent } from "src/app/shared/open-img/open-img.component";
import ContentTypes from "src/app/core/resources/content-type-ext.json";
import { AdditionalCost } from "src/app/core/interfaces/additionalCost";
import { CargoRatingComponent } from "../cargo-rating/cargo-rating.component";
import { UpdateAdditionalCostCargoComponent } from "./components/update-additional-cost-cargo/update-additional-cost-cargo.component";
import { LoadDownloadCargoComponent } from "./components/load-download-cargo/load-download-cargo.component";
import { CargoCharacteristicComponent } from "./components/cargo-characteristic/cargo-characteristic.component";
import { CargoAdvancePercentageComponent } from "../cargo-advance-percentage/cargo-advance-percentage.component";
import { DriverCargoComponent } from "../driver-cargo/driver-cargo.component";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { VehicleCargo } from "src/app/core/interfaces/vehicle-cargo";
import { ManualCreationCargoService } from "../manual-creation-cargo/manual-creation-cargo.service";
import { CargoEditTypeVehicleComponent } from "../cargo-edit-type-vehicle/cargo-edit-type-vehicle.component";
import { environment } from "src/environments/environment";
import { AdditionalCostsHistoryComponent } from "../additional-costs-history/additional-costs-history.component";
import { AdditionalCostDeleted } from "src/app/core/interfaces/additionalCostDeleted";
import { CompanyNamePipe } from "src/app/core/pipe/companyName.pipe";
import { SiigoManager } from "src/app/core/managers/siigo.manager";
import { Catalog } from "src/app/core/interfaces/catalog";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { switchMap, take } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { CargoMessages } from "src/app/core/messages/cargo-messages.enum";
import { CargoTypeComponent } from "./components/cargo-type/cargo-type.component";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { FirebaseDatabase } from "src/app/core/services/firebaseDatabase.service";
import { CargoTracking } from "src/app/core/interfaces/cargoTracking.interface";
import { CargoManager } from "src/app/core/managers/cargo.manager";
import { DateManager } from "src/app/core/managers/date.manager";
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { StandardMapComponent } from "src/app/shared/standard-map/standard-map.component";
import { Global } from "src/app/core/resources/global";
import { GeneralDataCargoComponent } from "./components/general-data-cargo/general-data-cargo.component";
import { UserManager } from "src/app/core/managers/user.manager";
import { Roles } from "src/app/core/enums/roles.enum";
import { MapComponent } from "src/app/shared/map/map.component";
import { CargoShippingCostDialogComponent } from "../cargo-shipping-cost-dialog/cargo-shipping-cost-dialog.component";
import { Tag } from "src/app/core/interfaces/tag";
import { EndCargoCheckComponent } from "../end-cargo-check/end-cargo-check.component";
import { BasicCompany } from "src/app/core/interfaces/basicCompany";
import { CargoStateEnum } from "src/app/core/enums/cargoState.enum";
import { CargoComplimentsComponent } from "../cargo-compliments/cargo-compliments.component";
import { TravelExpensesService } from "src/app/core/services/travel-expenses.service";
import { ApprovalStateEnum, TravelExpense, TravelExpenseDetail } from "src/app/core/interfaces/travel-expense";
import { TravelExpensesComponent } from "../manual-creation-cargo/travel-expenses/travel-expenses.component";
import { LegalizeTravelExpensesComponent } from "../legalize-travel-expenses/legalize-travel-expenses.component";
import { ServiceType } from "src/app/core/interfaces/serviceType";
import { CargoConsignment } from "src/app/core/interfaces/cargoEditConsignment";
import { PlanningRoute } from "src/app/core/interfaces/planning-route";
import { PlanningRouteService } from "src/app/core/services/planning-route.service";
import { CargoService } from "src/app/core/services/cargo.service";
import { CargoPlanningRouteComponent } from "../cargo-planning-route/cargo-planning-route.component"
import { Fmt } from "src/app/core/messages/fmt";
import { ShippingCost } from "src/app/core/enums/shipping-cost.enum";
import { UploadDownloadMapService } from "../manual-creation-cargo/components/upload-download-map/uploadDownloadMap.service";
import { CostCenter } from "src/app/core/interfaces/costCenter";
import { CompaniesService } from "../../administration/companies/list-companies.service";
import { BasicResponse } from "src/app/core/interfaces/basicResponse";
import { AssignCostCenterComponent } from "../../administration/companies/assign-cost-center/assign-cost-center.component";

@Component({
  selector: "app-cargo-detail",
  templateUrl: "./cargo-detail.component.html",
  styleUrls: ["./cargo-detail.component.scss"],
  providers: [CargoDetailService, GoogleService, CurrencyPipe, CompanyNamePipe, DateFormatPipe, FirebaseDatabase, SiigoManager, UserManager, DatePipe],
})
export class CargoDetailComponent implements OnInit {
  @Input() cargoParam: Cargo;
  updateExtraService: boolean = false;
  permission = Permission;
  popupActive: string = "";
  showRouteGoogle: boolean;
  routeGoogle: Object;
  cargo: Cargo;
  negotiationValue: FormControl = new FormControl('', Validators.required);
  negotiationRequestValue: FormControl = new FormControl('', Validators.required);
  isNegotiation: boolean = false;
  isNegotiationRequest: boolean = false;
  listActive = "";
  isCollapsedAdvanceInformation = false;
  message: any;
  driver: User;
  secondDriver: User;
  vehicle: Vehicle;
  refFirebaseActive: any;
  pointsHistory: Array<Object>;
  downloadTime: string = "";
  tracking: boolean;
  letterRetirement: FormControl = new FormControl();
  @ViewChild(MapComponent, { static: false }) mapComponent: MapComponent;
  @ViewChild(GeneralDataCargoComponent, { static: false })
  GeneralDataCargoComponent: GeneralDataCargoComponent;
  @ViewChild(ShipingCostComponent, { static: false })
  shipingCostComponent: ShipingCostComponent;
  @ViewChild(LoadDownloadCargoComponent, { static: false })
  loadDownloadCargoComponent: LoadDownloadCargoComponent;
  @ViewChild(CargoCharacteristicComponent, { static: false })
  CargoCharacteristicComponent: CargoCharacteristicComponent;
  @ViewChild(CargoTypeComponent, { static: false })
  CargoTypeComponent: CargoTypeComponent;
  @ViewChild(DriverCargoComponent, { static: false })
  DriverCargoComponent: DriverCargoComponent;
  @ViewChild(AssignCostCenterComponent, { static: false })
  assignCostCenterComponent: AssignCostCenterComponent;
  @ViewChild("modalRefuseCargo", { static: false })
  modalRefuseCargo: ElementRef;
  @ViewChild("modalConfirmNegotiationCargo", { static: false })
  modalConfirmNegotiationCargo: ElementRef;
  @ViewChild("modalConfirmRefuseRequest", { static: false })
  modalConfirmRefuseRequest: ModalComponent;
  @ViewChild("modalConfirmAcceptRequest", { static: false })
  modalConfirmAcceptRequest: ModalComponent;
  @ViewChild("modalConfirmAcceptCargo", { static: false })
  modalConfirmAcceptCargo: ModalComponent;
  @ViewChild("modalNegotiationRequest", { static: false })
  modalNegotiationRequest: ElementRef;
  @ViewChild("modalRegisterCargoPayment", { static: false })
  modalRegisterCargoPayment: RegisterCargoPaymentComponent;
  @ViewChild("modalListCargoPayments", { static: false })
  modalListCargoPayments: ListCargoPaymentsComponent;
  @ViewChild("modalCargoPDF", { static: false }) modalCargoPDF;
  @ViewChild("modalErrorRNDCApproveCargo", { static: false })
  modalErrorRNDCApproveCargo: ElementRef;
  instanceModalRefuseCargo: NgbModalRef;
  instanceModalNegotiationRequest: NgbModalRef;
  instanceModalConfirmNegotiationCargo: NgbModalRef;
  instanceModalConfirmRefuseRequest: NgbModalRef;
  company: Company;
  tags: Tag[] = [];
  cargoTags: string[] = [];
  tagControl = new FormControl('');
  zoneControl = new FormControl('', Validators.required);
  isSubCompany: boolean = false;
  isParentCompany: boolean = false;
  cargoPDF: any;
  errorRNDC: string[] = [];
  cargoEdit = {
    seal: "",
    container: "",
  };
  cleanCargo;
  isRootNit: boolean = false;
  editEnabled = false;
  isDefinedAdditionalCostDeleted = false;
  isAdditionalCostsDeleted = false;
  additionalCostsDeleted: Array<AdditionalCostDeleted> = [];
  destinationsWeights = [];
  cargoAmountEdit = {
    editing: false,
    value: 0
  };
  public listAdditionalService: AdditionalCost[] = [];
  public environment = environment;
  mapOptions: OptionsAutocomplete = {};
  locationsCargos: Object = {};
  tempListLocations: Object = {};
  listCompanies: BasicCompany[] = [];
  @ViewChild(StandardMapComponent, { static: false }) standardMapComponent: StandardMapComponent;
  travelExpenses: Array<TravelExpense> = [];
  minUtility: number = ShippingCost.MINIMUN_UTILITY;
  manifestErrors: string[] = [];
  ownerCompany: Company;
  constructor(
    private router: Router,
    private currencyPipe: CurrencyPipe,
    public service: CargoDetailService,
    public authService: AuthService,
    public roleService: RoleService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public utils: Utils,
    public titles: Titles,
    private accountService: AccountService,
    private modalService: NgbModal,
    private googleService: GoogleService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private adminUserService: AdminUsersService,
    public cargoItemService: CargoItemService,
    private vehiclesService: VehiclesService,
    private companyNamePipe: CompanyNamePipe,
    private dateFormatPipe: DateFormatPipe,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<CargoDetailComponent>,
    private permissionRole: PermissionRole,
    public manualCreationCargoService: ManualCreationCargoService,
    private firebaseDatabase: FirebaseDatabase,
    private siigoManager: SiigoManager,
    private userManager: UserManager,
    public global: Global,
    private cargoManager: CargoManager,
    private planningRouteService: PlanningRouteService,
    private cargoService: CargoService,
    private travelExpensesService: TravelExpensesService,
    public uploadDownloadMapService: UploadDownloadMapService,
    public companiesService: CompaniesService
  ) {
    this.company = this.authService.getCompany();
    this.isRootNit = this.authService.userIsFromRootNit();

    if (
      this.company &&
      this.company.parentCompany &&
      this.company.parentCompany.length
    ) {
      this.isSubCompany = true;
    } else if (
      this.company &&
      this.company.subCompanies &&
      this.company.subCompanies.length
    ) {
      this.isParentCompany = true;
    }
    if (this.dialogParams && this.dialogParams.cargo) {
      this.cargoParam = this.dialogParams.cargo;
    }
    this.getTags();
  }

  ngOnInit() {
    this.listCompanies = this.accountService.listRegisterCompanies();
    if (sessionStorage.getItem("_lastList")) {
      this.listActive = sessionStorage.getItem("_lastList").toString();
      if (sessionStorage.getItem("_lastList") === "loadsInNegotiation") {
        this.validateDataCargoNegotiation();
      }

      if (sessionStorage.getItem("_lastList") === "cargoRequestNegotiation") {
        this.validateDataCargoRequestNegotiation();
      }
    }
    if (sessionStorage.getItem("_lastList") !== "loadsInNegotiation") {
      this.paramsSubscribe();
    }
  }

  paramsSubscribe() {
    this.route.data.subscribe(
      (data: { aditionalServicesResolver: AdditionalCost[] }) => {
        if (data && data.aditionalServicesResolver) {
          this.listAdditionalService = data.aditionalServicesResolver;
        }
      }
    );

    const params = this.route.snapshot.params;
    if (!this.utils.objIsEmpty(params)) {
      if (this.utils.isDefined(params.consecutive)) {
        this.checkTypeOfService(params.consecutive.toString());
      } else {
        this.snackBarService.openSnackBar(
          "Ocurrió un error intentando traer el detalle",
          undefined,
          "error"
        );
        this.goToList();
      }
    } else if (this.authService.cargoActive.cargo) {
      this.setCargoDetail(this.authService.cargoActive.cargo);
    } else if (this.utils.isDefined(this.cargoParam)) {
      this.setCargoDetail(this.cargoParam);
    } else {
      this.snackBarService.openSnackBar(
        "Ocurrió un error intentando traer el detalle",
        undefined,
        "error"
      );
      this.goToList();
    }
  }

  validateDataCargoNegotiation() {
    if (this.authService.cargoActive.cargo) {
      this.isNegotiation = true;
      this.setCargoDetail(this.authService.cargoActive.cargo);
    } else {
      this.isNegotiation = false;
      this.cargo = null;
      this.showRouteGoogle = false;
      this.snackBarService.openSnackBar(
        "Ocurrió un error intentando traer el detalle"
      );
      this.goToList();
    }

    if (!this.authService.cargoActive.cargo) {
      this.goToList();
    }
  }

  validateDataCargoRequestNegotiation() {
    if (this.authService.cargoActive.cargo) {
      this.isNegotiationRequest = true;
      this.setCargoDetail(this.authService.cargoActive.cargo);
    } else {
      this.isNegotiationRequest = false;
      this.cargo = null;
      this.showRouteGoogle = false;
      this.snackBarService.openSnackBar(
        "Ocurrió un error intentando traer el detalle"
      );
      this.goToList();
    }

    if (!this.authService.cargoActive.cargo) {
      this.goToList();
    }
  }

  getListName() {
    let listName = "";
    switch (this.listActive) {
      case "loadingRoutes":
        listName = "servicios en ruta";
        break;
      case "latestLoads":
        listName = "últimos servicios";
        break;
      case "scheduledLoads":
        listName = "servicios programados";
        break;
      case "loadsInNegotiation":
        listName = "servicios en negociación";
        break;
      case "loadsFinished":
        listName = "servicios terminados";
        break;
      case "expired":
        listName = "servicios vencidos";
        break;
      case "deleted":
        listName = "servicios eliminados";
        break;
      case "approvePayments":
        listName = "aprobación de pagos";
        break;
      case "paymentAdvanceCargo":
      case "paymentExtraAdvanceCargo":
      case "paymentAdditionalCostsCargo":
      case "paymentBalanceCargo":
        listName = "pagos";
        break;
      case "charges":
        listName = "cobros pendientes";
        break;
      case "request":
        listName = "Solicitudes";
        break;
      case "cargoRequestNegotiation":
        listName = "Negociación B2B";
        break;
      case "serviceRequests":
        listName = "Solicitudes de servicio";
        break;
    }
    return listName;
  }

  setCargoDetail(cargoDetail: Cargo) {
    this.cargo = cargoDetail;
    this.travelExpensesService.byCargoId(this.cargo.id).subscribe(
      (expenses: Array<TravelExpense>) => {
        if (expenses && expenses.length)
          this.travelExpenses = expenses;
        else
          this.travelExpenses = [];
      }, () => this.travelExpenses = []
    );
    this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
    this.setDestinationsAddressesWeights(cargoDetail);
    this.downloadTime =
      cargoDetail.cargoFeature.uploadDownload.destination[0].addresses[0].time;
    this.showRouteGoogle = true;

    this.cargoEdit.container = this.cargo.container;
    this.cargoEdit.seal = this.cargo.seal;

    if (this.cargo.driver)
      this.getDetailDriver(this.cargo.driver);
    if (this.cargo.secondDriver && this.utils.getNestedValue(this.cargo, 'cargoModel.tripType.category') === 'Nacional')
      this.getDetailDriver(this.cargo.secondDriver, null, true);
    if (this.cargo.licensePlate)
      this.getDetailVehicle(this.cargo.licensePlate);
    if (this.cargo.idCompany) this.getOwnerCompany();
    if (this.cargo.labels && this.cargo.labels.length)
      this.cargoTags = this.cargo.labels;
    if (this.cargo.zone)
      this.zoneControl.setValue(this.cargo.zone);
    // tslint:disable-next-line: radix
    if (this.cargo.distancy && parseInt(this.cargo.distancy.toString()) === 0) {
      this.calculateDistanceCargo(this.cargo);
    }
    this.getDataTracking();
  }

  onRefreshCargo($event) {
    this.checkTypeOfService($event);
  }

  getCargoDetailById(consecutive: string, callback?: any) {
    this.spinner.show();
    this.service.detailCargoByConsecutive(consecutive).subscribe(
      (data: Cargo) => {
        this.spinner.hide();
        if (data && data.id) {
          if (data.manifestError && data.manifestError.error) this.processManifestErrors(data.manifestError.error)
          this.setLoadData(data, callback);
        } else {
          this.cargo = null;
          this.showRouteGoogle = false;
          this.snackBarService.openSnackBar(
            "Ocurrió un error intentando traer el detalle", undefined, 'error'
          );
          this.goToList();
        }
      },
      (error) => {
        this.spinner.hide();
        this.cargo = null;
        this.showRouteGoogle = false;
        this.snackBarService.openSnackBar(
          "Ocurrió un error intentando traer el detalle", undefined, 'error'
        );
        this.goToList();
      }
    );
  }

  private setLoadData(data: Cargo, callback?: any) {
    this.cargo = data;
    this.travelExpensesService.byCargoId(this.cargo.id).subscribe(
      (expenses: Array<TravelExpense>) => {
        if (expenses && expenses.length)
          this.travelExpenses = expenses
        else
          this.travelExpenses = [];
      }, () => this.travelExpenses = []
    );
    this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
    this.processRouteGoogle(data.id);
    this.setInfoStartEnd();
    this.setDestinationsAddressesWeights(data);
    this.cargoEdit.container = this.cargo.container;
    this.cargoEdit.seal = this.cargo.seal;
    if (data.driver) {
      this.getDetailDriver(data.driver, callback);
    } else {
      this.driver = null;
    }
    if (this.cargo.idCompany) this.getOwnerCompany();
    if (data.secondDriver && this.utils.getNestedValue(data, 'cargoModel.tripType.category') === 'Nacional') this.getDetailDriver(data.secondDriver, callback, true);
    else this.secondDriver = null;
    if (data.licensePlate) {
      this.getDetailVehicle(data.licensePlate);
    } else {
      this.vehicle = null;
    }
    if (data.labels && data.labels.length)
      this.cargoTags = data.labels;
    // tslint:disable-next-line: radix
    if (data.zone)
      this.zoneControl.setValue(data.zone);
    if (
      this.cargo.distancy &&
      parseInt(this.cargo.distancy.toString()) === 0
    ) {
      this.calculateDistanceCargo(this.cargo);
    }
    this.getDataTracking();
    this.showRouteGoogle = true;
  }

  private getServiceRequestByConsecutive(consecutive: string) {
    const serviceRequestsObserver = {
      next: (data: Cargo) => {
        this.spinner.hide();
        if (data && data.id) this.setLoadData(data);
        else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');

      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    };
    this.spinner.show();
    this.service.detailServiceRequestByConsecutive(consecutive).subscribe(serviceRequestsObserver);
  }

  private checkTypeOfService(consecutive: string) {
    if (this.listActive === "serviceRequests") this.getServiceRequestByConsecutive(consecutive);
    else this.getCargoDetailById(consecutive);
  }

  private getTags() {
    this.manualCreationCargoService.getLabelTags().subscribe(
      (success) => {
        if (success && success.length) this.tags = success;
      },
      (error) => {
      }
    )
  }

  public selectTag($event) {
    this.tagControl.setValue('');
    const tag: string = $event.value;
    if (!this.cargoTags.includes(tag)) {
      this.cargoTags.push(tag);
      this.service.updateCargoLabels(this.cargo.id, this.cargoTags).subscribe(
        (response) => {
          if (response) {
            this.snackBarService.openSnackBar("Etiqueta agregada correctamente");
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso",
            undefined,
            "alert"
          );
        }
      );
    }

  }

  public clearTag(index: number) {
    this.cargoTags.splice(index, 1);
    this.service.updateCargoLabels(this.cargo.id, this.cargoTags).subscribe(
      (response) => {
        if (response) {
          this.snackBarService.openSnackBar("Etiqueta eliminada correctamente");
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso",
          undefined,
          "alert"
        );
      }
    );
  }
  get canEditTags(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoTags);
  }

  savePublishZone() {
    if (this.zoneControl.invalid)
      return this.snackBarService.openSnackBar("Debes seleccionar la zona de publicación del servicio", undefined, 'alert');
    this.spinner.show();
    this.service.updateCargoZone(this.cargo.id, this.zoneControl.value).subscribe(
      (response: Cargo) => {
        this.spinner.hide();
        if (response && response.id) {
          this.snackBarService.openSnackBar("Configuración actualizada correctamente");
          this.setCargoDetail(response);
        } else {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
          this.zoneControl.setValue(this.cargo.zone ? this.cargo.zone : 'private');
        }

      },
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error");
        this.zoneControl.setValue(this.cargo.zone ? this.cargo.zone : 'private');
      }
    );
  }

  openCargoCompliments(indexDestination: number, indexAddress: number) {
    const hasFullEditPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoCompliments);
    const hasPartialEditPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoComplimentRequirements);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      indexDestination,
      indexAddress
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    if (hasFullEditPermission || hasPartialEditPermission)
      dialogConfig.disableClose = true;
    this.dialog.open(CargoComplimentsComponent, dialogConfig);
  }

  get canCheckCompliments(): boolean {
    const hasFullEditPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoCompliments);
    const hasPartialEditPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargoComplimentRequirements);
    const hasReadPermission = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readCargoCompliments);
    return (hasFullEditPermission || hasPartialEditPermission || hasReadPermission)
      && this.cargo && [this.cargoStateEnum.END_SERVICE, this.cargoStateEnum.START_SERVICE].includes(this.cargo.state);
  }

  editCargoValue(editing: boolean) {
    this.cargoAmountEdit = {
      editing,
      value: editing ? this.cargo.cargoFeature.cargoMeasure.amount : 0
    }
  }

  confirmCargoValue() {
    if (this.cargoAmountEdit.value < 1) {
      this.snackBarService.openSnackBar(CargoMessages.CARGO_AMOUNT_LESS_THAN_ZERO, undefined, 'alert');
      return;
    }
    let data = {
      cargoFeature: this.utils.clone(this.cargo.cargoFeature)
    }
    data.cargoFeature.cargoMeasure.amount = this.cargoAmountEdit.value;
    this.spinner.show();
    this.service.completeUpdateRequest(data, this.cargo).subscribe(
      () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.SUCCESS_EDIT_CARGO_AMOUNT);
        this.checkTypeOfService(this.cargo.consecutive.toString());
        this.cargoAmountEdit = {
          editing: false,
          value: 0
        }
      }, () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.ERROR_CARGO_AMOUNT, undefined, 'error');
      }
    )
  }

  setDestinationsAddressesWeights(cargo: Cargo): void {
    this.destinationsWeights = []
    if (cargo && cargo.cargoFeature && cargo.cargoFeature.uploadDownload && cargo.cargoFeature.uploadDownload.destination) {
      cargo.cargoFeature.uploadDownload.destination.forEach(city => {
        let cityArr = [];
        if (city && city.addresses) {
          city.addresses.forEach(address => {
            address.cargoMeasure && address.cargoMeasure.totalWeigth ?
              cityArr.push({ editing: false, weight: address.cargoMeasure.totalWeigth }) :
              cityArr.push({ editing: false, weight: 0 });
          })
        }
        this.destinationsWeights.push(cityArr)
      })
    }
  }

  rateCargo() {
    const idSessionCargo = this.authService
      .getUserSession()
      .information.document;
    const numberDocumentCreator = this.cargo.numberDocumentCreatorLoad;
    const companyUser = this.authService.getUserSession().clientCompanyId;

    if (
      idSessionCargo == numberDocumentCreator ||
      companyUser == this.cargo.idCompany
    ) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        cargo: this.cargo,
        enableClose: true
      };
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialogRef = this.dialog.open(CargoRatingComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((response) => {
        if (response && response.state)
          this.checkTypeOfService(this.cargo.consecutive.toString());
      });
    } else {
      this.snackBarService.openSnackBar(
        "El servicio debe ser calificado por su creador o por su compañia propietaria",
        undefined,
        "alert"
      );
    }
  }

  setIconEditWeight(indexCity, indexaddress, value) {
    try {
      this.destinationsWeights[indexCity][indexaddress].weight = this.cargo.cargoFeature.uploadDownload.destination[indexCity].addresses[indexaddress].cargoMeasure.totalWeigth;
      this.destinationsWeights[indexCity][indexaddress].editing = value;
    } catch (error) {
      this.snackBarService.openSnackBar("No es posible editar el peso de esta remesa", undefined, "error");
    }
  }

  showEditWeightIcon(indexCity, indexaddress): boolean {
    return this.destinationsWeights && this.destinationsWeights[indexCity]
      && this.destinationsWeights[indexCity][indexaddress]
      && this.destinationsWeights[indexCity][indexaddress].editing;
  }

  editWeight(indexCity: number, indexaddress: number) {
    if (!this.destinationsWeights[indexCity][indexaddress].weight || this.destinationsWeights[indexCity][indexaddress].weight <= 0) {
      this.snackBarService.openSnackBar(CargoMessages.WEIGHT_LESS_THAN_ZERO, undefined, "alert");
      return;
    }
    let observableVehicleId;
    const $observerVehicleId = {
      next: (data: Catalog) => {
        if (data.catalog && data.catalog.length > 0) {
          let vehicleId = (data.catalog as VehicleCargo[]).find((item: VehicleCargo) => {
            return item.name === this.cargo.cargoFeature.vehicleType.name;
          });
          if (vehicleId && vehicleId.name) {
            return this.manualCreationCargoService.getVehicleWeightById(vehicleId.id);
          }
        }
        return of(null);
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        return EMPTY;
      },
      finally: () => {
        this.spinner.hide();
        observableVehicleId.unsubscribe();
      }
    };

    let observableDataVehicle;

    const $observerDataVehicle = {
      next: (dataVehicle) => {

        let destinationsClone = this.utils.clone(this.cargo.cargoFeature.uploadDownload.destination);
        destinationsClone[indexCity].addresses[indexaddress].cargoMeasure.totalWeigth = this.destinationsWeights[indexCity][indexaddress].weight;
        if (this.destinationsWeights[indexCity][indexaddress].weight
          && this.destinationsWeights[indexCity][indexaddress].weight > 0 && this.manualCreationCargoService.isValidWeight(dataVehicle, destinationsClone)) {
          let body = {
            cargoId: this.cargo.id,
            destinationId: this.cargo.cargoFeature.uploadDownload.destination[indexCity].id,
            addressId: this.cargo.cargoFeature.uploadDownload.destination[indexCity].addresses[indexaddress].id,
            weight: this.destinationsWeights[indexCity][indexaddress].weight
          }
          return this.service.updateWeightConsignment(body);
        }
        else {
          // this.snackBarService.openSnackBar(CargoMessages.WEIGHT_LESS_THAN_ZERO, undefined, "alert");
          this.manualCreationCargoService.showMessageInvalidWeight(dataVehicle.weight);
          return of(null);
        }
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        return EMPTY;
      },
      finally: () => {
        this.spinner.hide();
        observableDataVehicle.unsubscribe();
      }
    };
    this.spinner.show();
    const observableVehicleType = this.manualCreationCargoService.getVehicleType()
      .pipe(switchMap((data: Catalog) => observableVehicleId = $observerVehicleId.next(data)))
      .pipe(switchMap((dataVehicle) => observableDataVehicle = $observerDataVehicle.next(dataVehicle)))
      .subscribe({
        next: (data: Cargo) => {
          this.spinner.hide();
          if (data) {
            this.setCargoDetail(data);
            this.snackBarService.openSnackBar(CargoMessages.SUCCESS_EDIT_WEIGHT_CONSIGNMENT);
          }
        },
        error: (error) => {
          this.spinner.hide();
          if (error.Error && typeof error.Error === 'string') this.snackBarService.openSnackBar(error.Error, undefined, "error");
          else if (error.error && error.error.ErrorRNDC && typeof error.error.ErrorRNDC === 'string') this.snackBarService.openSnackBar(error.error.ErrorRNDC, undefined, "error");
          else this.snackBarService.openSnackBar(CargoMessages.ERROR_WEIGHT_CONSIGNMENT, undefined, "error");
          this.setIconEditWeight(indexCity, indexaddress, false);
        },
        complete: () => {
          this.spinner.hide();
          observableVehicleType.unsubscribe();
        }
      });
  }

  private getDataTracking(): void {
    this.firebaseDatabase.getOldDataRealTime(this.cargo.id)
      .then((data: CargoTracking) => {
        return data.listLocations;
      })
      .then((listOldLocations) => {
        this.refFirebaseActive = this.firebaseDatabase.getRefDatabase(`cargo/${this.cargo.id}/tracking`);
        this.firebaseDatabase.lisenerDataReference(this.refFirebaseActive)
          .then((data) => {
            const dataTracking = this.firebaseDatabase.getDataTrackingSnapshot(data);
            const listLocations = [...listOldLocations, ...dataTracking.listLocations];
            this.tracking = listLocations.length ? false : true;
            let path = listLocations;
            let start = listLocations[0];
            let end = listLocations[listLocations.length - 1];
            if (listLocations.length && listLocations.length < 25) {
              this.setInfoStartEndReal(start, end);
              this.tempListLocations[this.cargo.id] = { path, showRouteGoogle: true };
              this.locationsCargos = this.tempListLocations;
              this.mapOptionsConfig({
                initialRealRoute: true,
                polyline: this.locationsCargos
              });
              this.standardMapComponent.processAnyGoogleRoute(listLocations, 'listLocationsSimulated');
            } else if (listLocations.length) {
              this.setInfoStartEndReal(start, end);
              this.tempListLocations[this.cargo.id] = { path, showRouteGoogle: true, showPolyline: true };
              this.locationsCargos = this.tempListLocations;
              this.mapOptionsConfig({
                initialRealRoute: true,
                polyline: this.locationsCargos
              });
            }
          });
      });
  }

  getDetailDriver(idDriver, callback?: any, secondDriver: boolean = false) {
    this.accountService.validateEntity(1, idDriver).subscribe(
      (data: User) => {
        if (this.utils.isDefined(data)) {
          this[secondDriver ? 'secondDriver' : 'driver'] = this.userManager.sanitizeUser(data, Roles.DRIVER);
          if (callback) {
            callback();
          }
          let storage = AuthService.fStorage;
          if (
            this.utils.isDefined(this[secondDriver ? 'secondDriver' : 'driver']) &&
            this.utils.isDefined(this[secondDriver ? 'secondDriver' : 'driver'].profilePicture)
          ) {
            let pathReference = storage.ref(this[secondDriver ? 'secondDriver' : 'driver'].profilePicture);
            pathReference.getDownloadURL().then(
              (data) => {
                this[secondDriver ? 'secondDriver' : 'driver'].profilePicture = data;
              },
              (error) => {
                this[secondDriver ? 'secondDriver' : 'driver'].profilePicture = "";
              }
            );
          }
        } else {
          this[secondDriver ? 'secondDriver' : 'driver'] = {
            information: {
              document: idDriver,
            },
          };
        }
      },
      (error) => {
        this[secondDriver ? 'secondDriver' : 'driver'] = {
          information: {
            document: idDriver,
          },
        };
      }
    );
  }

  getOwnerCompany(){
    const ownerCompanyObserver = {
      next: (data: Company) => {
        this.spinner.hide();
        if (data && data.companyId) this.ownerCompany = data;
      },
      error: (error) => {
        this.spinner.hide();
        console.error(error);
      }
    }
    this.spinner.show();
    this.accountService.validateEntity(2, this.cargo.idCompany).subscribe(ownerCompanyObserver);
  }

  getDetailVehicle(licensePlate) {
    this.vehiclesService.getVehicle(licensePlate).subscribe(
      (success: any) => {
        if (success) {
          this.vehicle = success;
        } else {
          this.vehicle = null;
        }
      },
      (error) => {
        this.vehicle = null;
      }
    );
  }

  getUrlImgProfile(dataImg) {
    return (
      "https://firebasestorage.googleapis.com/v0/b/teclogi-2ec2f.appspot.com/o/" +
      dataImg +
      "?alt=media"
    );
  }

  getConsignments(iDestination, iAddress) {
    try {
      return this.cargo.cargoFeature.uploadDownload.destination[
        iDestination
      ].addresses[iAddress].consignments
        .toString()
        .replace(/,/g, " - ");
    } catch (e) {
      return "-";
    }
  }

  getComplimentDate(destinationIndex, addressIndex): string {
    const dateTimeDownload = this.utils.getNestedValue(this.cargo,
      `cargoFeature
      .uploadDownload
      .destination
      .${destinationIndex}
      .addresses
      .${addressIndex}
      .dateTimeDownload
      .0`
    );

    return dateTimeDownload;
  }


  reverseCompliment(destinationIndex: string | number, addressIndex: string | number) {
    const destinationId = this.utils.getNestedValue(this.cargo, `cargoFeature.uploadDownload.destination.${destinationIndex}.id`);
    const addressId = this.utils.getNestedValue(this.cargo, `cargoFeature.uploadDownload.destination.${destinationIndex}.addresses.${addressIndex}.id`);
    const consignmentId = this.utils.getNestedValue(this.cargo, `cargoFeature.uploadDownload.destination.${destinationIndex}.addresses.${addressIndex}.consignments.0`);

    if (consignmentId === undefined) {
      return;
    }

    this.cargoItemService
      .reverseCompliment(this.cargo.id, consignmentId, destinationId, addressId)
      .subscribe({
        next: () => {
          this.snackBarService.openSnackBar('Operación exitosa', 'x', 'success');
          this.onRefreshCargo(this.cargo.consecutive);
        },
        error: () => {
          this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, 'x', 'error');
        }
      });
  }

  getStateNegotiation(status) {
    let titleStatus = "Esperando respuesta del conductor";
    if (status === "Waiting creator") {
      titleStatus = "Esperando respuesta de cliente";
    } else if (status === "Waiting driver") {
      titleStatus = "Esperando respuesta del conductor";
    } else if (status === "Accepted") {
      titleStatus = "Aceptada";
    }
    return titleStatus;
  }

  getModalElement(modalName: string) {
    switch (modalName) {
      case "modalRefuseCargo":
        this.negotiationValue.setValue("");
        return this.modalRefuseCargo;
      case "modalConfirmNegotiationCargo":
        return this.modalConfirmNegotiationCargo;
      case "modalNegotiationRequest":
        this.negotiationRequestValue.setValue("");
        return this.modalNegotiationRequest;
      case "modalConfirmRefuseRequest":
        return this.modalConfirmRefuseRequest;
    }
  }

  getModalInstance(modalName: string) {
    switch (modalName) {
      case "modalRefuseCargo":
        return this.instanceModalRefuseCargo;
      case "modalConfirmNegotiationCargo":
        return this.instanceModalConfirmNegotiationCargo;
      case "modalNegotiationRequest":
        return this.instanceModalNegotiationRequest;
      case "modalConfirmRefuseRequest":
        return this.instanceModalConfirmRefuseRequest;
    }
  }

  openModal(modalName: string) {
    let optionsModal = {
      centered: true,
    };
    let modalInstance = null;

    switch (modalName) {
      case "modalRefuseCargo":
        modalInstance = this.instanceModalRefuseCargo;
        break;
      case "modalNegotiationRequest":
        modalInstance = this.instanceModalNegotiationRequest;
        break;
      case "modalConfirmRefuseRequest":
        modalInstance = this.instanceModalConfirmRefuseRequest;
        break;
      case "modalConfirmNegotiationCargo":
        optionsModal["backdrop"] = "static";
        optionsModal["keyboard"] = false;
        optionsModal["centered"] = true;
        modalInstance = this.instanceModalConfirmNegotiationCargo;
        break;
    }

    modalInstance = this.modalService.open(
      this.getModalElement(modalName),
      optionsModal
    );
  }

  closeModal(modalName: string) {
    if (this.getModalInstance(modalName)) {
      this.getModalInstance(modalName).close();
    }
  }

  goToList() {
    const paymentCargoList = [
      "paymentAdvanceCargo",
      "paymentExtraAdvanceCargo",
      "paymentAdditionalCostsCargo",
      "paymentBalanceCargo"
    ]
    if (paymentCargoList.includes(this.listActive))
      this.router.navigate(["cargo/list/paymentCargo"]);
    else if (this.listActive === "serviceRequests")
      this.router.navigate(["cargo/list/service-requests"]);
    else
      this.router.navigate(["cargo/list/" + this.listActive]);

  }

  get canEditCargo(): boolean {
    return ([
      CargoStateEnum.REQUEST,
      this.cargoStateEnum.CREATED].includes(this.cargo.state)
      || [this.cargoStateEnum.REQUEST].includes(this.cargo.requestState)
    );
  }

  get canEditPublicationZone(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editPublicationZone);
  }

  acceptCargo() {
    this.spinner.show();
    const params = {
      cargoId: this.cargo.id,
      applicantId: this.authService.cargoActive.negotiation.driver,
    };

    this.service.acceptCargo(params).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          if (data['message']) {
            this.snackBarService.openSnackBar(
              data['message'], undefined, 'error'
            );
            this.closeModalAcceptCargo();
          } else {
            this.snackBarService.openSnackBar("Su servicio fue negociado");
            this.authService.cargoActive.negotiation = {
              state: null,
              driver: null,
              negotiatedValueCreator: null,
              negotiatedValueDriver: null,
            };
            this.isNegotiation = false;
            this.closeModalAcceptCargo();
            this.goToList();
          }

        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso", undefined, 'error'
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso", undefined, 'error'
        );
      }
    );
  }

  onConfirmAcceptCargo() {
    this.acceptCargo();
  }

  get isRequest(): boolean {
    return this.isNegotiationRequest && this.listActive === 'cargoRequestNegotiation' && this.isParentCompany &&
      this.getStateNegotiation(this.authService.cargoActive.negotiationRequest.state) !== 'Aceptada';
  }

  get isOffer(): boolean {
    return this.isNegotiation && this.getStateNegotiation(this.authService.cargoActive.negotiation.state) !== 'Aceptada'
      && this.cargo.cargoFeature.productType.type !== 'FixedCargo';
  }

  acceptOffer() {
    this.isRequest ? this.openModalAcceptRequest() : this.openModalAcceptCargo();
  }

  rejectOffer() {
    this.isRequest ? this.openModalRefuseRequest() : this.openModal('modalRefuseCargo');
  }

  createCargo() {
    let alertMessage = '';

    let isRateOrCostZero = !!(this.cargo && this.cargo.shippingCost && (this.cargo.shippingCost.rate === 0 || this.cargo.shippingCost.freightCost === 0));
    let permissionToCreateWithRateOrCostZero = this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.createCargoWithRateOrCostZero);
    if (isRateOrCostZero && permissionToCreateWithRateOrCostZero) {
      alertMessage += 'Has ingresado un valor de 0 '
      alertMessage += `${this.cargo.shippingCost.rate === 0 && this.cargo.shippingCost.freightCost === 0 ? 'para la tarifa y el flete' : this.cargo.shippingCost.rate === 0 ? 'para la tarifa' : 'para el flete'}`;
      alertMessage += '. Si esto es correcto, continúa; de lo contrario, revisa el valor ingresado';
    }
    this.spinner.show();
    this.cargoManager
      .checkTripTimes(this.cargo)
      .subscribe({
        next: (valid) => {
          if (valid && valid.valid) {
            if (!this.service.isValidCargo(this.cargo, true)) return;
            const dialogConfig = new MatDialogConfig();
            const value = this.utils.getNestedValue(this.cargo, 'cargoFeature.cargoMeasure.amount') | 0;
            dialogConfig.data = {
              title: `¿Estás seguro que deseas crear el servicio ${this.cargo.consecutive}?`,
              messageList: [
                `Operación: ${this.companyNamePipe.transform(this.cargo.idCompany)}`,
                `Origen: ${this.cargo.cargoFeature.uploadDownload.origin.name}`,
                `Fecha de cargue: ${this.dateFormatPipe.transform(this.cargo.dateLoad, "only-date")}`,
                `Destino: ${this.cargo.cargoFeature.uploadDownload.destination[this.cargo.cargoFeature.uploadDownload.destination.length - 1].name}`,
                `Fecha de descargue: ${this.dateFormatPipe.transform(this.cargo.cargoFeature.uploadDownload.destination[this.cargo.cargoFeature.uploadDownload.destination.length - 1].downloadDate, "only-date")}`,
                `¿Requiere manifiesto?: ${this.cargo.ministry ? 'Si' : 'No'}`,
                `Valor declarado: ${this.currencyPipe.transform(value, "COP", "code")}`
              ],
              showYesBtn: true,
              showNoBtn: true,
              hideBtnCancel: true,
            };
            if (alertMessage) dialogConfig.data.descriptionHTML = `
              <div class="alert alert-warning m-3 d-flex align-items-center justify-content-center" role="alert">
                <i class="fas fa-exclamation-triangle mr-2"></i><span>${alertMessage}</span>
              </div>
            `;
            dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
            dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
            dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
            dialogConfig.autoFocus = false;
            const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe((result) => {
              if (result && result.state) {
                this.spinner.show();
                this.service.requestCargo(this.cargo.id).subscribe(
                  (data: any) => {
                    this.spinner.hide();
                    this.snackBarService.openSnackBar(data && data.message ? data.message : `El servicio ${this.cargo.consecutive} ha sido programado exitosamente`);
                    this.router.navigate(["cargo/list/scheduledLoads"]);
                  },
                  (error) => {
                    this.spinner.hide();
                    this.snackBarService.openSnackBar(
                      error && error.error && error.error.message ? error.error.message :
                        ServiceMessages.GENERAL_HTTP_ERROR, undefined, "error"
                    );
                  }
                );
              }
            });
          } else {
            let message = 'Los tiempos estimados entre las direcciones son imposibles de cumplir.';
            if (valid && valid.min && valid.address) {
              message = `Para llegar a la dirección ${valid.address} se requieren al menos `;
              const duration = DateManager.durationFormat(valid.min, 'seconds');
              if (duration.hours) message += `${duration.hours} horas `;
              if (duration.minutes) message += `${duration.minutes} minutos `;
              if (duration.seconds) message += `${duration.seconds} segundos `;
            }
            this.snackBarService.openSnackBar(message, undefined, 'error');
          }
          this.spinner.hide();
        },
        error: (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(error, undefined, 'alert');
        },
        complete: () => {
          this.spinner.hide();
        }
      })
  }

  openModalAcceptCargo() {
    this.modalConfirmAcceptCargo.openConfirm();
  }

  closeModalAcceptCargo() {
    this.modalConfirmAcceptCargo.closeModal();
  }

  negotiateCargo() {
    if (this.negotiationValue.valid) {
      this.spinner.show();
      const params = {
        idCargo: this.cargo.id,
        idUser: this.authService.cargoActive.negotiation.driver,
        negotiatedValueCreator: this.negotiationValue.value,
      };

      this.service.negotiationCargo(params).subscribe(
        (data) => {
          this.spinner.hide();
          if (data && data["id"] === "200") {
            this.closeModal("modalRefuseCargo");
            this.openModal("modalConfirmNegotiationCargo");
          } else {
            this.snackBarService.openSnackBar("Ocurrió un error al realizar el proceso", undefined, 'alert');
          }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error al realizar el proceso", undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar("Debe ingresar un valor valido", undefined, 'alert');
    }
  }

  negotiateRequest() {
    if (this.negotiationRequestValue.valid) {
      this.spinner.show();
      const params = {
        cargoId: this.cargo.id,
        companyId: this.authService.getUserSession().clientCompanyId,
        initialValue: this.cargo.shippingCost.totalCost,
        companyNegotiatedValue: this.negotiationRequestValue.value,
        creatorId: this.cargo.idCompany,
      };

      this.service.negotiationRequest("Negotiate", params).subscribe(
        (data) => {
          this.spinner.hide();
          // if (data && data['id'] === '200') {
          //   this.snackBarService.openSnackBar('Debe ingresar un valor valido', undefined, 'alert');
          // } else {
          this.closeModal("modalNegotiationRequest");
          this.snackBarService.openSnackBar("Tu contraoferta ha sido enviada");
          this.goToList();
          // }
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Ocurrió un error al realizar el proceso", undefined, 'error');
        }
      );
    } else {
      this.snackBarService.openSnackBar("Debe ingresar un valor valido", undefined, 'alert');
    }
  }

  refuseRequest() {
    this.spinner.show();
    const params = {
      cargoId: this.cargo.id,
      companyId: this.isParentCompany
        ? this.authService.cargoActive.negotiationRequest.companyId
        : this.authService.getUserSession().clientCompanyId,
    };

    this.service.negotiationRequest("Refuse", params).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          this.snackBarService.openSnackBar(
            "Se rechazó la oferta correctamente"
          );
          this.goToList();
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso"
        );
      }
    );
  }

  onConfirmRefuseRequest() {
    this.refuseRequest();
  }

  openModalRefuseRequest() {
    this.modalConfirmRefuseRequest.openConfirm();
  }

  closeModalRefuseRequest() {
    this.modalConfirmRefuseRequest.closeModal();
  }

  acceptRequest() {
    this.spinner.show();
    const params = {
      cargoId: this.cargo.id,
      companyId: this.isParentCompany
        ? this.authService.cargoActive.negotiationRequest.companyId
        : this.authService.getUserSession().clientCompanyId,
    };

    this.service.negotiationRequest("Accepted", params).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          this.snackBarService.openSnackBar("El servicio fue aceptado");
          this.closeModalAcceptRequest();
          this.goToList();
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso"
        );
      }
    );
  }

  onConfirmAcceptRequest() {
    this.acceptRequest();
  }

  openModalAcceptRequest() {
    this.modalConfirmAcceptRequest.openConfirm();
  }

  closeModalAcceptRequest() {
    this.modalConfirmAcceptRequest.closeModal();
  }

  closeModalConfirmNegotiation() {
    this.closeModal("modalConfirmNegotiationCargo");
    // this.modalConfirmNegotiationCargo['hide']();
    this.goToList();
  }

  refuseCargo() {
    this.spinner.show();
    const params = {
      idCargo: this.cargo.id,
      idUser: this.authService.cargoActive.negotiation.driver,
    };

    this.service.refuseCargo(params).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          this.closeModal("modalRefuseCargo");
          this.snackBarService.openSnackBar(
            "Se rechazó la oferta correctamente"
          );
          this.goToList();
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso"
        );
      }
    );
  }

  cancelCargo() {
    this.spinner.show();
    const params = {
      cargoId: this.cargo.id,
      userId: this.cargo.driver,
    };

    this.service.cancelCargo(params).subscribe(
      (data) => {
        this.spinner.hide();
        if (data) {
          this.snackBarService.openSnackBar(
            "El servicio se canceló correctamente"
          );
          this.goToList();
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al realizar el proceso", undefined, 'error'
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al realizar el proceso", undefined, 'error'
        );
      }
    );
  }

  public get canUpdatePlanningRoute(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.routes.module,
      this.permission.routes.editCargoRoutePlanning
    ) && this.cargo.state !== CargoStateEnum.END_SERVICE;
  }

  public get canCheckPlanningRoute(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.routes.module,
      this.permission.routes.accessCargoRoutePlanning
    );
  }

  public async checkPlanningRoute() {
    this.spinner.show();
    let route: PlanningRoute;
    try { route = await this.planningRouteService.findRouteByCargo(this.cargo) } catch (e) { }
    if (!route || !route.id) {
      try {
        const body = await this.planningRouteService.createRouteItineraryFromCargo(this.cargo);
        if (typeof body === 'string') {
          this.snackBarService.openSnackBar(body, undefined, 'error');
          this.spinner.hide();
          return;
        }
        await this.planningRouteService.createRouteItinerary(body).toPromise();
        route = await this.planningRouteService.findRouteByCargo(this.cargo);
      } catch (e) { }
    }
    if (route && route.id) {
      let response: Cargo[];
      try { response = await this.updatePlanningRoute(this.cargo, route) } catch (e) { };
      if (response && response.length && response[0].routePlanId && response[0].itineraryId) {
        this.snackBarService.openSnackBar("Se ha asignado un plan de ruta al servicio");
        this.checkTypeOfService(this.cargo.consecutive.toString());
      } else this.snackBarService.openSnackBar("Ocurrió un error al asignar el plan de ruta", undefined, 'error');
    }
    this.spinner.hide();
  }


  private updatePlanningRoute(cargo: Cargo, route: PlanningRoute): Promise<Cargo[]> {
    const body = {
      cargoId: cargo.id,
      routePlanId: route.id,
      itineraryId: route.itineraries[0].id
    };
    return this.planningRouteService.setCargoRouteAndItinerary([body]).toPromise();
  }

  public watchItineraryDetail() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "detail",
      cargo: this.cargo,
      itineraryId: this.cargo.itineraryId,
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.dialog.open(CargoPlanningRouteComponent, dialogConfig);
  }

  public changeItinerary() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "edit",
      itineraryId: this.cargo.itineraryId,
      routePlanId: this.cargo.routePlanId,
      cargo: this.cargo
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CargoPlanningRouteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.state)
        this.checkTypeOfService(this.cargo.consecutive.toString());
    });
  }

  public calculateDistanceCargo(cargo) {
    this.googleService
      .getRouteDataCargo(cargo)
      .then((success: { cargoDistancy; cargoEstimatedTime }) => {
        this.updateFreightForwarder(
          success.cargoEstimatedTime,
          success.cargoDistancy
        );
      })
      .catch((error) => { });
  }

  updateFreightForwarder(estimatedTime, distancy) {
    const data = {
      id: this.cargo.id,
      estimatedTime,
      distancy,
    };
    this.service.updateFreightForwarder(data).subscribe(
      (success) => {
        this.checkTypeOfService(this.cargo.consecutive.toString());
      },
      (error) => {
        this.cargo.distancy = distancy;
        this.cargo.estimatedTime = estimatedTime;
      }
    );
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.refFirebaseActive) {
      this.refFirebaseActive.off();
    }
    this.authService.cargoActive.cargo = null;
    this.authService.cargoActive.negotiation = {
      state: null,
      driver: null,
      negotiatedValueDriver: null,
      negotiatedValueCreator: null,
    };
  }

  openModalRegisterPayment() {
    if (this.modalRegisterCargoPayment) {
      this.modalRegisterCargoPayment.openModal();
    }
  }

  openModalListPayments() {
    if (this.modalListCargoPayments) {
      this.modalListCargoPayments.openModal();
    }
  }

  onRegisterPayment($event) {
    if ($event)
      this.setCargoDetail($event);
    else
      this.checkTypeOfService(this.cargo.consecutive.toString());

  }

  onCancel($event) { }

  getTitleConfirm(a) { }

  updateDriver($event: { data: User, type: string }) {
    if ($event && $event.data && $event.type) this[$event.type] = this.userManager.sanitizeUser($event.data, Roles.DRIVER)
  }

  updateVehicle($event) {
    this.vehicle = $event;
  }

  checkStateDriver(driverUser: User) {
    return new Promise((resolve, reject) => {
      if (driverUser.state && driverUser.state.active) {
        resolve(true);
      } else if (
        !driverUser.state ||
        (!driverUser.state.active && driverUser.state.description === "Pending")
      ) {
        delete driverUser.profilePictureUrl;
        if (driverUser.information && driverUser.information.document) {
          this.spinner.show();
          this.adminUserService.checkValidationUser(driverUser.information.document).subscribe(
            (success: any) => {
              this.spinner.hide();
              if (success && success.driver && success.driver.state.active) {
                resolve(true);
              } else {
                reject(success.driver.state.description);
              }
            },
            (error) => {
              this.spinner.hide();
              reject(error);
            }
          );
        } else {
          reject(false);
        }

      } else {
        reject(false);
      }
    });
  }

  dismissAllModals() {
    this.modalService.dismissAll();
  }

  updateShippingCost() {
    this.shipingCostComponent.onUpdate.pipe(take(1)).subscribe(
      () => {
        this.service.detailCargo(this.cargo.id).subscribe((cargo: Cargo) => { this.cargo = cargo; })
        this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
      }
    );
    this.shipingCostComponent.onSubmit();
  }

  updateUploadDownload() {
    this.shipingCostComponent.onUpdate.pipe(take(1)).subscribe(
      () => {
        this.service.detailCargo(this.cargo.id).subscribe((cargo: Cargo) => { this.cargo = cargo; })
        this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
      }
    );
    this.loadDownloadCargoComponent.onSubmit();
  }

  updatecharacteristic() {
    this.shipingCostComponent.onUpdate.pipe(take(1)).subscribe(
      () => {
        this.service.detailCargo(this.cargo.id).subscribe((cargo: Cargo) => { this.cargo = cargo; })
        this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
      }
    );
    this.CargoCharacteristicComponent.onSubmit();
  }

  updateCargoType() {
    this.shipingCostComponent.onUpdate.pipe(take(1)).subscribe(
      () => {
        this.service.detailCargo(this.cargo.consecutive.toString()).subscribe((cargo: Cargo) => { this.cargo = cargo; })
        this.letterRetirement.setValue(this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement ? this.cargo.cargoModel.tripType.letterRetirement : null);
      }
    );
    this.CargoTypeComponent.onSubmit();
  }



  enabledEdit() {
    this.editEnabled = true;
  }

  disableEdit() {
    this.editEnabled = false;
    this.cargoEdit.container = this.cargo.container;
    this.cargoEdit.seal = this.cargo.seal;
  }

  updateContainerAndSeal() {
    this.spinner.show();
    const data = {
      id: this.cargo.id,
      container: this.cargoEdit.container,
      seal: this.cargoEdit.seal,
    };
    this.service.updateFreightForwarder(data).subscribe(
      (success) => {
        this.spinner.hide();
        this.checkTypeOfService(this.cargo.consecutive.toString());
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al guardar los campos",
          undefined,
          "error"
        );
      }
    );
  }

  openHistoryDiscountsAndBonuses(type, category) {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    config.data = {
      shippingCostToDisplay: this.service.getHistoryDiscountsAndBonuses(this.cargo, type, category),
      typePayment: 'DiscountsAndBonuses'
    };
    this.dialog
      .open(CargoShippingCostDialogComponent, config)
      .afterClosed()
      .subscribe(
        () => { }
      );
  }

  openHistoryPayments(type) {
    const config = new MatDialogConfig();
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.data = {
      shippingCostToDisplay: this.service.getHistoryPaid(this.cargo),
      cargo: this.cargo,
      typePayment: "Payments"
    };
    this.dialog
      .open(CargoShippingCostDialogComponent, config)
      .afterClosed()
      .subscribe(
        () => {
          this.checkTypeOfService(this.cargo.consecutive.toString());
        }
      );
  }

  get canOpenAdditionalCostDeleted(): boolean {
    if (this.cargo && !this.isDefinedAdditionalCostDeleted) {
      this.service.deletedAdditionalCosts(this.cargo.id).subscribe(
        (response: any) => {
          if (response) {
            if (response.additionalCostsDeleted) {
              this.additionalCostsDeleted = response.additionalCostsDeleted;
              this.isAdditionalCostsDeleted = true;
            } else {
              this.isAdditionalCostsDeleted = false;
            }
          } else {
            this.isAdditionalCostsDeleted = false;
          }
        },
        () => {
          this.isAdditionalCostsDeleted = false;
        }
      );
      this.isDefinedAdditionalCostDeleted = true;
    }
    return this.isAdditionalCostsDeleted;
  }

  openHistoryAdditionalCosts() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      list: this.additionalCostsDeleted,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(
      AdditionalCostsHistoryComponent,
      dialogConfig
    );
  }

  showEvidence(address, index, typeAddress, parent?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.utils.clone(this.cargo),
      index: this.utils.clone(index),
      typeAddress: this.utils.clone(typeAddress),
      address: this.utils.clone(address),
    };
    if (this.utils.isDefined(parent)) {
      dialogConfig.data.parent = this.utils.clone(parent);
    }
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(CargoEvidenceComponent, dialogConfig);
  }

  getCargoInvoicePDF() {
    this.spinner.show();
    this.cargoItemService
      .getCargoInvoicePDF(this.cargo.id)
      .toPromise()
      .then((response) => {
        var file = new Blob([response], { type: ContentTypes.pdf });
        var fileURL = URL.createObjectURL(file);
        this.openDocumentExtra(response, fileURL, `Factura ${this.cargo.shippingCost.billId}`);
      })
      .catch(() =>
        this.snackBarService.openSnackBar(
          "Ocurrió un error al cargar la factura",
          undefined,
          "error"
        )
      )
      .finally(() => this.spinner.hide());
  }

  public confirmGenerateJournal() {
    const dialogConfig = new MatDialogConfig();
    const title = `¿Estás seguro que deseas generar los Comprobantes Contables para el servicio ${this.cargo.consecutive}?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.generateJournal();
      }
    });
  }

  public generateJournal(): void {
    const date = DateManager.getLastMonthDay();
    this.siigoManager.generateJournal(date, [this.cargo.consecutive])
      .then((response) => {
        this.checkResponseGenerateJournal(response);
      })
      .catch((error) => {
        this.checkResponseGenerateJournal(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  private checkResponseGenerateJournal(data) {
    this.siigoManager.showResponseGenerateJournal(data).then((tryAgain) => {
      if (tryAgain)
        this.generateJournal();
      this.checkTypeOfService(this.cargo.consecutive.toString());
    });
  }

  showAdditionalServiceCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
      listAdditionalService: this.listAdditionalService,
      listActive: this.listActive
    };
    const width = this.cargo && this.cargo.additionalCosts && this.cargo.additionalCosts.length ? ModalEnum.LARGE_WIDTH : ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = width;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      UpdateAdditionalCostCargoComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(() => {
      this.checkTypeOfService(this.cargo.consecutive.toString());
      this.isDefinedAdditionalCostDeleted = false;
    });
  }

  get isDialog() {
    return !!(this.dialogParams && this.dialogParams.cargo);
  }

  public openDialogNewAdvancePercentage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargo: this.cargo,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(
      CargoAdvancePercentageComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state)
        this.checkTypeOfService(this.cargo.consecutive.toString());
    });
  }

  public getCargoJournalIdPDF(): void {
    this.spinner.show();
    this.cargoItemService.getCargoJournalPdf(this.cargo.id).toPromise()
      .then((response) => {
        var file = new Blob([response], { type: ContentTypes.pdf });
        var fileURL = URL.createObjectURL(file);
        this.openDocumentExtra(response, fileURL, `Comprobante ${this.cargo.journalId}`);
      })
      .catch(() => this.snackBarService.openSnackBar('Ocurrió un error al cargar el comprobante', undefined, 'error'))
      .finally(() => this.spinner.hide())
  }

  openDocumentExtra(blob: Blob, url: string, title: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      src: url,
      confirmBtn: "Descargar",
      title,
    };
    dialogConfig.height = "80vh";
    dialogConfig.width = "80vw";
    const dialogRef = this.dialog.open(OpenImgComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.utils.downloadFile(blob, "Factura", ContentTypes.pdf);
      }
    });
  }

  public openVehicle() {
    this.DriverCargoComponent.openDetailVehicle();
  }

  public showInspection() {
    this.DriverCargoComponent.openInspectionVehicle();
  }

  openDialogObservation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "Escribe una observación",
      textArea: true,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response) => {
      if (response && response.message && response.message != "") {
        this.addObservation(response.message);
      }
    });
  }
  addObservation(text: string) {
    const observation = {
      field: text,
    };
    this.spinner.show();
    this.service
      .addObservation(observation, this.cargo.id)
      .subscribe(
        (success: any) => {
          if (
            success &&
            success.message === "La observacion fue actualizada con exito"
          ) {
            this.checkTypeOfService(this.cargo.consecutive.toString());
            this.snackBarService.openSnackBar(
              "Se añadió correctamente la observacion",
              undefined,
              "success"
            );
          } else {
            this.snackBarService.openSnackBar(
              "Ocurrio un error al añadir la observación",
              undefined,
              "error"
            );
          }
        },
        (error) => {
          this.snackBarService.openSnackBar(
            "Ocurrio un error al añadir la observación",
            undefined,
            "error"
          );
        }
      )
      .add(() => {
        this.spinner.hide();
      });
  }

  onChangeCleanCargo($event) {
    this.cleanCargo = $event;
  }

  public async editTypeVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog: true,
      vehicleType: this.utils.isDefined(this.cargo.cargoFeature.vehicleType.name) ? this.cargo.cargoFeature.vehicleType.name : ''
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(
      CargoEditTypeVehicleComponent,
      dialogConfig
    );
    modalDialog.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.spinner.show();
        let feature = {
          cargoMeasure: this.cargo.cargoFeature.cargoMeasure,
          productType: this.cargo.cargoFeature.productType,
          vehicleType: this.cargo.cargoFeature.vehicleType,
        };
        feature.vehicleType.name = result.vehicleType.name;
        feature.vehicleType.bodyWorkType.name = result.vehicleType.bodyWorkType;
        this.service.updatecargoFeature(this.cargo.id, feature).subscribe(
          (response) => {
            if (response) {
              this.checkTypeOfService(this.cargo.consecutive.toString());
              this.snackBarService.openSnackBar(
                "Tipo de vehiculo actualizado correctamente"
              );
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(
              "Ocurrió un error al realizar el proceso",
              undefined,
              "alert"
            );
          }
        );
      }
    });
  }

  processRouteGoogle(cargoId: string) {
    this.tempListLocations[cargoId] = { showRouteGoogle: true };
    this.locationsCargos = this.tempListLocations;
    this.mapOptionsConfig({
      initialRoute: true,
      polyline: this.locationsCargos,
      cargo: this.cargo
    })
  }

  mapOptionsConfig(options: OptionsAutocomplete) {
    this.mapOptions = { ...this.mapOptions, ...options };
  }

  setInfoStartEnd() {
    let startEnd = [];
    if (!this.cargo) return '';
    const addresses = [];
    if (this.utils.getNestedValue(
      this.cargo, 'cargoFeature.uploadDownload.origin.addresses')) {
      this.cargo.cargoFeature.uploadDownload.origin.addresses.forEach((address) => {
        addresses.push(address);
      });
    }
    if (this.utils.getNestedValue(this.cargo, 'cargoFeature.uploadDownload.destination')) {
      for (const destination of this.cargo.cargoFeature.uploadDownload.destination) {
        if (destination.addresses) {
          destination.addresses.forEach((address) => {
            addresses.push(address);
          });
        }
      }
    }

    if (addresses.length > 1) {
      let start = addresses[0];
      let end = addresses[addresses.length - 1];
      if (start) {
        startEnd.push({
          key: 'originMarker',
          icon: this.global.pathMarkerOrigin,
          lat: start.location && start.location.lat ? start.location.lat : null,
          lng: start.location && start.location.lng ? start.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${start.address ? start.address : '-'}<br/>
          <b>Inicio:</b> ${start.durationTime && start.durationTime.startDate ? start.durationTime.startDate.slice(0, 16) : '-'}
          `,
          showMarker: true,
          setCenter: true,
          zoom: 16
        });
      }
      if (end) {
        startEnd.push({
          key: 'destinationMarker',
          icon: this.global.pathMarkerDestination,
          lat: end.location && end.location.lat ? end.location.lat : null,
          lng: end.location && end.location.lng ? end.location.lng : null,
          contentInfoWindow: `
          <b>Dirección:</b> ${end.address ? end.address : '-'}<br/>
          <b>fin:</b> ${end.durationTime && end.durationTime.endDate ? end.durationTime.endDate.slice(0, 16) : '-'}
          `,
          showMarker: true
        });
      }
    }
    let path = startEnd;
    this.tempListLocations[this.cargo.id] = { path, showRouteGoogle: true };
    this.locationsCargos = this.tempListLocations;
    this.mapOptionsConfig({
      initialMarkers: true,
      route: this.locationsCargos,
    })
  }

  setInfoStartEndReal(start, end) {
    if (start) {
      start['showMarker'] = true;
      start['icon'] = this.global.pathMarkerOrigin;
      start['contentInfoWindow'] = `
      <b>Registro inicial:</b> ${start.fingerprint && start.fingerprint.date ? start.fingerprint.date.slice(0, 16) : '-'}
      `
    }
    if (end) {
      end['showMarker'] = true;
      end['contentInfoWindow'] = `
      <b>Registro Final:</b> ${end.fingerprint && end.fingerprint.date ? end.fingerprint.date.slice(0, 16) : '-'}
      `
    }

  }

  processCostCenter($event: {costCenter: CostCenter, created: boolean} | null){
    let assignCostCenter = $event && $event.costCenter ? $event.costCenter : null;
    this.associateCostCenter(assignCostCenter, assignCostCenter ? $event.created : false);
  }
  associateCostCenter(costCenter: CostCenter, created: boolean = false) {
    const costCenterObserver = {
      next: (response: BasicResponse) => {
        this.spinner.hide();
        if (response && response.message && response.message === 'Actualizado') {
          this.service.detailCargo(this.cargo.id).subscribe((cargo: Cargo) => { this.cargo = cargo; })
          !created && this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.STATE_COST_CENTER, costCenter ? 'asociado' : 'desasociado'), undefined, 'success');
        } else {
          this.snackBarService.openSnackBar("Ocurrió un error al asociar el centro de costo", undefined, 'error');
        }
      },
      error: (error) => {
        this.spinner.hide();  
        console.error(error);
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    }
    this.spinner.show();
    this.companiesService.assignCostCenterToService(this.cargo.id, costCenter).subscribe(costCenterObserver);

  }

  cleanCostCenterCtrl($event: boolean){
    if ($event) this.assignCostCenterComponent.costCenterCtrl.setValue('');
  }

  get hasFinancialPermission(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.showCostCargo
    );
  }

  get hasAdditionalCostsPermission(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.additionalCosts
    )
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  get canCreateCargoFromRequest(): boolean {
    return this.cargo.state === CargoStateEnum.REQUEST && this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargoFromRequest
    )
  }

  get haveAdvancePayments(): boolean {
    return !!(this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.advanceState && this.cargo.shippingCost.payments && this.cargo.shippingCost.payments.length && this.cargo.shippingCost.payments.some(payment => payment.type === 'advance'))
  }

  get firebasePath(): boolean {
    let fileNameDecode = '';
    if (this.cargo && this.cargo.cargoModel && this.cargo.cargoModel.tripType && this.cargo.cargoModel.tripType.letterRetirement) {
      const url = decodeURIComponent(this.cargo.cargoModel.tripType.letterRetirement);
      const match = url.match(/\/([^\/]+)\?alt=media/);
      fileNameDecode = match ? match[1] : null;
    }
    return !!fileNameDecode;
  }

  public flowInitialFill() {
    if (!this.canEditInitialFulfill) return;
    const config = new MatDialogConfig();
    config.data = this.cargo;
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;

    this.dialog.open(EndCargoCheckComponent, config).afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.onRefreshCargo(this.cargo.consecutive);
        }
      },
      error: (_error) => { console.trace(_error) },
      complete: () => { this.spinner.hide() }
    });
  }

  public get canFinalizeCargo(): boolean {
    if (this.cargo) {
      const destinations = this.cargo.cargoFeature.uploadDownload.destination;
      const addresses = destinations[destinations.length - 1].addresses;
      return addresses[addresses.length - 1].state === 'Cargo unloaded';
    }
    return false;
  }

  public get pendingInitialFulfill(): boolean {
    try {
      const pendingInitialFulfill = this.cargoManager.isTripTypeNational(this.cargo) && this.canFinalizeCargo &&
        !this.cargo.totalConsignmentsInitialApproval &&
        this.cargo.ministry &&
        this.cargo.approval !== 'Approved' &&
        this.cargo.cargoFeature.uploadDownload.destination.some((destination) => {
          return destination.addresses.some(add => {
            const date = DateManager.stringToDate(`${destination.downloadDate.slice(0, 10)} ${add.time} +0500`);
            const less72hrs = DateManager.isBefore(new Date(), DateManager.add(date, 3, 'days'));
            return add && add.consignments && !add.approvalInitialConsignment && less72hrs;
          })
        });
      return pendingInitialFulfill;
    } catch (e) {
      return false;
    }
  }

  public get canEditInitialFulfill(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.editCompliment
    )
  }

  get isPrincipalCompany(): boolean {
    return !!(this.listCompanies.length && this.company && this.company.companyId && this.listCompanies.some(company => company.nit === this.company.companyId));
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }
  public belowSicetac(cargo: Cargo) {
    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    const min = formatter.format(cargo.minimumApprovedValueSicetac);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    this.service.detailCargoByConsecutive(cargo.consecutive.toString()).subscribe(
      (data: any) => {
        this.spinner.hide();
        cargo = data;
        if (this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.approvedBelowSicetac)) {
          if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
            dialogConfig.data = {
              title: '¿Desea aprobar el flete por debajo del SICETAC?',
              descriptionHTML: `El flete mínimo permitido por SICETAC es de <strong>${min}</strong>, si desea ajustarlo por favor realice una bonificación para ajustarse al valor mínimo permitido.`,
              btnDetailCargo: true,
              path: `cargo/detail/${cargo.consecutive}`,
            };
            const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(result => {
              if (result && result.state) {
                this.manualCreationCargoService.approvedBelowSiceTac(cargo.id).subscribe(
                  () => {
                    this.openConfirmBelowSicetac();
                  },
                  (error) => {
                    this.snackBarService.openSnackBar("No se pudo aprobar", undefined, 'error');
                  }
                );
              }
            })
          }
        } else {
          if (cargo.belowSicetac && !cargo.freightValueApprovedBelowSicetac) {
            this.snackBarService.openSnackBar(`El flete mínimo es ${min}, por favor realice una bonificación para ajustarse al valor mínimo permitido`, undefined, 'alert');
          }
        }
      })
  }

  openConfirmBelowSicetac() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Aprobación exitosa`,
      icon: true,
      description: `Se ha aprobado el servicio por un flete que está por debajo del valor mínimo permitido por el SICETAC`,
      hideBtnCancel: false,
      hideBtnConfirm: true,
    };
    this.dialog.open(DialogComponent, dialogConfig);
  }

  processManifestErrors(manifestErrors: string) {
    this.manifestErrors = manifestErrors.split('Error').filter(error => error.trim() !== '');
  }

  get errorsFieldsCargo(): string[] {
    if (!this.cargo.errorMassiveRequest || !this.cargo.errorFieldsExcelCargo) return [];
    let errors = [];
    const fields = this.cargo.errorFieldsExcelCargo;
    const serviceTypeName = this.cargo.cargoModel.serviceType && this.cargo.cargoModel.serviceType.name ? this.cargo.cargoModel.serviceType.name : null;
    const isEmptyContainer = ['Contenedor vacío con carga de compensación', 'Contenedor vacío express'].includes(serviceTypeName);
    const canBeEmpty = ['cargoMeasureTotal', 'cargoMeasureTotalWeigth', 'cargoMeasureTotalAmount'];
    Object.keys(fields).forEach((key) => {
      if (key === 'origin') {
        const addresses = this.cargo.cargoFeature.uploadDownload.origin.addresses;
        fields.origin.recipientsNames.forEach((name, index) => {
          if (name && this.cargo.ministry && (!addresses || !addresses[index] || !addresses[index].thirdPartyConsignment || !addresses[index].thirdPartyConsignment.document))
            errors.push(`Documento del tercero de la dirección de origen ${index + 1}: ${name}`);
        })
      }
      else if (key === 'destination') {
        const destinations = this.cargo.cargoFeature.uploadDownload.destination;
        fields.destination.forEach((destination, indexD) => {
          const addresses = destinations[indexD].addresses;
          destination.recipientsNames.forEach((name, index) => {
            if (name && this.cargo.ministry && (!addresses || !addresses[index] || !addresses[index].thirdPartyConsignment || !addresses[index].thirdPartyConsignment.document))
              errors.push(`Documento del tercero del destino ${indexD + 1} - dirección ${index + 1}: ${name}`);
          })
        })
      }
      else if (key === 'licensePlate') {
        if (!this.cargo.licensePlate || !this.cargo.driver)
          errors.push(`${[this.global.errorsFieldsExcelDict[key].alias]}: ${fields[key]}`)
      }
      else {
        const cargoValue = this.utils.getNestedValue(this.cargo, this.global.errorsFieldsExcelDict[key].field);
        if (!cargoValue || (cargoValue === 0 && !(isEmptyContainer && canBeEmpty.includes(key))))
          errors.push(`${[this.global.errorsFieldsExcelDict[key].alias]}: ${fields[key]}`)
      }
    })
    return errors;
  }

  get totalTravelExpenses(): number {
    if (!this.travelExpenses || !this.travelExpenses.length) return 0;
    return this.travelExpenses.reduce(
      (sum, travelExpense) => sum + travelExpense.totalPaid,
      0,
    );
  }
  get areTravelExpensesPaid(): boolean {
    if (!this.travelExpenses || !this.travelExpenses.length) return false;
    return this.travelExpenses.every(travel => travel && travel.paid);
  }

  get areTravelExpensesApproved(): boolean {
    if (!this.travelExpenses || !this.travelExpenses.length) return false;
    return this.travelExpenses.every(travel => travel && travel.approval === 'Approved');
  }

  public showTravelExpensesDialog() {
    const config = new MatDialogConfig();
    config.data = {
      cargoId: this.cargo.id,
      cargoConsecutive: this.cargo.consecutive,
      form: {
        travelExpenses: this.travelExpenses
      },
      addEmpty: true
    }
    config.width = ModalEnum.MEDIUM_WIDTH;
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.dialog.open(TravelExpensesComponent, config).afterClosed().subscribe(
      () => {
        this.checkTypeOfService(this.cargo.consecutive.toString());
      }
    );
  }

  public showLegalizeTravelExpensesDialog() {
    const config = new MatDialogConfig();
    config.data = {
      cargo: this.cargo,
      travelExpenses: this.travelExpenses
    }
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.maxHeight = ModalEnum.MAX_HEIGHT;

    this.dialog
      .open(LegalizeTravelExpensesComponent, config)
      .afterClosed()
      .subscribe(
        (refresh) => {
          this.checkTypeOfService(this.cargo.consecutive.toString());
        }
      );
  }

  public get isOwnFleetVehicle(): boolean {
    return !this.utils.isEmpty(this.vehicle.ownFleet) ? this.vehicle.ownFleet : false;
  }

  public get isChargeTaxes(): boolean {
    return this.cargoItemService.isChargeTaxes(this.cargo);
  }

  public getTravelExpensesBalance(): number {
    if (!this.travelExpenses || !this.travelExpenses.length) return 0;
    const totalPaid = this.travelExpenses
      .filter(travelExpense => travelExpense.paid)
      .reduce((acc, travelExpense: TravelExpense) => acc + travelExpense.totalPaid, 0);

    const totalValidSpent = this.travelExpenses
      .map(travelExpenses => travelExpenses.travelExpensesDetail)
      .reduce((acc, travelExpenseDetails: Array<TravelExpenseDetail>) => acc.concat(travelExpenseDetails), [])
      .filter(travelExpenseDetail => travelExpenseDetail.approval === ApprovalStateEnum.APPROVED)
      .reduce((acc, travelExpenseDetail) => acc + travelExpenseDetail.value, 0);
    return totalPaid - totalValidSpent;
  }

  public get balance() {
    const travelExpensesBalance = this.getTravelExpensesBalance();
    const balanceState = !!this.utils.getNestedValue(this.cargo, 'shippingCost.balanceState');
    const balance = balanceState ?
      this.cargoItemService.getCargoBalancePaid(this.cargo) :
      this.cargoItemService.getTotalValueBalance(this.cargo) - travelExpensesBalance;
    return balance;
  }

  get canReverseInitialComplitment() {
    const permission = this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.reverseInitialComplitment
    );
    return permission;
  }

  get canEditCargoConsignments(): boolean {
    return this.cargo && this.cargo.ministry && [CargoStateEnum.ACCEPTED, this.cargoStateEnum.CREATED, this.cargoStateEnum.START_SERVICE].includes(this.cargo.state)
      && !this.cargo.manifest && !this.cargo.manifestAuthorization; //Permiso
  }

  public goToEditConsignment(destinationId: number, addressId: number): void {
    if (!this.utils.isEmpty(this.cargo.manifestAuthorization)) {
      this.confirmEditConsignment(
        'Las remesas con manifiesto generado no se pueden editar',
        'Puede anular el manifiesto y volver a crear el servicio'
      );
      return;
    }
    if (this.cargo.cargoModel.tripType.name !== 'Nacional') {
      this.confirmEditConsignment(
        'Solo se pueden editar remesas nacionales',
        'Puede anular el manifiesto y volver a crear el servicio'
      );
      return;
    }
    this.router.navigateByUrl(
      `cargo/detail/${this.cargo.consecutive}/consignment-form?d=${destinationId}&a=${addressId}`
    );
  }

  private confirmEditConsignment(title: string, description?: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      description,
      hideBtnCancel: true,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    this.dialog.open(DialogComponent, dialogConfig);
  }
}
