<div 
    permission-display
    [module]="permission.cargo.module"
    [functionality]="permission.cargo.generateManifest"
    *ngIf="cargo?.state !== cargoStateEnum.CREATED 
           && cargo?.confirmedDriver && !automaticManifestGeneration">
    <!-- Button as mat-raised-button -->
    <button
        *ngIf="type === 'button-detail'"
        mat-raised-button
        color="primary"
        class="mx-0 mb-2"
        (click)="openDialogNewAdvancePercentage()">
        Generar manifiesto
    </button>
    <!-- Tracking button -->
    <button 
        *ngIf="type === 'button-tracking'"
        class="font-primary-light btn text-strong actions-card-primary-secondary"
        (click)="openDialogNewAdvancePercentage()">
        <i class="fas fa-file-alt"></i>
        Generar manifiesto
    </button>

    <!-- Button as mat-menu-item -->
    <button
        *ngIf="type === 'cargo-option'"
        mat-menu-item
        (click)="openDialogNewAdvancePercentage()">
        <i class="fas fa-file-alt"></i>
        Generar manifiesto
    </button>
</div>

