<div class="row-module-item row">
  <div class="col-9 col-md-10">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex flex-row">
          <div class="title" (click)="getDetailVehicle()" [ngClass]="{'cursor-pointer':showVehicleDetail}"
            [title]="showVehicleDetail ? 'Ver detalles del vehículo' : ''">
            {{enturnamiento.licensePlate}}
          </div>
          <i class="ml-2 fas fa-circle"
            [ngStyle]="{'color': diffInDays >= 1 ? '#198754' : (diffInDays === 0 ? '#FFC107' : '#DC3545') }"></i>
        </div>
      </div>
      <div *ngIf="showAvailableLoads" class="d-block d-md-none col-12 avaliable-loads margin-avaliable-loads" [ngClass]="{
          'color-primary': filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length,
          'color-primary-dark': !filteredCargosTurns || (filteredCargosTurns && filteredCargosTurns.matches.length === 0),
          'cursor-pointer': filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length
        }"
        [title]="filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length ? 'Ver listado de servicios disponibles para este vehículo': ''"
        (click)="AvaliableCargoList(enturnamiento.licensePlate, enturnamiento.numberOfTrips)">
        <u>
          {{ filteredCargosTurns.matches.length ?
          ('(' + filteredCargosTurns.matches.length + ')' + ' Servicio' +
          (filteredCargosTurns.matches.length > 1 ? 's' : '') +
          ' disponible' +
          (filteredCargosTurns.matches.length > 1 ? 's' : ''))
          : '(0) Servicios disponibles'}}
        </u>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Origen
              </span>
              <span matTooltip="{{enturnamiento?.origin?.name}}">
                {{enturnamiento.origin?.name}}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Destino
              </span>
              <span
                matTooltip="{{enturnamiento?.destination?.name? enturnamiento?.destination?.name : 'Sin especificar' }}">
                {{enturnamiento?.destination?.name? enturnamiento?.destination?.name : 'Sin especificar' }}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Vehículo disponible el dia:
              </span>
              <span matTooltip="{{enturnamiento.date | dateFormat:'only-date'}}">
                {{enturnamiento.date | dateFormat:'only-date'}}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Tipo de vehículo:
              </span>
              <span matTooltip="{{enturnamiento.vehicleType}}">
                {{enturnamiento.vehicleType ? enturnamiento.vehicleType : '-'}}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Tipo de carrocería:
              </span>
              <span matTooltip="{{enturnamiento.bodyworkType}}">
                {{enturnamiento.bodyworkType ? enturnamiento.bodyworkType : '-'}}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="info-enturnamiento">
              <span class="text-bold">
                Numero de viajes vehículo:
              </span>
              <span matTooltip="{{enturnamiento.numberOfTrips}}">
                {{enturnamiento.numberOfTrips ? enturnamiento.numberOfTrips : '0'}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 col-md-2 d-flex align-items-center flex-column justify-content-around">
    <div *ngIf="showAvailableLoads" class="w-100 d-none d-md-block text-center avaliable-loads" [ngClass]="{
        'color-primary': filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length,
        'color-primary-dark': !filteredCargosTurns || (filteredCargosTurns && filteredCargosTurns.matches.length === 0),
        'cursor-pointer': filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length
      }"
      [title]="filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length ? 'Ver listado de servicios disponibles para este vehículo': ''"
      (click)="AvaliableCargoList(enturnamiento.licensePlate, enturnamiento.numberOfTrips)">
      <u>
        {{ filteredCargosTurns.matches.length ?
        ('(' + filteredCargosTurns.matches.length + ')' + ' Servicio' +
        (filteredCargosTurns.matches.length > 1 ? 's' : '') +
        ' disponible' +
        (filteredCargosTurns.matches.length > 1 ? 's' : ''))
        : '(0) Servicios disponibles'}}
      </u>
    </div>
    <div class="d-flex align-items-center justify-content-around w-100">
      <i permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.editTurn"
        class="fas fa-edit" matTooltip="Editar enturnamiento" (click)="editTurn(enturnamiento)"></i>
      <i permission-display [module]="permission.cargo.module" [functionality]="permission.cargo.deleteTurn"
        class="fas fa-trash" matTooltip="Eliminar enturnamiento"
        (click)="removeTurn(enturnamiento.id, enturnamiento.licensePlate,  enturnamiento.date, enturnamiento.origin?.name, enturnamiento.destination?.name)"></i>
    </div>

  </div>
</div>