

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/core/guards/role.guard';
import { UserResolver } from 'src/app/core/resolves/user.resolve';
import { UserActiveGuard } from 'src/app/core/guards/user-active.guard';
import { ManualCreationCargoComponent } from './manual-creation-cargo/manual-creation-cargo.component';
import { CargoListComponent } from './cargo-list/cargo-list.component';
import { CargoTrackingComponent } from './cargo-tracking/cargo-tracking.component';
import { CargoDetailComponent } from './cargo-detail/cargo-detail.component';
import { CargoCreateTabsComponent } from './cargo-tabs-create/cargo-tabs-create.component';
import { DateCargoComponent } from './manual-creation-cargo/components/date-cargo/date-cargo.component';
import { TypeTripComponent } from './manual-creation-cargo/components/type-trip/type-trip.component';
import { TypeRegimeComponent } from './manual-creation-cargo/components/type-regime/typeRegime.component';
import { TripTypeDocumentsComponent } from './manual-creation-cargo/components/trip-type-documents/trip-type-documents.component';
import { TypeServiceComponent } from './manual-creation-cargo/components/type-service/type-service.component';
import { TypeCargoComponent } from './manual-creation-cargo/components/type-cargo/type-cargo.component';
import { ContainerMeasureComponent } from './manual-creation-cargo/components/container-measure/containerMeasure.component';
import { TypeMerchandiseComponent } from './manual-creation-cargo/components/type-merchandise/type-merchandise.component';
import { TypeVehicleComponent } from './manual-creation-cargo/components/type-vehicle/type-vehicle.component';
import { LoadMeasurementsComponent } from './manual-creation-cargo/components/load-measurements/load-measurements.component';
import { QuantityVehiclesComponent } from './manual-creation-cargo/components/quantity-vehicles/quantity-vehicles.component';
import { UploadComponent } from './manual-creation-cargo/components/upload/upload.component';
import { DownloadComponent } from './manual-creation-cargo/components/download/download.component';
import { CargoValueComponent } from './manual-creation-cargo/components/cargo-value/cargo-value.component';
import { MethodPaymentComponent } from './manual-creation-cargo/components/method-payment/method-payment.component';
import { TotalValueComponent } from './manual-creation-cargo/components/total-value/totalValue.component';
import { AssignLicensePlateComponent } from './manual-creation-cargo/components/assign-license-plate/assign-license-plate.component';
import { CargoSearchComponent } from './cargo-search/cargo-search.component';
import { AditionalServicesResolver } from 'src/app/core/resolves/additionalServices.resolve';
import { CreationAdditionalCostCargoComponent } from './manual-creation-cargo/components/creation-additional-cost-cargo/creation-additional-cost-cargo.component';
import { CargoPaymentsComponent } from './cargo-payments/cargo-payments.component';
import { CargoListTurnComponent } from './cargo-list-turn/cargo-list-turn.component';
import { BoxSealComponent } from './manual-creation-cargo/components/box-seal/box-seal.component';
import { GlobalTrackingComponent } from './global-tracking/global-tracking.component';
import { CargoTabsLoadingRoutesComponent } from './cargo-tabs-loading-routes/cargo-tabs-loading-routes.component';
import { CargoGenerateComponent } from './cargo-generate/cargo-generate.component';
import { CargoListExpensesIncomeComponent } from './cargo-list-expenses-income/cargo-list-expenses-income.component';
import { RequestUnsavedChangesGuard } from 'src/app/core/guards/request-unsaved-changes.guard';
import { CreateCargoComponent } from './create-cargo/create-cargo.component';
import { ApplicationsComponent } from './applications/applications.component';
import { CargoListServiceRequestsComponent } from './cargo-list-service-requests/cargo-list-service-requests.component';
import { TransportRequestListingTabsComponent } from './transport-request-listing-tabs/transport-request-listing-tabs.component';
import { VehicleApplicationsTransportRequestsComponent } from './vehicle-applications-transport-requests/vehicle-applications-transport-requests.component';
import { ServiceRequestsTabsComponent } from './service-requests-tabs/service-requests-tabs.component';
import { ConsignmentFormComponent } from './consignment-form/consignment-form.component';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: "Servicio"
    },
    children: [
      {
        path: 'create',
        component: CargoCreateTabsComponent,
        data: { breadcrumb: 'Creación' },
        canActivate: [UserActiveGuard, RoleGuard],
        resolve: {
          UserResolver
        },
      },
      {
        path: 'create-v2',
        component: CreateCargoComponent,
        data: { breadcrumb: 'Creación' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'search',
        component: CargoSearchComponent,
        data: { breadcrumb: 'Rastrear' },
        canActivate: [UserActiveGuard, RoleGuard],
      },
      {
        path: 'create-date-load',
        component: DateCargoComponent,
        data: { breadcrumb: 'Creación' },
        canActivate: [UserActiveGuard],
      },
      {
        path: 'manual-creation',
        component: ManualCreationCargoComponent,
        data: { breadcrumb: 'Creación' },
        canActivate: [UserActiveGuard],
        children: [
          // 1
          {
            path: 'type-trip',
            component: TypeTripComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'type-regime',
            component: TypeRegimeComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'type-cargo',
            component: TypeCargoComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'container-measure',
            component: ContainerMeasureComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'type-service',
            component: TypeServiceComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'trip-type-documents',
            component: TripTypeDocumentsComponent,
            canActivate: [UserActiveGuard],
          },
          // 2
          {
            path: 'type-merchandise',
            component: TypeMerchandiseComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'type-vehicle',
            component: TypeVehicleComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'quantity-vehicles',
            component: QuantityVehiclesComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'assign-vehicles',
            component: AssignLicensePlateComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'box-seal',
            component: BoxSealComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'size-load',
            component: LoadMeasurementsComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'upload',
            component: UploadComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'download',
            component: DownloadComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'additional-service',
            component: CreationAdditionalCostCargoComponent,
            resolve: {
              aditionalServicesResolver: AditionalServicesResolver
            },
            canActivate: [UserActiveGuard],
          },
          {
            path: 'cargo-value',
            component: CargoValueComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'method-payment',
            component: MethodPaymentComponent,
            canActivate: [UserActiveGuard],
          },
          {
            path: 'total-value',
            component: TotalValueComponent,
            canActivate: [UserActiveGuard],
          }
        ]
      },
      {
        path: 'list',
        canActivate: [UserActiveGuard],
        data: {
          breadcrumb: "Listado"
        },
        children: [
          {
            path: 'request',
            component: CargoListComponent,
            data: { breadcrumb: 'Solicitudes' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'cargoRequestNegotiation',
            component: CargoListComponent,
            data: { breadcrumb: 'Negociación B2B' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'enturnamiento',
            component: CargoListTurnComponent,
            data: { breadcrumb: 'Enturnamiento' },
            canActivate: [UserActiveGuard, RoleGuard],
          },
          {
            path: 'loadingRoutes',
            component: CargoTabsLoadingRoutesComponent,
            data: { breadcrumb: 'En Ruta' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver,
              aditionalServicesResolver: AditionalServicesResolver
            }
          },
          {
            path: 'scheduledLoads',
            component: CargoListComponent,
            data: { breadcrumb: 'Programados' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver,
              aditionalServicesResolver: AditionalServicesResolver
            }
          },
          {
            path: 'loadsInNegotiation',
            component: CargoListComponent,
            data: { breadcrumb: 'En Negociación' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'loadsFinished',
            component: CargoListComponent,
            data: { breadcrumb: 'Terminados' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'expired',
            component: CargoListComponent,
            data: { breadcrumb: 'Vencidos' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'deleted',
            component: CargoListComponent,
            data: { breadcrumb: 'Eliminados' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'service-requests',
            component: ServiceRequestsTabsComponent,
            data: { breadcrumb: 'Solicitudes de servicio' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          // Payments
          {
            path: 'paymentCargo',
            component: CargoPaymentsComponent,
            data: { breadcrumb: 'Pagos' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          {
            path: 'approvePayments',
            component: CargoListComponent,
            data: { breadcrumb: 'Aprobaciones' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          // {
          //     path: 'paymentCargo',
          //     component: CargoListComponent,
          //     data: { breadcrumb: 'Pagar servicios' },
          //     canActivate: [UserActiveGuard],
          //     resolve: {
          //         UserResolver
          //     }
          // },
          {
            path: 'charges',
            component: CargoListComponent,
            data: { breadcrumb: 'Facturación' },
            canActivate: [UserActiveGuard, RoleGuard],
            resolve: {
              UserResolver
            }
          },
          { path: '**', redirectTo: '/cargo/create' }
        ]
      },
      {
        path: 'tracking/:consecutive',
        component: CargoTrackingComponent,
        data: { breadcrumb: 'Tracking' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver,
        }
      },
      {
        path: 'global-tracking',
        component: GlobalTrackingComponent,
        data: { breadcrumb: 'Global Tracking' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver,
        }
      },
      {
        path: 'detail/:consecutive',
        component: CargoDetailComponent,
        data: { breadcrumb: 'Detalle' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver,
          AditionalServicesResolver,
          aditionalServicesResolver: AditionalServicesResolver
        }
      },
      {
        path: 'detail/:consecutive/consignment-form',
        component: ConsignmentFormComponent,
        data: { breadcrumb: 'Editar Remesa' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'negotiation',
        component: CargoDetailComponent,
        data: { breadcrumb: 'Negociación' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'applications/:id',
        component: VehicleApplicationsTransportRequestsComponent,
        data: { breadcrumb: 'Detalle' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'company-offers/:id',
        component: ApplicationsComponent,
        data: { breadcrumb: 'Solicitudes y ofertas' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'expenses-income',
        component: CargoListExpensesIncomeComponent,
        data: { breadcrumb: 'Gastos & Ingresos' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'transport-requests',
        component: TransportRequestListingTabsComponent,
        data: { breadcrumb: 'Solicitudes y Asignaciones' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      {
        path: 'create-transport-request',
        component: CreateCargoComponent,
        data: { breadcrumb: 'Crear Solicitud' },
        canActivate: [UserActiveGuard],
        resolve: {
          UserResolver
        }
      },
      { path: '**', redirectTo: '/cargo/create' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CargoRoutingModule { }
