import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { InsuranceInformation } from 'src/app/core/interfaces/insuranceInformation';
import { DateManager } from 'src/app/core/managers/date.manager';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';
@Injectable({
    providedIn: 'root'
})
export class UploadDownloadMapService {

    policyCompany: InsuranceInformation;
    constructor(
        private authService: AuthService,
        private utils: Utils
    ) { }

    isPolicyExpirated(): boolean {
        return !!(this.policyCompany && this.policyCompany.expirationDate && DateManager.dateDiff(this.policyCompany.expirationDate, 'DD/MM/YYYY', DateManager.setStartOfDay(new Date()), null, 'days') <= 0);
    }

    getApplyPolicyByCompany(): boolean {
        const companySaaS = this.authService.getCompanySaaS();
        return !!(companySaaS && this.utils.isDefined(companySaaS.policyByCompany) && companySaaS.policyByCompany);
    }

    setPolicyCompany(policyCompany: InsuranceInformation) {
        this.policyCompany = policyCompany;
    }
    getPolicyCompany(): InsuranceInformation {
        return this.policyCompany;
    }
    getHasSiigoIntegration(): boolean {
        return this.authService.getCompany().integrationCredentials &&
          this.authService.getCompany().integrationCredentials.length &&
          this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel &&
            integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state);
    }

}
