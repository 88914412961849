<div #mainFreightUpload class="upload__download">
  <div class="upload__download-form" [ngSwitch]="viewActive" *ngIf="manualCreationCargoService.cargoForm">
    <ng-scrollbar [disableOnBreakpoints]="false">
      <div class="width-100 upload__download-form-scroll">
        <!-- Owner -->
        <div class="col-md-12" *ngSwitchCase="'upload'">
          <div class="row" permission-display [module]="permission.cargo.module"
            [functionality]="permission.cargo.changeManifestRequirement">
            <div class="col-md-12">
              <label class="text-strong">¿Requiere Manifiesto?&nbsp;&nbsp;</label>
              <mat-button-toggle-group #group="matButtonToggleGroup"
                [formControl]="manualCreationCargoService.cargoForm.get('ministry')"
                (change)="onChangeManifestOption()">
                <mat-button-toggle [value]="true">
                  Si
                </mat-button-toggle>
                <mat-button-toggle [value]="false">
                  No
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <app-third-party-consignment [title]="'Propietario del servicio'"
                [inputFormControl]="manualCreationCargoService.cargoForm['controls'].cargoOwner"
                (emitToParent)="onSelectOwner($event)"></app-third-party-consignment>
            </div>
            <div *ngIf="manualCreationCargoService?.getOwnerSelected() && !uploadDownloadMapService?.getHasSiigoIntegration()" 
            class="col-12" 
            permission-display 
            [module]="permission.cargo.module"
            [functionality]="permission.cargo.assignUnassignCostCenterToService">
              <app-assign-cost-center [company]="manualCreationCargoService?.getOwnerSelected()" [initialValue]="costCenterSetted" (emitResult)="refreshCompaniesList($event)"></app-assign-cost-center>
            </div>
            <div
              *ngIf="manualCreationCargoService?.getOwnerSelected() && manualCreationCargoService?.cargoForm?.get('ministry')?.value"
              class="col-12">
              Número de póliza:
              <ng-container *ngIf="policy?.policyNumber; else noPolicy">
                <span class="text-bold color-primary-dark">{{ policy.policyNumber }}</span><span *ngIf="policyExpirated"
                  class="policy__not-found--font policy__expired--color"> (Vencida)
                  <u permission-display [module]="permission.administration.module"
                    [functionality]="permission.administration.updatePolicy" class="color-secondary-dark cursor-pointer"
                    (click)="redirectToCompany()"> Actualizar póliza</u></span><i *ngIf="!policyExpirated"
                  class="fas fa-info-circle color-secondary-dark cursor-pointer"
                  [matTooltip]="'Esta póliza es la' + (uploadDownloadMapService?.getApplyPolicyByCompany() ? ' póliza individual (por cliente)' : ' póliza única (para todos los clientes)') + '. Si deseas modificarla, por favor ve a la sección de configuración de la empresa para actualizarla.'"></i>
              </ng-container>
              <ng-template #noPolicy>
                <span class="policy__not-found--font policy__not-found--color">Sin póliza</span>
                <u permission-display [module]="permission.administration.module"
                  [functionality]="permission.administration.setUpPolicy"
                  class="color-secondary-dark policy__not-found--font cursor-pointer ml-2"
                  (click)="redirectToCompany()">Configurar póliza</u>
              </ng-template>
            </div>
          </div>
          <div class="row" *ngIf="ownerSelected && ownerSelected.branchOffices">
            <div class="col-md-12">
              <app-branch-office-owner-cargo [title]="'Sucursal'"
                [config]="{value: manualCreationCargoService.getBranchOfficeSelected()}"
                (selectedBranch)="onSelectBranch($event)" [companyNit]="ownerSelected.companyId">
              </app-branch-office-owner-cargo>
            </div>
          </div>
          <div class="row" *ngIf="ownerSelected && branchOfficeSelected && branchOfficeSelected.subBranchOffices">
            <div class="col-md-12">
              <app-branch-office-owner-cargo [title]="'Sub Sucursal'"
                [config]="{value: manualCreationCargoService.getSubBranchOfficeSelected()}"
                (selectedBranch)="onSelectSubBranch($event)" [listBranch]="branchOfficeSelected.subBranchOffices">
              </app-branch-office-owner-cargo>
            </div>
          </div>
        </div>
        <br>
        <app-upload-location *ngSwitchCase="'upload'" [cleanCity]="cleanCity" [companySelected]="ownerSelected"
          (emitToParent)="receiveFromChildren($event)" (removeAddressMarker)="addressRemovedOrigin($event)"
          (clearAllMarkers)="removeAllMarkersOrigin($event)">
        </app-upload-location>
        <app-download-location *ngSwitchCase="'destination'" [cleanCity]="cleanCity"
          (emitToParent)="receiveFromChildren($event)" (removeAddressMarker)="removeOneMarkerOneDestination($event)"
          (removeAllDestinationMarkers)="removeAllMarkersOneDestination($event)">
        </app-download-location>
      </div>
    </ng-scrollbar>
  </div>
  <!-- Map -->
  <div class="upload__download-map">
    <app-standard-map [options]="mapOptions" (clickMap)="onLoadMap($event)"></app-standard-map>
  </div>
</div>