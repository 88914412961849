import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatSelectChange, MatStepper } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Bank } from 'src/app/core/interfaces/bank';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { FileStorage } from 'src/app/core/interfaces/fileStorage';
import { Model, Vehicle } from 'src/app/core/interfaces/vehicle';
import { Global } from 'src/app/core/resources/global';
import { Patterns } from 'src/app/core/resources/patterns';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Titles } from 'src/app/core/resources/titles';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { FileComponent } from 'src/app/shared/files/file.component';
import { FileService } from 'src/app/shared/files/file.service';
import { VehiclesService } from '../list-vehicles.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { orderBy } from 'lodash';
import { AccountService } from 'src/app/modules/account/account.service';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/interfaces/user';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Dialog } from 'src/app/core/resources/dialog';
import { FragmentedAddressComponent } from 'src/app/shared/fragmented-address/fragmented-address.component';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Companies } from 'src/app/core/resources/companies';
@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss'],
  providers: [PermissionRole]
})
export class BankAccountComponent implements OnInit {

  permission = Permission;
  @ViewChildren(FileComponent) fileComponent: QueryList<FileComponent>;
  @ViewChild(FragmentedAddressComponent, { static: false }) fragmentedAddressComponent: FragmentedAddressComponent;
  @Input() form?: FormGroup;
  @Input() oppositeForm: FormGroup;
  @Input() type?: string;
  @Input() vehicle?: Vehicle;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  documenTypes: CatalogItem[];
  reactiveFormBankAcountAdvance = new ReactiveForm(this.formBuilder, this.modelBankAccount.model.bankAccountAdvance);
  reactiveFormBankAcountBalance = new ReactiveForm(this.formBuilder, this.modelBankAccount.model.bankAccountBalance);
  reactiveFormVehicle = new ReactiveForm(this.formBuilder, this.modelBankAccount.model);
  dataVehicleBank: any;

  bankList: Bank[] = [];
  bankListOrder = [];
  listAccountType = [
    {
      name: 'Ahorros',
      id: '1'
    },
    {
      name: 'Corriente',
      id: '2'
    }
  ];

  constructor(
    public utils: Utils,
    private global: Global,
    private vehiclesService: VehiclesService,
    private snackBarService: SnackBarService,
    private accountService: AccountService,
    public patterns: Patterns,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private fileService: FileService,
    private titles: Titles,
    public formBuilder: FormBuilder,
    public modelBankAccount: Model,
    public permissionRole: PermissionRole,
    private authService: AuthService,
    private dialogService: Dialog
  ) {
    this.documenTypes = this.utils.clone(this.global.documenTypes);
    this.listBank();
  }

  /**
  * @description Initializes the form depending of the input "type"
  */
  ngOnInit(): void {
    if (!this.utils.isDefined(this.form)) {
      if (this.type == 'advance') {
        this.form = this.reactiveFormBankAcountAdvance.form;
        this.reactiveFormBankAcountAdvance.setValidatorsForm(this.modelBankAccount.modelValidators, this.form);
      }
      if (this.type == 'balance') {
        this.form = this.reactiveFormBankAcountBalance.form;
        this.reactiveFormBankAcountBalance.setValidatorsForm(this.modelBankAccount.modelValidators, this.form);
      }
    }
    if (this.hasDocumentsException) {
      this.form.get('rut').setValidators(null);
      this.form.get('rut').updateValueAndValidity();
      this.form.get('authorization').setValidators(null);
      this.form.get('authorization').updateValueAndValidity();
      this.form.get('certificate').setValidators(null);
      this.form.get('certificate').updateValueAndValidity();
    }
  }

  /**
  * @description Gets the list of banks available
  */
  private listBank(): void {
    this.vehiclesService.getListBank().subscribe((success: Bank[]) => {
      this.bankList = success;
      this.bankListOrder = orderBy(this.bankList, ['name']);
    }, (error) => { this.bankList = [] });
  }

  /**
  * @description Finds a user by its document
  */
  findUser(): void {
    if (this.form.get('document').invalid) {
      this.utils.errorMessagesCustomized(this.form.get('document'), 'número de documento', 3, 10);
      return;
    }
    this.spinner.show();
    const $userObserv = this.accountService.validateEntity(1, this.form.get('document').value).subscribe({
      next: (result: User) => {
        this.spinner.hide();
        if (result) {
          this.setUserData(result);
        } else {
          this.snackBarService.openSnackBar(FormMessages.USER_NOT_FOUND, undefined, 'alert');
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(FormMessages.USER_NOT_FOUND, undefined, 'alert');
      },
      complete: () => {
        $userObserv.unsubscribe();
      }
    });
  }

  /**
  * @param {User} user is the user used to update the form 
  * @description Sets the user form's information from user param 
  */
  public cloneBankAccount(): void {
    const requiredFields = ['document', 'documentTypeName', 'name', 'bank.name', 'accountType', 'accountNumber'];
    if (!this.oppositeForm || !this.oppositeForm.value ||
      !requiredFields.every(field => this.oppositeForm.get(field) && this.oppositeForm.get(field).value))
      return this.snackBarService.openSnackBar(`No se ha diligenciado la información bancaría de ${this.type === 'advance' ? 'saldos' : 'anticipos'}`, undefined, 'alert');
    this.form.patchValue(this.oppositeForm.getRawValue());
  }

  /**
  * @param {User} user is the user used to update the form 
  * @description Sets the user form's information from user param 
  */
  private setUserData(user: User): void {
    this.form.patchValue(user);
    user.information && user.information.name && this.form.get('name').setValue(user.information.name);
    user.information && user.information.documentTypeId && this.form.get('documentTypeId').setValue(user.information.documentTypeId);
    user.information && user.information.documentTypeName && this.form.get('documentTypeName').setValue(user.information.documentTypeName);
  }

  /**
  * @param $event is the address selected
  * @description Updates the address selected into the form's control "address"
  */
  onSelectAddress($event): void {
    this.form.get('address').setValue($event);
  }

  /**
  * @param {MatSelectChange} $event is the document type selected
  * @description Updates the document type selected into the form's controls "documentTypeId" and "documentTypeName"
  */
  onChangeTypeDocumentUser($event: MatSelectChange): void {
    const documentType = this.documenTypes.filter((obj: CatalogItem) => {
      return obj.id === $event.value;
    });
    this.form.get('documentTypeId').setValue(documentType[0].id);
    this.form.get('documentTypeName').setValue(documentType[0].name);
  }

  /**
  * @param {MatSelectChange} $event is the bank selected
  * @description Updates the bank selected into the form
  */
  onSelectFilteredBanks($event: MatSelectChange): void {
    const result = this.bankList.filter((bank) => {
      return bank.id === $event.value;
    });
    this.form.get('bank.code').setValue(result[0].code);
    this.form.get('bank.codeBancolombia').setValue(result[0].codeBancolombia);
    this.form.get('bank.id').setValue(result[0].id);
    this.form.get('bank.name').setValue(result[0].name);
  }

  /**
  * @param {MatSelectChange} $event is the account type selected
  * @description Updates the account type selected into the form
  */
  onChangeAccountType($event: MatSelectChange): void {
    const result = this.listAccountType.filter((accountType) => {
      return accountType.name === $event.value;
    });
    this.form.get('accountType').setValue(result[0].name);
    this.form.get('accountTypeCode').setValue(result[0].id);
  }

  /**
  * @description Shows a modal to confirm bank information
  */
  openModalConfirmeSave(): void {
    this.form.markAllAsTouched();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.titles.titleConfirmeSave,
      licen: this.type,
      plate: this.vehicle.id,
      typeAccount: null,
      ownerAccount: null,
    };
    if (!this.stateFormBankAccount()) {
      return;
    }
    dialogConfig.data.typeAccount = this.type === "advance" ? 'Anticipos' : 'Saldos';
    dialogConfig.data.ownerAccount = this.form.get('name').value;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    if (this.hasDocumentsException || (['advance', 'balance'].includes(this.type) &&
      (!this.documentIsDifferent || (this.documentIsDifferent && !this.form.get('authorization.value'))))) {
      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.state) {
          if (this.type === 'advance')
            this.saveAccountAdvance();
          else if (this.type === 'balance')
            this.saveAccountBalance();
        }
      });
    } else
      this.snackBarService.openSnackBar('Es necesario cargar una carta de autorización para inscribir esta cuenta', undefined, 'error');

  }
  /**
  * @description Saves the balance's bank information
  */
  private saveAccountBalance() {
    const dataVehicleBank = this.vehicle;
    if (this.form.get('tenureContract') && this.form.get('tenureContract').value) {
      dataVehicleBank.tenureContract = this.form.get('tenureContract').value;
    }

    dataVehicleBank.bankAccountBalance = this.form.value;
    dataVehicleBank.bankAccountBalance.state = null;
    // dataVehicleBank.bankAccountBalance.address = this.fragmentedAddressComponent.form.value.nomenclature + ' ' + this.fragmentedAddressComponent.form.value.mainRoad + ' # ' + this.fragmentedAddressComponent.form.value.secundaryRoad + ' - ' + this.fragmentedAddressComponent.form.value.complementaryRoad;
    this.saveFilesDocumentsPayment()
      .then((success) => {
        if (success.status) {
          this.spinner.show();
          this.vehiclesService.sendDataRegisterBankAccount(dataVehicleBank).subscribe(
            (success) => {
              this.spinner.hide();
              this.vehiclesService.vehicleSelected = success;
              this.snackBarService.openSnackBar('Información bancaria para saldos registrada correctamente');
              this.emitToParent.emit(dataVehicleBank);
            },
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Ocurrió un error al guardar la informacion', undefined, 'error');
            }
          );
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error al guardar los archivos', undefined, 'error');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al guardar los archivos', undefined, 'error');
      });
  }
  /**
  * @description Saves the advance's bank information
  */
  private saveAccountAdvance() {
    let dataVehicleBank = this.vehicle;

    dataVehicleBank.bankAccountAdvance = this.form.value;
    dataVehicleBank.bankAccountAdvance.state = null;

    if (this.form.get('tenureContract') && this.form.get('tenureContract').value) {
      dataVehicleBank.tenureContract = this.form.get('tenureContract').value;
    }
    this.spinner.show();
    this.saveFilesDocumentsPayment()
      .then((success) => {
        if (success.status) {
          this.vehiclesService.sendDataRegisterBankAccount(dataVehicleBank).subscribe(
            (success) => {
              this.spinner.hide();
              this.vehiclesService.vehicleSelected = success;
              this.emitToParent.emit(dataVehicleBank);
              this.snackBarService.openSnackBar('Información bancaria para anticipos registrada correctamente');
            },
            (error) => {
              this.spinner.hide();
              this.snackBarService.openSnackBar('Ocurrió un error al guardar la información', undefined, 'error');
            }
          );
        } else {
          this.spinner.hide();
          this.emitToParent.emit(false);
          this.snackBarService.openSnackBar('Ocurrió un error al guardar los archivos', undefined, 'error');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al guardar los archivos', undefined, 'error');
      });
  }

  /**
  * @returns {Promise<any>} returns the result of trying upload the documents
  * @description Uploads the documents of each type of bank account
  */
  private saveFilesDocumentsPayment(): Promise<any> {
    return new Promise((resolve, reject) => {
      const files: { path, id, file: FileStorage }[] = []
      this.fileComponent.toArray().map((component) => {
        const file = component.getLoadFileStorage();
        if (
          !this.utils.isEmpty(file) &&
          !this.utils.isEmpty(file.path) &&
          !this.utils.isEmpty(file.file) &&
          !this.utils.isEmpty(file.id) &&
          file.id === this.type
        ) {
          files.push(file);
        }
      });
      if (files.length) {
        this.spinner.show();
        let count = 1;
        files.map((file: { path, file: FileStorage, id }) => {
          this.fileService.addMultipleFilesToStorages(
            file.path,
            file.file.fileData.name,
            file.file.fileData.file
          )
            .then((success) => {
              if (count === files.length) {
                resolve(success);
                return;
              } else {
                count++;
              }
            })
            .catch((error) => {
              reject(error);
              return;
            });
        });
      } else {
        resolve({ status: true });
      }
    });
  }

  /*public openModalConfirmThirdParty() {
    if (this.vehicle && this.vehicle.bankAccountBalance && this.vehicle.bankAccountBalance.state && !this.vehicle.bankAccountBalance.state.active) {
      this.vehicle.bankAccountBalance.licensePlate = this.vehicle.id;
      this.dialogService.openDialog({ title: 'Deseas crear el tercero?' }).then(() => {
        this.vehiclesService.createThirdParty('balance', this.vehicle.id, this.vehicle.bankAccountBalance).subscribe(
          (success) => {
            this.snackBarService.openSnackBar('Tercero registrado correctamente');
          },
          (error) => {
            this.snackBarService.openSnackBar('Tercero registrado correctamente', undefined, 'error');
          }
        );
      });
    } else {
      this.snackBarService.openSnackBar('La cuenta bancaria de saldos debe estar aprobada para crear el tercero', undefined, 'alert');
    }
  }*/

  /**
  * @returns {boolean} returns false if there is some field invalid (and show the respective message) returns true in othercase
  * @description Verifies if the form is valid for each field
  */
  private stateFormBankAccount(): boolean {
    if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre del titular')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('documentTypeId'), 'tipo de documento')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('documentTypeName'), 'tipo de documento')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('document'), 'documento', 3, 10)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('email'), 'correo electrónico', null, 100)) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('phone'), 'celular')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('address'), 'dirección')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('bank.id'), 'nombre del banco')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('bank.name'), 'nombre del banco')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('bank.code'), 'nombre del banco')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('accountType'), 'tipo de cuenta')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('accountTypeCode'), 'tipo de cuenta')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('accountNumber'), 'número de cuenta')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('certificate'), 'certificado bancario')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('rut'), 'rut')) return false;
    else if (this.utils.errorMessagesCustomized(this.form.get('authorization'), 'autorización de pago a tercero')) return false;
    return true;
  }

  public hasValidator(control: FormControl, type: string): boolean {
    return !!control.validator && control.validator({} as AbstractControl).hasOwnProperty(type);
  }

  get hasDocumentsException(): boolean {
    let exceptionsCompanies = [Companies.companiesNIT.SEGURIDAD_EXTREMA];
    return this.authService.getCompanySaaS() && exceptionsCompanies.includes(this.authService.getCompanySaaS().companyId);
  }

  /**
  * @returns {boolean} returns true if the form's document is different to driver's document and owner's document (and admin's document if exists)
  * @description Verifies the difference between form's document to driver and owner's document (and admin's document if exists)
  */
  get documentIsDifferent() {
    if (this.form.get('document') && this.vehicle && this.vehicle.driver && this.vehicle.owner) {
      if (this.vehicle.administrator)
        return (this.form.get('document').value != this.vehicle.owner.document &&
          this.form.get('document').value != this.vehicle.administrator.document &&
          this.form.get('document').value != this.vehicle.driver.document);
      return (this.form.get('document').value != this.vehicle.owner.document && this.form.get('document').value != this.vehicle.driver.document);
    }
    return false;
  }

  /**
  * @returns {boolean} returns true if the user has permission to update the bank information
  * @description Verifies the permission to update bank information
  */
  get showBtnSave(): boolean {
    return (
      this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.updateBankAdvance) &&
      this.type === 'advance'
    ) || (
        this.permissionRole.hasPermission(this.permission.administration.module, this.permission.administration.updateBankBalance) &&
        this.type === 'balance'
      );
  }
}
