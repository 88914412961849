import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Dialog } from 'src/app/core/resources/dialog';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { InputDocumentUserComponent } from 'src/app/shared/input-document-user/input-document-user.component';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { User } from 'firebase';
import { CreateDriverComponent } from '../../administration/vehicles/create-driver/create-driver.component';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Subscription } from 'rxjs';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-change-driver',
  templateUrl: './change-driver.component.html',
  styleUrls: ['./change-driver.component.scss']
})
export class ChangeDriverComponent implements OnInit {
  documentUserControl: FormControl = new FormControl('', Validators.required);
  documentUserSub: Subscription;
  expeditionDateField: FormControl = new FormControl('', Validators.required);
  validate: string = '';
  optionsDocumentUser: OptionsAutocomplete = {
    title: 'Ingresa el número de documento',
    mustAcceptTerms: true
  }
  searchButton: boolean = false;
  dateFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);
    d.setHours(0, 0, 0, 0);
    return d <= currentDate;
  };
  constructor(
    public dialogRef: MatDialogRef<ChangeDriverComponent>,
    @Inject(MAT_DIALOG_DATA)
    public paramsDialog: {
      title;
      label;
      inputFormControl;
      idVehicle;
      options: { documentTypeId; typeUser };
      linkDriverWithoutVehicle: boolean;
    },
    public utils: Utils,
    private dialogUtils: Dialog,
    private dialog: MatDialog,
    public snackBarService: SnackBarService,
    public dateFormatPipe: DateFormatPipe,
    public adminUserService: AdminUsersService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.setOptions();
    this.setSubscriptionDocument();
  }

  setOptions() {
    this.paramsDialog && this.paramsDialog.title === 'Modificación de conductor' ? this.optionsDocumentUser['type'] = 'editDriver' : null;
    this.optionsDocumentUser['title'] = this.paramsDialog && this.paramsDialog.label ? this.paramsDialog.label : '';
    this.optionsDocumentUser['typeUser'] = this.paramsDialog && this.paramsDialog.options && this.paramsDialog.options.typeUser ? this.paramsDialog.options.typeUser : '';
    this.optionsDocumentUser['createDriver'] = true;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  setSubscriptionDocument() {

    this.documentUserSub = this.documentUserControl.valueChanges
      .subscribe(value => {
        if (value) {
          if (value === 'createDriver') this.openDialogCreateDriver();
        }
      })

  }

  onConfirmUser(body) {
    this.dialogRef.close({
      state: true,
      data: body,
    });
  }

  confirmSelectedUser() {
    this.validate = 'touched';
    if (this.documentUserControl.invalid || !this.documentUserControl.value.information || !this.documentUserControl.value.information.name) {
      this.snackBarService.openSnackBar(
        Fmt.string(FormMessages.NOT_USER_SELECTED, 'un usuario'),
        undefined,
        "alert"
      );
    } else if (
      !this.documentUserControl.value.expeditionDateId &&
      this.expeditionDateField.invalid
    ) {
      this.expeditionDateField.markAsTouched();
      this.snackBarService.openSnackBar(
        Fmt.string(FormMessages.NOT_USER_SELECTED, 'una fecha de expedición'),
        undefined,
        "alert"
      );
    } else {
      this.dialogUtils
        .openDialog({
          title: `¿Estás seguro que deseas asignar a ${this.documentUserControl.value.information.name} como conductor?`,
        })
        .then(() => {
          let body = this.documentUserControl.value;
          if (!this.documentUserControl.value.expeditionDateId) {
            const dateFormatted = this.dateFormatPipe.transform(
              this.expeditionDateField.value,
              "only-date"
            );
            body["expeditionDateId"] = dateFormatted
          }
          this.onConfirmUser(body);
        }).catch(err => err);
    }
  }


  openDialogCreateDriver() {
    const dialogConfig = new MatDialogConfig();
    if (!this.paramsDialog || !this.paramsDialog.idVehicle) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'alert');
      return;
    };

    dialogConfig.data = {
      licensePlate: this.paramsDialog.idVehicle,
      linkDriverWithoutVehicle: this.paramsDialog.linkDriverWithoutVehicle
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CreateDriverComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.state && result.data) {
        let body = result.data;
        if (this.paramsDialog && this.paramsDialog.linkDriverWithoutVehicle) {
          let newDriver = await this.createDriverWithoutVehicle(result.data);
          if (newDriver) body = newDriver;
        }
        if (body){
          this.dialogUtils.openDialog({
            title: `¿Estás seguro que deseas asignar a ${result.data.information.name} como conductor?`,
            }).then(() => {  
              this.onConfirmUser(body);
            }).catch(err => err);
        } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'alert');
              
      } else {
        this.documentUserControl.setValue('');
        this.optionsDocumentUser['initialValue'] = '';
        this.optionsDocumentUser = { ...this.optionsDocumentUser };
      }
    });
  }
  async createDriverWithoutVehicle(driverToCreate: User) {
    try {
      this.spinner.show();
      const driver: User = await this.adminUserService.validateUser(driverToCreate, true).toPromise();
      return driver;
    } catch (error) {
      this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      return null;
    } finally {
      this.spinner.hide();
    }
  }
  
  searchButtonEvent($event: boolean){
    this.searchButton = $event;
  }

  ngOnDestroy() {
    if (this.documentUserSub) this.documentUserSub.unsubscribe();
  }
}
