import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ToPayLoads } from 'src/app/core/interfaces/toPayLoads';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-massive-cargo-operation',
  templateUrl: './massive-cargo-operation.component.html',
  styleUrls: ['./massive-cargo-operation.component.scss']
})
export class MassiveCargoOperationComponent {
  consecutivesControl: FormControl = new FormControl('', Validators.required);
  consecutiveList: Array<string> = [];
  availableList: Cargo[] = [];
  unavaliableList: Cargo[] = [];
  nonExistentList: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<MassiveCargoOperationComponent>,
    public utils: Utils,
    public snackbarService: SnackBarService,
    public spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      title: string,
      subtitle: string,
      label: string,
      confirmButton: string,
      method: Function,
      validationToCheckValid?: Function,
      filters?: string,
    },
  ) { }

  processConsecutives() {
    this.consecutiveList = [];
    this.availableList = [];
    this.unavaliableList = [];
    this.nonExistentList = [];
    if (this.consecutivesControl.invalid)
      return this.snackbarService.openSnackBar(FormMessages.AT_LEAST_ONE_ELEMENT, undefined, 'alert');
    if (this.consecutivesControl.value.endsWith(',')) this.consecutivesControl.setValue(this.consecutivesControl.value.slice(0, -1));
    this.consecutiveList = this.consecutivesControl.value.split(",").reduce((arr, consecutive) => {
      if (!arr.includes(consecutive)) arr.push(consecutive);
      return arr;
    }, []);
    if (!this.consecutiveList.length)
      return this.snackbarService.openSnackBar(FormMessages.AT_LEAST_ONE_ELEMENT, undefined, 'alert');
    this.processCargos();

  }

  private async findCargo(consecutive: string) {
    let cargos = [];
    try {
      let filters = this.dialogParams.filters ? this.dialogParams.filters : sessionStorage.getItem('_filterListStatic');
      cargos = await this.dialogParams.method(`consecutive=${consecutive}${filters}`, 1, 3).toPromise();
    } catch (error) { }
    if (cargos && cargos.length) {
      if ("cargo" in cargos[0] && !this.utils.isDefined(cargos[0].cargo.validatedBankAccount))
        cargos[0].cargo['validatedBankAccount'] = cargos[0].validatedBankAccount;
      if (!this.dialogParams.validationToCheckValid) this.availableList.push("cargo" in cargos[0] ? cargos[0].cargo : cargos[0])
      else if (!this.dialogParams.validationToCheckValid("cargo" in cargos[0] ? cargos[0].cargo : cargos[0])) this.unavaliableList.push("cargo" in cargos[0] ? cargos[0].cargo : cargos[0])
      else this.availableList.push("cargo" in cargos[0] ? cargos[0].cargo : cargos[0])
    }
    else this.nonExistentList.push(parseInt(consecutive));
  }

  private async processCargos() {
    this.spinner.show();
    for (let consecutive of this.consecutiveList) {
      await this.findCargo(consecutive);
    }
    this.spinner.hide();
    if (this.nonExistentList.length === this.consecutiveList.length)
      return this.snackbarService.openSnackBar("Ninguno de los consecutivos está habilitado, vuelve a intentarlo", undefined, 'alert');
    this.availableList.forEach(cargo => cargo.checked = true);
    this.dialogRef.close({
      availableList: this.availableList,
      unavaliableList: this.unavaliableList,
      nonExistentList: this.nonExistentList,
      consecutiveList: this.consecutiveList
    });
  }

}
