import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Model } from 'src/app/core/interfaces/user';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.scss'],
  providers: [AuthService, ManualCreationCargoService, Model]
})
export class CreateDriverComponent extends ReactiveForm {

  companySelected: FormControl = new FormControl('', Validators.required);
  companySub: Subscription;
  licensePlate: FormControl = new FormControl();
  optionsCompany = {
    title: 'Compañía',
    optionAll: false
  };
  validateCompany: string = '';
  constructor(
    public utils: Utils,
    private snackBarService: SnackBarService,
    public formBuilder: FormBuilder,
    public modelUser: Model,
    public matDialog: MatDialog,
    private patterns: Patterns,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<CreateDriverComponent>,
  ) {
    super(
      formBuilder,
      modelUser.modelCreateDriver,
      modelUser.modelCreateDriver,
      modelUser.modelCreateDriver
    );
    this.setValidatorsForm(
      modelUser.modelCreateDriverValidators,
      this.form
    );
    this.companySelected.setValidators([
      Validators.required,
    ]);
    this.setDataParamsDialog();
    this.lisenerCompanySelected();
  }

  /**
  * @description Makes a subscription companySelected value changes to update the company's field of the form
  */
  private lisenerCompanySelected(): void {
    this.companySub = this.companySelected.valueChanges.subscribe(() => {
      this.form.get('operationId').setValue(this.companySelected.value && this.companySelected.value.companyId ? this.companySelected.value.companyId : '');
      this.form.get('exclusive').setValue(this.companySelected.value && this.companySelected.value.exclusive ? this.companySelected.value.exclusive : '');
    });
  }

  /**
  * @description Sets the value of licensePlate variable if exists on dialogParams
  */
  private setDataParamsDialog(): void {
    if (this.dialogParams && this.dialogParams.licensePlate) {
      this.licensePlate.setValue(this.dialogParams.licensePlate);
      this.licensePlate.disable();
    }
  }

  /**
  * @returns {boolean} returns true if licensePlate exists in dialogParams
  * @description Verifies the existance of licensePlate in dialogParams
  */
  get preconfigured(): boolean {
    return this.dialogParams && this.dialogParams.licensePlate;
  }

  /**
  * @description Verifies if the form is valid for each field to create a driver
  */
  onSubmit(): void {
    this.validateCompany = 'touched';
    this.form.markAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('information.documentTypeId'), 'tipo de identificación')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('information.documentTypeName'), 'tipo de identificación')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('information.document'), 'documento', AmountsCargoEnum.MIN_DOCUMENT_LENGTH, this.form.get('information.documentTypeId').value === '3' ? AmountsCargoEnum.MAX_NIT_LENGTH : AmountsCargoEnum.MAX_DOCUMENT_LENGTH)) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('information.name'), 'nombre')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('phone'), 'celular')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('expeditionDateId'), 'fecha de expedición del documento')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('email'), 'correo electrónico')) { }
    else if (this.utils.errorMessagesCustomized(this.form.get('operationId'), 'compañía')) { }
    else if (!this.stateCompanySelected) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.GENERAL_ERROR, "compañía seleccionada"), undefined, 'alert');
    } else
      this.createDriver();
  }

  /**
  * @description Sends on dialogRef closing the params to create a driver
  */
  private createDriver(): void {
    const data = this.utils.clone(this.form.value);
    if (this.dialogParams && this.dialogParams.linkDriverWithoutVehicle) data.role = 'Driver';
    this.dialogRef.close({
      state: true,
      data
    });
  }

  /**
  * @returns {boolean} returns true if the companySelected variable is filled and valid
  * @description Verifies the validity of companySelected variable
  */
  private get stateCompanySelected(): boolean {
    return this.utils.isDefined(this.companySelected) && this.utils.isDefined(this.companySelected.value) &&
      this.patterns.ONLY_NUMBERS.test(this.companySelected.value.companyId) &&
      this.utils.isDefined(this.companySelected.value.exclusive);
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }

}
