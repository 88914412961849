<div class="trailers px-2 p-sm-3">
    <div class="col-md-12 mb-0 h-100-margin trailers-list-container pl-0 pr-1 p-sm-3">
        <div class="row m-0 p-0">
            <div class="col-md-8 col-sm-12 m-0 p-0">
                <form class="row m-0 p-0 pl-3" [formGroup]="filters">
                    <mat-form-field class="col-md-3 col-sm-12 m-0 p-0" appearance="outline">
                        <mat-label>Placa</mat-label>
                        <input appLicensePlate matInput type="text" formControlName="id">
                    </mat-form-field>
                    <mat-form-field class="col-md-3 col-sm-12 m-0 p-0" appearance="outline">
                        <mat-label>Propietario</mat-label>
                        <input matInput type="text" formControlName="owner">
                    </mat-form-field>
                    <mat-form-field class="col-md-4 col-sm-12 m-0 p-0" appearance="outline">
                        <mat-label>Placa de vehículo asociado</mat-label>
                        <input matInput type="text" formControlName="vehicle">
                    </mat-form-field>
                </form>
            </div>
            <div class="col-md-4 col-sm-12 m-0 px-2 p-sm-0 button-container align-items-baseline">

                <button mat-raised-button color="accent" (click)="search()">
                    <i class="fas fa-search"></i>
                </button>
                <button mat-raised-button color="primary" class="primary-secondary" (click)="cleanFilters()">
                    <i class="fas fa-trash"></i>
                </button>
                <button mat-raised-button color="accent" (click)="create()">
                    <i class="fas fa-plus"></i>
                    Crear
                </button>
            </div>
        </div>
        <br>
        <div class="container-list-body" infinite-scroll [infiniteScrollDistance]="paginationList.getScrollDistance()"
            [infiniteScrollThrottle]="paginationList.getThrottle()" [scrollWindow]="false" (scrolled)="onScrollDown()">
            <div class="col-md-12 mb-md-0 mb-12" *ngFor="let trailer of paginationList.getList(); let i = index;">
                <!-- <app-item-vehicle [vehicle]="vehicle" [stateAccount]="stateAccount" select-item-list
          [active]="service.getActiveItem(stateAccount, vehicle)" [item]="vehicle"
          [list]="service.paginationList.getList()" (onSelectItem)="onSelectItem($event)">
        </app-item-vehicle> -->
                <app-item-trailer [trailer]="trailer"></app-item-trailer>
            </div>
        </div>

    </div>
</div>
