<div class="container">
  <div id="mainFreightMethodPayment" class="col-md-12 mb-md-0 mb-12">
    <div class="row">
      <!-- Title -->
      <div class="col-md-12 mb-md-0 mb-12">
        <label class="title">
          Escriba las observaciones
        </label>
      </div>
      <!-- Boxs -->
      <div class="container">
        <div class="col-md-12 mb-md-0 mb-12 wraper-additional-service">
          <mat-card-content>
            <mat-form-field appearance="standard">
              <mat-label>Observaciones<span class="asterisk">*</span></mat-label>
              <textarea (blur)="removeLeadingSpaces()" matInput
                [formControl]="manualCreationCargoService.cargoForm.controls.observation"></textarea>
              <mat-error *ngIf="manualCreationCargoService.cargoForm.controls.observation.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
              </mat-error>
              <mat-error *ngIf="manualCreationCargoService.cargoForm.controls.observation.errors?.minlength">
                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observaciones', minLengthObservation) }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-content>
            <mat-form-field appearance="standard">
              <mat-label>Observaciones para el conductor</mat-label>
              <textarea matInput
                [formControl]="manualCreationCargoService.cargoForm.controls.observationDriver"></textarea>
            </mat-form-field>
          </mat-card-content>
          <mat-card-content class="mt-3">
            <mat-radio-group [formControl]="manualCreationCargoService.cargoForm.controls.zone"
              class="d-flex flex-column">
              <mat-label class="text-bold">Publicación</mat-label>
              <mat-radio-button value="private" class="ml-2"><span class="zone-radio-button">
                  <b>Privada:</b> Visible solo para los conductores
                  directamente asociados a mi compañía.
                </span></mat-radio-button>
              <mat-radio-button value="public" class="ml-2"><span class="zone-radio-button">
                  <b>Pública:</b> Visible para todos los
                  conductores de la comunidad LoggiApp.
                </span></mat-radio-button>
            </mat-radio-group>
          </mat-card-content>
        </div>
      </div>
      <!-- Button -->
      <div class="col-12 button-container justify-content-center mt-3">
        <button mat-raised-button class="secondary-sharp mr-4" (click)="stepBack()">Atrás</button>
        <app-request-button></app-request-button>
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
      </div>
    </div>
  </div>
</div>