<div class="d-flex flex-column position-relative">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <label class="title-component mx-5 mt-3 text-center">
    {{ paramsDialog.title }}
  </label>
  <div class="mx-4">
    <app-input-document-user [options]="optionsDocumentUser" [inputFormControl]="documentUserControl"
      [validate]="validate" (searchButtonClick)="searchButtonEvent($event)">
    </app-input-document-user>
    <div *ngIf="documentUserControl?.value?.information && !(documentUserControl?.value?.expeditionDateId)"
      class="extra-data-user">
      <p><b>Por favor introduzca la fecha de expedición del documento del usuario:</b></p>
      <mat-form-field appearance="standard" class="expedition-date-field">
        <mat-label>Fecha de expedición del documento<span class="asterisk"
            *ngIf="utils.isRequiredField(expeditionDateField)">*</span></mat-label>
        <input class="cursor-pointer" matInput [matDatepicker]="picker" [matDatepickerFilter]="dateFilter"
          [formControl]="expeditionDateField" placeholder="Seleccione una fecha" (click)="picker.open()" readonly>
        <mat-error *ngIf="expeditionDateField.errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="documentUserControl?.value?.information?.name; else spinner" class="text-center mb-3">
      <b style="font-weight: bold;">Usuario seleccionado:</b> {{ documentUserControl?.value?.information?.name }}
    </div>
    <ng-template #spinner>
      <div *ngIf="searchButton" class="d-flex w-100 align-items-center justify-content-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-template>
  </div>
  <div class="mb-3 mt-2 button-container">
    <button mat-raised-button class="button-width-auto mb-2 bg-color-primary" color="primary"
      (click)="dialogRef.close()">
      Cerrar
    </button>
    <button *ngIf="documentUserControl?.value?.information?.name" mat-raised-button color="primary" type="button" class="button-width-auto mb-2 bg-color-primary"
      (click)="confirmSelectedUser()">
      {{paramsDialog.title === 'Modificación de conductor' ? 'Cambiar' : 'Asignar'}}
    </button>
  </div>
</div>