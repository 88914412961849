import { Component, OnInit, HostListener, ViewChild, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationComponent } from '../notifications/notifications.component';
import { Role } from 'src/app/core/interfaces/role';
import { Company } from 'src/app/core/interfaces/company';
import { AngularFireStorage } from '@angular/fire/storage';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { fromEvent } from 'rxjs';
import { InactivityModalComponent } from '../inactivity-modal/inactivity-modal.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Utils } from 'src/app/core/resources/utils';
import { CompaniesService } from 'src/app/modules/administration/companies/list-companies.service';
import { AccountService } from 'src/app/modules/account/account.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { DynamicFirebaseService } from 'src/app/core/services/dynamic-firebase.service';
import { CompanyResource } from 'src/app/core/interfaces/companyResource';
// declare var FB: any;

// window['fbAsyncInit'] = () => {
//   FB.init({
//     appId: '203328834846268',
//     autoLogAppEvents: true,
//     xfbml: true,
//     version: 'v10.0'
//   });
// };

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('appNotification', { static: false }) appNotification: NotificationComponent;
  public environment = environment;
  companyUser: Company;
  businessUser: Company;
  userName: string;
  urlLogoSubcompany: string;
  secondsToShow = 300;
  onClick$;
  interval;
  isInactivityModalOpen = false;
  public primaryLogo: string;
  public secondaryLogo: string;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private modalService: NgbModal,
    private angularFireStorage: AngularFireStorage,
    public titleService: Title,
    private utils: Utils,
    private companiesService: CompaniesService,
    private accountService: AccountService,
    private companyService: CompanyService,
    public dynamicService: DynamicFirebaseService
  ) { }

  ngOnInit() {
    if (this.authService.sessionActive()) {
      this.resetTimer();
      this.onClick$ = fromEvent(document, 'mousedown').subscribe(() => {
        this.resetTimer();
      });
      this.companyUser = this.authService.getCompany();
      if (this.authService.getUserSession().information.name) {
        this.userName = this.authService.getUserSession().information.name.split(' ')[0];
      }
      if (this.companyUser && this.companyUser.name) {
        this.businessUser = {
          name: this.companyUser.name,
          companyId: this.companyUser.companyId
        };
      }
    }
    this.getCurrentLogo();
  }
  resetTimer() {
    clearInterval(this.interval);
    if (!this.isInactivityModalOpen)
      this.interval = setInterval(() => {
        if (!window.location.hash.includes('analytics'))
          this.openInactivityModal();
      }, this.secondsToShow * 1000);
  }

  openInactivityModal() {
    this.isInactivityModalOpen = true;
    this.resetTimer();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(InactivityModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => { this.isInactivityModalOpen = false; this.resetTimer() });

  }

  openModalConfirmLogOut(modal) {
    this.modalService.open(modal, { keyboard: false, centered: true, ariaLabelledBy: 'modal-basic-title' });
  }

  closeModalConfirmLogOut() {
    this.modalService.dismissAll();
  }

  private async getCurrentLogo() {
    try {
      if (this.authService.sessionActive()) {
        //Validate by company's logo
        let companyUserLogo: string;
        if (this.companyUser && this.companyUser.logo) companyUserLogo = await this.getUrlFileStorageLogo(this.companyUser.companyId, this.companyUser.logo);
        if (!companyUserLogo) companyUserLogo = await this.getUrlCompanyResourceLogo(this.companyUser.companyId);
        if (companyUserLogo) {
          if (this.authService.userIsFromRootNit()) {
            this.primaryLogo = companyUserLogo;
            return;
          } else this.secondaryLogo = companyUserLogo;
        }
        //Validate by Holder company's logo
        let holderCompanyLogo: string;
        let holderCompany: Company = this.companyService.holderCompany;
        if (holderCompany && holderCompany.logo && holderCompany.companyId) holderCompanyLogo = await this.getUrlFileStorageLogo(holderCompany.companyId, holderCompany.logo);
        if (!holderCompanyLogo) holderCompanyLogo = await this.getUrlCompanyResourceLogo(sessionStorage.getItem('_companyId'));
        if (holderCompanyLogo) {
          this.primaryLogo = holderCompanyLogo;
          return;
        }
      }
      //Validate by default
      this.primaryLogo = this.utils.getDefaultUrlLogo('mainLogo');
    } catch (error) {
      console.error(error);
      this.primaryLogo = this.utils.getDefaultUrlLogo('mainLogo');
    }

  }

  async logOut() {
    if (sessionStorage.getItem('_companyId')) sessionStorage.removeItem('_companyId');
    const responseSetToken = await this.authService.updateUId();
    if (responseSetToken) {
      this.authService.logOut().then(
        async () => {
          this.userName = '';
          try {
            await this.dynamicService.deleteAllFirebaseInstances();
          } catch { }
          this.closeModalConfirmLogOut();
          this.authService.clearSession();
          location.href = '/';
          location.reload();
        },
        (error) => {
          console.error(error)
        }
      );
    }
  }

  goHome() {
    this.router.navigate(['/home']);
  }

  refreshNotifications() {

    this.appNotification.refreshNotifications();
    this.appNotification.getNotifications();
  }

  async getUrlFileStorageLogo(companyId: string, companyLogo: string) {
    try {
      const path = '/company/' + companyId + '/' + companyLogo;
      const url = await this.getDownloadURL(path);
      return url;
    } catch (error) {
      return null;
    }

  }

  async getUrlCompanyResourceLogo(companyId: string) {
    try {
      const resources: CompanyResource[] = await this.companiesService.getResourcesCompany(companyId).toPromise();
      if (!resources || !resources.length) return null;
      const resource = resources.find(resource => resource.fileType === "logo");
      if (!resource || !resource.path) return null;
      const url = await this.getDownloadURL(resource.path);
      return url;
    } catch (error) {
      return null;
    }
  }

  async getDownloadURL(path) {
    const pathReference = this.angularFireStorage.ref(path);
    const urlCompanyLogo = await pathReference.getDownloadURL().toPromise();
    if (urlCompanyLogo) return urlCompanyLogo;
    return null;
  }

}
