import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material";
import {
  MatDialog,
  MatDialogConfig
} from "@angular/material/dialog";
import { MatAutocompleteTrigger } from "@angular/material";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "src/app/core/interfaces/user";
import { Utils } from "src/app/core/resources/utils";
import { AccountService } from "src/app/modules/account/account.service";
import { AdminUsersService } from "src/app/modules/administration/admin-users/admin-users.service";
import { DialogComponent } from "../dialog/dialog.component";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { OptionsAutocomplete } from "src/app/core/interfaces/optionsAutocomplete";
import { AmountsCargoEnum } from "src/app/core/enums/amountsCargo.enum";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { RequestTermsComponent } from "./request-terms/request-terms.component";
import { LegalTermsService } from "src/app/modules/administration/legal-terms/legal-terms.service";
import { RequestTermsDialogComponent } from "src/app/modules/administration/request-terms-dialog/request-terms-dialog.component";
@Component({
  selector: "app-input-document-user",
  templateUrl: "./input-document-user.component.html",
  styleUrls: ["./input-document-user.component.scss"],
})
export class InputDocumentUserComponent implements OnInit {
  @Input() options: OptionsAutocomplete;
  @Input() inputFormControl: FormControl;
  @Input() validate: string = '';

  //Output
  @Output() searchButtonClick: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger, { static: false })
  trigger: MatAutocompleteTrigger;
  documentInputFormControl: FormControl = new FormControl();
  searchButtonPressed: boolean = false;
  listUsers: Observable<User[]>;
  showCreateDriverOption: boolean = false;
  filteredUsers: any[] = [];
  private lastValue = '';

  constructor(
    private adminUsersService: AdminUsersService,
    public utils: Utils,
    public spinner: NgxSpinnerService,
    public snackbarservice: SnackBarService,
    public dateFormatPipe: DateFormatPipe,
    private matDialog: MatDialog,
    private termsAndConditionsService: LegalTermsService,
    private accountService: AccountService
  ) {

  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.options && changes.options.currentValue) {
        this.setValidatorsInput();
        if (changes.options.currentValue.initialValue || changes.options.currentValue.initialValue === "") {
          if (changes.options.currentValue.initialValue === "") {
            this.showCreateDriverOption = false;
            this.searchButtonPressed = false;
            this.filteredUsers = [];
            this.documentInputFormControl.setValue('');
          } else {
            this.searchButtonPressed = true;
            this.documentInputFormControl.setValue({
              information: { document: changes.options.currentValue.initialValue }
            });
          }

        }
        if (changes.options.currentValue.showUserName && this.options.initialValue) this.searchUser(this.options.initialValue);
      }

      if (changes.validate) {
        switch (this.validate) {
          case 'touched':
            this.documentInputFormControl.markAsTouched();
            break;
          case 'untouched':
            this.documentInputFormControl.markAsUntouched();
            break;
          case 'enable':
            this.documentInputFormControl.enable();
            break;
          case 'disable':
            this.documentInputFormControl.disable();
            break;
          case 'disable&untouched':
            this.documentInputFormControl.markAsUntouched();
            this.documentInputFormControl.disable();
            break;
          case 'setValidators':
            this.setValidatorsInput(true);
            break;
          case 'clearValidators':
            this.documentInputFormControl.clearValidators();
            this.documentInputFormControl.updateValueAndValidity();
            break;
          default:
            break;
        }
      }
    }
  }

  setValidatorsInput(isRequired?: boolean) {
    const validator = this.inputFormControl && this.inputFormControl.validator ? this.inputFormControl.validator({} as AbstractControl) : '';
    const maxLength = this.options && this.options.documentTypeId && this.options.documentTypeId === '3' ? AmountsCargoEnum.MAX_NIT_LENGTH : AmountsCargoEnum.MAX_DOCUMENT_LENGTH;
    if ((validator && validator.required) || isRequired) this.documentInputFormControl.setValidators([Validators.required, Validators.maxLength(maxLength), Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH)]);
    else this.documentInputFormControl.setValidators([Validators.maxLength(maxLength), Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH)]);
    this.documentInputFormControl.updateValueAndValidity();
  }

  private filterUsers(value: string): Observable<User[]> {
    this.spinner.show();
    this.documentInputFormControl.disable();
    return this.adminUsersService
      .getUsersList("user", 1, 4, null, value)
      .pipe(
        map((response) => {
          this.showCreateDriverOption = true;
          this.documentInputFormControl.enable();
          this.spinner.hide();
          if (response) {
            return response;
          } else {
            return [];
          }
        })
      );
  }

  displayUserDocument(user?: User): string | undefined {
    try {
      return user ? user.information.document : "";
    } catch (e) {
      return user.toString();
    }
  }

  onSelectUser($event: MatAutocompleteSelectedEvent) {
    const selectedUser = $event.option.value;
    if (selectedUser === "createDriver") {
      this.inputFormControl.setValue(selectedUser);
      return;
    }
    const document: string = selectedUser.information.document;

    //Usuarios existentes
    if (this.options && this.options.typeUser && this.options.typeUser === "driver") {
      this.searchButtonClick.emit(true);
      this.accountService
        .validateEntity(1, document)
        .subscribe((user: User) => {
          if (user && user.information && (user.information.documentTypeId === '3' || user.information.documentTypeName === 'NIT')) {
            this.documentInputFormControl.setValue('');
            this.inputFormControl.setValue(null);
            this.filteredUsers = [];
            this.snackbarservice.openSnackBar("No es posible asignar a una empresa como conductor de un vehículo", undefined, 'error');
          } else if (!this.validPolicies(user) && user && user.information && user.information.name && user.information.document && user.phone) {
            this.requestTermsToExistentUser(user);
          } else {
            this.emitValidTerms(this.options.typeUser);
            this.inputFormControl.setValue(selectedUser);
          }
        });
    } else {
      this.emitValidTerms(this.options.typeUser);
      this.inputFormControl.setValue(selectedUser);
    }
  }

  changeValue(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchButtonClick.emit(false);
    if (value != this.lastValue) {
      this.searchButtonPressed = false;
      if (this.inputFormControl)
        this.inputFormControl.setValue('');
      this.showCreateDriverOption = false;
      this.filteredUsers = [];
      this.lastValue = value;
    }
  }

  searchUser(input: HTMLInputElement | any) {
    if (!this.documentInputFormControl.value) {
      this.snackbarservice.openSnackBar(
        "Por favor digite un documento",
        undefined,
        "alert"
      );
      return;
    };
    if (this.documentInputFormControl.invalid) {
      this.snackbarservice.openSnackBar(FormMessages.GENERAL_ERROR_FIELD, undefined, 'alert');
      return;
    }
    if (!this.options.showUserName) input.focus();
    this.searchButtonPressed = true;
    const data = this.options.showUserName && this.options.initialValue ? this.options.initialValue : this.documentInputFormControl.value;
    const value =
      typeof data === "object" ? (data ? data.information.document : "") : data;
    if ((typeof data === "object" || typeof data === "string") && value.trim().length >= 1) {
      this.filterUsers(value || "").subscribe(
        (users) => {
          if (this.options.showUserName && users.length) this.documentInputFormControl.setValue(users[0])
          if (this.inputFormControl && this.documentInputFormControl.value && this.documentInputFormControl.valid && !users.length)
            this.inputFormControl.setValue(this.documentInputFormControl.value);
          this.filteredUsers = users;
          //Busqueda por conductores no registrados desde accesos distintos al cambio de conductor
          if (!this.filteredUsers.length && this.options && !this.options.createDriver && this.options.typeUser === "driver") {
            const document: string = typeof data === "object" ? data.information.document : data;
            this.accountService
              .validateEntity(1, document)
              .subscribe((user: User) => {

                if (this.options.typeUser !== "driver" || (user && user.information && user.information.documentTypeId === '3' && user.information.documentTypeName === 'NIT')) {
                  this.emitValidTerms(this.options.typeUser);
                } else if (this.options.typeUser === "driver" && users.length === 0 && this.options.documentTypeId !== '3') {
                  if (this.options.mustAcceptTerms) {
                    this.requestTerms(document);
                  }
                } else if (!this.validPolicies(user) && user && user.information && user.information.name && user.information.document && user.phone) {
                  this.requestTermsToExistentUser(user);
                } else {
                  this.emitValidTerms(this.options.typeUser);
                }
              }, (error) => { });
          }
        });
    } else {
      this.filteredUsers = [];
    }
  }

  emitValidTerms(typeUser: 'driver' | 'owner' | 'admin') {
    switch (typeUser) {
      case 'driver':
        LegalTermsService.driverHasValidTerms.next(true);
        break;
      case 'owner':
        LegalTermsService.ownerHasValidTerms.next(true);
        break;
      case 'admin':
        LegalTermsService.administratorHasValidTerms.next(true);
        break;
    }
  }

  validPolicies(user): boolean {
    return user && user.lastUserTerm && user.lastUserTerm.state &&
      user && user.lastPrivacyPolicies && user.lastPrivacyPolicies.state &&
      user && user.lastPersonalDataPolicy && user.lastPersonalDataPolicy.state
  }

  private requestTerms(document: string) {
    switch (this.options.typeUser) {
      case 'driver':
        LegalTermsService.driverHasValidTerms.next(false);
        break;
      case 'owner':
        LegalTermsService.ownerHasValidTerms.next(false);
        break;
      case 'admin':
        LegalTermsService.administratorHasValidTerms.next(false);
        break;
    }
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.data = document;
    this.matDialog
      .open(RequestTermsComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        (result) => {
          this.documentInputFormControl.setValue('');
          this.inputFormControl.setValue(null);
          this.filteredUsers = [];
          if (!!result) {
            this.snackbarservice.openSnackBar('Se han enviado los términos, condiciones y políticas para su aceptación');
          }
        }
      );
  }

  private requestTermsToExistentUser(user) {
    const phone = user.phone.startsWith('57') ? user.phone.slice(2) : user.phone;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { name: user.information.name, document: user.information.document, phone, user };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    this.matDialog.open(RequestTermsDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => { });
    this.filteredUsers = [];
  }

  //GETTERS
  get documentMaxLength() {
    return this.options && this.options.documentTypeId === '3' ? AmountsCargoEnum.MAX_NIT_LENGTH : AmountsCargoEnum.MAX_DOCUMENT_LENGTH;
  }
  get documentMinLength() {
    return AmountsCargoEnum.MIN_DOCUMENT_LENGTH;
  }
}
