import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { AssignVehicleCargoService } from '../assign-vehicle-cargo/assign-vehicle-cargo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/core/interfaces/user';
import { AdminUsersService } from '../../administration/admin-users/admin-users.service';
import { Router } from '@angular/router';
import { DateFormatPipe } from 'src/app/core/pipe/dateFormat.pipe';

import { Utils } from 'src/app/core/resources/utils';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Companies } from 'src/app/core/resources/companies';

@Component({
  selector: 'app-cargo-turn-form',
  templateUrl: './cargo-turn-form.component.html',
  styleUrls: ['./cargo-turn-form.component.scss']
})
export class CargoTurnFormComponent implements OnInit {
  myFilter = (d: Date | null): boolean => {
    if (!d) return false;
    const currentDate: Date = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() - 2); // Restar 2 días a la fecha actual
    d.setHours(0, 0, 0, 0);
    return d >= currentDate;
  };
  listVehicles: Observable<Vehicle[]>;
  assignVehicleForm: FormGroup;
  vehicleSelected: Vehicle;
  vehicleControl = new FormControl('', Validators.required);
  vehicleSub: Subscription;
  InvalidLicensePLate: boolean = false;
  driver: User = {};
  notVehicle: boolean = false;
  optionsCityOrigin = {
    title: 'Ciudad origen',
  };
  cityControlOrigin: FormControl = new FormControl('', Validators.required);
  cityOriginSub: Subscription;
  optionsCityDestination = {
    title: 'Ciudad destino',
  };
  cityControlDestination: FormControl = new FormControl('');
  cityDestinationSub: Subscription;
  validateCityOrigin: string = '';

  constructor(
    public patterns: Patterns,
    public utils: Utils,
    public snackBarService: SnackBarService,
    public dialogRef: MatDialogRef<CargoTurnFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public authService: AuthService,
    public assignVehicleCargoService: AssignVehicleCargoService,
    public spinner: NgxSpinnerService,
    public adminUsersService: AdminUsersService,
    public router: Router,
    private dateFormatPipe: DateFormatPipe
  ) {
  }

  enturnamientoForm: FormGroup;

  ngOnInit() {
    this.checkPlateField();
    this.createEntunamientoForm();
    this.assignVehicle();
    this.setSubscriptions();
    this.setOptions();
  }
  setSubscriptions() {
    this.cityOriginSub = this.cityControlOrigin.valueChanges
      .subscribe(value => {
        if (value && value.id) this.enturnamientoForm.get('origin').setValue(value);
      });

    this.cityDestinationSub = this.cityControlDestination.valueChanges
      .subscribe(value => {
        if (value && value.id) this.enturnamientoForm.get('destination').setValue(value);
      });
  }
  setOptions() {
    this.optionsCityOrigin['initialValue'] = this.enturnamientoForm && this.enturnamientoForm.get('origin') && this.enturnamientoForm.get('origin').value && this.enturnamientoForm.get('origin').value.name ? this.enturnamientoForm.get('origin').value.name : '';
    this.optionsCityOrigin['initialId'] = this.enturnamientoForm && this.enturnamientoForm.get('origin') && this.enturnamientoForm.get('origin').value && this.enturnamientoForm.get('origin').value.id ? this.enturnamientoForm.get('origin').value.id : '';
    this.optionsCityDestination['initialValue'] = this.enturnamientoForm && this.enturnamientoForm.get('destination') && this.enturnamientoForm.get('destination').value && this.enturnamientoForm.get('destination').value.name ? this.enturnamientoForm.get('destination').value.name : '';
    this.optionsCityDestination['initialId'] = this.enturnamientoForm && this.enturnamientoForm.get('destination') && this.enturnamientoForm.get('destination').value && this.enturnamientoForm.get('destination').value.id ? this.enturnamientoForm.get('destination').value.id : '';
    this.optionsCityOrigin = { ...this.optionsCityOrigin };
    this.optionsCityDestination = { ...this.optionsCityDestination };
  }

  createEntunamientoForm() {
    this.enturnamientoForm = new FormGroup({
      //los que llena el usuario
      origin: new FormControl('', Validators.required),
      destination: new FormControl(''),
      licensePlate: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      loadCapacityVolume: new FormControl(),
      userId: new FormControl(),
      //los que se llenan pr debajo si el vehiculo existe
      state: new FormControl(),
      vehicleType: new FormControl(''),
      bodyworkType: new FormControl(),
      loadCapacityWeight: new FormControl(''),
      driverName: new FormControl(),
      cellphone: new FormControl(),
      numberOfTrips: new FormControl(0),
      id: new FormControl()
    });
    if (this.dialogParams && this.dialogParams.turn) {
      this.updateValueForm(this.dialogParams.turn);
    }
  }

  updateValueForm(turn) {
    // Usar setValue o patchValue según tus necesidades
    this.enturnamientoForm.setValue({
      origin: turn.origin ? turn.origin : '',
      destination: turn.destination ? turn.destination : '',
      licensePlate: turn.licensePlate ? turn.licensePlate : '',
      date: turn.date ? turn.date : '',
      loadCapacityVolume: turn.loadCapacityVolume ? turn.loadCapacityVolume : 0,
      userId: turn.userId ? turn.userId : '',
      state: turn.state ? turn.state : '',
      vehicleType: turn.vehicleType ? turn.vehicleType : '',
      bodyworkType: turn.bodyworkType ? turn.bodyworkType : '',
      loadCapacityWeight: turn.loadCapacityWeight ? turn.loadCapacityWeight : 0,
      driverName: turn.driverName ? turn.driverName : '',
      cellphone: turn.cellphone ? turn.cellphone : '',
      numberOfTrips: turn.numberOfTrips ? turn.numberOfTrips : '',
      id: turn.id ? turn.id : ''
    });
    if (this.enturnamientoForm.get('licensePlate') && this.enturnamientoForm.get('licensePlate').value) this.vehicleControl.setValue(this.enturnamientoForm.get('licensePlate').value);

    if (this.enturnamientoForm.get('date') && this.enturnamientoForm.get('date').value) {
      this.enturnamientoForm.get('date').setValue(new Date(this.enturnamientoForm.get('date').value))
    }
  }

  public onSelectCity(event, type) {
    if (event.data) {
      if (type == "origin") {
        this.enturnamientoForm.get('origin').setValue(event.data);
      }
      if (type == "destination") {
        this.enturnamientoForm.get('destination').setValue(event.data);
      }
    }
  }

  public onSubmit() {
    this.validateCityOrigin = 'touched';
    this.vehicleControl.markAsTouched();
    this.enturnamientoForm.markAllAsTouched();
    this.InvalidLicensePLate = false;
    if (this.notVehicle) {
      this.snackBarService.openSnackBar("No se ha digitado una placa o la placa digitada no existe", undefined, "alert")
      return;
    }
    if (this.enturnamientoForm.invalid) {
      if (this.utils.errorMessagesCustomized(this.enturnamientoForm.get('origin'), 'ciudad de origen')) return;
      else if (this.utils.errorMessagesCustomized(this.enturnamientoForm.get('licensePlate'), 'placa del vehículo')) return;
      else if (this.utils.errorMessagesCustomized(this.enturnamientoForm.get('date'), 'fecha de disponibilidad')) return;
      else
        this.snackBarService.openSnackBar(FormMessages.MISSING_FIELDS, undefined, 'alert');
    } else {
      let userId = this.authService.getUserSession().information.document
      this.enturnamientoForm.get('userId').setValue(userId);
      const formatDate = this.dateFormatPipe.transform(
        this.enturnamientoForm.get('date').value,
        "date-time"
      );
      this.enturnamientoForm.get('date').setValue(formatDate);
      this.enturnamientoForm.get('loadCapacityVolume').setValue(Number(this.enturnamientoForm.get('loadCapacityVolume').value || 0));
      this.close(true);
    }
  }

  createTurn() {

  }

  updateTurn() {

  }

  public close(status) {
    this.dialogRef.close({
      state: status,
      value: this.enturnamientoForm.value,
      type: this.dialogParams.type
    });
  }


  assignVehicle() {
    this.listVehicles = this.vehicleControl.valueChanges
      .pipe(
        startWith(''),
        distinctUntilChanged(),
        switchMap(val => {
          try {
            if (val.trim().length > 2) {
              return this.filter(val || '');
            } else {
              this.vehicleSelected = null;
              return [];
            }
          } catch (e) {
            return [];
          }
        })
      )

  }

  filter(value: string): Observable<Vehicle[]> {
    const filterValue = value.toUpperCase();
    const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    if (holderCompany !== null) {
      return this.assignVehicleCargoService.getVehicleById(filterValue, holderCompany)
        .pipe(
          map(response => {
            if (response && response.length) {
              return response.filter(option => {
                return option.id.toLowerCase().indexOf(filterValue.toLowerCase()) === 0;
              })
            } else {
              return [];
            }
          }
          )
        );
    } else return of([]);

  }

  optionSelected($event) {
    this.notVehicle = false;
    this.enturnamientoForm.controls.licensePlate.setValue($event.option.value);
    if ($event && $event.option && $event.option._element && $event.option._element.nativeElement && $event.option._element.nativeElement.iddriver) {
      let vehicle = $event.option._element.nativeElement
      this.enturnamientoForm.controls.vehicleType.setValue(vehicle.vehicletype);
      this.enturnamientoForm.controls.bodyworkType.setValue(vehicle.bodyworkType);
      this.enturnamientoForm.controls.numberOfTrips.setValue(Number(vehicle.numberOfTrips));
      //!!PENDIENTE LOAD CAPACITY WEIGHT POR AHORA ESTA CON UN DATO QUEMADO ESPERANDO QUE ME LO RETORNE EL VEHICULO
      //this.enturnamientoForm.controls.loadCapacityWeight.setValue(Number(vehicle.loadCapacityWeight))
      this.enturnamientoForm.controls.loadCapacityWeight.setValue(200);
      this.enturnamientoForm.controls.driverName.setValue(vehicle.namedriver);
      this.getDataDriver(vehicle.iddriver);
    }
  }

  getDataDriver(document?: string) {
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(document)
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          if (success && success.information) {
            this.enturnamientoForm.controls.cellphone.setValue(success.phone);
            // this.enturnamientoForm.controls.numberOfTrips.setValue(success.numberOfTrips);
          } else {
            this.enturnamientoForm.controls.cellphone.setValue(null);
          }
        },
        (error) => {
          this.enturnamientoForm.controls.cellphone.setValue(null);
          this.spinner.hide();
        }
      );
  }

  checkPlateField() {
    this.vehicleSub = this.vehicleControl.valueChanges.subscribe(data => {

      if (data && this.enturnamientoForm.controls.licensePlate && this.enturnamientoForm.controls.licensePlate.value) {
        if (data === this.enturnamientoForm.controls.licensePlate.value) {
          this.notVehicle = false;
        } else {
          this.notVehicle = true;
          this.enturnamientoForm.controls.licensePlate.setValue('');
        }
      } else if (data && !this.enturnamientoForm.controls.licensePlate.value) {
        this.notVehicle = true;
      }
      else {
        this.notVehicle = false;
      }
    })
  }

  validateVehicle(licensePlate) {
    this.spinner.show();
    const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
    this.assignVehicleCargoService.getVehicleById(licensePlate, holderCompany).subscribe(data => {
      if (data && data !== null) {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Este vehiculo ya existe. Por favor seleccionelo de las opciones desplegadas", undefined, "alert");
      } else if (licensePlate.length <= 6 && this.patterns.GET_REGEX('LICENSE_PLATES').test(licensePlate)) {
        this.dialogRef.close();
        const dataToSend = { licensePlate: licensePlate }; // Puedes ajustar esta información según tus necesidades
        this.router.navigate(['administration/vehicles/create'], { state: dataToSend });
        // this.router.navigate(['administration/vehicles/create']);
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Formato incorrecto de placa", undefined, "alert");
      }
    },
      error => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("hubo un error procesando su solicitud", undefined, "error")
      })
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  ngOnDestroy() {
    if (this.cityOriginSub) this.cityOriginSub.unsubscribe();
    if (this.cityDestinationSub) this.cityDestinationSub.unsubscribe();
    if (this.vehicleSub) this.vehicleSub.unsubscribe();
  }
}
