<div class="modal-body wrapper-modal position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="content">
        <header class="d-flex align-items-center">
            <div class="dialog-title">
                {{pointId? 'Actualizar': 'Crear'}} punto autorizado
            </div>
        </header>
        <br>
        <div class="content-child">
            <main class="row mt-2">
                <aside class="col-md-6">
                    <form [formGroup]="form">
                        <app-autocomplete-city [options]="cityOptions" [inputFormControl]="cityControl"
                            [validate]="cityValidate">
                        </app-autocomplete-city>
                        <app-complete-address title="Dirección"
                            icon="../../../../../assets/svg/icons/tl_ico__pin_google_maps_origen.svg"
                            actionIcon="../../../../../assets/svg/icons/tl_ico__x.svg" [draggable]="false"
                            [deleteButton]="false" (location)="onLocation($event, 0)">
                            <img src="../../../../assets/svg/icons/tl_ico__draggable.svg" cdkDragHandle
                                class="position-absolute" height="100%">
                        </app-complete-address>
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de Servicio</mat-label>
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let type of types" [value]="type.id">{{type.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="form.get('type').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input type="text" matInput formControlName="name">
                            <mat-error *ngIf="form.get('name').errors?.required">
                                {{ utils.giveMessageError('MANDATORY_FIELD') }}
                            </mat-error>
                            <mat-error *ngIf="form.get('name').errors?.minlength">
                                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'nombre', 8) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Teléfono</mat-label>
                            <input type="text" matInput formControlName="phone" onlyNumbersInt>
                            <mat-error *ngIf="form.get('phone').errors?.pattern">
                                {{ utils.giveMessageError('INCORRECT_FORMAT', 'teléfono') }}
                            </mat-error>
                            <mat-error *ngIf="form.get('phone').errors?.minlength">
                                {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'teléfono', 7) }}
                            </mat-error>
                        </mat-form-field>
                    </form>
                </aside>
                <aside class="col-md-6">
                    <app-routes-map></app-routes-map>
                </aside>
            </main>
        </div>
        <br>
        <div class="actions">
            <button mat-button color="accent" style="border: 2px solid #02d7dc;" (click)="dialogRef.close()">
                Volver
            </button>&nbsp;
            <button mat-raised-button color="accent" (click)="create()">
                {{pointId? 'Actualizar': 'Crear'}}
            </button>
        </div>
    </div>
</div>