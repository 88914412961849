import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import PlaceResult = google.maps.places.PlaceResult;
import * as _ from 'lodash';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { City } from 'src/app/core/interfaces/city';
import { CargoResources } from '../../resources/cargo';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication.service';
import { ThirdPartyConsignmentComponent } from '../third-party-consignment/third-party-consignment.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CityService } from 'src/app/modules/administration/city/city.service';
import { Company } from 'src/app/core/interfaces/company';
import { Fmt } from 'src/app/core/messages/fmt';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/core/resources/permission';
import { UploadDownloadMapService } from '../upload-download-map/uploadDownloadMap.service';
import { PermissionRole } from 'src/app/core/resources/permission-role';
@Component({
  selector: 'app-upload-location',
  templateUrl: './upload-location.component.html',
  styleUrls: ['./upload-location.component.scss'],
  providers: [Global, CityService]
})

export class UploadLocationComponent implements OnInit {
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() removeAddressMarker: EventEmitter<number> = new EventEmitter();
  @Output() clearAllMarkers: EventEmitter<boolean> = new EventEmitter();
  @Input() companiaSelect: Company;
  @Input() cleanCity: boolean;
  showLocationsLoad = false;
  panelOpenState = true;
  listCities: City[];
  @ViewChildren(ThirdPartyConsignmentComponent) thirdPartyConsignmentList: QueryList<ThirdPartyConsignmentComponent>;
  optionsCity: OptionsAutocomplete = {
    title: 'Ciudad de Origen',
    requireFullCity: true,
  }
  validate: string = '';
  cityControl: FormControl = new FormControl('', Validators.required);
  citySub: Subscription;
  permission = Permission;
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    private cargoResources: CargoResources,
    public utils: Utils,
    public authService: AuthService,
    private snackBarService: SnackBarService,
    private cityService: CityService,
    private uploadDownloadMapService: UploadDownloadMapService,
    private permissionRole: PermissionRole
  ) {

  }

  ngOnInit() {
    this.setOptions();
    this.setSubscriptions();
  }

  setOptions() {
    this.optionsCity['initialValue'] = this.manualCreationCargoService.formOrigin.value.name ? this.manualCreationCargoService.formOrigin.value.name : '';
    this.optionsCity['isInternational'] = !!this.manualCreationCargoService.formOrigin.get('isInternational').value;
    this.optionsCity = { ...this.optionsCity };
  }

  setSubscriptions() {
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => {
        const isInternational = !!this.manualCreationCargoService.formOrigin.get('isInternational').value;
        if ((value && value.name && this.manualCreationCargoService.formOrigin.get('name').value && this.manualCreationCargoService.formOrigin.get('name').value !== value.name) || !value) {
          try {
            const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses[0]);
            this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [];
            this.refreshFormCargo();
            this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [
              basicAddress
            ];
            this.refreshFormCargo();
            this.clearAllMarkers.emit(true);
          } catch (e) {
          }
        }
        this.manualCreationCargoService.formOrigin.get('name').setValue(value && value.name ? value.name : '');
        this.manualCreationCargoService.formOrigin.get('country.name').setValue(value && value.country ? value.country : 'Colombia');
        this.manualCreationCargoService.formOrigin.get('country.code').setValue(value && value.code ? value.code : 'CO');
        if (isInternational) this.cleanFields();
        else {
          this.manualCreationCargoService.formOrigin.get('name').setValue(value && value.name ? value.name : '');
          const reteica = this.cityService.getReteicaCity(value);
          this.manualCreationCargoService.formOrigin.get('municipalityCode').setValue(value && value.id ? value.id : '');
          if (reteica > 0) {
            this.manualCreationCargoService.formOrigin.get('reteica').setValue(reteica);
          } else if (reteica === 0 && value && !value.mainCity) {
            if (!this.manualCreationCargoService.permissionToSkipReteica) {
              this.showErrorReteica();
              this.cleanFields();
            }
          }
        }
      })
  }

  cleanFields() {
    this.manualCreationCargoService.formOrigin.get('municipalityCode').setValue('');
    this.manualCreationCargoService.formOrigin.get('reteica').setValue(0);
  }

  showErrorReteica(): void {
    this.snackBarService.openSnackBar("Esta ciudad no tiene asignado un ReteIca por favor asocielo o cambie de ciudad", undefined, 'alert');
  }

  onChangeInternational() {
    this.manualCreationCargoService.formOrigin.get('name').setValue('');
    this.setOptions();
    let initialValue = this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.origin);
    delete initialValue.isInternational;
    const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses[0]);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [];
    this.refreshFormCargo();
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [basicAddress];
    this.refreshFormCargo();
    this.manualCreationCargoService.formOrigin.patchValue(initialValue);

  }

  onSelectAddress($event: any) {
    ((this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls
      .origin.get('addresses') as FormArray).at($event.options.index) as FormGroup).patchValue($event.data);
  }

  onSelectThirdParty($event: any) {
    this.emitToParent.emit($event);
  }

  addAddressForm() {
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses
      .push(this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses[0]));
    this.refreshFormCargo();
  }

  removeAddressField(index: number) {
    this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.origin
      .controls.addresses.removeAt(index);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses.splice(index, 1);
    this.removeAddressMarker.emit(index);
  }

  refreshFormCargo() {
    this.emitToParent.emit({
      data: {
        refreshForm: true
      }
    });
  }

  get addresses() {
    return (this.manualCreationCargoService.cargoForm.controls.cargoFeature['controls'].uploadDownload.controls.origin
      .controls.addresses.controls as FormArray);
  }

  findErrorOnOrigin(): boolean {
    let haveErrors = false;
    for (let i = 0; i < this.addresses.length; i++) {
      const address = this.addresses.at(i) as FormGroup;
      const locationDefined: boolean = !!(address.get('location.lat') && address.get('location.lng') && address.get('location.lat').value && address.get('location.lng').value);
      if (!locationDefined) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.LAT_LNG_NOT_DEFINED_ORIGIN, i + 1), undefined, 'alert');
        haveErrors = true;
        break;
      }
    }
    return haveErrors;
  }

  nextStep() {
    this.validate = 'touched';
    if ((!this.uploadDownloadMapService.getPolicyCompany() || !this.uploadDownloadMapService.getPolicyCompany().policyNumber) && this.manualCreationCargoService.cargoForm.get('ministry').value) return this.snackBarService.openSnackBar(FormMessages.EMPTY_POLICY, undefined, 'alert');
    
    if (this.uploadDownloadMapService.isPolicyExpirated() && !this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.createServicesWithExpiredPolicy) && this.manualCreationCargoService.cargoForm.get('ministry').value)  return this.snackBarService.openSnackBar(FormMessages.EXPIRED_POLICY, undefined, 'alert');

    if (!this.manualCreationCargoService.isValidCompany()) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'propietario del servicio'), undefined, 'alert');
    }
    else if (!this.manualCreationCargoService.isValidUploadLocation(this.manualCreationCargoService.getCargoForm().get('ministry').value)) {
      if (!this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin.name').value) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'ciudad de origen'), undefined, 'alert');
      }
      else if (this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin.reteica').value <= 0 && !this.manualCreationCargoService.permissionToSkipReteica) {
        this.showErrorReteica();
      }
      else if (!this.manualCreationCargoService.cargoForm.get('cargoFeature.uploadDownload.origin').value.addresses.every(add => !!add.address)) {
        this.snackBarService.openSnackBar(Fmt.string(FormMessages.FIELDS_UNCOMPLETED, 'dirección'), undefined, 'alert');
      }
      else {
        if (this.findErrorOnOrigin()) return;
        else this.manualCreationCargoService.showMessageMissingFields();
      }
    } else {
      this.emitToParent.emit({
        data: {
          viewActive: 'destination'
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
