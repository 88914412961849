import { NavItem } from '../interfaces/navItem';
import { CollectionPointZone } from '../interfaces/collectionPointZone';
import { CollectionPointType } from '../interfaces/collectionPointType';
import { Role } from '../interfaces/role';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Catalog } from '../interfaces/catalog';
import { CatalogItem } from '../interfaces/catalogItem';
import { Permission } from './permission';
import { ExcelField } from '../interfaces/fieldsColumnCargo';
import { VehicleDocumentCatalog } from '../interfaces/vehicleDocuments';
import { CheckListQuestionVehicle, CheckLists } from '../interfaces/checkListsInspectVehicle';
import { ProtectionElements } from '../interfaces/protectionElementInspectVehicle';

export class Global {

  currentYear = new Date().getFullYear();
  permission = Permission;
  _k: string = "T3CL0g12020";
  pathImgLogoteclogi: string = "./assets/img/logo.png";
  pathMarkerOrigin: string = "/assets/img/pin-origin.png";
  pathImgTransparent: string = "/assets/img/transparent.png";
  pathMarkerDestination: string = "/assets/img/pin-destination.png";
  pathMarkerVehicle: string = "/assets/img/pin-vehicle-inverted.png";
  pathMarkerVehicleNoLastLocations: string = "/assets/img/banderita.png";
  pathMarkerLocation: string = "/assets/img/location.png";
  pathClusterMarker: string = "/assets/img/clusters-";
  pathLocationPin: string = "/assets/img/location-pin.png";
  markerTrackingFreightForwarder: string = 'trackingFreightForwarder';
  markerPointRoute: string = 'markerPointRoute';
  markerGeneral: string = 'markerGeneral';

  defaultRoles = {
    webUserPendingActivate: {
      id: 'webUserPendingActivate',
      nit: '',
      name: 'webUserPendingActivate'
    },
    Driver: {
      id: 'Driver',
      nit: '',
      name: 'Driver'
    }
  };

  guideState: string = "GuideState";

  typeFilesExport = {
    xls: {
      format: 'xlsx',
      name: 'XLSX',
      contentType: 'application/octet-stream'
    },
    csv: {
      format: 'csv',
      name: 'csv',
      contentType: 'application/csv'
    },
    txt: {
      format: 'txt',
      name: 'txt',
      contentType: 'application/txt'
    },
    pdf: {
      format: 'pdf',
      name: 'pdf',
      contentType: 'application/pdf'
    }
  };

  typesExportList = {
    collectionPoint: 1,
    freightForwarder: 2,
    reports: 3
  };

  companiesEnabledsToExport = [
    "8305072789" // gl
  ]

  //type fiels
  typeFiles: Array<Object> = [
    {
      idFile: 1,
      title: 'RUT',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 2,
      title: 'Estados financieros',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 3,
      title: 'Circular 018',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 4,
      title: 'Certificado Basc, OEA o Acuerdo de seguridad',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 5,
      title: 'Certificación de calidad y otros',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 6,
      title: 'Referencia bancaria',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    },
    {
      idFile: 7,
      title: 'Referencia comercial',
      file: null,
      name: '',
      uploaded: false,
      size: 0,
      storageRef: null,
      uploadTask: null,
      uploadProgress: null
    }
  ];

  // Menu options
  public menu: NavItem[] = [
    {
      displayName: 'Administración',
      name: 'administration',
      iconName: 'users-cog',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear Usuario',
          route: '/administration/admin-users/create',
          visible: false
        },
        {
          displayName: 'Usuarios',
          name: 'adminUsers',
          route: '/administration/admin-users/list',
          visible: false
        },
        {
          displayName: 'Términos Legales',
          name: 'adminTerms',
          route: '/administration/legal-terms/list',
          visible: false
        },
        {
          displayName: 'Ciudades',
          name: 'adminCities',
          route: '/administration/cities/list',
          visible: false
        },
        {
          displayName: 'Vehículos',
          name: 'adminVehicles',
          route: '/administration/vehicles/list',
          visible: false
        },
        {
          displayName: 'Trailers',
          name: 'adminTrailers',
          route: '/administration/trailers/list',
          visible: false
        },
        {
          displayName: 'Empresas',
          name: 'adminCompanies',
          route: '/administration/companies/list',
          visible: false
        },
        {
          displayName: 'Roles',
          name: 'adminRoles',
          route: '/administration/roles/list',
          visible: false
        },
        {
          displayName: 'Modulos',
          name: 'adminModules',
          route: '/administration/module/list',
          visible: false
        },
        {
          displayName: 'Configuración SaaS',
          name: 'updateCompany',
          route: '/administration/companies/config',
          visible: false
        },
        {
          displayName: 'Mantenimientos',
          name: 'adminMaintenance',
          route: '/administration/maintenance/list',
          visible: false
        },
      ]
    },
    {
      displayName: 'Financiero',
      iconName: 'money-bill-wave',
      name: 'payments',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Aprobar Servicios',
          route: '/cargo/list/approvePayments',
          name: 'approvePayments',
          visible: false
        },
        {
          displayName: 'Realizar Pagos',
          route: '/cargo/list/paymentCargo',
          name: 'paymentCargo',
          visible: false
        },
        {
          displayName: 'Facturacion',
          route: '/cargo/list/charges',
          name: 'charges',
          visible: false
        },
        {
          displayName: 'Cartera',
          name: 'adminReceivable',
          route: '/administration/receivable/list',
          visible: false
        },
        {
          displayName: 'Generación de Archívos',
          name: 'generateAccountingFiles',
          route: '/cargo/expenses-income',
          visible: false
        }
      ]
    },
    {
      displayName: 'Servicio',
      name: 'cargo',
      iconName: 'truck-loading',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Reportes',
          route: '/reports/main',
          name: 'reports',
          visible: false,
          responsive: false
        },
        {
          displayName: 'Solicitudes y Postulaciones',
          route: '/cargo/transport-requests',
          name: 'listRequestsAndApplications',
          visible: false,
        },
        {
          displayName: 'Crear servicios',
          name: [
            'createManualCargo',
            'createCargoRequest',
            'createCargoByGuides',
            'createMassiveCargo',
            'createCargoFromRoute',
            'createEscortService'
          ],
          route: '/cargo/create',
          visible: false
        },
        {
          displayName: 'Solicitudes de servicio',
          name: 'serviceRequests',
          route: '/cargo/list/service-requests',
          visible: false
        },
        {
          displayName: 'Solicitudes',
          name: 'cargoRequest',
          route: '/cargo/list/request',
          visible: false
        },
        {
          displayName: 'Servicios programados',
          route: '/cargo/list/scheduledLoads',
          name: 'scheduledCargo',
          visible: false
        },
        {
          displayName: 'Enturnamiento',
          name: 'turn',
          route: '/cargo/list/enturnamiento',
          visible: false
        },
        /*{
          displayName: 'Negociación B2B',
          name: 'cargoRequestNegotiation',
          route: '/cargo/list/cargoRequestNegotiation',
          visible: false
        },*/
        /*{
          displayName: 'Servicios en negociación',
          route: '/cargo/list/loadsInNegotiation',
          name: 'cargosInNegotiation',
          visible: false
        },*/
        {
          displayName: 'Servicios en ruta',
          name: 'cargosInRoute',
          route: '/cargo/list/loadingRoutes',
          visible: false
        },
        {
          displayName: 'Tracking Global',
          route: '/cargo/global-tracking',
          name: 'cargosInRoute',
          visible: false,
        },
        {
          displayName: 'Servicios terminados',
          route: '/cargo/list/loadsFinished',
          name: 'finishedCargos',
          visible: false
        },
        {
          displayName: 'Servicios vencidos',
          route: '/cargo/list/expired',
          name: 'expiredCargo',
          visible: false
        },
        {
          displayName: 'Servicios eliminados',
          route: '/cargo/list/deleted',
          name: 'deletedCargo',
          visible: false
        },
        {
          displayName: 'Rastrear servicios',
          name: 'searchCargo',
          route: '/cargo/search',
          visible: false
        },

      ]
    },
    {
      displayName: 'Ruteo',
      iconName: 'fas fa-map-marked-alt',
      name: 'routing',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear Ruteo',
          name: 'createRouting',
          route: '/routing/create-route',
          visible: false
        },
        {
          displayName: 'Listado de ruteos',
          name: 'routingList',
          route: '/routing/route-list',
          visible: false
        },
      ]
    },
    {
      displayName: 'Guías',
      name: 'guide',
      iconName: 'file',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Cargar guías',
          name: [this.permission.guide.loadGuides, this.permission.guide.changeStateGuides, this.permission.guide.loadGuides],
          route: '/guide/upload-guides',
          visible: false
        },
        {
          displayName: 'Generar guías',
          name: 'generateGuides',
          route: '/guide/generate-guides',
          visible: false
        },
        {
          displayName: 'Generar guías',
          route: '/guide/seller-generate-guides',
          visible: false
        },
        {
          displayName: 'Rastreo de Guias',
          name: 'trackGuide',
          route: '/guide/track-guide',
          visible: true
        }
      ]
    },
    {
      displayName: 'Puntos',
      name: 'point',
      iconName: 'map-marker-alt',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Crear punto',
          name: 'createPoint',
          route: '/collection-point/create',
          visible: false
        },
        {
          displayName: 'Listado de puntos',
          name: 'listPoints',
          route: '/collection-point/list',
          visible: false
        }
      ]
    },
    {
      displayName: 'Calendario',
      iconName: 'calendar-alt',
      name: 'calendar',
      expanded: false,
      visible: false,
      childrens: [
        {
          displayName: 'CEDI',
          name: 'schedule-cedi',
          route: '/schedule/booking',
          visible: false
        },
      ]
    },
    {
      displayName: 'Rutas',
      name: 'routes',
      iconName: 'route',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        // {
        //   displayName: 'Novedades',
        //   name: [this.permission.guide.loadGuides, this.permission.guide.changeStateGuides, this.permission.guide.loadGuides],
        //   route: '/routes/news',
        //   visible: true
        // },
        {
          displayName: 'Rutas',
          name: this.permission.routes.routePlanning,
          route: '/routes/planning',
          visible: false
        },
        {
          displayName: 'Sitios Autorizados',
          name: this.permission.routes.routeSites,
          route: '/routes/sites',
          visible: false
        },
        // {
        //   displayName: 'Condiciones inseguras',
        //   name: 'unsafeConditions',
        //   route: '/routes/unsafe-conditions',
        //   visible: true
        // }
      ]
    },
    {
      displayName: 'Configuración',
      iconName: 'cog',
      name: 'setting',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        // {
        //     displayName: 'Documentos de la empresa',
        //     name: 'companyDocuments',
        //     route: '/settings/documents',
        //     visible: false
        // },
        {
          displayName: 'Cambiar contraseña',
          route: '/settings/password',
          name: 'changePassword',
          visible: false
        },
        {
          displayName: 'Términos y condiciones',
          route: '/terms',
          name: 'termsAndConditions',
          visible: false
        },
        {
          displayName: 'Politicas de privacidad',
          route: '/privacy-policy',
          name: 'privacyPolicy',
          visible: false
        },
        {
          displayName: 'Politicas tratamiento de datos personales',
          route: '/personal-data-policies',
          name: 'personalDataPolicies',
          visible: false
        },
        {
          displayName: 'HABEAS DATA',
          route: '/habeas-data',
          name: 'habeasData',
          visible: false
        },
        {
          displayName: 'Anexo Manifiesto de Carga',
          route: '/terms-manifest',
          name: 'termsManifest',
          visible: false
        },
        {
          displayName: 'Politicas BASC',
          route: '/basc-policies',
          name: 'bascPolicies',
          visible: false
        },
        {
          displayName: 'Pronto Pago',
          route: '/quick-pay',
          name: 'quickPay',
          visible: false
        }
      ]
    },
    {
      displayName: 'Soporte',
      iconName: 'ticket-alt',
      name: 'help-desk',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Tickets',
          name: 'listTicket',
          route: '/help-desk/list',
          visible: false
        },
      ]
    },
    {
      displayName: 'Monitoring',
      iconName: 'desktop',
      name: 'monitoring',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Logging',
          name: 'monitorlogging',
          route: '/monitoring/logging',
          visible: false
        },
        {
          displayName: 'Solicitudes por usuario',
          name: 'fingerprintslogging',
          route: '/monitoring/fingerprintslogging',
          visible: false
        },
      ]
    },
    {
      displayName: 'Analíticas',
      iconName: 'chart-bar',
      name: 'analytics',
      expanded: false,
      visible: false,
      responsive: false,
      childrens: [
        {
          displayName: 'Operaciones',
          name: 'analyticsOperations',
          route: '/analytics/analyticsOperations',
          visible: false
        },
        {
          displayName: 'Vehículos',
          name: 'vehicleAnalytics',
          route: '/analytics/vehicleAnalytics',
          visible: false
        },
        {
          displayName: 'Rutas frecuentes',
          name: 'frequentRoutes',
          route: '/analytics/frequentRoutes',
          visible: false
        },
        {
          displayName: 'Despachos',
          name: 'dispatch',
          route: '/analytics/dispatch',
          visible: false
        },
        {
          displayName: 'Servicios detenidos',
          name: 'stopedCargos',
          route: '/analytics/stopedCargos',
          visible: false
        },
        {
          displayName: 'Métricas históricas',
          name: 'historicalKPI',
          route: '/analytics/historicalKPI',
          visible: false
        },
        {
          displayName: 'Facturación',
          name: 'analyticsBilling',
          route: '/analytics/analyticsBilling',
          visible: false
        },
        {
          displayName: 'Operación ITR',
          name: 'operationITR',
          route: '/analytics/operationITR',
          visible: false
        },
        {
          displayName: "Operaciones Enturnamiento",
          name: "operationsTurn",
          route: '/analytics/operationsTurn',
          visible: false,
        },
        {
          displayName: "Comercial Enturnamiento",
          name: "comercialTurn",
          route: "/analytics/comercialTurn",
          visible: false
        },
      ]
    },

  ];

  collectionPointZones: CollectionPointZone[] = [
    {
      name: "Tuta"
    },
    {
      name: "Cogua"
    }
    ,
    {
      name: "Simijaca"
    }
  ];

  collectionPointTypes: CollectionPointType[] = [
    {
      name: "CALIENTE"
    },
    {
      name: "FRIA"
    }
  ];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '100px',
    maxHeight: '500px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese el texto aquí...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  public listTypeDocuments: VehicleDocumentCatalog[] =
    [
      { name: 'frontPicture' },
      { name: 'leftLateralPicture' },
      { name: 'rightLateralPicture' },
      { name: 'propertyCard' }
    ];

  public typesReports = [
    {
      id: 'addressDuration',
      name: 'Duracion en cada dirección'
    },
    {
      id: 'addressQuantity',
      name: 'Cantidad de unidades en cada dirección'
    },
    {
      id: 'routeDuration',
      name: 'Duracion total de cada servicio'
    },
    {
      id: 'dayQuantity',
      name: 'Cantidad de unidades por días'
    },
    {
      id: 'durationTakeCargo',
      name: 'Duración en tomar el servicio'
    }
  ];

  public riskProfiles: CatalogItem[] = [
    { id: '1', name: 'Riesgo bajo' },
    { id: '2', name: 'Riesgo medio' },
    { id: '3', name: 'Riesgo alto' }
  ];

  public documenTypes: CatalogItem[] = [
    {
      id: '1',
      name: 'Cédula de ciudadanía'
    },
    {
      id: '2',
      name: 'Pasaporte'
    },
    {
      id: '3',
      name: 'NIT'
    },
    {
      id: '4',
      name: 'Tarjeta de identidad'
    },
    {
      id: '5',
      name: 'Cédula de extranjería'
    }
  ];

  public fieldsGuide = [
    {
      id: 'guideId',
      title: 'Id. de Guía (*)',
      disabled: false
    },
    {
      id: 'origin',
      title: 'Origen (*)',
      disabled: false
    },
    {
      id: 'clientName',
      title: 'Cliente nombre (*)',
      disabled: false
    },
    {
      id: 'destination',
      title: 'Ciudad Destino (*)',
      disabled: false
    },
    {
      id: 'destinationAddress',
      title: 'Dirección de destino (*)',
      disabled: false
    },
    {
      id: 'product',
      title: 'Producto (*)',
      disabled: false
    },
    {
      id: 'clientId',
      title: 'Id. de Cliente',
      disabled: false
    },
    {
      id: 'clientEmail',
      title: 'Cliente email',
      disabled: false
    },
    {
      id: 'clientCellPhone',
      title: 'Cliente celular',
      disabled: false
    },
    {
      id: 'addressComplement',
      title: 'Complemento dirección',
      disabled: false
    },
    {
      id: 'neighborhood',
      title: 'Barrio',
      disabled: false
    },
    {
      id: 'lat',
      title: 'Latitud',
      disabled: false
    },
    {
      id: 'lng',
      title: 'Longitud',
      disabled: false
    },
    {
      id: 'zone',
      title: 'Zona',
      disabled: false
    },
    {
      id: 'deadLine',
      title: 'Fecha máxima para entregar',
      disabled: false
    },
    {
      id: 'productId',
      title: 'Id. de producto',
      disabled: false
    },
    {
      id: 'cost',
      title: 'Costo',
      disabled: false
    },
    {
      id: 'store',
      title: 'Tienda',
      disabled: false
    },
    {
      id: 'packagesNumber',
      title: 'Número de paquétes',
      disabled: false
    },
  ];

  public fieldsXLSXCargo: ExcelField[] = [
    {
      id: 'numberDocumentSender',
      title: 'Nº Documento remitente*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'tripName',
      title: 'Tipo de viaje*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'vehicleTypeName',
      title: 'Tipo de vehículo*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'bodyWorkTypeName',
      title: 'Tipo de carrocería*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'shippingCostTotal',
      title: 'Flete*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'dateCargo',
      title: 'Fecha de cargue*',
      disabled: false,
      mandatory: true
    },
    {
      id: 'nit',
      title: 'NIT',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoTypeName',
      title: 'Tipo de carga',
      disabled: false,
      mandatory: false
    },
    {
      id: 'serviceTypeName',
      title: 'Tipo de servicio',
      disabled: false,
      mandatory: false
    },
    {
      id: 'productDescription',
      title: 'Descripción del producto',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureQuantity',
      title: 'Cantidad de paquetes',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotal',
      title: 'Medida total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalWeigth',
      title: 'Peso total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalAmount',
      title: 'Valor total',
      disabled: false,
      mandatory: false
    },
    {
      id: 'cargoMeasureTotalType',
      title: 'Tipo de paquete',
      disabled: false,
      mandatory: false
    },
    {
      id: 'licensePlate',
      title: 'Placa',
      disabled: false,
      mandatory: false
    },
    {
      id: 'rate',
      title: 'Tarifa',
      disabled: false,
      mandatory: false
    },
    {
      id: 'advancePercentage',
      title: '% de anticipo',
      disabled: false,
      mandatory: false
    },
    {
      id: 'observations',
      title: 'Observaciones',
      disabled: false,
      mandatory: false
    },

  ];
  public errorsFieldsExcelDict = {
    cargoTypeName: {
      alias: 'Tipo de carga',
      field: 'cargoModel.cargoType.name'
    },
    serviceTypeName: {
      alias: 'Tipo de servicio',
      field: 'cargoModel.serviceType.name'
    },
    cargoMeasureQuantity: {
      alias: 'Cantidad de paquetes',
      field: 'cargoFeature.cargoMeasure.quantity'
    },
    cargoMeasureTotal: {
      alias: 'Medida total',
      field: 'cargoFeature.cargoMeasure.totalMeasurement'
    },
    cargoMeasureTotalWeigth: {
      alias: 'Peso total',
      field: 'cargoFeature.cargoMeasure.totalWeigth'
    },
    cargoMeasureTotalAmount: {
      alias: 'Valor declarado de la mercancia',
      field: 'cargoFeature.cargoMeasure.amount'
    },
    cargoMeasureTotalType: {
      alias: 'Unidad',
      field: 'cargoFeature.cargoMeasure.unit'
    },
    advancePercentage: {
      alias: 'Porcentaje de anticipo',
      field: 'shippingCost.advancePercentage'
    },
    rate: {
      alias: 'Tarífa',
      field: 'shippingCost.rate'
    },
    licensePlate: {
      alias: 'Placa',
      field: 'licensePlate'
    },
  }

  public vehicleEditableFieldsAlias = {
    'manufacturingYear': 'Año de fabricación',
    'brand.description': 'Marca',
    'color': 'Color',
    'axles': 'N° de ejes',
    'line.description': 'Línea',
    'fuelType.description': 'Tipo de combustible',
    'chassisId': 'N° de Chasis',
    'motorId': 'N° de motor',
  };
  public vehicleEditableFields = Object.keys(this.vehicleEditableFieldsAlias);

  public vehicleInspectionQuestions = {
    "LAT": "Latonería",
    "PYC": "Puertas y Compartimentos",
    "AAC": "Asientos Apoya Cabezas",
    "SBP": "Seguros y Bloqueo de puertas",
    "EVI": "Estado de los vidrios",
    "EES": "Estado de los espejos",
    "SDR": "Sistema de refrigeración",
    "SPT": "Sección del pasajero y estado del techo",
    "EPL": "Estado de las paredes laterales",
    "EFP": "Estado de la pared frontal y las puertas",
    "LDC": "Llantas del cabezote",
    "LDT": "Llantas del Trailer",
    "LDR": "Llantas de repuesto",
    "FDE": "Freno de emergencia",
    "ASR": "Avisos o señales reglamentarias",
    "EIV": "Extintor de vehiculos de acuerdo al tipo de vehiculo",
    "CDI": "Comportamiento del interior - Piso de la cabina y litera",
    "CDH": "Caja de Herramientas",
    "FBA": "Farolas bajas",
    "CCD": "Cocuyos Delanteros",
    "CCT": "Cocuyos Traseros",
    "LDE": "Luces de estacionamiento",
    "DID": "Dirrecionales delanteras",
    "DIT": "Direccionales Traceras",
    "LPR": "Luces y alerta de reversa",
    "STP": "Stop",
    "LST": "Luces del trailer",
    "CDC": "Cinturones de seguridad",
    "LIN": "Linterna",
    "AGV": "Aseo general del vehiculo",
    "IGG": "Inspección del generador (GENSET)",
    "QRP": "Area de la quinta rueda y Patin - KING PING",
    "PMC": "Pata mecanica",
    "BYC": "Bateria and comportamientos",
    "TCA": "Tanques (Combustible y Agua) y Compartimientos",
    "PAR": "Parachoques - Aros",
    "EPA": "Estado puntos de anclaje",
    "SFT": "Señales en forma de triángulo",
    "FPR": "Freno principal",
    "LIM": "Limpiabrisas - Derecho/Izquierdo/Atras",
    "KDD": "Kit de derrame",
    "BPA": "Botiquín de primeros auxilios",
    "JDH": "Juego de herramientas",
    "COT": "Cuñas y/o Tacos",
    "GEM": "Gato ó Elevador Mecánico ó HID",
    "CRU": "Cruceta",
    "FDA": "Filtro de Aire",
    "PAF": "Pared frontal",
    "PIS": "Piso",
    "EYB": "Exterior y Bastidor",
    "COR": "Corral",
    "LAI": "Lado Izquierdo",
    "TEC": "Techo",
    "PIN": "Pines",
    "CAR": "Carpa",
    "LAR": "Lado Derecho",
    "PYM": "Puertas y Mecanismos de cierre",
    "AMR": "Amarres",
    "PLA": "Plancha",
    "TBC": "Tapabocas",
    "GOA": "Gel / Alcohol",
    "GUA": "Guantes",
    "MDS": "Monogafas de seguridad",
    "CHR": "Chaleco reflectivo",
    "CSC": "Casco",
    "KLM": "Kilometraje",
    "LM": "Luces: altas y bajas, posición, direccionales y freno",
    "DM": "Direccionales derecha e izquierda",
    "CLX": "Pito",
    "LCT": "Luz indicadores tablero",
    "FD": "Freno delantero",
    "FT": "Freno trasero",
    "LD": "Llanta delantera (profundidad mínima de labrado)",
    "LT": "Llanta trasera (profundidad mínima de labrado)",
    "JDL": "Juego de levas",
    "RDM": "Rodamientos",
    "TM": "Timón",
    "GY": "Guayas",
    "BT": "Bujes tijera",
    "EPJ": "Espejos",
    "CJN": "Cojín",
    "GDB": "Guarda barros",
    "AMT": "Amortiguación",
    "CRJ": "Carenaje",
    "CDN": "Cadena",
    "EXT": "Exosto",
    "FAC": "Filtro de Aceite",
    "FGS": "Filtro de Gasolina",
    "TPL": "Tapas laterales",
    "RA": "Ruidos anormales",
    "HM": "Salida de humo",
    "AMCH": "Ajuste del motor en chasis",
    "FAM": "Funcionamiento del arranque",
    "CBJ": "Capuchón de bujías",
    "KARR": "Estado de kit de arrastre",
    "PDA": "Pata de apoyo",
    "LLGS": "Llave de gasolina",
    "MPC": "Manillas y palanca de cambios",
    "DMTR": "Defensa del motor",
    "CCS": "¿Está en condiciones de salud y descanso para usar el vehículo (sin malestar general, mareo, fiebre, sueño u otros síntomas)?",
    "CSCR": "Casco reglamentario con reflectivos",
    "PCR": "Prendas con reflectivos ubicados en las piernas, brazos y espalda",
    "CP": "Complementos de protección: Chaqueta y botas de motociclista, rodilleras y guantes"
  };

  public estandarVehicleInspection: {
    "vehicleStructure": Array<CheckLists>,
    "bodyworkElements": Array<CheckLists>,
    "elements": Array<ProtectionElements>
  } = {
      vehicleStructure: [
        { question: CheckListQuestionVehicle.LAT, response: "B" },
        { question: CheckListQuestionVehicle.PYC, response: "B" },
        { question: CheckListQuestionVehicle.AAC, response: "B" },
        { question: CheckListQuestionVehicle.SBP, response: "B" },
        { question: CheckListQuestionVehicle.EVI, response: "B" },
        { question: CheckListQuestionVehicle.EES, response: "B" },
        { question: CheckListQuestionVehicle.SDR, response: "B" },
        { question: CheckListQuestionVehicle.SPT, response: "B" },
        { question: CheckListQuestionVehicle.EPL, response: "B" },
        { question: CheckListQuestionVehicle.EFP, response: "B" },
        { question: CheckListQuestionVehicle.LDC, response: "B" },
        { question: CheckListQuestionVehicle.LDT, response: "B" },
        { question: CheckListQuestionVehicle.LDR, response: "B" },
        { question: CheckListQuestionVehicle.FDE, response: "B" },
        { question: CheckListQuestionVehicle.ASR, response: "B" },
        { question: CheckListQuestionVehicle.EIV, response: "B" },
        { question: CheckListQuestionVehicle.CDI, response: "B" },
        { question: CheckListQuestionVehicle.CDH, response: "B" },
        { question: CheckListQuestionVehicle.FBA, response: "B" },
        { question: CheckListQuestionVehicle.CCD, response: "B" },
        { question: CheckListQuestionVehicle.CCT, response: "B" },
        { question: CheckListQuestionVehicle.LDE, response: "B" },
        { question: CheckListQuestionVehicle.DID, response: "B" },
        { question: CheckListQuestionVehicle.DIT, response: "B" },
        { question: CheckListQuestionVehicle.LPR, response: "B" },
        { question: CheckListQuestionVehicle.STP, response: "B" },
        { question: CheckListQuestionVehicle.LST, response: "B" },
        { question: CheckListQuestionVehicle.CDC, response: "B" },
        { question: CheckListQuestionVehicle.LIN, response: "B" },
        { question: CheckListQuestionVehicle.AGV, response: "B" },
        { question: CheckListQuestionVehicle.IGG, response: "B" },
        { question: CheckListQuestionVehicle.QRP, response: "B" },
        { question: CheckListQuestionVehicle.PMC, response: "B" },
        { question: CheckListQuestionVehicle.BYC, response: "B" },
        { question: CheckListQuestionVehicle.TCA, response: "B" },
        { question: CheckListQuestionVehicle.PAR, response: "B" },
        { question: CheckListQuestionVehicle.EPA, response: "B" },
        { question: CheckListQuestionVehicle.SFT, response: "B" },
        { question: CheckListQuestionVehicle.FPR, response: "B" },
        { question: CheckListQuestionVehicle.LIM, response: "B" },
        { question: CheckListQuestionVehicle.KDD, response: "B" },
        { question: CheckListQuestionVehicle.BPA, response: "B" },
        { question: CheckListQuestionVehicle.JDH, response: "B" },
        { question: CheckListQuestionVehicle.COT, response: "B" },
        { question: CheckListQuestionVehicle.GEM, response: "B" },
        { question: CheckListQuestionVehicle.CRU, response: "B" },
        { question: CheckListQuestionVehicle.FDA, response: "B" },
      ],
      bodyworkElements: [
        { question: CheckListQuestionVehicle.PAF, response: "B" },
        { question: CheckListQuestionVehicle.PIS, response: "B" },
        { question: CheckListQuestionVehicle.EYB, response: "B" },
        { question: CheckListQuestionVehicle.COR, response: "B" },
        { question: CheckListQuestionVehicle.LAI, response: "B" },
        { question: CheckListQuestionVehicle.TEC, response: "B" },
        { question: CheckListQuestionVehicle.PIN, response: "B" },
        { question: CheckListQuestionVehicle.CAR, response: "B" },
        { question: CheckListQuestionVehicle.LAR, response: "B" },
        { question: CheckListQuestionVehicle.PYM, response: "B" },
        { question: CheckListQuestionVehicle.AMR, response: "B" },
        { question: CheckListQuestionVehicle.PLA, response: "B" }
      ],
      elements: [
        { name: CheckListQuestionVehicle.TBC, available: true, status: "B" },
        { name: CheckListQuestionVehicle.GOA, available: true, status: "B" },
        { name: CheckListQuestionVehicle.GUA, available: true, status: "B" },
        { name: CheckListQuestionVehicle.MDS, available: true, status: "B" },
        { name: CheckListQuestionVehicle.CHR, available: true, status: "B" },
        { name: CheckListQuestionVehicle.CSC, available: true, status: "B" },
      ]
    }

  public motorcycleVehicleInspection: {
    "vehicleStructure": Array<CheckLists>,
    "elements": Array<ProtectionElements>
  } = {
      vehicleStructure: [
        { question: CheckListQuestionVehicle.KLM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.LM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.DM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.CLX, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.LCT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.FD, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.FT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.LD, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.LT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.JDL, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.RDM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.TM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.GY, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.BT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.EPJ, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.CJN, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.GDB, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.AMT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.CRJ, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.CDN, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.EXT, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.FAC, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.FGS, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.TPL, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.RA, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.HM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.AMCH, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.FAM, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.CBJ, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.KARR, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.PDA, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.LLGS, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.MPC, response: "B", observation: '' },
        { question: CheckListQuestionVehicle.DMTR, response: "B", observation: '' },
      ],
      elements: [
        { name: CheckListQuestionVehicle.CCS, available: true, status: "B", observation: '' },
        { name: CheckListQuestionVehicle.CSCR, available: true, status: "B", observation: '' },
        { name: CheckListQuestionVehicle.PCR, available: true, status: "B", observation: '' },
        { name: CheckListQuestionVehicle.CP, available: true, status: "B", observation: '' },
      ],
    };

}
