<form class="row m-0 d-flex align-items-start justify-content-start">
    <div class="col-md-6 m-0 p-0" *ngIf="canAssociateCostCenter">
        <mat-form-field appearance="outline" class="p-0">
            <mat-label>Centro de Costo<span class="asterisk">*</span></mat-label>
            <input type="text" #inputAutoComplete matInput [formControl]="costCenterCtrl" [matAutocomplete]="auto">
            <mat-error *ngIf="costCenterCtrl.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCostCenterSelected">
                <mat-option *ngFor="let costCenter of listGeneralCostCenter | async" [value]="costCenter">
                    {{costCenter.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="col-md-4 m-0 p-0" *ngIf="canAssociateCostCenter">
        <div class="button-container justify-content-start">
            <button type="button" (click)="onSubmit()" mat-raised-button class="bg-color-primary" matTooltip="Asociar centro de costo">
                Asociar
            </button>
        </div>
    </div>
    <div class="col-12 m-0 p-0" *ngIf="canCreateCostCenter && costCenters?.length">
        <div class="button-container justify-content-end">
            <button matTooltip="Crear centro de costo" mat-raised-button type="button" class="bg-color-primary"
                (click)="openCreateCostCenter()">
                <i class="fas fa-plus-circle"></i>
                <span>Crear</span>
            </button>
        </div>
    </div>
</form> 
<aside class="cost__center--list">
    <app-cost-center-item *ngFor="let costCenter of costCenters; let i = index;" [costCenter]="costCenter" [activeTab]="activeTab" [companyId]="company?.companyId" (refreshCompany)="refreshList.emit()">
    </app-cost-center-item>
    <div *ngIf="!costCenters?.length && canCreateCostCenter" class="container_no_results h-100" (click)="openCreateCostCenter()">
        <h4 class="text-bold text-center container_no_results__text"><i class="fas fa-plus"></i> Crear centro de costos</h4>
    </div>
</aside>
