import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DriverLicenseCategory } from 'src/app/core/interfaces/driverLicenseCategory';
import { User } from 'src/app/core/interfaces/user';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { UpdateVehicleFieldsComponent } from '../../administration/vehicles/update-vehicle-fields/update-vehicle-fields.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Utils } from 'src/app/core/resources/utils';

@Component({
    selector: 'app-modal-license-plate',
    templateUrl: './modal-license-plate.component.html',
    styleUrls: ['./modal-license-plate.component.scss']
})
export class ModalLicensePlateComponent {
    permission = Permission;
    title: string;
    cargos: Array<any>;
    licenses: DriverLicenseCategory[];

    constructor(
        public dialogRef: MatDialogRef<ModalLicensePlateComponent>,
        private permissionRole: PermissionRole,
        private matDialog: MatDialog,
        private utils: Utils,
        @Inject(MAT_DIALOG_DATA) public paramsDialog: {
            title: string, cargos: Cargo[], licenses: DriverLicenseCategory[], driver: User
        },
    ) {
        if (this.paramsDialog) {
            if (this.paramsDialog.title) this.title = this.paramsDialog.title;
            if (this.paramsDialog.cargos) this.cargos = this.paramsDialog.cargos;
            if (this.paramsDialog.licenses) this.licenses = this.paramsDialog.licenses;
        } else this.dialogRef.close();

    }

    /**
    * @param {DriverLicenseCategory} license is the license to edit 
    * @description Opens a modal to edit the license
    */
    editLicense(license: DriverLicenseCategory) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { type: 'license', license, driver: this.paramsDialog.driver };
        dialogConfig.width = ModalEnum.LARGE_WIDTH;
        dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
        dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
        dialogConfig.autoFocus = false;
        this.matDialog.open(UpdateVehicleFieldsComponent, dialogConfig).afterClosed().subscribe(
            (result) => result && result.state && this.dialogRef.close({ state: true })
        );
    }

    /**
    * @returns {boolean} Returns true if the user the "editFieldsRUNT" permission, otherwise false
    * @description Verifies if the user has the "editFieldsRUNT" permission
    */
    get canOverwriteInfo(): boolean {
        return this.permissionRole.hasPermission(
            this.permission.administration.module,
            this.permission.administration.editFieldsRUNT
        );
    }
}
