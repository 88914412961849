import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/core/interfaces/city';
import { BasicCity } from 'src/app/core/interfaces/basicCity';
import { Observable } from 'rxjs';
import { ApiResponseTax } from 'src/app/core/interfaces/tax';


@Injectable({
    providedIn: 'root'
})
export class CityService {

    public static citySelected: City | BasicCity;

    constructor(
        private http: HttpClient,
        private endpointResources: Endpoints,
        private globalResources: Global
    ) {

    }

    public updateMainCity(city) {
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.updateMainCity, city, { observe: "response" }
        )
    }

    public updateIndependentCity(city) {
        return this.http.put(
            environment.urlServerTeclogi + this.endpointResources.urlUpdateIndependentCity, city
        )
    }

    public createCity(city) {
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.cities, city
        )
    }

    public addSubcities(city) {
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.assignica, city
        )
    }

    public getAllCities(pageKey: number, pageSize: number) {
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.cities + '?pageKey=' + pageKey + '&pageSize=' + pageSize
        );
    }

    public getTaxes(): Observable<ApiResponseTax> {
        return this.http.get<ApiResponseTax>(
            environment.urlServerTeclogi + this.endpointResources.companyTaxes
        );
    }

    public getProdutSiigo(pageKey: number, pageSize: number): Observable<any> {
        const params = new HttpParams()
            .set('pageKey', String(pageKey))
            .set('pageSize', String(pageSize));

        return this.http.get(environment.urlServerTeclogi + this.endpointResources.companyProductSiigo, { params });
    }

    public getSubcityByIdCity(name) {
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.subcity + name
        );
    }

    public removeSubCity(idSubcity) {
        return this.http.delete(
            environment.urlServerTeclogi + this.endpointResources.subcity + idSubcity
        );
    }

    public getCityById(id) {
        return this.http.get(
            environment.urlServerTeclogi + this.endpointResources.cities + "/" + id
        );
    }

    public putICACity(city) {
        return this.http.put(
            environment.urlServerTeclogi + this.endpointResources.cities + this.endpointResources.urlUpdatecities, city
        )
    }

    public getReteicaCity(city: City | BasicCity): number {
        let reteica: number = 0;
        if (city && city['mainCity'] && city['mainCity'].reteica > 0) {
            reteica = city['mainCity'].reteica;
        } else if (city && city['reteica'] > 0) {
            reteica = city['reteica'];
        }
        return reteica;
    }

    public getCityValue(city: City, value: string) {
        if (city && city[value]) return city[value];
        else if (city && city.mainCity && city.mainCity[value]) return city.mainCity[value];
        return undefined;
    }
}
