export enum UserState {
    WRONG_DRIVER_DATA = 'Los datos de propietario suministrados no corresponden con los propietarios activos del vehiculo',
    PENDING = 'Pending',
    PENDING_VALIDATE_DOCUMENTS = 'Pendiente validar documentos',
    TRUORA_WRONG_DRIVER_DATA = 'El número de documento no se encuentra en la base de datos o la fecha de expedición ingresada no corresponde con la cédula.',
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
    DRIVER_LICENCE_NOT_ACTIVE = 'Licencia de conducción no activa',
    DRIVER_LICENCE_ACTIVE = 'Licencia de conducción activa',
    ACTIVATE_BY_SCORE = 'Activo puntaje bajo',
    EXPIRED = 'Vencido',
    DOES_NOT_MEET_REQUIREMENTS = 'No cumple con los requisitos',
    LOW_SCORE = 'Puntaje de seguridad demasiado bajo',
    WITHOUT_VALIDATION = 'Sin validacion',
}
