<section class="vehicle__documents">
  <div class="row title-2 text-strong color-secondary-dark">
    <div class="col-md-12">Documentos del Vehículo</div>
  </div>
  <hr />
  <section>
    <form>
      <div class="row align-items-baseline">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Tipo de documento:</mat-label>
            <mat-select [(ngModel)]="selectDocumentView" (selectionChange)="onChangeTypeDocument()"
              name="selectDocumentView">
              <mat-option *ngFor="let documentType of listTypeDocuments" [value]="documentType">
                <i class="fas fa-exclamation-circle color--error"
                  *ngIf="documentType?.mandatory && !isUploadedDocument(documentType)"></i>
                <i class="fas fa-exclamation-circle color--warning"
                  *ngIf="documentType?.mandatory && isUploadedDocument(documentType) && !isValidatedDocument(documentType)"></i>
                <i class="fas fa-check-circle color--success"
                  *ngIf="documentType?.mandatory && isUploadedDocument(documentType) && isValidatedDocument(documentType)"></i>
                {{documentType.name}}
                <span class="asterisk" *ngIf="documentType?.mandatory">*</span>
                <span class="optional--black" *ngIf="!documentType?.mandatory">(opcional)</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6" *ngIf="!!selectDocumentView">
          <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" />
          <mat-form-field appearance="standard" (click)="fileInput.click()">
            <mat-label>
              {{!!selectDocumentView? selectDocumentView.name: "Seleccione un documento"}}<span class="asterisk"
                *ngIf="selectDocumentView?.mandatory">*</span>

            </mat-label>
            <input matInput type="text" [(ngModel)]="nameFileSelected" name="nameFileSelected" readonly />
            <i class="fas fa-paperclip field__form--icon color-primary" for="file"></i>
          </mat-form-field>
        </div>

        <div class="col-md-3" *ngIf="!!selectDocumentView && !isStaticDocument(selectDocumentView)">
          <mat-form-field appearance="standard">
            <mat-label>¿Validado?</mat-label>
            <mat-select (selectionChange)="setValidation($event)" [formControl]="validated"
              [disabled]="!(hasPermissionToValidateDocuments)">
              <mat-option [value]="true">Sí</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </section>

  <section>
    <button *ngIf="!!selectDocumentView" mat-raised-button color="primary" type="button"
      class="button-width-auto bg-color-primary" (click)="downloadCurrentFile()">
      Descargar
    </button>
    &nbsp;
    <button mat-raised-button color="primary" type="button" class="button-width-auto bg-color-primary"
      (click)="downloadAll()">
      Descargar Todo
    </button>
  </section>
  <br />
  <section>
    <app-files-storage class="width-100" [nameFile]="path" *ngIf="!!path">
    </app-files-storage>
  </section>
</section>