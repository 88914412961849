import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Global } from 'src/app/core/resources/global';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/core/resources/utils';
import { Company } from 'src/app/core/interfaces/company';
import { ModelRoleComany, RoleComany } from 'src/app/core/interfaces/roleCompany';
import { Observable} from 'rxjs';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import __costCenters from '__mock/costCenters.json';
import { TreeFolder } from 'src/app/core/interfaces/treeFolder';
import { AuthService } from 'src/app/core/services/authentication.service';
import { AdditionalCost } from 'src/app/core/interfaces/additionalCost';
import { WhoToPay } from 'src/app/core/interfaces/whoToPay';
import { PaymentType } from 'src/app/core/interfaces/paymentType';
import { CompanyResource } from 'src/app/core/interfaces/companyResource';
import { Tag } from 'src/app/core/interfaces/tag';
import { map, timeout } from 'rxjs/operators';
import { ListHistoryResponse } from 'src/app/core/interfaces/list-history';
import { ValidationRule } from 'src/app/core/interfaces/validation-rule';
import { CompanyCompliment, CompanyComplimentDTO, ComplimentTitle } from 'src/app/core/interfaces/companyCompliment';
import { VehicleDocumentRequired } from 'src/app/core/interfaces/vehicleDocuments';
import { Companies } from 'src/app/core/resources/companies';
import { Fmt } from 'src/app/core/messages/fmt';


@Injectable({
  providedIn: 'root'

})
export class CompaniesService {

  compnySelected: Company;
  private mockCostCenters: CostCenter[] = __costCenters;

  constructor(
    private http: HttpClient,
    private endpointResources: Endpoints,
    private globalResources: Global,
    public utils: Utils,
    private modelRoleComany: ModelRoleComany,
    private authService: AuthService
  ) {

  }

  public getListCompaniesChildren(nit: string, pageKey: number, pageSize: number) {
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.getAllCompaniesChildrens}${nit}`
    );
  }

  public getHistoryCompanies(
    companyId?: string,
    serviceType?: string[],
    startDate?: string,
    endDate?: string
  ): Observable<ListHistoryResponse> {
    let params = new HttpParams();
    const data = {
      companyId,
      serviceType,
      startDate,
      endDate
    };
    for (let [key, value] of Object.entries(data)) {
      if (value) {
        if (Array.isArray(value)) value = JSON.stringify(value);
        params = params.append(key, value);
      }
    }
    return this.http.get<ListHistoryResponse>(
      `${environment.urlServerTeclogi}${this.endpointResources.getHistoryCompanies}${params}`
    );
  }

  public getReportHistoryCompanies(
    companyId?: string,
    serviceTypes?: string[],
    startDate?: string,
    endDate?: string,
    email = false,
    excel = true
  ) {
    let params = new HttpParams();
    const data = {
      companyId,
      serviceTypes,
      startDate,
      endDate,
      email,
      excel
    };
    for (let [key, value] of Object.entries(data)) {
      if (this.utils.isDefined(value)) {
        if (Array.isArray(value)) value = JSON.stringify(value);
        params = params.append(key, value.toString());
      }
    }
    return this.http.get(
      `${environment.urlServerTeclogi}${this.endpointResources.getReportHistoryCompanies}`,
      { params, observe: 'response', responseType: 'blob' }
    ).pipe(timeout(600000));
  }

  public getAdditionalServices(): Observable<AdditionalCost[]> {
    return this.http.get<AdditionalCost[]>(environment.urlServerTeclogi + this.endpointResources.companyAdditionalCost);
  }

  public getWhoToPays(): Observable<WhoToPay[]> {
    return this.http.get<WhoToPay[]>(`${environment.urlServerTeclogi}${this.endpointResources.urlWhoToPays}`)
  }

  public saveWhoToPaysUtilities(body) {
    return this.http.put(
      environment.urlServerTeclogi + this.endpointResources.companyAdditionalCost,
      body
    );
  }

  public createCompany(companyData: Company) {

    let url = `${environment.urlServerTeclogi}${this.endpointResources.urlCreateCompany}`;

    return this.http.post(
      url,
      companyData
    );
  }

  public updateCompany(companyData: Company) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.updateCompany}`,
      companyData
    );
  }

  public deleteCompany(nit: string) {
    return this.http.post(
      environment.urlServerTeclogi + this.endpointResources.deleteCompany,
      [nit]
    );
  }

  public uploadEmailByCompany(storageName: string, fileExternsion: string, typeName: string, body: string) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.addEmailCompany}storageName=${storageName}&fileExternsion=${fileExternsion}&fileType=${typeName}`,
      body
    );
  }

  public uploadResourcesCompany(companyId: string, body: CompanyResource) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.companyFile}?companyId=${companyId}`,
      body
    );
  }

  public getResourcesCompany(companyId: string): Observable<CompanyResource[]> {
    return this.http.get<CompanyResource[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.getCompanyFile}?companyId=${companyId}`
    );
  }

  public createRole(role: RoleComany) {

    const url = `${environment.urlServerTeclogi}${this.endpointResources.createRole}`;

    return this.http.post(
      url,
      [role]
    );
  }

  public createMultipleRole(rolesCompanies: RoleComany[]) {
    const url = `${environment.urlServerTeclogi}${this.endpointResources.createRole}`;
    return this.http.post(url, rolesCompanies);
  }

  /**
  * @returns {boolean} Returns true if the user's SaaS company has escort services
  * @description Verifies if the user's SaaS company has escort services
  */
  get hasEscortServicesCompany(): boolean {
    return this.authService.getCompanySaaS() && this.authService.getCompanySaaS().companyId === Companies.companiesNIT.SEGURIDAD_EXTREMA;
  }

  public getModelRole(nit: string): RoleComany[] {
    let listRoles: RoleComany[] = [];

    let adminDefaultCompany = this.modelRoleComany.adminDefaultCompany;
    const blockedForEscort = ['createRequestFromManualCreationCargo', 'createCargoFromRequest'];
    const allowedForEscort = ['createEscortService'];
    adminDefaultCompany.nit = nit;
    adminDefaultCompany.modules.forEach((module) => {
      if (module.name === 'cargo' && this.hasEscortServicesCompany) {
        module.functionalities.forEach((funct) => {
          if (blockedForEscort.includes(funct.name)) funct.visible = false;
          if (allowedForEscort.includes(funct.name)) funct.visible = true;
        })
      }
    });
    listRoles.push(adminDefaultCompany);

    let reader = this.modelRoleComany.reader;
    reader.nit = nit;
    listRoles.push(reader);

    listRoles.forEach((role) => {
      role.modules.forEach(module => {
        module.functionalities = module.functionalities.filter(functionality => !!functionality);
      })
    })

    return listRoles;
  }

  public getCostCenters(): Observable<CostCenter[]> {
    return this.http.get<CostCenter[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.costCenters}`
    );
  }

  public getPaymentTypes(): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.paymentTypes}`
    )
  }

  public associateCostCenter(body: CostCenter, nit: string): Observable<Company> {
    return this.http.put<Company>(
      `${environment.urlServerTeclogi}${this.endpointResources.associateCostCenter}${nit}`,
      body
    );
  }

  public uploadFilesCompany(paths: string[]) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.companyDocuments}`,
      {
        companyId: this.authService.getUserSession().clientCompanyId,
        documents: paths
      }
    );
  }

  public async transformFilesToArrayPath(list: TreeFolder[]): Promise<any> {
    return new Promise(resolve => {
      let listPaths: string[] = [];
      let count = 1;
      list.forEach(async item => {
        await this.getLastPathChildren(item, listPaths);
        if (count === list.length) {
          listPaths = listPaths.filter((value, index, self) => {
            return self.indexOf(value) === index;
          }).filter((value) => {
            return value.length;
          });
          resolve(listPaths);
        } else {
          count++;
        }
      });
    });
  }

  private getLastPathChildren(item: TreeFolder, listPaths) {
    let path: string = '';
    if (item.children.length) {
      item.children.forEach(async (element) => {
        if (element.children.length) {
          this.getLastPathChildren(element, listPaths);
        } else {
          path = element.path;
        }
        listPaths.push(path);
      });
    } else {
      path = item.path;
      listPaths.push(path);
    }
  }

  public addEmailToCompany(body, companyId) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.addEmailToCompany}?companyId=${companyId}`,
      body
    );
  }

  public updateAlertsAndBlocks(body, companyId) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.updateAlertsAndBlocks}?companyId=${companyId}`,
      body
    );
  }

  public updatePolicies(rules: ValidationRule[]) {
    const endpoint = `${environment.urlServerTeclogi}${this.endpointResources.validationRules}`;
    return this.http.put(endpoint, rules);
  }

  setDaysToDeactivateVehicle(body) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.urlSetDaysDeactivateVehicle}`,
      body
    );
  }

  updateChargeOwnFleet(companyId: string, value: boolean) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpointResources.updateChargeOwnFleet}${companyId}?active=${value}`, {});
  }

  public setResume(company: string, validateTruora: boolean) {
    return this.http.put(
      `${environment.urlServerTeclogi}${this.endpointResources.urlSetResume}/${company}?active=${validateTruora}`, {}
    );
  }

  public getLabelTags(): Observable<Tag[]> {
    return this.http.get<any>(
      `${environment.urlServerTeclogi}${this.endpointResources.labelTags}`
    ).pipe(
      map(response => response.catalog) // Transforma la respuesta para extraer solo el arreglo 'catalog'
    );
  }

  public addLabelTag(body) {
    return this.http.post(
      `${environment.urlServerTeclogi}${this.endpointResources.addlabelTag}`,
      body
    );
  }

  public getCompanyCompliments(companyNit: string): Observable<CompanyCompliment[]> {
    return this.http.get<CompanyCompliment[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.compliments}companyId=${companyNit}`
    );
  }

  public setMonitorState(req: { companyId: string, status: string }) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpointResources.monitorState}`, req);
  }

  public setRequiredVehicleDocuments(body: VehicleDocumentRequired[]) {
    return this.http.put(`${environment.urlServerTeclogi}company/saas/${this.authService.getCompanySaaS().companyId}/vehicle-documents`, body);
  }

  public createCompanyCompliment(companyNit: string, body: CompanyComplimentDTO): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${environment.urlServerTeclogi}${this.endpointResources.compliments}companyId=${companyNit}`,
      body
    );
  }

  public updateCompanyCompliment(companyNit: string, body: CompanyCompliment): Observable<{ message: string }> {
    return this.http.put<{ message: string }>(
      `${environment.urlServerTeclogi}${this.endpointResources.compliments}companyId=${companyNit}`,
      body
    );
  }

  public getComplimentTitles(): Observable<ComplimentTitle[]> {
    return this.http.get<ComplimentTitle[]>(
      `${environment.urlServerTeclogi}${this.endpointResources.getComplimentTypesCatalog}`
    );
  }

  public createComplimentTitle(title: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${environment.urlServerTeclogi}${this.endpointResources.addPrivateComplimentType}`,
      [{ name: title }]
    );
  }

  public deleteCostCenter(companyId: string, costCenterId: string): Observable<Company> {
    return this.http.delete<Company>(
      `${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.individualCostCenter, companyId)}/${costCenterId}`
    );
  }

  public assignCostCenterToService(cargoId: string, body: CostCenter){
    return this.http.put(`${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.assignCostCenterToService, cargoId)}`, body)
  }

}
