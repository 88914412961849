import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';

@Component({
  selector: 'app-cargo-planning-route',
  templateUrl: './cargo-planning-route.component.html',
  styleUrls: ['./cargo-planning-route.component.scss']
})
export class CargoPlanningRouteComponent implements OnInit {
  selectedItinerary: string = '';
  cargo: Cargo;
  routePlan: PlanningRoute;
  itineraries: RouteItinerary[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private utils: Utils,
    private planningRouteService: PlanningRouteService,
    public dialogRef: MatDialogRef<CargoPlanningRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public paramsDialog: {
      mode: "edit" | "detail",
      itineraryId: string,
      routePlanId?: string,
      cargo?: Cargo
    },
  ) {
    if (this.paramsDialog.itineraryId) this.selectedItinerary = this.paramsDialog.itineraryId;
    if (this.paramsDialog.cargo) this.cargo = this.paramsDialog.cargo;
  }

  ngOnInit() {
    if (this.paramsDialog.routePlanId && this.paramsDialog.mode === 'edit')
      this.getRouteById();
    else if (this.paramsDialog.itineraryId && this.paramsDialog.mode === 'detail')
      this.getItinerary(this.selectedItinerary);
    else {
      this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
      this.dialogRef.close();
    }
  }

  private getRouteById() {
    this.spinner.show();
    this.planningRouteService.getRoute(this.paramsDialog.routePlanId).subscribe(
      (response: PlanningRoute) => {
        if (!response || !response.id || !response.itineraries || !response.itineraries.length || !response.active) {
          this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
          this.spinner.hide();
          this.dialogRef.close();
          return;
        }
        this.routePlan = response;
        this.routePlan.itineraries.forEach(async (itinerary) => {
          await this.getItinerary(itinerary.id, itinerary);
        })
        this.spinner.hide();
      }, (error) => {
        this.snackBarService.openSnackBar("Ocurrió un error al acceder al plan de ruta", undefined, 'error');
        this.spinner.hide();
        this.dialogRef.close();
      }
    )
  }

  private async getItinerary(id: string, incompleteItinerary?: RouteItinerary) {
    try {
      const itinerary = await this.planningRouteService.getItinerary(id ? id : incompleteItinerary.id).toPromise();
      if (itinerary && itinerary.id)
        this.itineraries.push(itinerary);
      else if (incompleteItinerary)
        this.itineraries.push(incompleteItinerary);
    } catch (e) {
      if (incompleteItinerary) this.itineraries.push(incompleteItinerary);
    }
  }

  onSelectItinerary(itinerary: RouteItinerary) {
    this.selectedItinerary = itinerary.id;
  }

  saveItinerary() {
    if (!this.selectedItinerary) return this.snackBarService.openSnackBar("No ha seleccionado un itinerario", undefined, 'alert');
    const body = {
      cargoId: this.paramsDialog.cargo.id,
      routePlanId: this.paramsDialog.routePlanId,
      itineraryId: this.selectedItinerary
    }
    this.spinner.show();
    this.planningRouteService.setCargoRouteAndItinerary([body]).subscribe(
      (response) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar("Se actualizó correctamente el itinerario");
        this.dialogRef.close({ state: true });
      }, (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    )

  }



}
