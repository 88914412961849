<form class="modal-body wrapper-modal position-relative" [formGroup]="form">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <section *ngIf="step===1" class="container p-0">
    <p class="title mx-3">
      Ingresa las fechas de cargue y descargue
    </p>
    <mat-form-field appearance="standard" class="col-12">
      <mat-label>Fecha de cargue<span class="asterisk">*</span></mat-label>
      <input [formControl]="dateTimeForm.get('dateLoadControl')" matInput readonly [matDatepicker]="datePickerLoad"
        (dateChange)="onChangeDate($event,'load')" (input)="processInput($event,'load')" (click)="datePickerLoad.open()"
        [min]="minDateLoad">
      <mat-error *ngIf="dateTimeForm.get('dateLoadControl').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePickerLoad"></mat-datepicker-toggle>
      <mat-datepicker #datePickerLoad></mat-datepicker>
    </mat-form-field>
    <div class="col-6 d-inline-block">
      <mat-form-field appearance="standard" class="time__address-list-time">
        <mat-label>Hora de cargue<span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="dateTimeForm.get('timeLoadControl')" (selectionChange)="onChangeHour($event,'load')">
          <mat-option [value]="time.value" *ngFor="let time of timeList">
            {{time.title}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="dateTimeForm.get('timeLoadControl').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 d-inline-block">
      <mat-button-toggle-group appearance="standard" class="time__address-type-time" #group="matButtonToggleGroup"
        [(ngModel)]="timeLoad" [ngModelOptions]="{standalone: true}">
        <mat-button-toggle value="AM">
          AM
        </mat-button-toggle>
        <mat-button-toggle value="PM">
          PM
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="standard" class="col-12">
      <mat-label>Fecha de descargue<span class="asterisk">*</span></mat-label>
      <input [formControl]="dateTimeForm.get('dateDownloadControl')" matInput readonly
        [matDatepicker]="datePickerDownload" (dateChange)="onChangeDate($event,'Download')"
        (input)="processInput($event,'load')" (click)="datePickerDownload.open()" [min]="minDateDownload">
      <mat-error *ngIf="dateTimeForm.get('dateDownloadControl').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePickerDownload"></mat-datepicker-toggle>
      <mat-datepicker #datePickerDownload></mat-datepicker>
    </mat-form-field>
    <div class="col-6 d-inline-block">
      <mat-form-field appearance="standard" class="time__address-list-time">
        <mat-label>Hora de descargue<span class="asterisk">*</span></mat-label>
        <mat-select [formControl]="dateTimeForm.get('timeDownloadControl')"
          (selectionChange)="onChangeHour($event,'Download')">
          <mat-option [value]="time.value" *ngFor="let time of timeList">
            {{time.title}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="dateTimeForm.get('timeDownloadControl').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-4 d-inline-block">
      <mat-button-toggle-group appearance="standard" class="time__address-type-time" #group="matButtonToggleGroup"
        [(ngModel)]="timeDownload" [ngModelOptions]="{standalone: true}">
        <mat-button-toggle value="AM">
          AM
        </mat-button-toggle>
        <mat-button-toggle value="PM">
          PM
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <mat-form-field appearance="standard" class="col-12">
      <mat-label>Valor declarado del contenedor vacío<span class="asterisk">*</span></mat-label>
      <input matInput type="text" placeholder="$000.000" currencyMask formControlName="commodityValue">
      <mat-error *ngIf="form.get('commodityValue').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="form.get('commodityValue').errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor declarado', minCommodityValue) }}
      </mat-error>
    </mat-form-field>
    <div class="button-container mt-2">
      <button type="button" mat-raised-button class="primary-secondary" color="primary"
        (click)="closeDialog()">Cancelar</button>
      <button type="button" mat-raised-button color="primary" class="bg-color-primary"
        (click)="nextStep(1)">Siguiente</button>
    </div>
  </section>
  <section *ngIf="step===2" formGroupName="shippingCost" class="container">
    <p class="title">
      Ingresa el valor declarado de la mercancia
    </p>

    <div class="col-12">
      <mat-form-field>
        <mat-label>
          Valor de la Tarifa<span class="asterisk">*</span>
        </mat-label>
        <input matInput type="text" currencyMask placeholder="$000.000" formControlName="rate">
        <i matSuffix class="fas fa-info-circle color-secondary-dark"
          matTooltip="Hace referencia al valor que TECLOGI cobra al CLIENTE" matTooltipClass="tooltip__alert"
          matTooltipPosition="right"></i>
        <mat-error *ngIf="form.get('shippingCost.rate').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('shippingCost.rate').errors?.min">
          {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'tarifa', minRate) }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mt-4">
        <mat-label>
          Valor del flete<span class="asterisk">*</span>
        </mat-label>
        <input matInput type="text" currencyMask placeholder="$000.000" formControlName="freightCost"
          (keyup)="setTotalCost()">
        <i matSuffix class="fas fa-info-circle color-secondary-dark"
          matTooltip="Hace referencia al valor que se paga al CONDUCTOR" matTooltipClass="tooltip__alert"
          matTooltipPosition="right"></i>
        <mat-error *ngIf="form.get('shippingCost.freightCost').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('shippingCost.freightCost').errors?.min">
          {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'flete', minFreightCost) }}
        </mat-error>
      </mat-form-field>
      <div class="alert alert-danger col-md-12 mb-md-0 d-flex flex-row align-items-center mt-4" role="alert"
        *ngIf="utilityCargo < utilityOwner">
        <i class="fas fa-exclamation-circle fa-lg mr-3"></i> <span class="text-bold">La utilidad no debe ser
          menor a {{utilityOwner}}%</span>
      </div>
      <div class="alert alert-warning col-md-12 mb-md-0 d-flex flex-row align-items-center mt-4" role="alert"
        *ngIf="utilityCargo > 50 && form.get('shippingCost.freightCost').value > 0">
        <i class="fas fa-exclamation-circle fa-lg mr-3"></i> <span class="text-bold">La utilidad es mayor al
          50%</span>
      </div>

      <mat-form-field class="my-4">
        <mat-label>
          Porcentaje de anticipo<span class="asterisk">*</span>
        </mat-label>
        <input matInput type="number" formControlName="advancePercentage">
        <i class="fas fa-info-circle color-secondary-dark" matSuffix [matTooltip]="messageAdvancePercentageAllowed"></i>
        <mat-error *ngIf="form.get('shippingCost.advancePercentage').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('shippingCost.advancePercentage').errors?.min">
          {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'porcentaje de anticipo', minAdvancePercentage) }}
        </mat-error>
        <mat-error *ngIf="form.get('shippingCost.advancePercentage').errors?.max">
          {{ utils.giveMessageError('MAX_VALUE_EXCEED', 'porcentaje de anticipo',
          maxAdvancePercentage )}}
        </mat-error>
      </mat-form-field>

    </div>
    <div class="button-container">
      <button type="button" mat-raised-button color="primary" class="bg-color-primary"
        (click)="nextStep(2)">Siguiente</button>
    </div>
  </section>
  <section *ngIf="step===3" class="container">
    <p class="title">
      Ingresa el tiempo de pago
    </p>

    <div formGroupName="shippingCost">
      <mat-form-field>
        <mat-label>Tiempo de pago del saldo (en días)<span class="asterisk">*</span></mat-label>
        <mat-select formControlName="paymentTime">
          <mat-option *ngFor="let day of paymentDays | keyvalue" [value]="day.value" (click)="othersSelected(false)">
            {{day.key}}
          </mat-option>
          <mat-option [value]="otherPaymentType.value" (click)="othersSelected(true)">
            Otro
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field appearance="standard" *ngIf="showOtherPaymentType" class="my-2">
      <mat-label>Otro valor (en días)<span class="asterisk">*</span></mat-label>
      <input matInput type="number" min="0" step="1" placeholder="valor" [formControl]="otherPaymentType"
        oninput="this.value=(parseInt(this.value)||0)">
      <mat-error *ngIf="otherPaymentType.errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
      <mat-error *ngIf="otherPaymentType.errors?.min">
        {{ utils.giveMessageError('MIN_VALUE_NOT_REACHED', 'valor', 0) }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="mb-2">
      <mat-label>Observaciones<span class="asterisk">*</span></mat-label>
      <textarea matInput formControlName="observation"></textarea>
      <mat-error *ngIf="form.get('observation').errors?.required">
        {{ utils.giveMessageError('MANDATORY_FIELD') }}
      </mat-error>
    </mat-form-field>

    <div class="col-md-12">
      <div class="button-container">
        <button mat-raised-button color="primary" class="bg-color-primary" (click)="onSubmit()">Terminar</button>
      </div>
    </div>
  </section>
</form>