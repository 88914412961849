import { TRIP_TYPES } from "src/app/core/enums/tripTypes.enum";
import { AddressCargo } from "src/app/core/interfaces/addressCargo";
import { Cargo } from "src/app/core/interfaces/cargo";
import { CargoMeasure } from "src/app/core/interfaces/cargoMeasure";
import { CargoNature } from "src/app/core/interfaces/cargoNature";
import { Destination } from "src/app/core/interfaces/destination";
import { OperationType } from "src/app/core/interfaces/operationType";

export class CargoResources {
  public steps = {
    main: {
      url: "/cargo/create",
      id: 1,
      parentId: 0,
    },
    dateLoad: {
      url: "/cargo/create-date-load",
      id: 2,
      parentId: 0,
    },
    typeTrip: {
      url: "/cargo/manual-creation/type-trip",
      id: 1,
      parentId: 1,
    },
    typeRegime: {
      url: "/cargo/manual-creation/type-regime",
      id: 2,
      parentId: 1,
    },
    typeCargo: {
      url: "/cargo/manual-creation/type-cargo",
      id: 3,
      parentId: 1,
    },
    containerMeasure: {
      url: "/cargo/manual-creation/container-measure",
      id: 4,
      parentId: 1,
    },
    typeService: {
      url: "/cargo/manual-creation/type-service",
      id: 5,
      parentId: 1,
    },
    tripTypeDocuments: {
      url: "/cargo/manual-creation/trip-type-documents",
      id: 6,
      parentId: 1,
    },
    // 2
    vehicleType: {
      url: "/cargo/manual-creation/type-vehicle",
      id: 1,
      parentId: 2,
    },
    quantityVehicles: {
      url: "/cargo/manual-creation/quantity-vehicles",
      id: 2,
      parentId: 2,
    },
    assignVehicles: {
      url: "/cargo/manual-creation/assign-vehicles",
      id: 3,
      parentId: 2,
    },
    typeMerchandise: {
      url: "/cargo/manual-creation/type-merchandise",
      id: 4,
      parentId: 2,
    },
    optional: {
      url: "/cargo/manual-creation/box-seal",
      id: 5,
      parentId: 2,
    },
    sizeLoad: {
      url: "/cargo/manual-creation/size-load",
      id: 6,
      parentId: 2,
    },
    // 3
    upload: {
      url: "/cargo/manual-creation/upload",
      id: 1,
      parentId: 3,
    },
    download: {
      url: "/cargo/manual-creation/download",
      id: 2,
      parentId: 3,
    },
    additionalService: {
      url: "/cargo/manual-creation/additional-service",
      id: 3,
      parentId: 3,
    },
    // 4
    cargoValue: {
      url: "/cargo/manual-creation/cargo-value",
      id: 1,
      parentId: 4,
    },
    methodPayment: {
      url: "/cargo/manual-creation/method-payment",
      id: 2,
      parentId: 4,
    },
    totalValue: {
      url: "/cargo/manual-creation/total-value",
      id: 3,
      parentId: 4,
    },
  };

  public cargoMock: Cargo = {
    ministry: true,
    id: "",
    dateLoad: "",
    distancy: 0,
    quantity: 0,
    state: null,
    numberDocumentSender: "",
    numberDocumentCreatorLoad: "",
    idCompany: "",
    businessName: "",
    driver: "",
    observation: "",
    observationDriver: "",
    riskProfile: {
      id: '',
      name: ''
    },
    labels: [],
    costCenter: {
      id: 0,
      code: '',
      name: '',
      active: false
    },
    cargoModel: {
      tripType: {
        name: TRIP_TYPES.EMPTY,
        regimeType: "",
        letterRetirement: "",
        date: "",
      },
      cargoType: {
        name: "",
        containerMeasure: {
          size: "",
        },
      },
      serviceType: {
        name: "",
        cost: "",
      },
      operationType: {
        code: "",
        description: "",
      },
      packagingType: {
        code: "",
        description: "",
      },
      cargoNature: {
        code: "1",
        description: "Carga Normal",
      },
    },
    cargoFeature: {
      productType: {
        name: "",
        type: "No", // Pendiente por implementar
        description: "",
        code: "",
        cargoNature: "",
        cargoNatureId: "",
      },
      vehicleType: {
        name: "",
        quantity: 0,
        quality: "",
        bodyWorkType: {
          name: "",
        },
      },
      cargoMeasure: {
        quantity: 0,
        totalMeasurement: 0,
        unit: "",
        totalWeigth: 0,
        amount: 0,
        type: "",
      },
      uploadDownload: {
        origin: {
          name: "",
          isInternational: false,
          country: {
            name: "Colombia",
            code: "CO",
          },
          municipalityCode: "",
          reteica: 0,
          addresses: [
            {
              id: "",
              address: "",
              location: {
                lat: 0,
                lng: 0,
              },
              time: "",
              cellphone: "",
              timePact: 0,
              minutePact: 0,
              quantity: 0,
              thirdPartyConsignment: {
                documentType: "",
                documentNumber: "",
                branchOffice: "",
                name: "",
                documentTypeName: "",
              },
              timeType: "AM",
            },
          ],
        },
        destination: [
          {
            id: "",
            name: "",
            isInternational: false,
            country: {
              name: "Colombia",
              code: "CO",
            },
            municipalityCode: "",
            downloadDate: "",
            reteica: 0,
            addresses: [
              {
                id: "",
                guidesId: [{ value: "" }],
                address: "",
                location: {
                  lat: 0,
                  lng: 0,
                },
                time: "",
                timePact: 0,
                minutePact: 0,
                cellphone: "",
                email: "",
                quantity: 0,
                cargoMeasure: {
                  quantity: 1,
                  totalMeasurement: 0,
                  unit: "1",
                  totalWeigth: 0,
                  amount: 0,
                  type: "Apilable",
                },
                timeType: 'AM',
                thirdPartyConsignment: {
                  documentType: '',
                  documentNumber: '',
                  branchOffice: '',
                  name: '',
                  documentTypeName: ''
                }
              }
            ]
          }
        ]
      }
    },
    shippingCost: {
      freightCost: 0,
      totalCost: 0,
      advancePercentage: 0,
      valueAdvance: 0,
      advancePaymentMethod: 'Transferencia',
      balancePaymentMethod: 'Transferencia',
      reteicaPercentage: 0,
      reteftePercentage: 0,
      paymentTime: 30,
      rate: 0
    },
    additionalCosts: [],
    estimatedTime: 0,
    exclusive: false,
    cargoOwner: {
      documentType: '',
      documentNumber: '',
      branchOffice: '',
      name: '',
      //
      address: '',
      location: {
        lat: 0,
        lng: 0
      }
    },
    seal: '',
    container: '',
    containerExpirationDate: '',
    booking: '',
    realTime: 0,
    axles: 2,
    zone: "private"
  };

  public thirdParty = {
    municipalityCode: "",
    parent: "",
    information: {
      documentTypeId: "",
      documentTypeName: "",
      document: "",
      name: "",
    },
    address: {
      address: "",
      location: {
        lat: 0,
        lng: 0,
      },
    },
  };

  public modelDestination: Destination = {
    id: null,
    name: "",
    addresses: [],
    downloadDate: "",
    municipalityCode: "",
  };

  public modelLoadMeasurement: CargoMeasure = {
    quantity: 1,
    totalMeasurement: 0,
    totalWeigth: 0,
    amount: 1,
    type: "Apilable",
    unit: "1",
  };

  public listVehicles = [
    {
      id: 1,
      name: "AUTOMOVIL",
      selected: false,
      icon: "AUTOMOVIL",
    },
    {
      id: 2,
      name: "CAMION",
      selected: false,
      icon: "CAMION",
    },
    {
      id: 3,
      name: "CAMIONETA",
      selected: false,
      icon: "CAMIONETA",
    },
    {
      id: 4,
      name: "TRACTOCAMION",
      selected: false,
      icon: "TRACTOCAMION",
    },
    {
      id: 5,
      name: "MOTOCICLETA",
      selected: false,
      icon: "MOTOCICLETA",
    },
  ];

  public listUploadDownloadFor: { code; description }[] = [
    {
      code: "C",
      description: "CONDUCTOR",
    },
    {
      code: "D",
      description: "DESTINATARIO",
    },
    {
      code: "R",
      description: "REMITENTE",
    },
    {
      code: "E",
      description: "EMPRESA DE TRANSPORTE",
    },
  ];

  public getTypeOperation(cargo: Cargo): OperationType {
    const typeOperation: OperationType = {
      code: null,
      description: null,
    };

    if (cargo.cargoFeature.productType.name === "CONTENEDOR VACIO") {
      typeOperation.code = "V";
      typeOperation.description = "Contenedor vacío";
    } else if (cargo.cargoModel.serviceType.name === "Carga consolidada") {
      typeOperation.code = "P";
      typeOperation.description = "Paqueteo";
    } else if (
      (cargo.cargoModel.tripType.name === "Exportación" || cargo.cargoModel.tripType.name === "Importación") &&
      cargo.cargoModel.cargoType.name === "Contenedor"
    ) {
      typeOperation.code = "C";
      typeOperation.description = "Contenedor cargado";
    } else {
      typeOperation.code = "G";
      typeOperation.description = "General";
    }

    return typeOperation;
  }

  public setTypeOperation(cargo: Cargo) {
    cargo.cargoModel.operationType = this.getTypeOperation(cargo);
  }

  public getCargoNature(cargo: Cargo): CargoNature {
    const cargoNature: CargoNature = {
      code: cargo.cargoModel.cargoNature.code,
      description: cargo.cargoModel.cargoNature.description,
    };

    if (cargo.cargoFeature.productType.cargoNatureId === "2" || cargo.cargoFeature.productType.cargoNatureId === "5") {
      cargoNature.code = cargo.cargoFeature.productType.cargoNatureId;
      cargoNature.description = cargo.cargoFeature.productType.cargoNature;
    } else if (cargo.cargoFeature.vehicleType && cargo.cargoFeature.vehicleType.quality && cargo.cargoFeature.vehicleType.quality === "Refrigerado") {
      cargoNature.code = "7";
      cargoNature.description = "Refrigerada";
    }

    return cargoNature;
  }

  public setCargoNature(cargo: Cargo) {
    cargo.cargoModel.cargoNature = this.getCargoNature(cargo);
  }
}
