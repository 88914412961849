<section class="w-100 main-container complete-height" id="section-complete-height">
    <div class="map-container">
        <app-routes-map #map [show-options]="false" class="google-map"></app-routes-map>
    </div>
    <header class="sites-type-tooltip">
        <div class="route-card cursor-pointer d-flex">
            <div (click)="siteButton('1')" class="site-type-button user-select-none" id="hotel-button">
                <img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg" alt="">
            </div>
            <div (click)="siteButton('2')" class="site-type-button user-select-none" id="fuel-button">
                <img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg" alt="">
            </div>
            <div (click)="siteButton('3')" class="site-type-button user-select-none" id="parking-button">
                <img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg" alt="">
            </div>
            <div (click)="siteButton('4')" class="site-type-button user-select-none" id="restaurant-button">
                <img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg" alt="">
            </div>
        </div>
    </header>
    <aside class="tools-container">
        <div class="row">
            <div class="col-12">
                <div style="padding: 15px" class="title-create-route">
                    <a (click)="goBack()" role="button">
                        <i class="fas fa-arrow-left"></i>
                    </a>
                    &nbsp;
                    <b>
                        {{
                        mode == 'RUTE' ? 'Ruta' :
                        mode == '1' ? 'Hoteles' :
                        mode == '2' ? 'Estaciones de servicio' :
                        mode == '3' ? 'Parqueaderos' :
                        mode == '4' ? 'Restaurantes' : 'Sitio Autorizado'
                        }}
                    </b>
                </div>
            </div>
        </div>
        <form [formGroup]="form" id="form" class="flex-fill">
            <div *ngIf="mode == 'RUTE'">
                <div>
                    <div cdkDropList (cdkDropListDropped)="drop($event)" class="addresses overflow-y">
                        <app-autocomplete-city [options]="originOptions" [inputFormControl]="originControl"
                            [validate]="originValidate">
                        </app-autocomplete-city>
                        <div *ngFor="let location of locations; let i = index" cdkDrag>
                            <app-complete-address [title]="'Punto de control #' + (i+1)" [icon]="i === 0 ? '../../../../../assets/svg/icons/tl_ico__pin_google_maps_origen.svg' :
                            '../../../../../assets/svg/icons/tl_ico__pin_google_maps_destino.svg'"
                                [actionIcon]="'../../../../../assets/svg/icons/tl_ico__x.svg'" [draggable]="true"
                                (remove)="deleteLocation(i)" (location)="onLocation($event, i)"
                                [control]="locationNames[i]">
                                <img src="../../../../assets/svg/icons/tl_ico__draggable.svg" cdkDragHandle
                                    class="position-absolute" height="100%">
                            </app-complete-address>
                        </div>
                        <app-autocomplete-city [options]="destinationOptions" [inputFormControl]="destinationControl"
                            [validate]="destinationValidate">
                        </app-autocomplete-city>
                    </div>

                    <div class="mx-2 user-select-none">
                        <div (click)="appendLocation()" class="cursor-pointer accent-text">
                            <i class="fas fa-plus-circle"></i>
                            &nbsp;
                            <b class="underline">Agregar punto de control</b>
                        </div>
                    </div>
                </div>

                <div *ngIf="sites?.length" class="mt-2">
                    <br>
                    <b style="font-size: 14px; font-weight: 700;">Puntos autorizados</b>
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex align-items-center w-100 search-result"
                                *ngFor="let result of sites | keyvalue;">
                                <img *ngIf="result.value?.details?.pointType?.id == '1'"
                                    src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg">
                                <img *ngIf="result.value?.details?.pointType?.id == '2'"
                                    src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg">
                                <img *ngIf="result.value?.details?.pointType?.id == '3'"
                                    src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg">
                                <img *ngIf="result.value?.details?.pointType?.id == '4'"
                                    src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg">

                                <div class="w-100 site-name">
                                    {{result.value?.details?.name}}<br>
                                    <small>{{result.value?.address}}</small>
                                </div>
                                <a role="button" (click)="removeSiteFromCurrentSiteList(result.key)">
                                    <img src="../../../../assets/svg/icons/tl_ico__x _purple.svg" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="mode != 'RUTE'" class=" d-flex flex-column">
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Buscar {{
                            mode == '1' ? 'hoteles' :
                            mode == '2' ? 'gasolinería' :
                            mode == '3' ? 'parqueaderos' :
                            mode == '4' ? 'restaurantes' : 'sitio autorizado'
                            }}
                        </mat-label>
                        <input type="text" matInput formControlName="search">
                        <i (click)="search()" matSuffix class="fas fa-search fa-lg cursor-pointer color-primary"></i>
                    </mat-form-field>
                </div>
                <div class="h-100 d-flex flex-column overflow-auto">
                    <div *ngIf="searchResult?.length">
                        <div class="d-flex justify-content-between w-100 search-result mb-1"
                            *ngFor="let result of searchResult; let i = index;">
                            <div class="w-100 site-name pl-0">
                                <b>{{result?.details?.name}}</b><br>
                                <small>{{result.address}}</small><br>
                                <small>{{result?.details?.contactPhone}}</small><br>
                            </div>
                            <div class="d-flex align-items-center">
                                <a role="button" (click)="addSite(i)" *ngIf="!sites || !sites[result.id]">
                                    <b class="text-success"><i class="fas fa-plus-circle"></i></b>
                                </a>
                                <a role="button" (click)="removeSite(i)" *ngIf="sites && sites[result.id]">
                                    <b class="text-danger"><i class="fas fa-times-circle"></i></b>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 text-center d-flex justify-content-center flex-column align-items-center"
                        *ngIf="!searchResult?.length">
                        <img src="../../../../assets/svg/icons/tl_ico__caja_vacia.svg">
                        <div class="w-100 mt-3">
                            No se encontraron resultados
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="actions">
            <button *ngIf="mode === 'RUTE'" mat-raised-button color="accent" class="w-100" (click)="createItinerary()">
                Agregar Itinerario
            </button>
            <button *ngIf="mode !== 'RUTE'" mat-raised-button color="accent" class="w-100"
                (click)="createAuthorizedPoint()">
                Crear punto
            </button>
        </div>
    </aside>
</section>

<aside class="route-card-container d-flex flex-column align-items-end">
    <div class="route-card cursor-pointer itinerary-card" *ngFor="let itinerary of itineraries; let i = index"
        [id]="itinerary.id" (click)="setCurrentItinerary(itinerary.id)" [ngClass]="{'active':itinerary.active}">
        <div class="row">
            <div class="col-12 text-center">
                <b class="title">#{{i+1}} {{itinerary.name}}</b>
            </div>
            <div class="col-12 d-flex justify-content-around">
                <small class="text-nowrap">
                    {{getDurationLabel(itinerary.estimatedTime)}}
                </small>
                <small>
                    <b *ngIf="itinerary.estimatedDistance">
                        {{itinerary.estimatedDistance / 1000 | number:'1.1-1'}} km
                    </b>
                </small>
            </div>
        </div>
        <div class="row">
            <div *ngIf="!itinerary.news">
                <p>
                    <img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_ok.svg"
                        alt="Símbolo de vía sin novedades">&nbsp;Sin
                    novedades
                </p>
            </div>
            <!-- <div *ngIf="route.news">
                <p><img src="../../../../assets/svg/icons/tl_ico__pin_google_maps_trip_new.svg"
                        alt="Símbolo de novedades en la vía">&nbsp;{{route.news}}</p>
            </div> -->
        </div>
        <div class="row mt-1" *ngIf="!itinerary?.active">
            <div class="col">
                <a role="button" class="teclogi-link" (click)="createRoute(itinerary.id)">Activar</a>
            </div>
        </div>
        <div class="alert alert-success text-center mb-0 mt-1 text-bold" role="alert" *ngIf="itinerary?.active">
            Itinerario activo
        </div>
    </div>
</aside>