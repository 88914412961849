import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ShippingCost } from 'src/app/core/interfaces/shippingCost';
import { CargoDetailService } from '../../cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CargoItemService } from '../../../cargo-item/cargo-item.service';
import { ShippingCost as ShippingCostEnum } from 'src/app/core/enums/shipping-cost.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { CargoAdvancePercentageService } from '../../../cargo-advance-percentage/cargo-advance-percentage.service';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { CompanyManager } from 'src/app/core/managers/company.manager';
import { environment } from 'src/environments/environment';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';

@Component({
  selector: 'app-shipping-cost',
  templateUrl: './shipping-cost.component.html',
  styleUrls: ['./shipping-cost.component.scss'],
  providers: [AuthService, Global]
})
export class ShipingCostComponent implements OnInit {

  otherFreightCost: FormControl = new FormControl(0, [Validators.required, Validators.min(0)]);
  permission = Permission;
  showOtherFreightCost: boolean = false;
  @Input() cargo: Cargo;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  reactiveForm: ReactiveForm;
  form: FormGroup;
  paymentTimeValues = [0, 8, 15, 30, 60, 90]
  MaxPercentage: number = this.authService.getCompany().companyId && (this.authService.getCompany().companyId === environment.rootNit) ? AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_TECLOGI : AmountsCargoEnum.MAX_ADVANCE_PERCENTAGE_ALLOWED_ALL_COMPANIES;
  minUtility: number = ShippingCostEnum.MINIMUN_UTILITY;
  onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public cargoAdvancePercentageService: CargoAdvancePercentageService,
    private router: Router,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private cargoResources: CargoResources,
    private snackBarService: SnackBarService,
    private cargoDetail: CargoDetailService,
    private companyManager: CompanyManager,
    private spinner: NgxSpinnerService,
    private cargoItemService: CargoItemService,
    private permissionRole: PermissionRole,
    private authService: AuthService
  ) { }

  ngOnInit() {
    if (this.cargo && this.cargo.shippingCost) {
      this.initForm(this.cargo);
    }
  }

  initForm(cargo) {
    if (cargo && cargo.shippingCost) {
      this.MaxPercentage = this.companyManager.getAdvancePercentageByCompanyId(this.cargo.idCompany);
      this.minUtility = this.companyManager.getUtilityByCompanyId(this.cargo.idCompany);
      this.reactiveForm = new ReactiveForm(this.formBuilder, cargo.shippingCost);
      this.form = this.reactiveForm.form;
      if (this.form && this.form.get('advancePercentage')) this.form.get('advancePercentage').setValidators([Validators.required, Validators.min(0), Validators.max(this.MaxPercentage)]);
      if (this.form && this.form.get('rate')) this.form.get('rate').setValidators([Validators.required, Validators.min(this.hasPermissionNoMinimumUtility || this.cargo.state === CargoStateEnum.REQUEST ? 0 : 1)]);
      if (this.form && this.form.get('freightCost')) this.form.get('freightCost').setValidators([Validators.required, Validators.min(this.hasPermissionNoMinimumUtility || this.cargo.state === CargoStateEnum.REQUEST ? 0 : 1)]);
      if (this.form && this.form.get('paymentTime') && !this.paymentTimeValues.some(value => this.form.get('paymentTime').value === value)) {
        this.otherFreightCost.setValue(this.form.get('paymentTime').value);
        this.form.get('paymentTime').setValue(-1);
      }

      if ((cargo.state && cargo.state !== CargoStateEnum.REQUEST) || (cargo.requestState && cargo.requestState !== CargoStateEnum.REQUEST)) {
        this.form.get('rate').disable();
        this.form.get('freightCost').disable();
        this.reactiveForm = new ReactiveForm(this.formBuilder, this.cargo.shippingCost);
      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.cargo && changes.cargo.currentValue) {
      if (this.form) this.form.patchValue(changes.cargo.currentValue.shippingCost);
      else this.initForm(changes.cargo.currentValue);
    }
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.utils.errorMessagesCustomized(this.form.get('rate'), 'tarifa', null, null, this.hasPermissionNoMinimumUtility ? 0 : 1)) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('freightCost'), 'flete', null, null, this.hasPermissionNoMinimumUtility ? 0 : 1)) return;
    else if (this.utils.errorMessagesCustomized(this.form.get('advancePercentage'), 'porcentaje de anticipo', null, null, 0, this.MaxPercentage)) return;
    else if ((this.utilityCargo < this.minUtility && !this.hasPermissionNoMinimumUtility) && this.cargo.state !== CargoStateEnum.REQUEST) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.MINIMUN_UTILITY_NOT_REACHED, this.minUtility), undefined, 'alert');
    } else if (this.form.invalid) {
      this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert')
    } else {
      let shippingCost = this.utils.clone(this.form.value);
      if (this.form.value.paymentTime === -1) {
        if (this.otherFreightCost.invalid) {
          if (this.utils.errorMessagesCustomized(this.otherFreightCost, 'valor', null, null, 0)) return;
          else {
            this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
            return;
          }
        } else {
          if (!this.otherFreightCost.value) this.otherFreightCost.setValue(0);
          shippingCost.paymentTime = this.otherFreightCost.value ? this.otherFreightCost.value : 0;
        }
      }
      this.updateShippingCost(shippingCost);
    }
  }

  updateShippingCost(shippingCost: ShippingCost) {
    const shippingCostObserver = {
      next: () => {
        this.spinner.hide();
        this.updateAdvancePercentage(this.form.get('advancePercentage').value);
      },
      error: () => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATE_ERROR_DEFAULT, undefined, 'error');
      }
    }
    this.spinner.show();
    this.cargoDetail.updateShippingCost(shippingCost, this.cargo.id).subscribe(shippingCostObserver);
  }

  updateAdvancePercentage(percentageValue: number) {
    this.cargoAdvancePercentageService.updatePercentage(this.cargo.id, percentageValue).toPromise()
      .then((success: Cargo) => {
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATED);
      })
      .catch((error) => {
        this.snackBarService.openSnackBar(Fmt.string(CargoMessages.CARGO_UPDATE_ERROR, 'porcentaje de anticipo'), undefined, 'error');
      })
      .finally(() => {
        this.spinner.hide();
        this.emitToParent.emit(this.cargo.consecutive);
      });
  }

  get utilityCargo() {
    this.form.get('totalCost').setValue(
      this.form.get('freightCost').value
    );
    const utilityValue = ((this.form.get('rate').value - this.form.get('totalCost').value) / this.form.get('rate').value * 100);
    return !Number.isNaN(utilityValue) ? utilityValue : 0;
  }

  get messageAdvancePercentageAllowed(): string {
    return `El porcentaje máximo permitido por ${this.companyManager.getNameByCompanyId(this.cargo.idCompany)} es ${this.MaxPercentage}%`;
  }

  get hasPermissionNoMinimumUtility() {
    return this.permissionRole.hasPermission(
      this.permission.cargo.module,
      this.permission.cargo.createCargosWithoutMinimumUtility
    )
      && this.cargo.cargoModel.tripType.name === "Urbana"
      && this.cargo.cargoFeature.productType
      && this.cargo.cargoFeature.productType.name === "CONTENEDOR VACIO";
  }

}
