<mat-form-field appearance="standard" class="p-0">
    <mat-label>Centro de costo (opcional)</mat-label>
    <input type="text" #inputAutoComplete matInput [formControl]="costCenterCtrl" [matAutocomplete]="auto">
    <i *ngIf="costCenterCtrl?.value?.code && !cargo?.shippingCost?.billId" matSuffix class="fas fa-times-circle cost-center__close-icon" matTooltip="Quitar centro de costo" (click)="clearCostCenter()"></i>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCostCenterSelected" (optionSelected)="onCostCenterSelected($event)">
        <mat-option *ngFor="let costCenter of listGeneralCostCenter | async" [value]="costCenter">
            {{costCenter.name}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<div *ngIf="canCreateCostCenter && costCenters?.length && !cargo?.shippingCost?.billId" class="text-bold color-secondary-dark mb-2 d-flex justify-content-end">
    <span (click)="openCreateCostCenter()" class="cursor-pointer"><i class="fas fa-plus-circle"></i> <u>Crear centro de costo</u></span>
</div>
<div *ngIf="!costCenters?.length && !cargo?.shippingCost?.billId" class="alert alert-warning" role="alert">
    No hay centros de costos asociados a la empresa<span *ngIf="canCreateCostCenter">; pero si deseas puedes crear un centro de costo <u class="cursor-pointer text-bold" (click)="openCreateCostCenter()">aquí</u></span>
</div>
<div *ngIf="cargo?.shippingCost?.billId" class="alert alert-primary" role="alert">
    <span class="text-bold">Recuerda:</span> No es posible asociar o cambiar un centro de costo a un servicio que ya ha sido facturado.
</div>
