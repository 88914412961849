import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Utils } from 'src/app/core/resources/utils';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { FreightListService } from '../cargo-list/cargo-list.service';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToPayLoads } from 'src/app/core/interfaces/toPayLoads';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { MassiveCargoOperationComponent } from '../massive-cargo-operation/massive-cargo-operation.component';
@Component({
  selector: 'app-massive-payments',
  templateUrl: './massive-payments.component.html',
  styleUrls: ['./massive-payments.component.scss']
})
export class MassivePaymentsComponent implements OnInit {
  massivePaymentsInput: FormControl = new FormControl('', Validators.required);
  massivePaymentsList: Array<string> = [];
  pageKey: number = 1;
  pageSize: number = 25;
  massiveAvailableLoadsListShow: Cargo[] = [];
  massiveUnavaliableLoadsListShow: Cargo[] = [];
  massiveNonExistentLoadsListShow: number[] = [];
  loadsSelectedList: Cargo[] = [];
  activeTab: number = 0;
  typeList: string = sessionStorage.getItem('_activeTab');
  constructor(
    public dialogRef: MatDialogRef<MassivePaymentsComponent>,
    public utils: Utils,
    public snackbarService: SnackBarService,
    public toPayService: FreightListService,
    public spinner: NgxSpinnerService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.openModalMassiveCargoOperation();
  }

  private async openModalMassiveCargoOperation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: "Pagar servicios masivamente",
      subtitle: "Ingrese los consecutivos de los servicios que desea pagar separados por una coma.",
      label: "Servicios a pagar",
      confirmButton: "Siguiente",
      method: this.toPayService.getListCargoToPay.bind(this.toPayService),
      validationToCheckValid: this.getIsPendingApproveBankAccount.bind(this)
    }
    let result: {
      availableList: Cargo[],
      unavaliableList: Cargo[],
      nonExistentList: number[],
      consecutiveList: string[]
    } = await this.dialog.open(MassiveCargoOperationComponent, dialogConfig).afterClosed().toPromise();
    if (!result) {
      this.dialogRef.close();
      return;
    };
    this.massivePaymentsList = result.consecutiveList;
    this.massiveNonExistentLoadsListShow = result.nonExistentList;
    this.massiveAvailableLoadsListShow = result.availableList;
    this.massiveUnavaliableLoadsListShow = result.unavaliableList;
  }

  onSelectTab($event: number) {
    this.activeTab = $event;
  }

  //GETTERS
  getLoadLabel(label: string, numberOfLoads: number) {
    return `${label} (${numberOfLoads})`;
  }

  getIsPendingApproveBankAccount(cargo: Cargo) {
    return !(
      this.toPayService.isPaymentCargo(this.typeList) &&
      ((this.utils.isDefined(cargo.validatedBankAccount) &&
        !cargo.validatedBankAccount) ||
        (!this.utils.isDefined(cargo.validatedBankAccount) &&
          cargo.shippingCost &&
          !cargo.shippingCost.paid))
    );
  }

  getNonExistentLoadsList(list: number[]) {
    return list.join(', ');
  }

  loadsSelected($event: Cargo[]) {
    this.loadsSelectedList = [];
    this.loadsSelectedList = $event;
  }

  payMassivePayments() {
    this.dialogRef.close(this.loadsSelectedList);
  }


}
