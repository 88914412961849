import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CompaniesService } from '../list-companies.service';
import { Company } from 'src/app/core/interfaces/company';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadDownloadMapService } from 'src/app/modules/cargo/manual-creation-cargo/components/upload-download-map/uploadDownloadMap.service';

@Component({
  selector: 'app-cost-center-item',
  templateUrl: './cost-center-item.component.html',
  styleUrls: ['./cost-center-item.component.scss']
})
export class CostCenterItemComponent implements OnInit {
  // Outputs
  @Output() refreshCompany: EventEmitter<Company> = new EventEmitter();
  // Inputs
  @Input() costCenter: CostCenter;
  @Input() activeTab: string;
  @Input() companyId: string;
  constructor(
    private dialog: MatDialog,
    private companiesService: CompaniesService,
    private snackBarService: SnackBarService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    public uploadDownloadMapService: UploadDownloadMapService
  ) { }

  ngOnInit() {
  }

  confirmDelete(costCenterId: string) {
    const dialogConfig = new MatDialogConfig();
    let title: string = '';
    title = `¿Estas seguro que deseas eliminar el centro de costo?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) this.deleteCostCenter(costCenterId);
    });
  }

  deleteCostCenter(costCenterId: string) {
    const costCenterObserver = {
      next: (company: Company) => {
        this.spinner.hide();
        if (company && this.utils.isDefined(company.costCenter)){
          this.refreshCompany.emit(company);
          this.snackBarService.openSnackBar(Fmt.string(ServiceMessages.STATE_COST_CENTER, 'eliminado'), undefined, 'success');
        } else this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      },
      error: (error) => {
        console.error(error);
        this.spinner.hide();
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
      }
    }
    this.spinner.show();
    this.companiesService.deleteCostCenter(this.companyId, costCenterId).subscribe(costCenterObserver);
  }

}
