import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable, combineLatest } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { environment } from '../../../../environments/environment';
import { CargoPayment } from 'src/app/core/interfaces/cargoPayment';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Fmt } from 'src/app/core/messages/fmt';
import { Utils } from 'src/app/core/resources/utils';

@Injectable({
    providedIn: 'root'
})
export class DriverCargoService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private endpointResources: Endpoints,
        private utils: Utils
    ) { }

    registerPayment(data: CargoPayment) {
        data['userId'] = this.authService.getUserSession().information.document;
        return this.http.post(
            environment.urlServerTeclogi + this.endpointResources.registerPaymentCargo,
            data
        );
    }

    changeAssignSecondDriver(document: string, cargoId: string) {
        let params = this.utils.getParamsFormatted(document);
        return this.http.put(`${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.assignChangeSecondDriver, cargoId)}`, null, { params });
    }

    deleteSecondDriver(cargoId: string) {
        return this.http.delete(`${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.assignChangeSecondDriver, cargoId)}`);
    }

    activateSecondDriver(cargoId: string) {
        return this.http.put(`${environment.urlServerTeclogi}${Fmt.string(this.endpointResources.activateSecondDriver, cargoId)}`, null);
    }

}
