import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VehiclesService } from '../list-vehicles.service';
import { InformationUser } from 'src/app/core/interfaces/informationUser';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AccountService } from 'src/app/modules/account/account.service';
import { User } from 'src/app/core/interfaces/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { Roles } from 'src/app/core/enums/roles.enum';
import { UserManager } from 'src/app/core/managers/user.manager';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
@Component({
  selector: 'app-set-administrator',
  templateUrl: './set-administrator.component.html',
  styleUrls: ['./set-administrator.component.scss'],
  providers: [UserManager]
})
export class SetAdministratorComponent implements OnInit {
  public title = "Edición de administrador";
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      licensePlate: string,
      hasAdministrator: boolean,
      administrator: User,
      title?: string
    },
    private vehiclesService: VehiclesService,
    private accountService: AccountService,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<SetAdministratorComponent>,
    private userManager: UserManager,
  ) { }

  /**
  * @description Executes the initForm method and updates the title and form's admin with getDetailAdmin
  */
  ngOnInit() {
    this.initForm();
    if (this.data && this.data.administrator && this.data.administrator.information && this.data.administrator.information.document)
      this.getDetailAdmin(this.data.administrator.information.document);
    if (this.data && this.data.title) this.title = this.data.title;
  }

  /**
  * @description Initializes the form
  */
  private initForm(): void {
    this.form = new FormGroup({
      hasAdministrator: new FormControl(false),
      administrator: new FormGroup(
        {
          simpleRegimen: new FormControl(false),
          exclusive: new FormControl(false),
          phone: new FormControl('', Validators.required),
          information: new FormGroup(
            {
              documentTypeId: new FormControl('', Validators.required),
              documentTypeName: new FormControl('', Validators.required),
              document: new FormControl('', Validators.required),
              name: new FormControl('', Validators.required),
            }
          ),
          municipalityCode: new FormControl('', Validators.required),
          city: new FormControl('', Validators.required),
          address: new FormControl('', Validators.required),
        }
      )
    });
    this.form.get('hasAdministrator').setValue(this.data.hasAdministrator);
  }

  /**
  * @param {string} document is the admin's document
  * @description Gets the admin's detail and passes it to setAdmin method sanitized with userManager.sanitizeUser
  */
  private getDetailAdmin(document: string): void {
    this.accountService.validateEntity(1, document).subscribe(
      (data: User) => {
        this.setAdmin(this.userManager.sanitizeUser(data, Roles.DRIVER));
      }, () => { }
    );
  }

  /**
  * @param {User} admin is the current admin
  * @description Updates the form's administrator with the admin param
  */
  setAdmin(admin: User) {
    this.form.get('administrator').patchValue(admin);
  }

  /**
  * @description Validates and updates the vehicle's admin
  */
  onSubmit() {
    if (this.form.get('hasAdministrator').value && !this.form.get('administrator.information.documentTypeId').value) this.snackBarService.openSnackBar(`Falta diligenciar el tipo de documento`, undefined, "error");
    else if (this.form.get('hasAdministrator').value && !this.form.get('administrator.information.document').value) this.snackBarService.openSnackBar(`Falta diligenciar el no. de identificación`, undefined, "error");
    else if (this.form.get('hasAdministrator').value && !this.form.get('administrator.information.name').value) this.snackBarService.openSnackBar(`Falta diligenciar el nombre`, undefined, "error");
    else if (this.form.get('hasAdministrator').value && !this.form.get('administrator.phone').value) this.snackBarService.openSnackBar(`Falta diligenciar el celular`, undefined, "error");
    else if (this.form.get('hasAdministrator').value && (!new RegExp('^(?=[0-9])').test(this.form.get('administrator.phone').value) || this.form.get('administrator.phone').value.length < 10))
      this.snackBarService.openSnackBar(`El celular no tiene un formato valido`, undefined, "error");
    else {
      this.spinner.show();
      let body = this.form.get('hasAdministrator').value ? this.form.get('administrator').value : {};
      const $ = this.vehiclesService.updateAdministrator(
        body,
        this.data.licensePlate
      ).subscribe(
        {
          error: (error) => {
            this.spinner.hide();
            if (error.error && error.error.message) this.snackBarService.openSnackBar(`${ServiceMessages.GENERAL_HTTP_ERROR}: ${error.error.message}`, undefined, "error");
            else this.snackBarService.openSnackBar(`Ocurrió un error en la petición`, undefined, "error");
          },
          next: (response) => {
            $.unsubscribe();
            this.spinner.hide();
            if (response && response.message) this.snackBarService.openSnackBar(`${ServiceMessages.GENERAL_HTTP_ERROR}: ${response.message}`, undefined, "error");
            else this.snackBarService.openSnackBar(`Se guardaron los cambios`);
            this.dialogRef.close(response);
          }
        }
      );
    }
  }


}
