import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { DataPaymenCargo } from 'src/app/core/interfaces/dataPaymenCargo';
import { RequestApprovalCargo } from 'src/app/core/interfaces/requestApprovalCargo';
import { CargoBillingComponent } from 'src/app/modules/cargo/cargo-billing/cargo-billing.component';
import { CargoUpdateComponent } from 'src/app/modules/cargo/cargo-update/cargo-update.component';
import { DialogComponent } from '../dialog/dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Filter } from 'src/app/core/models/filter';
import { FreightListService } from 'src/app/modules/cargo/cargo-list/cargo-list.service';
import { Titles } from 'src/app/core/resources/titles';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentTypePipe } from 'src/app/core/pipe/paymentType.pipe';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CurrencyPipe } from '@angular/common';
import { CargoDetailService } from 'src/app/modules/cargo/cargo-detail/cargo-detail.service';
import { SiigoManager } from 'src/app/core/managers/siigo.manager';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { CargoBillingXmlComponent } from 'src/app/modules/cargo/cargo-billing-xml/cargo-billing-xml.component';
import { ApproveCargoDialogComponent } from '../approve-cargo-dialog/approve-cargo-dialog.component';
import { CargoApprovalRequest } from 'src/app/core/interfaces/cargo-approval-request';
import { ApprovalResponse } from 'src/app/core/interfaces/approvalResponse';
import { ReceivableModificationsComponent } from 'src/app/modules/administration/receivable/receivable-modifications/receivable-modifications.component';
import { EMPTY, fromEvent, of } from 'rxjs';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { debounceTime, delay, switchMap, take } from 'rxjs/operators';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { ReceivableService } from 'src/app/modules/administration/receivable/receivable.service';
import { VehiclesService } from 'src/app/modules/administration/vehicles/list-vehicles.service';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { environment } from 'src/environments/environment';
import { CargoApproval } from 'src/app/core/interfaces/cargo-approval';
import { MassivePaymentsComponent } from 'src/app/modules/cargo/massive-payments/massive-payments.component';
import { AlertAdvancePaymentComponent } from 'src/app/modules/cargo/alert-advance-payment/alert-advance-payment.component';
import { ListOperationsService } from './list-operations.service';
import { MoveAdvanceDialogComponent } from 'src/app/modules/cargo/move-advance-dialog/move-advance-dialog.component';
import { Company } from 'src/app/core/interfaces/company';
import { User } from 'src/app/core/interfaces/user';
import { MassiveCargoOperationComponent } from 'src/app/modules/cargo/massive-cargo-operation/massive-cargo-operation.component';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';

@Component({
  selector: 'app-list-operations',
  templateUrl: './list-operations.component.html',
  styleUrls: ['./list-operations.component.scss'],
  providers: [SiigoManager]
})
export class ListOperationsComponent implements OnInit {
  @Input() filterBy: "cargo" | "company" | "vehicle" | "user" = "cargo";
  @Input() typeList: string;
  @Input() itemsSelecteds: Cargo[];
  @Input() options: Filter;
  @Input() filters: string;
  @Output() showDialog: EventEmitter<any> = new EventEmitter();
  @Output() refreshActualList: EventEmitter<any> = new EventEmitter();
  @Output() resetActualList: EventEmitter<any> = new EventEmitter();

  permission = Permission;
  isRootNit: boolean;
  cargoTabsLoadingRoutes = [
    "emptyContainers",
    "urbanRoutes",
    "lastMileRoutes",
    "nationalRoutes",
    "internationalRoutes"
  ];
  staticFilters = {};
  holder: any;

  isSomeErrorApproveCargos: boolean = false;
  companyToOperations = new FormControl('', Validators.required);
  validateToOperations: string = '';
  optionsCompany: OptionsAutocomplete = {
    title: 'Compañía',
    optionAll: false,
  };
  firstLoadsToPay: Cargo[] = [];
  firstItemsSelecteds: Cargo[] = [];

  totalTravelExpensesBalance = 0;
  constructor(
    private authService: AuthService,
    public snackBarService: SnackBarService,
    private permissionRole: PermissionRole,
    private freightListService: FreightListService,
    public titles: Titles,
    public utils: Utils,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private paymentTypePipe: PaymentTypePipe,
    private currencyPipe: CurrencyPipe,
    private cargoDetailService: CargoDetailService,
    private siigoManager: SiigoManager,
    public matDialog: MatDialog,
    public receivableService: ReceivableService,
    private vehiclesService: VehiclesService,
    private cargoManager: CargoManager,
    private listOperationsService: ListOperationsService
  ) { }

  async ngOnInit() {
    this.isRootNit = await this.authService.userIsFromRootNit();
    this.setOptions();
  }

  setAllFilters() {
    if (this.filters && this.filters !== "null") {
      this.filters.split("&").forEach((filter) => {
        if (filter && filter.split("=")[1] && filter.split("=")[1] !== "undefined")
          this.staticFilters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]);
      })
    } else {
      this.staticFilters = {};
    }
    this.ngOnInit();
  }

  setOptions() {
    if (this.staticFilters && this.staticFilters['nit']) {
      this.optionsCompany = {
        title: 'Compañía',
        optionAll: true,
        initialNit: this.staticFilters['nit'],
      }
    }
    if (!this.isRootNit) this.validateToOperations = 'disable';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.filters && changes.filters.currentValue) {
      this.setAllFilters();
    }
    if (changes && changes.itemsSelecteds && changes.itemsSelecteds.currentValue) {
      this.setCompanyByItemsSelected();

      /*this.freightListService
        .getTotalTravelExpensesBalance(changes.itemsSelecteds.currentValue)
        .then(v => this.totalTravelExpensesBalance = v);*/
    }
  }

  setCompanyByItemsSelected() {
    if (this.itemsSelecteds.length) {
      //Cargo
      if (this.itemsSelecteds[0].idCompany) {
        if (this.itemsSelecteds.every(item => item.idCompany && item.idCompany === this.itemsSelecteds[0].idCompany)) {
          this.optionsCompany = {
            title: 'Compañía',
            optionAll: true,
            initialNit: this.itemsSelecteds[0].idCompany,
          }
        } else {
          this.optionsCompany = {
            title: 'Compañía',
            optionAll: true,
            initialNit: "",
          }
        }

      }
    }
  }


  get isTabLoadingRoutes(): boolean {
    return this.typeList && this.cargoTabsLoadingRoutes.includes(this.typeList);
  }
  //Funciones adicionales
  //Enviar reportes
  get showSendReport() {
    return (this.typeList && this.typeList == 'loadingRoutes') || this.isTabLoadingRoutes;
  }
  public sendReport() {
    this.validateToOperations = 'touched';
    this.companyToOperations.markAsTouched();
    if (this.companyToOperations.value && this.companyToOperations.value.companyId) {
      this.showDialog.emit(this.companyToOperations.value);
    } else {
      this.snackBarService.openSnackBar("Debe seleccionar una compañia", undefined, "alert");
    }
  }


  get showOperations(): boolean {
    return this.canPayAdvance || this.canPayExtraAdvance || this.canConfirmCargoPayment
      || this.canConfirmEntryPayment || this.canApproveCargos || this.canModifyBill
      || this.canModifyVoucher || this.canGenerateJournal || this.canPayTravelExpenses;
  }

  get canPayTravelExpenses(): boolean {
    return this.typeList === 'paymentTravelExpenses';
  }

  get canPayAdvance(): boolean { //No requiere compañía
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.paymentAdvance
    ) && (this.options.showFilterPaymentAdvanceCargo ||
      this.options.showFilterPaymentBalanceCargo ||
      this.options.showFilterPaymentAdditionalCostsCargo) &&
      (this.typeList === "paymentAdvanceCargo" ||
        this.typeList === "paymentBalanceCargo" ||
        this.typeList === "paymentAdditionalCostsCargo");
  }
  get canPayExtraAdvance(): boolean { //No requiere compañía
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.paymentExtraAdvance
    ) && this.options &&
      this.options.showFilterPaymentExtraAdvanceCargo &&
      this.typeList === 'paymentExtraAdvanceCargo';
  }
  get canConfirmCargoPayment(): boolean {
    return this.options &&
      this.options.showFilterCharges && this.staticFilters['cashed'] === 'false';
  }
  get canConfirmEntryPayment(): boolean {
    return this.options &&
      this.options.showFilterCharges && this.staticFilters['cashed'] === 'true'
      && this.staticFilters['checked'] === 'false';
  }
  get canApproveCargos(): boolean {
    return this.options
      && this.options.showFilterAprovedCargo;
  }
  get canModifyBill(): boolean {
    return this.options &&
      this.options.showFilterCharges && this.staticFilters['cashed'] === 'true'
      && this.staticFilters['checked'] !== '';
  }
  get canModifyVoucher(): boolean {
    return this.options &&
      this.options.showFilterCharges && this.staticFilters['cashed'] === 'true'
      && this.staticFilters['checked'] === 'true';
  }

  get canGenerateJournal(): boolean {
    return this.typeList === 'withoutPayment' && this.options &&
      this.options.showFilterCreationDateInitial;
  }

  get typePaymentByTab(): string {
    let cargoTabsTypePayment = {
      paymentAdvanceCargo: "advance",
      paymentExtraAdvanceCargo: "extraAdvance",
      paymentAdditionalCostsCargo: "additionalCost",
      paymentBalanceCargo: "balance",
      paymentTravelExpenses: 'travelExpenses'
    };
    return this.typeList && cargoTabsTypePayment[this.typeList] ? cargoTabsTypePayment[this.typeList] : "";
  }

  get totalValueBalanceCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalValueBalanceCargos(this.itemsSelecteds)
      : 0;
  }

  get totalValueAdvanceCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalValueAdvanceCargos(this.itemsSelecteds)
      : 0;
  }

  async getTotalValueTravelExpensesCargos() {
    return this.filterBy === "cargo"
      ? await this.freightListService.getTotalValueTravelExpenses(this.itemsSelecteds)
      : 0;
  }

  get cargosWithTravelExpenses(): boolean {
    const cargos = this.itemsSelecteds.filter((cargo) => {
      return !this.utils.isEmpty(cargo.travelExpenses);
    });
    return !!cargos.length;
  }

  get totalValueRateCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalValueRateCargos(this.itemsSelecteds)
      : 0;
  }

  get totalAdditionals() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalAdditionals(this.itemsSelecteds)
      : 0;
  }
  get totalAdditionalCosts() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalAdditionalCosts(this.itemsSelecteds)
      : 0;
  }

  get totalAdditionalStandBy() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalAdditionalStandBy(this.itemsSelecteds)
      : 0;
  }
  get totalAdditionalCostsStandBy() {
    return this.filterBy === "cargo"
      ? this.freightListService.getTotalAdditionalCostsStandBy(this.itemsSelecteds)
      : 0;
  }

  get totalValueUploadCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.totalValueUploadCargos(this.itemsSelecteds)
      : 0;
  }
  get totalValueDownloadCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.totalValueDownloadCargos(this.itemsSelecteds)
      : 0;
  }
  get totalValueStandByCargos() {
    return this.filterBy === "cargo"
      ? this.freightListService.totalValueStandByCargos(this.itemsSelecteds)
      : 0;
  }

  async openModalPaymentTravelExpenses() {
    if (!this.itemsSelecteds.length)
      return;

    if (!this.authService.getCompany().bankAccounts || this.authService.getCompany().bankAccounts.length === 0) {
      this.snackBarService.openSnackBar("La empresa no tiene cuentas bancarias registradas en el sistema", undefined, "alert");
      return;
    }

    const listAccount = this.authService.getCompany().bankAccounts;

    if (!listAccount.length) {
      this.snackBarService.openSnackBar("La empresa no tiene cuentas bancarias registradas para este pago", undefined, "alert");
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.titles.titlesModalFileForPaymentsTravelExpenses, viewSelect: true,

      listLabel: "Seleccione un método de pago",
      list: this.utils.listToDefault(listAccount as [], "accountNumber", "Pagar con "),
      inputText: true, placeholder: "Descripción del pago", maxLength: 15,
      description: (this.itemsSelecteds.length > 1 ? 'Se seleccionaron las cargas: ' : 'Se seleccionó la carga: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', ')
    };

    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);

    const cargos: Array<string> = this.utils.getItemsCheked(this.itemsSelecteds, "id");
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        const data: DataPaymenCargo = {
          travelExpenses: this.itemsSelecteds.map((cargo: Cargo) => cargo && cargo.travelExpense ? cargo.travelExpense.id : null),
          cargos: cargos.filter((value, index) => cargos.indexOf(value) === index),
          bankAccount: {
            accountTypeCode: result.itemSelected.accountTypeCode,
            accountType: result.itemSelected.accountType,
            accountNumber: result.itemSelected.accountNumber,
          },
          description: result.inputText,
        };

        this.getFileForPaymentsCargo(data, 0, this.typePaymentByTab, false);
      } else {
        if (this.firstItemsSelecteds.length) this.itemsSelecteds = this.firstItemsSelecteds;
      }
    });
  }

  async openModalPaymentCargo(type?: string) {

    if (!this.itemsSelecteds.length || this.filterBy !== "cargo") return;
    if (!this.authService.getCompany().bankAccounts || this.authService.getCompany().bankAccounts.length === 0) {
      this.snackBarService.openSnackBar("La empresa no tiene cuentas bancarias registradas en el sistema", undefined, "alert");
      return;
    }

    if ((!type || (type && type !== 'availableLoadsToPayAdvance')) && sessionStorage.getItem('_activeTab') === 'paymentAdvanceCargo' && !this.permissionRole.hasPermission(this.permission.payments.module, this.permission.payments.unrestrictedAdvancePayments)) {
      let stateAdvance: boolean = await this.checkStateAdvanceLoads();
      if (stateAdvance) return;
    }

    const listAccount = this.authService.getCompany().bankAccounts.filter(account =>
      this.canPayExtraAdvance
        ? account.purpose === "All" || account.purpose.toLowerCase() === "extraAdvance"
        : account.purpose === "All" || account.purpose.toLowerCase() === this.typePaymentByTab
    );

    if (!listAccount.length) {
      this.snackBarService.openSnackBar("La empresa no tiene cuentas bancarias registradas para este pago", undefined, "alert");
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.titles.titlesModalFileForPaymentsCargo, viewSelect: true,
      listLabel: "Seleccione un método de pago",
      list: this.utils.listToDefault(listAccount as [], "accountNumber", "Pagar con "),
      inputText: true, placeholder: "Descripción del pago", maxLength: 15
    };

    if (
      this.permissionRole
        .hasPermission(
          this.permission.cargo.module,
          this.permission.cargo.payWithTravelExpenses
        ) && sessionStorage.getItem('_activeTab') === 'paymentAdvanceCargo'
    ) {
      const totalValueTravelExpensesCargos = await this.getTotalValueTravelExpensesCargos();
      dialogConfig.data.descriptionHTML = `
        ${(this.itemsSelecteds.length > 1 ? 'Se seleccionaron los servicios: ' : 'Se seleccionó el servicio: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', ')}<br/>
      `;

      dialogConfig.data.descriptionHTML += `Recuerde que el pago del anticipo <b>incluye los viáticos</b> registrados en cada servicio.<br/>`;

      if (this.totalValueAdvanceCargos > 0) {
        dialogConfig.data.descriptionHTML += `
          <div class="d-flex" style="font-weight: 700">
            <span class="w-100">Total de anticipos:</span>
            <span>${this.currencyPipe.transform(this.totalValueAdvanceCargos, 'COP', 'code')}</span>
          </div>
        `;
      }

      if (this.totalAdditionalCosts > 0) {
        dialogConfig.data.descriptionHTML += `
          <div class="d-flex" style="font-weight: 700">
            <span class="w-100">Total de servicios adicionales:</span>
            <span>${this.currencyPipe.transform(this.totalAdditionalCosts, 'COP', 'code')}</span>
          </div>
        `;
      }

      if (totalValueTravelExpensesCargos > 0) {
        dialogConfig.data.descriptionHTML += `
          <div class="d-flex" style="font-weight: 700">
          <span class="w-100">Total de viáticos:</span>
          <span>${this.currencyPipe.transform(totalValueTravelExpensesCargos, 'COP', 'code')}</span>
        </div>
        `;
        dialogConfig.data['checkbox'] = [{
          id: "payWithTravelExpenses",
          name: "Incluir pago de viáticos"
        }]
      }

      dialogConfig.data.descriptionHTML += `
        <div class="d-flex" style="font-weight: 700">
          <span class="w-100">Total a desembolsar:</span>
          <span>${this.currencyPipe.transform(this.totalValueAdvanceCargos + totalValueTravelExpensesCargos + this.totalAdditionalCosts, 'COP', 'code')}</span>
        </div><br/>
      `;
    } else {
      dialogConfig.data.description = (this.itemsSelecteds.length > 1 ? 'Se seleccionaron los servicios: ' : 'Se seleccionó el servicio: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', ');
    }

    if (this.canPayExtraAdvance) {
      dialogConfig.data['inputExtraAdvance'] = true;
      dialogConfig.data['placeholderAdvance'] = "Ingrese el valor del Sobre Anticipo"
    }
    const canPayAdditionalCosts: boolean = this.itemsSelecteds.some(cargo => cargo.additionalCosts && cargo.additionalCosts.some(cost =>
      (cost.type && (cost.type.name === "Servicio cargue" || cost.type.name === "Servicio descargue") && this.typePaymentByTab === "advance")
      || (cost.type && (cost.type.name === "Servicio cargue" || cost.type.name === "Servicio descargue" || cost.type.name === "Stand by") && this.typePaymentByTab === "balance")
    ));

    if (canPayAdditionalCosts) {
      const objCheck = {
        id: "payWithAdditionalCost",
        name: "Incluir pago de servicios adicionales"
      };
      if (dialogConfig.data['checkbox'] && dialogConfig.data['checkbox'].length) {
        dialogConfig.data['checkbox'].push(objCheck);
      } else {
        dialogConfig.data['checkbox'] = [objCheck];
      }
    }

    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if ((!result || !result.state) && type && type === 'massive') this.itemsSelecteds = [];
      if (result && result.state) {
        const data: DataPaymenCargo = {
          cargos: this.utils.getItemsCheked(this.itemsSelecteds, "id"),
          bankAccount: {
            accountTypeCode: result.itemSelected.accountTypeCode,
            accountType: result.itemSelected.accountType,
            accountNumber: result.itemSelected.accountNumber,
          },
          description: result.inputText,
        };
        let payWithAdditionalCost = false;
        let payWithTravelExpenses = false;
        if (result.checkbox && result.checkbox.length) {
          const iPayWithAdditionalCost = result.checkbox.findIndex(
            (obj) => obj.id === 'payWithAdditionalCost'
          );
          payWithAdditionalCost = iPayWithAdditionalCost >= 0;
          if (sessionStorage.getItem('_activeTab') === 'paymentAdvanceCargo') {
            const iPayWithTravelExpenses = result.checkbox.findIndex(
              (obj) => obj.id === 'payWithTravelExpenses'
            );
            payWithTravelExpenses = iPayWithTravelExpenses >= 0;
          }
        }
        if (sessionStorage.getItem('_activeTab') === 'paymentBalanceCargo') {
          payWithTravelExpenses = this.cargosWithTravelExpenses;
        }
        this.getFileForPaymentsCargo(
          data,
          result.inputExtraAdvance,
          this.typePaymentByTab,
          payWithAdditionalCost,
          payWithTravelExpenses
        );
      } else {
        if (this.firstItemsSelecteds.length) this.itemsSelecteds = this.firstItemsSelecteds;
      }
    });
  }
  /**
  * This method check the state of the loads in route to advance payment.
  */
  async checkStateAdvanceLoads() {
    this.firstLoadsToPay = [];
    const matchLoadsByVehicle: { [licensePlate: string]: Cargo[] } = {};
    let licensePlates = [...new Set(this.itemsSelecteds
      .map(cargo => cargo && cargo.licensePlate)
    )];
    this.spinner.show();
    for (const licensePlate of licensePlates) {
      if (licensePlate) {
        let matchLoads: Cargo[] = await this.listOperationsService.getLoadsToPayByParameters('licensePlate', licensePlate);
        if (matchLoads && matchLoads.length) {
          matchLoadsByVehicle[licensePlate] = matchLoads;
          this.firstLoadsToPay.push(matchLoads[0]);
          matchLoads = [];
        }
      }
    }
    this.spinner.hide();
    // Si hay servicios en ruta con anticipo de dicha placa y si hay alguna de las placas con mas de un servicio en ruta.
    // Ademas si todos los servicios seleccionados son validas deberia dejar seguir el flujo.
    let hasDifferentCargo: boolean = false;
    if (this.firstLoadsToPay.length) {
      hasDifferentCargo = this.itemsSelecteds.some(cargo =>
        !this.firstLoadsToPay.map(load => load.consecutive).includes(cargo.consecutive)
      );
    }
    if (Object.keys(matchLoadsByVehicle).length > 0 && Object.values(matchLoadsByVehicle).some(cargos => cargos.length > 1) && hasDifferentCargo) {
      this.openAlertAdvancePayment(matchLoadsByVehicle);
      return true;
    }
    return false;
  }

  /**
  * This method open a dialog that shows an alert related with the advance payment of the loads.
  */
  openAlertAdvancePayment(matchLoadsByVehicle: { [licensePlate: string]: Cargo[] }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      matchLoadsByVehicle
    };
    dialogConfig.width = ModalEnum.FULL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.FORMS_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(
      AlertAdvancePaymentComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.loads && result.loads.length) {
          this.firstItemsSelecteds = this.utils.clone(this.itemsSelecteds);
          this.itemsSelecteds = result.loads.map((load: Cargo) => {
            load.checked = true;
            return load;
          });
          this.openModalPaymentCargo('availableLoadsToPayAdvance');
        }
        else if (result.state && result.state === 'loadDeletedOrFinished') this.openModalPaymentCargo();
      }
    });
  }

  getFileForPaymentsCargo(dataPayment?: any, extraAmount?, type?, payWithAdditionalCost?: boolean, payWithTravelExpenses?: boolean) {
    let paymentType = type === "additionalCost"
      ? this.staticFilters["additionalCost"] === "Servicio cargue"
        ? "additionalServicesLoad"
        : this.staticFilters["additionalCost"] === "Servicio descargue"
          ? "additionalServicesDownload"
          : this.staticFilters["additionalCost"] === "Stand by"
            ? "additionalServicesLoadStandBy"
            : type
      : type;
    this.spinner.show();
    this.freightListService.getFileForPaymentsCargo(dataPayment, paymentType, extraAmount, payWithAdditionalCost, payWithTravelExpenses)
      .subscribe(
        (success: any) => {
          this.resetActualList.emit();
          this.snackBarService.openSnackBar(
            "Se descargará el archivo en breve"
          );
          // tslint:disable-next-line: max-line-length
          this.utils.downloadFile(
            success.body,
            "Pago-" +
            this.paymentTypePipe.transform(this.typePaymentByTab) +
            "-Servicios-" +
            DateManager.dateToString(new Date(), 'YYYY-MM-DD HH-mm')
          );
          of(null).pipe(delay(1500)).subscribe(() => {
            this.refreshActualList.emit(true);
            this.spinner.hide();
          });
        },
        (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar(
            "Ocurrió un error al enviar la informacion",
            undefined,
            "error"
          );
        }
      );
  }
  //Confirmar cobro e ingreso de servicios
  openModalCashedCargo(typeBilling: string, cargos?: Cargo[]) {
    const items = cargos && cargos.length ? cargos : this.itemsSelecteds;
    if (!items.length || this.filterBy !== "cargo") return;
    this.validateToOperations = 'touched';
    if (!this.companyToOperations.value || !this.companyToOperations.value.companyId)
      return this.snackBarService.openSnackBar("Debe seleccionar una compañia", undefined, "alert");
    if (!items.every(cargo => cargo.idCompany === this.companyToOperations.value.companyId))
      return this.snackBarService.openSnackBar("Todos los servicios deben pertenecer a la compañía seleccionada", undefined, "alert");

    let idsWithBill = [];
    let idsWithoutBill = [];
    items.forEach((element) => {
      if (!element.shippingCost.billId && !element.expeditionDateBill) {
        idsWithBill.push(element.consecutive);
      } else if (!element.shippingCost.billId && element.expeditionDateBill) {
        idsWithoutBill.push(element.consecutive);
      }
    });
    if (this.hasIntegration && this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.billingWithFlexibilityChoice
    ))
      this.selectTypeBilling(typeBilling, cargos);
    else if (idsWithBill.length && !idsWithoutBill.length && this.hasIntegration) {
      this.openDialogBillWithIntegration(typeBilling, cargos);
    } else if (!idsWithBill.length && idsWithoutBill.length) {
      this.openDialogEditBill(cargos);
    } else if (!idsWithBill.length && !idsWithoutBill.length) {
      this.snackBarService.openSnackBar('Los servicios ya fueron facturados', undefined, 'alert');
    } else {
      this.openErrorBills(idsWithBill, idsWithoutBill);
    }
  }
  checkSameCompanyId(cargo: Cargo): boolean {
    return this.companyToOperations.value && this.companyToOperations.value.companyId
      && cargo.idCompany === this.companyToOperations.value.companyId;
  }
  async openModalCashedCargoMassive(typeBilling: 'bill' | 'income') {
    this.validateToOperations = 'touched';
    if (!this.companyToOperations.value || !this.companyToOperations.value.companyId)
      return this.snackBarService.openSnackBar("Debe seleccionar una compañia", undefined, "alert");

    let filters = this.parseQueryString(sessionStorage.getItem('_filterListStatic'));
    if (filters['approval'] && filters['approval'] === 'all') delete filters['approval'];
    let filtersToSearch = '';
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        if (value.length)
          filtersToSearch += "&" + key + "=" + encodeURIComponent(JSON.stringify(value));
      } else
        filtersToSearch += "&" + key + "=" + encodeURIComponent(value.toString());
    });

    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: `Confirmar ${typeBilling === 'bill' ? 'cobro' : 'ingreso'} de servicios`,
      subtitle: `Ingrese los consecutivos de los servicios que desea confirmar 
      su ${typeBilling === 'bill' ? 'cobro' : 'ingreso'} separados por una coma.`,
      label: "Servicios a confirmar",
      confirmButton: "Continuar",
      method: this.freightListService.getListCargo.bind(this.freightListService),
      validationToCheckValid: this.checkSameCompanyId.bind(this),
      filters: filtersToSearch
    }
    let result: {
      availableList: Cargo[],
      unavaliableList: Cargo[],
      nonExistentList: number[],
      consecutiveList: string[]
    } = await this.dialog.open(MassiveCargoOperationComponent, dialogConfig).afterClosed().toPromise();
    if (!result) return;
    if (result.availableList.length && result.availableList.length !== result.consecutiveList.length) {
      let messageList: string[] = [
        `Consecutivos ingresados: ${result.consecutiveList.join(', ')}`,
      ];
      result.availableList.length && messageList.push(`Servicios habilitados: ${result.availableList.map(cargo => cargo.consecutive).join(', ')}`);
      result.unavaliableList.length && messageList.push(`Servicios inhabilitados: ${result.unavaliableList.map(cargo => cargo.consecutive).join(', ')}`);
      result.nonExistentList.length && messageList.push(`Servicios no encontrados/registrados: ${result.nonExistentList.join(', ')}`);
      dialogConfig.data = {
        title: result.availableList.length ? '¿Deseas continuar?' : 'No es posible continuar',
        messageList,
      };
      if (result.availableList.length) {
        dialogConfig.data['showYesBtn'] = true;
        dialogConfig.data['showNoBtn'] = true;
        dialogConfig.data['hideBtnCancel'] = true;
      }
      else {
        dialogConfig.data.descriptionHTML = `
          <div class="alert alert-warning m-3 d-flex align-items-center justify-content-center" role="alert">
            <i class="fas fa-exclamation-triangle mr-2"></i><span>No hay ningún servicio habilitado</span>
          </div>`;
        dialogConfig.data['hideBtnCancel'] = false;
        dialogConfig.data['hideBtnConfirm'] = true;
      }
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const confirmContinue = await this.dialog.open(DialogComponent, dialogConfig).afterClosed().toPromise();
      if (!confirmContinue || !confirmContinue.state || !result.availableList.length) return;
    }

    this.openModalCashedCargo(typeBilling, result.availableList);
  }

  selectTypeBilling(typeBilling: string, cargos?: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Por favor selecciona que tipo de facturacion deseas usar',
      labelButton1: 'Facturación manual',
      labelButton2: 'Facturación por integración',
      hideBtnCancel: true,
      hideBtnConfirm: true,
    };
    dialogConfig.height = 'auto';
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.refuse) {
        result.refuse === 'Facturación por integración'
          ? this.openDialogBillWithIntegration(typeBilling, cargos)
          : this.openDialogEditBill(cargos);
      }
    });
  }

  public openDialogBillWithIntegration(typeBilling: string, cargos?: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      cargos: cargos && cargos.length ? cargos : this.utils.getItemsCheked(this.itemsSelecteds),
      nitCompany: this.companyToOperations.value.companyId,
      typeBilling,
    };
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    const dialogRef = this.dialog.open(CargoBillingComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.authService.getAllCompanies().toPromise().finally();
        this.refreshActualList.emit(true);
      }
    });
  }
  public openDialogEditBill(cargos?: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    const title = `Atar número de factura manualmente`;
    dialogConfig.data = {
      title: title,
      cargos: cargos && cargos.length ? cargos : this.utils.getItemsCheked(this.itemsSelecteds),
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(CargoUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.refreshActualList.emit(true);
      }
    });
  }
  public openErrorBills(idsWithBill, idsWithoutBill) {
    const dialogConfig = new MatDialogConfig();
    const title = `MENSAJE DE ERROR`;
    dialogConfig.data = {
      title: title,
      idsWithBill: idsWithBill,
      idsWithoutBill: idsWithoutBill,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    this.dialog.open(CargoUpdateComponent, dialogConfig);
  }
  public openDialogBillXML() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    this.dialog.open(CargoBillingXmlComponent, dialogConfig).afterClosed().subscribe(
      (result) => {
        if (result && result.state) this.refreshActualList.emit(true);
      }
    );
  }



  //Aprobar servicios
  async approveOrDisapproveCargo(approve: boolean) {
    if (!this.itemsSelecteds.length || this.filterBy !== "cargo") return;
    if (!approve) {
      this.openModalDisapproveCargo();
      return;
    }
    this.isSomeErrorApproveCargos = false;
    if (this.isTeclogiUser) {
      this.simpleApproveCargo(this.itemsSelecteds);
      return;
    }
    let manual_consignment_dates;
    if (this.itemsSelecteds.some((cargo: Cargo) => cargo.ministry && this.cargoManager.isTripTypeNational(cargo))) {
      manual_consignment_dates = await this.askManualOrAutomatedDates();
      if (!this.utils.isDefined(manual_consignment_dates)) return;
    }
    this.openModalApproveCargo(manual_consignment_dates);
  }

  private simpleApproveCargo(cargos: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.itemsSelecteds.length > 1
        ? `${this.titles.titlesModalApproveCargos} ${this.currencyPipe.transform(this.totalValueBalanceCargos, "COP", "code")} ?`
        : `${this.titles.titlesModalApproveCargo} ${this.currencyPipe.transform(this.totalValueBalanceCargos, "COP", "code")}?`,
      descriptionHTML: (this.itemsSelecteds.length > 1 ? 'Se seleccionaron los servicios: ' : 'Se seleccionó el servicio: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', '),
      showNoBtn: true,
      showYesBtn: true,
      hideBtnCancel: true,
      date: true,
      untilDate: new Date()
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;

    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state) {
        const body: CargoApprovalRequest = {
          userId: this.authService.getUserSession().information.document,
          userName: this.authService.getUserSession().information.name,
          cargos: cargos.map(cargo => {
            return this.cargoManager.createCargoApproval(cargo)
          }),
          receiptDateDocuments: result.date
        };
        this.approvedCargoRNDC(true, body);
      }
    });
  }

  private askManualOrAutomatedDates() {
    return new Promise<boolean>((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        title: this.isSomeErrorApproveCargos
          ? `Ocurrió un error al aprobar`
          : `¿Desea ingresar manualmente las fechas de los cumplidos?`,
        descriptionHTML: this.isSomeErrorApproveCargos
          ? `<b class="text-bold">¿Desea ingresar manualmente las fechas de los cumplidos?</b><br>
              Si selecciona no, los valores serán calculados automáticamente.`
          : `Si selecciona no, los valores serán calculados automáticamente.`,
        showNoBtn: true,
        showYesBtn: true,
        hideBtnCancel: true,
        untilDate: new Date()
      };
      if (this.isSomeErrorApproveCargos) dialogConfig.data['iconError'] = true;
      dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
      dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
      dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
      dialogConfig.autoFocus = false;
      const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result && result.state)
      }, reject
      );
    });
  }

  private openModalApproveCargo(manual_consignment_dates: boolean = false, cargos?: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    let currentCargos = cargos && cargos.length ? cargos : this.itemsSelecteds;
    let initialTitle = currentCargos.length > 1 ? this.titles.titlesModalApproveCargos : this.titles.titlesModalApproveCargo;
    dialogConfig.data = {
      title: `${initialTitle} ${this.currencyPipe.transform(this.totalValueBalanceCargos, "COP", "code")} ?`,
      description: (currentCargos.length > 1 ? 'Se seleccionaron los servicios: ' : 'Se seleccionó el servicio: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', '),
      cargos: currentCargos,
      manual_consignment_dates,
      date: true,
      untilNow: new Date()
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;

    this.dialog.open(ApproveCargoDialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (!!result) {
        const body: CargoApprovalRequest = {
          userId: this.authService.getUserSession().information.document,
          userName: this.authService.getUserSession().information.name,
          cargos: result.cargos,
          receiptDateDocuments: result.receiptDateDocuments
        };
        this.approvedCargoRNDC(true, body);
      }
    });
  }

  private openModalDisapproveCargo() {
    const dialogConfig = new MatDialogConfig();
    let initialTitle = this.itemsSelecteds.length > 1 ? this.titles.titleRefuseCargos : this.titles.titleRefuseCargo;
    dialogConfig.data = {
      title: initialTitle,
      description: (this.itemsSelecteds.length > 1 ? 'Se seleccionaron los servicios: ' : 'Se seleccionó el servicio: ') +
        this.utils.getItemsCheked(this.itemsSelecteds, "consecutive").join(', '),
      cargos: this.itemsSelecteds,
      textArea: true
    };
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;

    this.dialog.open(DialogComponent, dialogConfig).afterClosed().subscribe((result) => {
      if (result && result.state) {
        const body: CargoApprovalRequest = {
          userId: this.authService.getUserSession().information.document,
          userName: this.authService.getUserSession().information.name,
          cargos: this.utils.getItemsCheked(this.itemsSelecteds, 'id').map((id) => { return { cargoId: id } }),
          reason: result.message
        };
        this.approvedCargoRNDC(false, body);
      }
    });
  }

  private approvedCargoRNDC(state: boolean, cargoApprovalRequest: CargoApprovalRequest) {
    this.spinner.show();
    this.freightListService.approvedCargo(cargoApprovalRequest, state).subscribe({
      next: (result: Cargo[]) => {
        this.spinner.hide();
        this.checkResponseApproval(result, state);
      },
      error: async (error) => {
        this.spinner.hide();
        if (this.isTeclogiUser && !this.isSomeErrorApproveCargos && state) {
          this.isSomeErrorApproveCargos = true;
          let manual_consignment_dates;
          if (this.itemsSelecteds.some((cargo: Cargo) => cargo.ministry && this.cargoManager.isTripTypeNational(cargo))) {
            manual_consignment_dates = await this.askManualOrAutomatedDates();
            if (!this.utils.isDefined(manual_consignment_dates)) return;
          }
          this.openModalApproveCargo(manual_consignment_dates);
        }
        else if (error.error)
          this.checkResponseApproval(error.error, state);
        else
          this.snackBarService.openSnackBar(error, "Aceptar", "error");

      }
    });
  }

  async checkResponseApproval(cargos: Cargo[], state: boolean) {
    let errorList = [];
    let errorCargos: Cargo[] = [];
    let messageSuccess;

    if (cargos) {
      cargos.forEach((cargo) => {
        if (cargo.cargoFeature && cargo.cargoFeature.uploadDownload && cargo.cargoFeature.uploadDownload.destination && cargo.cargoFeature.uploadDownload.destination.length) {
          cargo.cargoFeature.uploadDownload.destination.forEach((destination) => {
            if (destination.addresses && destination.addresses.length) {
              destination.addresses.forEach((address) => {
                if (address.ministryError && address.ministryError.error) {
                  if (!errorCargos.includes(cargo)) errorCargos.push(cargo);
                  errorList.push(`Servicio ${cargo.consecutive}${address.consignments && address.consignments[0] ? `- ${address.consignments[0]}` : ''}: ${address.ministryError.error}`);
                } else {
                  if (messageSuccess) {
                    messageSuccess += `, Servicio ${cargo.consecutive}${address.consignments && address.consignments[0] ? `- ${address.consignments[0]}` : ''}`;
                  } else {
                    messageSuccess = `Servicio ${cargo.consecutive}${address.consignments && address.consignments[0] ? `- ${address.consignments[0]}` : ''}`;
                  }
                }
              });
            }
          });
        }
      });
    }

    if (messageSuccess) {
      messageSuccess = `${state ? 'Se aprobaron' : 'Se rechazaron'} correctamente los servicios: ${messageSuccess}`
    }
    if (this.isTeclogiUser && !this.isSomeErrorApproveCargos && state && errorCargos.length) {
      this.isSomeErrorApproveCargos = true;
      let manual_consignment_dates;
      if (errorCargos.some((cargo: Cargo) => cargo.ministry && this.cargoManager.isTripTypeNational(cargo))) {
        manual_consignment_dates = await this.askManualOrAutomatedDates();
        if (!this.utils.isDefined(manual_consignment_dates)) return;
      }
      this.openModalApproveCargo(manual_consignment_dates, errorCargos);
    }
    else this.showResponseApproval({ errorList, messageSuccess, cargos }, state);
  }

  showResponseApproval(content: { errorList?, messageSuccess?, cargos?}, state: boolean) {
    const isError: boolean = content && content.errorList && content.errorList.length;
    const isSuccess: boolean = content && content.messageSuccess && content.messageSuccess.length;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: isError ? "Resultado de la operación" : "Revisión exitosa",
      hideBtnCancel: true,
    };
    if (isError) dialogConfig.data.iconError = true;
    else dialogConfig.data.icon = true;

    if (isError) {
      dialogConfig.data.descriptionHTML = `Error al intentar ${state ? 'aprobar' : 'rechazar'} las siguientes Remesas:`;
      dialogConfig.data.messageList = content.errorList;
    }
    if (isSuccess) dialogConfig.data.description = content.messageSuccess;

    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.refreshActualList.emit(true);
      if (content && content.cargos && content.cargos.some((cargo: Cargo) => 'message' in cargo)) {
        const cargoToVerifyReceivable = content.cargos.filter((cargo: Cargo) => (cargo.approval === 'Rejected' || cargo.approval === 'Approved') && cargo.message);
        if (cargoToVerifyReceivable.length)
          this.verifyReceivableModifications(cargoToVerifyReceivable);
      }
    });
  }

  verifyReceivableModifications(cargos: Cargo[]) {
    const dialogConfig = new MatDialogConfig();
    let title = '';
    if (cargos && cargos[0] && cargos[0].message) {
      title = `${cargos[0].message}. ¿Desea hacer el abono a la cartera?`;
    }
    dialogConfig.data = {
      title: title,
      showYesBtn: true,
      showNoBtn: true
    };
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.receivableList(cargos[0].licensePlate);
      }
    });
  }

  receivableList(licensePlate: string) {
    let observableVehicleInfo;
    const $observerVehicleInfo = {
      next: (vehicle: Vehicle) => {
        if (vehicle && vehicle.bankAccountBalance && vehicle.bankAccountBalance.document) {
          this.holder = {
            name: vehicle.bankAccountBalance.name,
            document: vehicle.bankAccountBalance.document,
            documentTypeName: vehicle.bankAccountBalance.documentTypeName
          };
          return this.receivableService.getReceivableList(null, null, vehicle.bankAccountBalance.document, null, null, 'list');
        } else {
          this.snackBarService.openSnackBar('No existe un usuario asociado al balance de la cuenta', undefined, "error")
          return of(null);
        }
      },
      error: () => {
        this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        return EMPTY;
      },
      finally: () => {
        this.spinner.hide();
        observableVehicleInfo.unsubscribe();
      }
    };
    this.spinner.show();
    const observableVehicle = this.vehiclesService.getVehicle(licensePlate).pipe(switchMap(vehicle => observableVehicleInfo = $observerVehicleInfo.next(vehicle)))
      .subscribe({
        next: (listModifications) => {
          this.spinner.hide();
          if (listModifications) {
            this.openModificationsList(listModifications);
          } else {
            this.snackBarService.openSnackBar("No hay modificaciones para mostrar", undefined, "alert")
          }
        },
        error: (error) => {
          this.spinner.hide();
          this.snackBarService.openSnackBar("Hubo un error trayendo las modificaciones. Vuelva a intentarlo", undefined, "alert")
        },
        complete: () => {
          this.spinner.hide();
          observableVehicle.unsubscribe();
        }
      })
  }

  openModificationsList(listReceivables) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      listReceivables: listReceivables,
      holder: this.holder
    }
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.disableClose = true;
    const dialogRef = this.matDialog.open(ReceivableModificationsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'undefined') {
        this.snackBarService.openSnackBar('Por favor rechace o apruebe la modificacion a la cartera para poder continuar', undefined, 'alert')
      }
    })
  }

  approvedCargo(state, result) {
    const data: RequestApprovalCargo = {
      cargos: this.utils.getItemsCheked(this.itemsSelecteds, "id"),
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name
    };
    if (state) {
      data.date = result.date;
    } else {
      data.reason = result.message;
    }
    this.spinner.show();
    /*this.freightListService.approvedCargo(data, state).subscribe(
      (success) => {
        this.spinner.hide();
        if (success) {
          this.refreshActualList.emit(true);
          this.snackBarService.openSnackBar(
            "Se han " +
            (state ? "aprobado" : "rechazado") +
            " los servicios correctamente"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al " +
            (state ? "aprobar" : "rechazar") +
            " los servicios",
            undefined,
            "error"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          "Ocurrió un error al aprobar los servicios",
          undefined,
          "error"
        );
      }
    );*/
  }

  //Editar facturas o comprobantes
  public openDialogEdit(type, data) {
    if (!this.itemsSelecteds.length || this.filterBy !== "cargo") return;
    this.validateToOperations = 'touched';
    if (!this.companyToOperations.value || !this.companyToOperations.value.companyId) {
      this.snackBarService.openSnackBar("Debe seleccionar una compañia", undefined, "alert");
      return;
    } else if (!this.itemsSelecteds.every(cargo => cargo.idCompany === this.companyToOperations.value.companyId)) {
      this.snackBarService.openSnackBar("Todas los servicios deben pertenecer a la compañía seleccionada", undefined, "alert");
      return;
    }
    const dialogConfig = new MatDialogConfig();
    const title = `Modificar ${data}`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(CargoUpdateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (this.utils.isDefined(result)) {
        let ids = this.itemsSelecteds.map((cargo) => {
          return cargo.id;
        });
        this.updateInvoice(ids, type, result);
      }
    });
  }
  public updateInvoice(idCargos, type, value) {
    this.spinner.show();
    this.cargoDetailService.editInvoice(idCargos, type, value).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.message) {
          this.snackBarService.openSnackBar(data.message);
          this.refreshActualList.emit(true);
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          `Ocurrió un error al desasociar`,
          undefined,
          "error"
        );
      }
    );
  }

  //Desasociar facturas o comprobantes
  public confirmDisassociate(type, data) {
    if (!this.itemsSelecteds.length || this.filterBy !== "cargo") return;
    this.validateToOperations = 'touched';
    if (!this.isRootNit) this.companyToOperations.setValue({ nit: this.authService.getUserSession().clientCompanyId });
    if (!this.companyToOperations.value || !this.companyToOperations.value.companyId) {
      this.snackBarService.openSnackBar("Debe seleccionar una compañia", undefined, "alert");
      return;
    } else if (!this.itemsSelecteds.every(cargo => cargo.idCompany === this.companyToOperations.value.companyId)) {
      this.snackBarService.openSnackBar("Todos los servicios deben pertenecer a la compañía seleccionada", undefined, "alert");
      return;
    }
    const dialogConfig = new MatDialogConfig();
    const title = `¿Estás seguro que deseas desasociar todos los ${data} seleccionados?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        let ids = this.itemsSelecteds.map((cargo) => {
          return cargo.id;
        });
        this.disassociate(ids, type);
      }
    });
  }
  public disassociate(idCargos, type) {
    this.spinner.show();
    this.cargoDetailService.disassociate(idCargos, type).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data && data.message) {
          this.snackBarService.openSnackBar(data.message);
          this.refreshActualList.emit(true);
        }
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(
          `Ocurrió un error al desasociar`,
          undefined,
          "error"
        );
      }
    );
  }

  public confirmGenerateJournal() {
    if (!this.itemsSelecteds.length || this.filterBy !== "cargo") return;
    const dialogConfig = new MatDialogConfig();
    const title = `¿Estás seguro que deseas generar los Comprobantes Contables para los servicios seleccionados?`;
    dialogConfig.data = {
      title: title,
    };
    dialogConfig.height = "auto";
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.generateJournal();
      }
    });
  }

  public generateJournal(cargosError?: Cargo[]): void {
    const date = DateManager.getLastMonthDay();
    this.spinner.show();
    const list = cargosError || this.itemsSelecteds;
    const listConsecutives = this.utils.getItemsCheked(list, "consecutive");
    this.siigoManager.generateJournal(date, listConsecutives)
      .then((response) => {
        this.checkResponseGenerateJournal(response);
      })
      .catch((error) => {
        this.checkResponseGenerateJournal(error);
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  private checkResponseGenerateJournal(data) {
    this.siigoManager.showResponseGenerateJournal(data).then((dataTryAgain: Cargo[]) => {
      if (dataTryAgain) {
        this.generateJournal(dataTryAgain);
        this.refreshActualList.emit(true);
      }
    });
  }

  openModalMassivePaymentCargo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(MassivePaymentsComponent);
    dialogRef.afterClosed().subscribe((cargos: Cargo[]) => {
      if (cargos && cargos.length) {
        this.itemsSelecteds = [];
        this.itemsSelecteds = cargos;
        this.openModalPaymentCargo('massive');
      }
    });
  }

  openModalMoveAdvance() {
    const config = new MatDialogConfig();
    config.data = { cargos: this.itemsSelecteds };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.SMALL_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;

    this.matDialog.open(MoveAdvanceDialogComponent, config);
  }

  parseQueryString(queryString: string): { [key: string]: string | boolean | string[] } {
    const params = new URLSearchParams(queryString);
    const result: { [key: string]: string | boolean | string[] } = {};

    params.forEach((value, key) => {
      // Intenta convertir a boolean si es "true" o "false"
      if (value === 'true' || value === 'false') {
        result[key] = value === 'true';
      } else {
        // Divide por comas si es una lista
        const values = value.split(',');
        result[key] = values.length > 1 ? values : value;
      }
    });

    return result;
  }

  get hasIntegration(): boolean {
    const integrationChannels = [IntegrationEnum.SIIGO.toLowerCase(), IntegrationEnum.WORLD_OFFICE.toLowerCase()];
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel && integrationChannels.includes(integration.integrationChannel.toLowerCase()) && integration.state);
  }

  get showBillingXML(): boolean {
    return this.typeList === "charges" &&
      this.permissionRole.hasPermission(
        this.permission.payments.module,
        this.permission.payments.billingXML
      );
  }

  get canMoveAdvance(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.moveAdvance)
      && this.typeList === 'paymentAdvanceCargo';
  }

  private get isTeclogiUser(): boolean {
    return this.authService.getCompany().companyId === environment.rootNit;
  }
}
