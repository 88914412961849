<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
        <ng-container formGroupName="cargoModel"
            *ngIf="!hiddenFields?.tripType || !hiddenFields?.regimeType || !hiddenFields?.tripTypeDate">
            <div class="col-md-4" formGroupName="tripType" *ngIf="!hiddenFields?.tripType">
                <mat-form-field appearance="standard">
                    <mat-label>Tipo de viaje<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="name" placeholder="Tipo de viaje"
                        (selectionChange)="changeTripType($event)">
                        <mat-option *ngFor="let type of types" [value]="type.name">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="tripType"
                *ngIf="!hiddenFields?.regimeType && (form.get('cargoModel.tripType.name').value==='Exportación' || form.get('cargoModel.tripType.name').value==='Importación')">
                <mat-form-field appearance="standard">
                    <mat-label>Tipo de régimen<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="regimeType" placeholder="Tipo de régimen"
                        (selectionChange)="changeRegimeType($event)">
                        <mat-option *ngFor="let type of filteredRegimeTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="tripType"
                *ngIf="!hiddenFields?.tripTypeDate && (form.get('cargoModel.tripType.regimeType').value==='OTM'||form.get('cargoModel.tripType.regimeType').value==='DTA'||form.get('cargoModel.tripType.regimeType').value==='DUTA')">
                <mat-form-field appearance="standard">
                    <mat-label>Fecha de descargue del buque<span class="asterisk">*</span></mat-label>
                    <input matInput [matDatepicker]="picker" (dateChange)="onDateSelected($event)"
                        [formControl]="dateFieldDTA" placeholder="Seleccione una fecha">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container formGroupName="cargoModel"
            *ngIf="!hiddenFields?.cargoType || !hiddenFields?.containerMeasure || !hiddenFields?.serviceType">
            <div class="col-md-4" formGroupName="cargoType" *ngIf="!hiddenFields?.cargoType && (form.get('cargoModel.tripType.name').value==='Exportación'
            || form.get('cargoModel.tripType.name').value==='Importación'
            || form.get('cargoModel.tripType.name').value==='Nacional'
            || form.get('cargoModel.tripType.name').value==='Internacional'
            || form.get('cargoModel.tripType.name').value==='Urbana')">
                <mat-form-field appearance="standard">
                    <mat-label>Tipo de carga<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="name" placeholder="Tipo de carga"
                        (selectionChange)="changeCargoType($event)">
                        <mat-option *ngFor="let type of cargoTypes" [value]="type.name">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4" formGroupName="cargoType"
                *ngIf="!hiddenFields?.containerMeasure && form.get('cargoModel.cargoType.name').value==='Contenedor'">
                <div formGroupName="containerMeasure">
                    <mat-form-field appearance="standard">
                        <mat-label>Medidas del contenedor<span class="asterisk">*</span></mat-label>
                        <mat-select formControlName="size" placeholder="Medidas del contenedor"
                            (selectionChange)="changeContainerMeasure($event)">
                            <mat-option *ngFor="let type of containerTypes" [value]="type.size">
                                {{type.size}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4" formGroupName="serviceType" *ngIf="!hiddenFields?.serviceType">
                <mat-form-field appearance="standard">
                    <mat-label>Tipo de servicio<span class="asterisk">*</span></mat-label>
                    <mat-select formControlName="name" placeholder="Tipo de servicio">
                        <mat-option *ngFor="let type of filteredServiceTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <div class="row mb-3" *ngIf="!hiddenFields?.letterRetirement">
        <div class="col-md-6" *ngIf="form.get('cargoModel.tripType.name').value==='Exportación'">
            <label class="title">
                Sube la carta de retiro<span class="asterisk">*</span>
            </label>
            <app-file
                [options]="{title: 'Carta retiro', path: 'Cargo'+ '/' + cargo.id + '/letterRetirement', storage: true, storageName: 'tripType_letterRetirement', firebasePath: firebasePath ? form.get('cargoModel.tripType.letterRetirement').value : null, docUrl: docUrl, hideAlert: true}"
                [inputFormControl]="form.get('cargoModel.tripType.letterRetirement')"
                (emitToParent)="handleFileInput($event)">
            </app-file>
        </div>
    </div>

    <div class="row" *ngIf="!hiddenFields?.observation || !hiddenFields?.observationDriver">
        <div class="col-md-6" *ngIf="!hiddenFields?.observationDriver">
            <mat-form-field appearance="standard">
                <mat-label>Observaciones<span class="asterisk">*</span></mat-label>
                <input matInput type="text" formControlName="observation" placeholder="Observaciones">
                <mat-error *ngIf="form.get('observation').errors?.required">
                    {{ utils.giveMessageError('MANDATORY_FIELD') }}
                </mat-error>
            </mat-form-field>

        </div>
        <div class="col-md-6" *ngIf="!hiddenFields?.observationDriver">
            <mat-form-field appearance="standard">
                <mat-label>Observaciones para el conductor</mat-label>
                <input matInput type="text" formControlName="observationDriver"
                    placeholder="Observaciones para el conductor">
            </mat-form-field>
        </div>
    </div>
</form>