import { CargoNature } from "./cargoNature"
import { CargoType } from "./cargoType"
import { Country } from "./country"
import { LocationAddress } from "./locationAddress"
import { OperationType } from "./operationType"
import { PackagingType } from "./packagingType"
import { ProductType } from "./productType"
import { ServiceType } from "./serviceType"
import { ThirdParty } from "./thirdParty"
import { TripType } from "./tripType"

export interface CargoConsignment {
    cargoId: string,
    operationType?: OperationType,
    packagingType: PackagingType,
    cargoNature?: CargoNature,
    productType: ProductType,
    cargoOwner: ThirdParty,
    tripType: TripType;
    cargoType: CargoType;
    serviceType: ServiceType;
    loadDate: string,
    sender: {
        name: string,
        municipalityCode: string,
        country: Country,
        id: string,
        address: string,
        location: LocationAddress,
        thirdParty: ThirdParty,
        time: string,
        agreedTime: number, //timePact
        agreedMinutes: number // minutePact
    },
    recipients: {
        [key: string]: CargoEditConsignmentRecipent,
    }
};

export interface CargoEditConsignmentRecipent {
    destinationId: number, //mandatory if you send recipient
    downloadDate: string,
    name: string,
    municipalityCode: string,
    country: Country,
    addresses: {
        [key: string]: CargoEditConsignmentAddress
    }
}

export interface CargoEditConsignmentAddress {
    loadedQuantity: number, //weight
    weightUnit: string,
    id: string,
    address: string,
    location: LocationAddress,
    thirdParty: ThirdParty,
    time: string,
    agreedTime: number, //timePact
    agreedMinutes: number //minutePact
}

export const formModel = {
    "cargoId": "",
    "operationType": {
        "code": "",
        "description": ""
    },
    "packagingType": {
        "code": "",
        "description": ""
    },
    "cargoNature": {
        "code": "",
        "description": "l"
    },
    "productType": {
        "code": "",
        "name": ""
    },
    "cargoOwner": {
        "documentType": "",
        "documentNumber": "",
        "name": "",
        "branchOffice": ""
    },
    "sender": {
        "name": "",
        "municipalityCode": "",
        "country": {
            "name": "",
            "code": ""
        },
        "id": "",
        "address": "",
        "location": {
            "lat": 0,
            "lng": 0
        },
        "thirdParty": {
            "documentType": "",
            "documentNumber": "",
            "name": "",
            "branchOffice": ""
        },
        "time": "",
        "agreedTime": 0,
        "agreedMinutes": 0
    },
    "recipients": {
        "0": {
            "destinationId": "",
            "downloadDate": "",
            "addresses": {
                "0": {
                    "loadedQuantity": 0,
                    "weightUnit": "",
                    "name": "",
                    "municipalityCode": "",
                    "country": {
                        "name": "",
                        "code": ""
                    },
                    "id": "",
                    "address": "",
                    "location": {
                        "lat": 0,
                        "lng": 0
                    },
                    "thirdParty": {
                        "documentType": "",
                        "documentNumber": "",
                        "name": "",
                        "branchOffice": ""
                    },
                    "time": "",
                    "agreedTime": 0,
                    "agreedMinutes": 0
                }
            }
        }
    }
};

export const formBasicModel = {
    tripType: {
        name: ""
    },
    cargoType: {
        name: ""
    },
    serviceType: {
        name: ""
    },
    packagingType: {
        code: "",
        description: ""
    },
    productType: {
        code: "",
        name: "",
        type: "",
        description: ""
    },
    vehicleType: {
        name: "",
        bodyWorkType: {
            name: ""
        }
    }
}