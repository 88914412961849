import { Injectable } from '@angular/core';
import { RouteAuthorizedPoint } from '../interfaces/route-authorized-point';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../resources/endpoints';
import { RoutePointType } from '../interfaces/route-point-type';
import { Observable, Observer } from 'rxjs';
import { RouteAuthorizedPointSearchParam } from '../interfaces/route-authorized-point-search-param';
import { RouteItinerary } from '../interfaces/route-itinerary';
import { RouteBasicCity } from '../interfaces/route-basic-city';
import { Cargo } from '../interfaces/cargo';
import { Utils } from '../resources/utils';
import { Origin } from '../interfaces/origin';
import { Destination } from '../interfaces/destination';
import { GoogleService } from './google.service';
import { LocationAddress } from '../interfaces/locationAddress';
import { RouteControlPoint } from '../interfaces/route-control-point';
import { Address } from '../interfaces/address';
import { AddressCargo } from '../interfaces/addressCargo';
import { AuthService } from './authentication.service';
import { DateManager } from '../managers/date.manager';
import { PlanningRoute } from '../interfaces/planning-route';

@Injectable({
  providedIn: 'root'
})
export class PlanningRouteService {

  constructor(
    private endpoints: Endpoints,
    private http: HttpClient,
    private utils: Utils,
    private googleService: GoogleService,
    private authService: AuthService,
  ) { }

  public getPointTypes(): Observable<RoutePointType[]> {
    return new Observable<RoutePointType[]>((observer: Observer<RoutePointType[]>) => {
      this.http.get(`${environment.urlServerTeclogi}${this.endpoints.urlCatalog}/${this.endpoints.AuthorizedStopPointsTypes}`)
        .subscribe((result: { catalog: RoutePointType[] }) => {
          observer.next(result.catalog);
          observer.complete();
        });
    });
  }

  public createAuthorizedPoint(point: RouteAuthorizedPoint) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createAuthorizedPoint}`, point);
  }

  public createAuthorizedPointBatch(points: RouteAuthorizedPoint[]) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createAuthorizedPointBatch}`, points);
  }

  public createRouteItinerary(itinerary: RouteItinerary) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpoints.createRouteItinerary}`, itinerary);
  }

  public updateAuthorizedPoint(point: RouteAuthorizedPoint) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.updateAuthorizedPoint}`, point);
  }

  public updateCity(city: RouteBasicCity): Observable<RouteBasicCity> {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.updateCity}`, city) as unknown as Observable<RouteBasicCity>;
  }

  public deactivateAuthorizedPoint(pointId: string) {
    return this.http.delete(`${environment.urlServerTeclogi}${this.endpoints.deactiveAuthorizedPoint}?id=${pointId}`);
  }

  public getAuthorizedPoints(params?: RouteAuthorizedPointSearchParam) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.getAuthorizedPoints}`, { params: params as any });
  }

  public getRoutes(origin?: string, destination?: string): Observable<PlanningRoute[]> {
    const params = {};
    params['active'] = 'true';
    !!origin ? params['origin'] = origin : 0;
    !!destination ? params['destination'] = destination : 0;
    return this.http.get<PlanningRoute[]>(`${environment.urlServerTeclogi}${this.endpoints.listRoutes}`, { params });
  }

  public getRoute(id: string) {
    return this.http.get(`${environment.urlServerTeclogi}${this.endpoints.listRoutes}/${id}`);
  }

  public getItinerary(id: string): Observable<RouteItinerary> {
    return this.http.get<RouteItinerary>(`${environment.urlServerTeclogi}${this.endpoints.routeItinerary}/${id}`);
  }

  public selectItinerary(id: string) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpoints.routeItinerarySelect}/?id=${id}`, null);
  }

  public createRouteItineraryFromCargo(cargo: Cargo): Promise<RouteItinerary | string> {
    return new Promise(async (resolve, reject) => {
      try {
        const originCity: Origin = this.utils.getNestedValue(cargo, "cargoFeature.uploadDownload.origin");
        if (!originCity || !originCity.name || !originCity.municipalityCode || !originCity.addresses)
          reject("Ocurrió un error al acceder a la ciudad de origen");
        const destinations: Destination[] = this.utils.getNestedValue(cargo, "cargoFeature.uploadDownload.destination");
        if (!destinations || !destinations.length)
          reject("Ocurrió un error al acceder a la ciudad de destino");
        const destinationCity: Destination = destinations[destinations.length - 1];

        const locations: AddressCargo[] = [];
        if (!originCity.addresses[0] || !originCity.addresses[0].location)
          reject("Ocurrió un error al acceder a la posición geográfica de la ciudad de origen");
        locations[0] = originCity.addresses[0];
        destinations.forEach(destination => {
          if (destination && destination.addresses && destination.addresses[0] && destination.addresses[0].location)
            locations.push(destination.addresses[0]);
        });

        const controlPoints: RouteControlPoint[] = [];
        for (const location of locations.filter((loc, i) => i !== 0 && i !== locations.length - 1)) {
          const controlPoint: RouteControlPoint = {
            location: location.location,
            name: location.address,
            address: location.address,
            fingerprint: {
              userId: this.authService
                .getUserSession()
                .information.document,
              userName: this.authService.getUserSession().information.name,
              date: DateManager.dateToString(new Date()),
            }
          };
          controlPoints.push(controlPoint);
        }

        const data = await this.googleService.getRouteData(locations.map(loc => loc.location), 'DRIVING', true) as { cargoDistancy: number, cargoEstimatedTime: number, cargoRoute: google.maps.DirectionsResult };
        if (!data || !data.cargoDistancy || !data.cargoEstimatedTime || !data.cargoRoute)
          reject("No fue posible crear el plan de ruta de este servicio");

        const render = new google.maps.DirectionsRenderer();
        render.setDirections(data.cargoRoute);

        const itinerary: RouteItinerary = {
          name: `Itinerario Estandar`,
          overviewPolylines: render.getDirections().routes[0].overview_polyline,
          estimatedTime: data.cargoEstimatedTime,
          estimatedDistance: data.cargoDistancy,
          mustSleep: data.cargoEstimatedTime > (14 * 60 * 60),
          originPoint: {
            id: originCity.municipalityCode,
            name: originCity.name,
            location: locations[0].location
          },
          destinationPoint: {
            id: destinationCity.municipalityCode,
            name: destinationCity.name,
            location: locations[locations.length - 1].location
          },
          authorizedStops: [],
          controlPoints
        };

        resolve(itinerary);
      } catch (e) {
        console.error(e)
        reject("No fue posible crear el plan de ruta de este servicio");
      }


    });
  }

  public async findRouteByCargo(cargo: Cargo): Promise<PlanningRoute> {
    const originCity: Origin = this.utils.getNestedValue(cargo, "cargoFeature.uploadDownload.origin");
    if (!originCity || !originCity.name || !originCity.municipalityCode || !originCity.addresses)
      return null;
    const destinations: Destination[] = this.utils.getNestedValue(cargo, "cargoFeature.uploadDownload.destination");
    if (!destinations || !destinations.length)
      return null;
    const destinationCity: Destination = destinations[destinations.length - 1];
    try {
      const routes = await this.getRoutes(originCity.name, destinationCity.name).toPromise();
      return routes && routes.length ? routes[0] : null;
    } catch (e) {
      return null;
    }
  }

  public setCargoRouteAndItinerary(body: { cargoId: string, itineraryId: string, routePlanId: string }[]): Observable<Cargo[]> {
    return this.http.put<Cargo[]>(`${environment.urlServerTeclogi}${this.endpoints.setCargoPlanItinerary}`, body);
  }

}
