import { Component, OnInit, Input, Inject, ViewChild } from "@angular/core";
import { AuthService } from "src/app/core/services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Utils } from "src/app/core/resources/utils";
import { NgxSpinnerService } from "ngx-spinner";
import { SnackBarService } from "src/app/core/services/snackBar.service";
import { VehiclesService } from "../list-vehicles.service";
import { Model, Vehicle } from "src/app/core/interfaces/vehicle";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { ManualCreationCargoService } from "src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service";
import { ReactiveForm } from "src/app/core/resources/reactive-form";
import {
  MatButtonToggleChange,
  MatDialog,
  MatDialogRef,
  MatStepper,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from "@angular/material";
import { Patterns } from "src/app/core/resources/patterns";
import { AdminUsersService } from "../../admin-users/admin-users.service";
import { User } from "src/app/core/interfaces/user";
import { CompanyNamePipe } from "src/app/core/pipe/companyName.pipe";
import { StringValueToken } from "html2canvas/dist/types/css/syntax/tokenizer";
import { CdkStepper } from "@angular/cdk/stepper";
import { DateFormatPipe } from "src/app/core/pipe/dateFormat.pipe";
import { VehicleDocumentsComponent } from "../vehicle-documents/vehicle-documents.component";
import { LegalTermsService } from "../../legal-terms/legal-terms.service";
import { Roles } from "src/app/core/enums/roles.enum";
import { BehaviorSubject, Subscription, merge } from "rxjs";
import { Fmt } from "src/app/core/messages/fmt";
import { FormMessages } from "src/app/core/messages/form-messages.enum";
import { ModalEnum } from "src/app/core/enums/modal.enum";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { map } from "rxjs/operators";
import { ServiceMessages } from "src/app/core/messages/service-messages.enum";
import { Company } from "src/app/core/interfaces/company";
import { AccountService } from "src/app/modules/account/account.service";
import { VehicleCreate } from "src/app/core/interfaces/vehicleCreate";
import { DuplicatedPhoneComponent } from "../duplicated-phone/duplicated-phone.component";
import { UserValidate } from "src/app/core/interfaces/userValidate";
import { CompanyService } from "src/app/core/services/company.service";

@Component({
  selector: "app-form-vehicle",
  templateUrl: "./form-vehicle.component.html",
  styleUrls: ["./form-vehicle.component.scss"],
  providers: [
    AuthService,
    ManualCreationCargoService,
    Model,
    CompanyNamePipe,
    CdkStepper,
  ],
})
export class FormVehicleComponent extends ReactiveForm implements OnInit {
  @Input() mode: string;
  companySelected: FormControl = new FormControl();
  companySub: Subscription;
  isSameOwnerCtrl: FormControl = new FormControl(false);
  isOwnedFleet: FormControl = new FormControl(false);
  hasAdministratorCtrl: FormControl = new FormControl(false);
  vehicle: Vehicle;
  existingVehicle: Vehicle = null;
  driver: User = {};
  owner: User = {};
  isOptional = false;
  licensePlate: StringValueToken;
  onlyGps: boolean = false;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  @ViewChild(VehicleDocumentsComponent, { static: false })
  vehicleDocumentsComponent;
  configStepper: { title };
  stepActive: number = 0;
  licenseInput: FormControl = new FormControl();
  validateTruora: FormControl = new FormControl(true);
  optionsCompany = {
    title: 'Compañía',
    optionAll: true,
    hint: 'Si no selecciona ninguna compañía será accesible desde todas'
  };
  allowCreatingWithoutTruora: boolean = false;
  public roles = Roles;

  constructor(
    private authService: AuthService,
    private vehiclesService: VehiclesService,
    public utils: Utils,
    private spinner: NgxSpinnerService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public modelVehicle: Model,
    public matDialog: MatDialog,
    private patterns: Patterns,
    @Inject(MAT_DIALOG_DATA) public dialogParams: {
      company?: Company,
      vehicle?: Vehicle,
      configStepper?: { title: string },
      onlyGps?: boolean,
      omitNavigate?: boolean,
    },
    public dialogRef: MatDialogRef<FormVehicleComponent>,
    private router: Router,
    private adminUsersService: AdminUsersService,
    private companyNamePipe: CompanyNamePipe,
    private dateFormatPipe: DateFormatPipe,
    private companyService: CompanyService,
    private accountService: AccountService,
  ) {
    super(
      formBuilder,
      modelVehicle.modelCreate,
      modelVehicle.modelCreate,
      modelVehicle.modelCreate
    );
    this.setValidatorsForm(modelVehicle.vehicleCreationValidators, this.form);
    this.form.get("vehicle.extraImages").setValue([]);
    this.setDataParamsDialog();
    this.lisenerCompanySelected();
    this.infoHolderCompany();
  }

  /**
  * @description Verifies the route.routeConfig to set this mode as "create" or "detail"
  */
  ngOnInit() {
    this.route.routeConfig === null || this.route.routeConfig.path === "create" ?
      this.mode = "create" : this.mode = "detail";
    this.subscribeToCheckValidTerms(LegalTermsService.driverHasValidTerms, this.driverControls, 'driver');
    //this.subscribeToCheckValidTerms(TermsAndConditionsService.ownerHasValidTerms, this.ownerControls, 'owner');
    //this.subscribeToCheckValidTerms(TermsAndConditionsService.administratorHasValidTerms, this.administratorControls, 'admin');
  }

  private infoHolderCompany() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
  }

  /**
  * @param {BehaviorSubject<boolean>} observable is the observable of the terms to subscribe
  * @param {AbstractControl} control are the controls of subject to validate
  * @param {'driver'|'owner'|'admin'} type is the type of subject
  * @description Verifies if the terms are valid to execute the "switchControls" method of some fields of the control
  */
  private subscribeToCheckValidTerms(observable: BehaviorSubject<boolean>, control: AbstractControl, type: 'driver' | 'owner' | 'admin'): void {
    observable.subscribe((valid: boolean) => {
      this.switchControls(control, 'information.name', valid);
      this.switchControls(control, 'phone', valid);

      if (type === 'driver')
        this.switchControls(control, 'email', valid);

      this.switchControls(control, 'address', valid);
      this.switchControls(control, 'city', valid);
      this.switchControls(control, 'expeditionDateId', valid);
    });
  }

  /**
  * @param {AbstractControl} control are the controls of the subject to disable/enable
  * @param {string} name is the name of the sub-control to get
  * @param {boolean} valid indicates if the subject has valid terms or not
  * @description Verifies if the terms are valid to execute the "switchControls" method of some fields of the control
  */
  private switchControls(control: AbstractControl, name: string, valid: boolean) {
    try {
      if (control.get(name)) !valid ? control.get(name).disable() : control.get(name).enable();
    } catch (e) {
      console.error(e);
    }

  }

  /**
  * @description Gets the driver's detail updated
  */
  private getDataDriver() {
    if (this.vehicle.driver && this.vehicle.driver.document) {
      this.spinner.show();
      this.adminUsersService
        .getUsersDetailByDocument(this.vehicle.driver.document)
        .subscribe(
          (success: User) => {
            this.spinner.hide();
            if (success && success.information) {
              this.driver = success;
              this.form.get("driver").patchValue(this.driver);
            } else {
              this.driver = null;
            }
          },
          () => {
            this.driver = null;
            this.spinner.hide();
          }
        );
    }

  }

  /**
  * @description Gets the owner's detail updated
  */
  private getDataOwner() {
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(this.vehicle.owner.document)
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          if (success && success.information) {
            this.owner = success;
            this.form.get("owner").patchValue(this.owner);
          } else {
            this.owner = null;
          }
        },
        () => {
          this.owner = null;
          this.spinner.hide();
        }
      );
  }

  /**
  * @description Gets the admin's detail updated
  */
  private getDataAdmin() {
    this.spinner.show();
    this.adminUsersService
      .getUsersDetailByDocument(this.vehicle.administrator.document)
      .subscribe(
        (success: User) => {
          this.spinner.hide();
          if (success && success.information) this.form.get("administrator").patchValue(success);
          else {
            this.hasAdministratorCtrl.setValue(false);
            this.form.get("administrator").patchValue(null);
          }
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
  * @description Makes a subscription to companySelected valueChanges to update owner,driver, admin and vehicle's fields
  */
  private lisenerCompanySelected() {
    this.companySub = this.companySelected.valueChanges.subscribe((value) => {
      this.form.get("driver.exclusive").setValue(value && value.exclusive ? value.exclusive : '');
      this.form.get("owner.exclusive").setValue(value && value.exclusive ? value.exclusive : '');
      this.form.get("driver.operationId").setValue(value && value.companyId ? value.companyId : '');
      this.form.get("owner.operationId").setValue(value && value.companyId ? value.companyId : '');
      this.form.get("administrator.operationId").setValue(value && value.companyId ? value.companyId : '');
      this.form.get("vehicle.companyId").setValue(value && value.companyId ? value.companyId : '');
    });
  }

  /**
  * @description Updates the local variables with dialogParams
  */
  private setDataParamsDialog() {
    if (this.dialogParams && this.dialogParams.company) {
      this.companySelected.patchValue(this.dialogParams.company);
    }
    if (this.dialogParams && this.utils.isDefined(this.dialogParams.vehicle)) {
      if (this.utils.isDefined(this.dialogParams.configStepper))
        this.configStepper = this.dialogParams.configStepper;
      this.vehicle = this.dialogParams.vehicle;
      if (this.vehicle.owner && this.vehicle.owner.document) {
        this.isSameOwnerCtrl.setValue(
          this.vehicle.owner.document === this.vehicle.driver.document
        );
      }
      this.onlyGps = this.dialogParams.onlyGps;
      this.form.get("vehicle").patchValue(this.vehicle);
      const company = this.companyNamePipe.transform(
        this.vehicle.companyId,
        "full"
      );
      if (this.utils.isDefined(company)) {
        this.companySelected.setValue(company);
      }
      this.getDataDriver();
      if (this.vehicle.owner && this.vehicle.owner.document) this.getDataOwner();
      if (this.vehicle && this.vehicle.administrator && this.vehicle.administrator.document) {
        this.hasAdministratorCtrl.setValue(true);
        this.getDataAdmin();
      }

    } else {
      const licensePlateCtrl = this.form.get("vehicle.id") as FormControl;
      licensePlateCtrl.setAsyncValidators(this.checkVehicleExistance());
      licensePlateCtrl.updateValueAndValidity();
    }
  }

  /**
  * @returns {AsyncValidatorFn} returns an async validator who checks the vehicle existance
  *  @description Gets a async validators to check the vehicle existance
  */
  private checkVehicleExistance(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      this.existingVehicle = null;
      if (this.vehicle) return null;
      if (control.hasError('pattern')) return null;
      const holderCompany = this.authService.getUserSession() && this.authService.getUserSession().clientCompanyId ? this.authService.getUserSession().clientCompanyId : null;
      let filters = `&id=${control.value}`;
      return this.vehiclesService.getListVehicles(1, 2, filters).pipe(
        map(res => {
          if (res && res[0] && res[0].holderCompany) {
            this.existingVehicle = res[0];
            for (const company of this.existingVehicle.holderCompany) {
              if (company.toString() === holderCompany)
                return { vehicleExistsCompany: true };
            }
            if (this.existingVehicle.holderIsExclusive === false) {
              this.showModalVehicleCompany(this.existingVehicle);
              return { vehicleExistsNotExclusive: true };
            }
            return { vehicleExists: true };
          }
          return null;
        })
      );
    };
  }

  /**
  * @returns {boolean} returns true if dialogParams.company or dialogParams.vehicle exists, otherwise false
  * @description Verifies if dialogParams vehicle or vehicle exists
  */
  private get preconfigured(): boolean {
    return this.dialogParams && !!(this.dialogParams.company || this.dialogParams.vehicle);
  }

  /**
  * @returns {AbstractControl} returns the form's administrator controls
  * @description Gets the form's administrator controls
  */
  get administratorControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("administrator");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's owner controls
  * @description Gets the form's owner controls
  */
  get ownerControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("owner");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's driver.referenceLaboral controls
  * @description Gets the form's driver.referenceLaboral controls
  */
  private get referenceLaboralControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("driver.referenceLaboral");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's driver.referencePersonal controls
  * @description Gets the form's driver.referencePersonal controls
  */
  private get referencePersonalControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("driver.referencePersonal");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's driver.emergencyContact controls
  * @description Gets the form's driver.emergencyContact controls
  */
  private get emergencyContactControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("driver.emergencyContact");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's driver controls
  * @description Gets the form's driver controls
  */
  get driverControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("driver");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's vehicle.gps controls
  * @description Gets the form's vehicle.gps controls
  */
  get gpsControls(): AbstractControl {
    if (this.mode === "create") return this.form.get("vehicle.gps");
    return null;
  }

  /**
  * @returns {AbstractControl} returns the form's driver.id control
  * @description Gets the form's driver.id control
  */
  get licensePlateControl(): AbstractControl {
    let licensePlateControl = this.form.get("vehicle.id");
    if (history && history.state && history.state.licensePlate)
      licensePlateControl.setValue(history.state.licensePlate);
    return licensePlateControl;
  }

  /**
  * @returns {boolean} returns true if the form's first step is valid, otherwise false.
  * @description Checks the validity of form's first step and show an error message if is not.
  */
  private isValidStepOne(): boolean {
    this.setDefaultValuesForm();
    this.validateIsSameOwner();
    this.form.get("vehicle.id").markAsTouched();
    this.form.get("driver").markAllAsTouched();
    this.form.get("owner").markAllAsTouched();
    this.form.get("administrator").markAllAsTouched();
    //Vehicle validation
    if (this.form.get("vehicle.id").value && this.form.get("vehicle.id").hasError('vehicleExistsCompany') && !this.vehicle) {
      this.showModalRedirectVehicle(this.form.get("vehicle.id").value);
      return false;
    }
    if (this.form.get("vehicle.id").value && this.form.get("vehicle.id").hasError('vehicleExistsNotExclusive') && !this.vehicle) {
      this.showModalVehicleCompany(this.existingVehicle);
      return false;
    }
    if (this.form.get("vehicle.id").value && this.form.get("vehicle.id").hasError('vehicleExists') && !this.vehicle) {
      this.snackBarService.openSnackBar(`El vehículo con placa ${this.licensePlateControl.value} ya existe, y pertenece a otra compañía`, undefined, 'alert');
      return false;
    }
    if (this.utils.errorMessagesCustomized(this.form.get("vehicle.id"), 'placa del vehículo', 5, 6)) return false;
    //Driver validation
    if (this.utils.errorMessagesCustomized(this.form.get("driver.information.documentTypeId"), 'tipo de documento del conductor')) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.information.documentTypeName"), 'tipo de documento del conductor')) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.information.document"), 'documento del conductor', 3, 10)) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.information.name"), 'nombre del conductor')) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.phone"), 'celular del conductor', 7, 12)) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.expeditionDateId"), 'fecha de expedición del conductor')) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.email"), 'email del conductor', null, 100)) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.city"), 'ciudad del conductor')) return false;
    if (this.utils.errorMessagesCustomized(this.form.get("driver.address"), 'dirección del conductor')) return false;
    //Owner validation
    if (!this.isSameOwnerCtrl.value) {
      if (this.utils.errorMessagesCustomized(this.form.get("owner.information.documentTypeId"), 'tipo de documento del propietario')) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("owner.information.documentTypeName"), 'tipo de documento del propietario')) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("owner.information.document"), 'documento del propietario', 3, 10)) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("owner.information.name"), 'nombre del propietario')) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("owner.phone"), 'celular del propietario', 7, 12)) return false;
      if (this.form.get('owner.information.documentTypeId').value === "3") {
        if (this.utils.errorMessagesCustomized(this.form.get("owner.city"), 'ciudad del propietario')) return false;
        if (this.utils.errorMessagesCustomized(this.form.get("owner.address"), 'dirección del propietario')) return false;
        if (this.utils.errorMessagesCustomized(this.form.get("owner.municipalityCode"), 'código de municipio del propietario')) return false;
      }
    }
    //Admin validation
    if (this.hasAdministratorCtrl.value) {
      if (this.utils.errorMessagesCustomized(this.form.get("administrator.information.documentTypeId"), 'tipo de documento del administrador')) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("administrator.information.documentTypeName"), 'tipo de documento del administrador')) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("administrator.information.document"), 'documento del administrador', 3, 10)) return false;
      if (this.utils.errorMessagesCustomized(this.form.get("administrator.information.name"), 'nombre del administrador')) return false;
      if (this.form.get('administrator.information.documentTypeId').value === "3") {
        if (this.utils.errorMessagesCustomized(this.form.get("administrator.city"), 'ciudad del administrador')) return false;
        if (this.utils.errorMessagesCustomized(this.form.get("administrator.address"), 'dirección del administrador')) return false;
        if (this.utils.errorMessagesCustomized(this.form.get("administrator.municipalityCode"), 'código de municipio del administrador')) return false;
      }
    }
    return true;
  }

  /**
  * @returns {boolean} returns true if the form's third step is valid, otherwise false.
  * @description Checks the validity of form's third step and show an error message if is not.
  */
  private isValidStepThree(): boolean {
    this.gpsControls.markAllAsTouched();
    const gpsType: string = this.gpsControls.get('gpsType').value;
    const isPortable = this.gpsControls.get("isPortableUnit") && !!this.gpsControls.get("isPortableUnit").value;
    const userGps = this.gpsControls.get('userGps').value;
    const passwordGps = this.gpsControls.get('passwordGps').value;

    if (!gpsType && !userGps && !passwordGps) return true;
    !gpsType ? this.gpsControls.get('gpsType').setErrors({ required: true }) : this.gpsControls.get('gpsType').setErrors(null);
    !userGps ? this.gpsControls.get('userGps').setErrors({ required: true }) : this.gpsControls.get('userGps').setErrors(null);
    !passwordGps ? this.gpsControls.get('passwordGps').setErrors({ required: true }) : this.gpsControls.get('passwordGps').setErrors(null);

    this.gpsControls.updateValueAndValidity();

    if (this.gpsControls.get('gpsType').invalid) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.AT_LEAST_ONE_OPTION_VAR, 'tipo de gps'), undefined, 'alert');
      return false;
    }
    if (this.utils.errorMessagesCustomized(this.gpsControls.get('gpsType'), 'tipo de GPS')) return false;
    if (this.utils.errorMessagesCustomized(this.gpsControls.get('userGps'), 'usuario del GPS')) return false;
    if (this.utils.errorMessagesCustomized(this.gpsControls.get('passwordGps'), 'contraseña de GPS')) return false;
    return true;
  }

  /**
  * @returns {boolean} returns true if the form's fourth step is valid, otherwise false.
  * @description Checks the validity of form's fourth step and show an error message if is not.
  */
  private isValidStepFour(): boolean {
    if (this.emergencyContactControls.get('validated').value && (!this.emergencyContactControls.get('name').value ||
      !this.emergencyContactControls.get('lastName').value || !this.emergencyContactControls.get('phone').value)) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.VALIDATE_MISSING_ERROR, 'el contacto de emergencia'), undefined, 'alert');
      return false;
    }
    if (this.referenceLaboralControls.get('validated').value && (!this.referenceLaboralControls.get('name').value || !this.referenceLaboralControls.get('phone').value)) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.VALIDATE_MISSING_ERROR, 'la referencia laboral'), undefined, 'alert');
      return false;
    }
    if (this.referencePersonalControls.get('validated').value && (!this.referencePersonalControls.get('name').value ||
      !this.referencePersonalControls.get('lastName').value || !this.referencePersonalControls.get('phone').value)) {
      this.snackBarService.openSnackBar(Fmt.string(FormMessages.VALIDATE_MISSING_ERROR, 'la referencia personal'), undefined, 'alert');
      return false;
    }
    return true;
  }

  /**
  * @returns {boolean} returns true if the form's basic fields are valid, otherwise false.
  * @description Checks the validity of form's basic fields
  */
  private isValidDocumentType(form: FormGroup): boolean {
    return (
      (form.get("information.documentTypeId").value === "3" &&
        form.get("city").value.length &&
        form.get("address").value.length &&
        form.get("municipalityCode").value.length) ||
      form.get("information.documentTypeId").value !== "3"
    );
  }

  /**
  * @description Checks the validity of the complete form to execute "createVehicleDriverOwner" method
  */
  onSubmit() {
    if (this.mode === "create") {
      const isValidForm = this.isValidStepOne();
      const isValidGPS = this.isValidStepThree();
      const isValidReferences = this.isValidStepFour();
      if (isValidForm && isValidGPS && isValidReferences) {
        this.createVehicleDriverOwner(true);
      }
    }
  }

  /**
  * @returns {boolean} returns true if the form's owner, driver and admin's documents, municipalityCode and address are valid
  * @description Checks the validity of form's owner, driver and admin's documents, municipalityCode and address are valid
  */
  /*private validateDocumentTypeSelect():boolean {
    return !(this.form.get("owner.information").value.documentTypeId === "3" &&
        (!this.form.get("owner").value.municipalityCode.trim().length ||
          !this.form.get("owner").value.address.trim().length)) ||
      (this.form.get("driver.information").value.documentTypeId === "3" &&
        (!this.form.get("driver").value.municipalityCode.trim().length ||
          !this.form.get("driver").value.address.trim().length)) ||
      (this.form.get("administrator") &&
        this.form.get("administrator.information") &&
        this.form.get("administrator.information").value.documentTypeId ===
        "3" &&
        (!this.form.get("administrator").value.municipalityCode.trim().length ||
          !this.form.get("administrator").value.address.trim().length))
  }*/

  /*async onUpdate() {
    this.validateIsSameOwner();
    const isValidDocumentType = await this.validateDocumentTypeSelect();
    const isValidFormDriver = await this.stateFormDriverCreate;
    const isValidFormOwner = await this.stateFormOwnerCreate;
    // const isValidCompanySelected = await this.stateCompanySelected;
    if (
      isValidFormDriver &&
      isValidFormOwner &&
      // isValidCompanySelected &&
      isValidDocumentType
    ) {
      this.setDefaultValuesForm();
      this.updateVehicleDriverOwner();
    } else {
      this.snackBarService.openSnackBar(
        "Datos incorrectos",
        undefined,
        "alert"
      );
    }
  }*/

  /*updateVehicleDriverOwner() {
    this.spinner.show();
    const data = this.utils.clone(this.form.value);
    data.driver.phone = data.driver.phone.startsWith("57")
      ? data.driver.phone
      : `57${data.driver.phone}`;
    data.owner.phone = data.owner.phone.startsWith("57")
      ? data.owner.phone
      : `57${data.owner.phone}`;
    data.forceCreation = true;
    delete data.administrator;
    this.vehiclesService.createVehicleDriverOwner(data).subscribe(
      (success: VehicleCreate) => {
        this.spinner.hide();
        if (
          !this.utils.isEmpty(success) &&
          !this.utils.isEmpty(success.message)
        ) {
          this.snackBarService.openSnackBar(success.message);
        } else {
          this.snackBarService.openSnackBar(
            "Vehículo actualizado correctamente",
            undefined,
            "success"
          );
        }
        if (this.preconfigured) {
          this.dialogRef.close({
            state: true,
            value: this.form.value,
          });
        } else {
          setTimeout(() => {
            this.goToDetail();
          }, 400);
        }
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (
          !this.utils.isEmpty(error) &&
          !this.utils.isEmpty(error.error) &&
          !this.utils.isEmpty(error.error.message)
        ) {
          this.snackBarService.openSnackBar(
            error.error.message,
            undefined,
            "alert"
          );
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al actualizar el vehículo",
            undefined,
            "error"
          );
        }
      }
    );
  }*/

  /**
  * @param {boolean} lastStep indicates if the function is called from last step or not
  * @description Builds the body and creates the vehicle from the form values
  */
  private createVehicleDriverOwner(lastStep = false) {
    const data = this.utils.clone(this.form.getRawValue());

    if (data && data.driver && data.driver.information && data.driver.information.name)
      data.driver.information.name = data.driver.information.name.replace(/\s+/g, ' ').trim();

    if (data && data.owner && data.owner.information && data.owner.information.name)
      data.owner.information.name = data.owner.information.name.replace(/\s+/g, ' ').trim();

    if (data && data.administrator && data.administrator.information && data.administrator.information.name)
      data.administrator.information.name = data.administrator.information.name.replace(/\s+/g, ' ').trim();

    if (data.driver.simpleRegimen === undefined) {
      data.driver.simpleRegimen = false;
    }
    data.forceCreation = this.stepActive === 0 ? this.onlyGps ? false : true : false;
    data.driver.phone = data.driver.phone.startsWith("57")
      ? data.driver.phone
      : `57${data.driver.phone}`;
    data.owner.phone = data.owner.phone.startsWith("57")
      ? data.owner.phone
      : `57${data.owner.phone}`;
    if (!this.hasAdministratorCtrl.value) {
      delete data.administrator;
    }
    data.vehicle.createdBy = {
      userId: this.authService.getUserSession().information.document,
      userName: this.authService.getUserSession().information.name,
      date: this.dateFormatPipe.transform(new Date(), "date-time"),
    };
    data.validateTruora = !this.allowCreatingWithoutTruora;
    this.licensePlate = this.form.get("vehicle.id").value;
    this.spinner.show();
    this.vehiclesService.createVehicleDriverOwner(data).subscribe(
      (success: VehicleCreate) => {
        this.spinner.hide();
        if (success && success.message) {
          this.snackBarService.openSnackBar(
            success.message,
            undefined,
            "alert"
          );
        } else if (this.utils.getNestedValue(success, 'administrator.errorRNDC.error')){
          this.snackBarService.openSnackBar(
            `${Fmt.string(ServiceMessages.GENERAL_ERROR_BY_USER, 'administrador')}: ${success.administrator.errorRNDC.error}`,
            undefined,
            "error"
          );
        } else if (
          success &&
          this.utils.isEmpty(success.message) &&
          success.vehicle
        ) {
          this.snackBarService.openSnackBar(
            "Datos guardados correctamente",
            undefined,
            "success"
          );
          this.vehicle = success.vehicle;
          if (lastStep) {
            if (this.preconfigured || (this.dialogParams && this.dialogParams.omitNavigate)) {
              this.dialogRef.close({
                state: true,
                value: this.form.value,
              });
            } else {
              this.goToDetail();
            }
          } else {
            this.stepActive += 1;
            this.stepper.next();
          }
        } else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al guardar los datos",
            undefined,
            "error"
          );
        }
      },
      (error) => {
        this.spinner.hide();
        if (error && error.error && error.error.message) {
          if (error.status && error.status === 400 && error.error.message === FormMessages.REPEATED_DRIVER_PHONE)
            this.duplicatePhoneErrorManagement(data);
          else this.snackBarService.openSnackBar(error.error.message, undefined, "error");
        }
        else {
          this.snackBarService.openSnackBar(
            "Ocurrió un error al guardar los datos",
            undefined,
            "error"
          );
        }
      });
  }

  /**
  * @param {UserValidate} data is the data of the vehicle's user to check
  * @description Checks the user's same phone error
  */
  private duplicatePhoneErrorManagement(data: UserValidate) {
    const phones = {
      driver: !!data.driver ? (!!data.driver.phone ? data.driver.phone : null) : null,
      owner: !!data.owner ? (!!data.owner.phone ? data.owner.phone : null) : null,
      administrator: !!data.administrator ? (!!data.administrator.phone ? data.administrator.phone : null) : null,
    }
    const observables = [];
    const subjects = ['driver', 'owner', 'admin'];
    subjects.forEach(subject => {
      if (!!phones[subject]) {
        const singlePhone = this.vehiclesService.removeCountryCode(phones[subject]);
        const countryPhone = this.vehiclesService.addCountryCode(phones[subject]);
        observables.push(
          this.vehiclesService.getUsersByPhone(singlePhone),
          this.vehiclesService.getUsersByPhone(countryPhone)
        );
      }
    })
    const results: User[] = [];
    merge(...observables).subscribe(
      {
        next: (users: User[]) => {
          if (Array.isArray(users))
            results.push(...users);
        },
        error: (error: Error) => {
          console.error(error);
        },
        complete: () => {
          const documents = [];
          const findedUsers = results.filter(user => {
            const document = user.information.document;
            const distinct = documents.indexOf(document) === -1;
            documents.push(document);
            return distinct;
          });
          this.showDuplicatedPhoneDialog(findedUsers);
        }
      }
    );

  }

  /**
  * @param {User[]} users is the list of users with the same phone number
  * @description Shows a modal to update the users with same phone number
  */
  public showDuplicatedPhoneDialog(users: User[]): void {
    const config: MatDialogConfig = {
      data: users
    };
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.width = ModalEnum.LARGE_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(DuplicatedPhoneComponent, config);
  }

  /**
  * @description Updates the form's owner with the form's driver info if the driver is the same owner
  */
  private validateIsSameOwner() {
    if (this.isSameOwnerCtrl.value) this.form.get("owner").patchValue(this.form.get("driver").value);
  }

  /**
  * @description Resets the form's owner values
  */
  private clearOwnerForm() {
    this.form.get("owner").patchValue(this.modelVehicle.modelCreate.owner);
    this.form.get("owner").reset();
  }

  /**
  * @description Resets the form's admin values
  */
  clearAdminForm() {
    this.form.get("administrator").patchValue(this.modelVehicle.modelCreate.administrator);
    this.form.get("administrator").reset();
  }

  /**
  * @param {MatButtonToggleChange} $event is the event with the desicion of driver being the same owner or not
  * @description Checks if the driver is the same owner to use "clearOwnerForm" method and disables or enables driver.simpleRegimen
  */
  onChangeIsSameOwner($event: MatButtonToggleChange) {
    if (!$event.value) {
      this.clearOwnerForm();
      this.driverControls.get("simpleRegimen").setValue(false);
      this.driverControls.get("simpleRegimen").disable();
    } else {
      this.driverControls.get("simpleRegimen").enable();
    }
    if (this.vehicle && !this.vehicle.owner && !$event.value) {
      this.clearOwnerForm();
    }
  }

  /**
  * @param {MatButtonToggleChange} $event is the event with the desicion of driver being the same owner or not
  * @description Checks if the driver is the same owner to use "clearOwnerForm" method and disables or enables driver.simpleRegimen
  */
  onChangeIsOwnedFleet($event: MatButtonToggleChange) {
    this.form.get('vehicle.ownFleet').setValue($event.value);
  }

  /**
  * @param {MatButtonToggleChange} $event is the event with the desicion of vehicle having admin or not
  * @description Checks if the vehicle hasn't admin to use "clearAdminForm" method
  */
  onChangeHasAdmin($event: MatButtonToggleChange) {
    if (!$event.value) {
      this.clearAdminForm();
    }
  }

  /*createBankAcountAdvance(stepper: MatStepper, $event) {
    if ($event) {
      this.vehicle = $event;
      stepper.next();
    } else {
      this.snackBarService.openSnackBar(
        "Ocurrió un error al guardar la información",
        undefined,
        "error"
      );
    }
  }*/

  /*createBankAcountBalance($event) {
    if ($event) {
      this.goToDetail();
    } else {
      this.snackBarService.openSnackBar(
        "Ocurrió un error al guardar la información",
        undefined,
        "error"
      );
    }
    this.goToDetail();
  }*/

  /**
  * @description Updates the driver and owner's role depending if driver is the same owner or not
  */
  private setDefaultValuesForm() {
    if (this.isSameOwnerCtrl.value) {
      this.form.get("driver.role").setValue("Driver");
      this.form.get("owner.role").setValue("Driver");
    } else {
      this.form.get("driver.role").setValue("Driver");
      this.form.get("owner.role").setValue("Owner");
    }
  }

  /**
  * @param {string} vehicleId is the vehicle's license plate written
  * @description Shows a modal indicating that the vehicle already exists and allow to go to its detail
  */
  private showModalRedirectVehicle(vehicleId: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `El vehículo con placa ${vehicleId} ya existe ¿Quieres ir al detalle de este?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.goToVehicle(vehicleId);
      }
      else this.snackBarService.openSnackBar("No es posible crear vehículos duplicados", undefined, 'error');
    });
  }

  /**
  * @param {Vehicle} vehicle is the vehicle written
  * @param {string} holderCom is the current user's company
  * @description Shows a modal indicating that the vehicle already exists in another company and allows to associate it to user's company
  */
  showModalVehicleCompany(vehicle: Vehicle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `El vehículo con placa ${vehicle.id} ya existe ¿Quieres asociarlo a esta compañia?`,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        this.vehiclesService.updateVehicle(vehicle).subscribe(
          (success) => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(ServiceMessages.VEHICLE_HOLDERCOMPANY_ASSOCIATED);
            this.goToVehicle(vehicle.id);
          },
          () => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(
              ServiceMessages.GENERAL_HTTP_ERROR,
              undefined,
              "error"
            );

          }
        )
      }
      else this.snackBarService.openSnackBar("No es posible crear vehículos duplicados", undefined, 'error');
    })
  }

  /**
  * @param {string} vehicleId is the vehicle's license plate written
  * @description Navigates to vehicle's detail
  */
  goToVehicle(vehicleId: string) {
    this.router.navigate(['administration/vehicles/detail', vehicleId]);
  }

  /*private get stateFormDriverCreate(): boolean {
    return (
      this.patterns.CELLPHONE.test(this.form.get("driver.phone").value) &&
      this.patterns.ONLY_NUMBERS.test(
        this.form.get("driver.information.document").value
      ) &&
      this.patterns.ONLY_NUMBERS.test(
        this.form.get("driver.information.documentTypeId").value
      ) &&
      this.patterns.ONLY_WORDS.test(
        this.form.get("driver.information.documentTypeName").value
      ) &&
      this.patterns.DATE_YYYY_MM_DD.test(
        this.form.get("driver.expeditionDateId").value
      )
    );
  }*/

  /*private get stateFormOwnerCreate(): boolean {
    return (
      this.patterns.CELLPHONE.test(this.form.get("owner.phone").value) &&
      this.patterns.ONLY_NUMBERS.test(
        this.form.get("owner.information.document").value
      ) &&
      this.patterns.ONLY_NUMBERS.test(
        this.form.get("owner.information.documentTypeId").value
      ) &&
      this.patterns.ONLY_WORDS.test(
        this.form.get("owner.information.documentTypeName").value
      )
    );
  }*/

  /*get stateFormAdminCreate(): boolean {
    if (this.hasAdministratorCtrl.value) {
      return (
        this.patterns.ONLY_NUMBERS.test(
          this.form.get("administrator.information.document").value
        ) &&
        this.patterns.ONLY_NUMBERS.test(
          this.form.get("administrator.information.documentTypeId").value
        ) &&
        this.patterns.ONLY_WORDS.test(
          this.form.get("administrator.information.documentTypeName").value
        ) &&
        this.isValidDocumentType(this.form.get("administrator"))
      );
    } else {
      return true;
    }
  }*/

  /*get stateCompanySelected() {
    return (
      this.utils.isDefined(this.companySelected) &&
      this.utils.isDefined(this.companySelected.value) &&
      this.patterns.ONLY_NUMBERS.test(this.companySelected.value.nit) &&
      this.utils.isDefined(this.companySelected.value.exclusive)
    );
  }*/

  /**
  * @returns {FormGroup} returns the form's vehicle controls
  * @description Gets the form's vehicle controls
  */
  get formVehicle(): FormGroup {
    return this.form.get("vehicle") as FormGroup;
  }

  /**
  * @returns {boolean} returns true if this component is being called from a modal, otherwise false
  * @description Verifies if this component is being called from a modal, otherwise false
  */
  get isDialog(): boolean {
    return !!(this.dialogParams && Object.keys(this.dialogParams).length);
  }

  /**
  * @description Navigates to vehicle's detail view passing vehicle's license plate as param
  */
  goToDetail() {
    this.router.navigate([
      "administration/vehicles/detail",
      this.form.get("vehicle.id").value,
    ]);
  }

  /**
  * @param {MatStepper} stepper is the form's stepper
  * @description Goes to previous step
  */
  goBack(stepper: MatStepper) {
    this.stepActive -= 1;
    stepper.previous();
  }

  /**
  * @description Checks if the current and every previous steps are valid to execute "createVehicleDriverOwner" method
  */
  goForward() {
    let isValidForm = true;
    for (let i = 0; i < this.stepActive + 1; i++) {
      isValidForm = isValidForm && this.validStep(i);
    }
    if (isValidForm) {
      this.createVehicleDriverOwner(false);
    }
  }

  /**
  * @param {number} step is the step to check
  * @returns {boolean} returns true if the step to check is valid, otherwise false.
  * @description Checks if a step is valid.
  */
  private validStep(step: number): boolean {
    let isValidForm = true;
    switch (step) {
      case 0:
        isValidForm = this.isValidStepOne();
        break;
      case 2:
        isValidForm = this.isValidStepThree();
        break
      case 3:
        isValidForm = this.isValidStepFour();
        break;
    }
    return isValidForm;
  }

  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }
}

