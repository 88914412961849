import { environment } from "src/environments/environment";

export const FREQUENT_ROUTES_LOOKER =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/c7724ee0-8ccb-406c-ad45-97e5e4be39d7/page/JFe3D' :
        'https://lookerstudio.google.com/embed/reporting/c7724ee0-8ccb-406c-ad45-97e5e4be39d7/page/JFe3D';
export const OPERATIONS_ROUTES_LOOKER =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/1fff3865-c422-4d26-a4bf-18af0700b5a8/page/p_ml2z3j81dd' :
        'https://lookerstudio.google.com/embed/reporting/1fff3865-c422-4d26-a4bf-18af0700b5a8/page/p_ml2z3j81dd';
export const VEHICLE_ANALYTICS_LOOKER =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/f776b90b-4306-457e-9b53-f1619b4110d9/page/G2czD' :
        'https://lookerstudio.google.com/embed/reporting/f776b90b-4306-457e-9b53-f1619b4110d9/page/G2czD'
export const DISPATCH_CARGO_LOOKER =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/2a9a928b-dc02-45b6-8024-f21109451cc9/page/p_302d99duid' :
        'https://lookerstudio.google.com/embed/reporting/2a9a928b-dc02-45b6-8024-f21109451cc9/page/p_302d99duid';
export const STOPED_CARGO_LOOKER =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/06b3d437-cfb3-47e0-83e5-c357b96d0b1f/page/0C47D' :
        'https://lookerstudio.google.com/embed/reporting/06b3d437-cfb3-47e0-83e5-c357b96d0b1f/page/0C47D';
export const HISTORICAL_KPI =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/ad32e458-4238-47c4-9d8e-cfbecb355afe/page/4WeEE' :
        'https://lookerstudio.google.com/embed/reporting/ad32e458-4238-47c4-9d8e-cfbecb355afe/page/4WeEE';

export const OPERATION_ITR =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/49d0e135-14c8-44e4-8c3f-2be4ce790ae2/page/lVXCE' :
        'https://lookerstudio.google.com/embed/reporting/49d0e135-14c8-44e4-8c3f-2be4ce790ae2/page/lVXCE';

export const BILLING =
    environment.production ?
        'https://lookerstudio.google.com/embed/reporting/c83534bc-c25b-4ebd-be15-7a4b636879b1/page/p_5lt2xqthkd' :
        'https://lookerstudio.google.com/embed/reporting/c83534bc-c25b-4ebd-be15-7a4b636879b1/page/p_5lt2xqthkd';

export const OPERATIONS_TURN = 'https://lookerstudio.google.com/embed/reporting/2cfc0d4f-c95c-4b68-b00a-fd4d0676b154/page/p_cph9a45bmd';
export const COMERCIAL_TURN = 'https://lookerstudio.google.com/embed/reporting/4c31eaa7-338d-4c26-955f-b18b246b7a7d/page/p_cph9a45bmd';

