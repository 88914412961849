<div class="position-relative py-4 px-2 px-md-4">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h2 class="mt-2 mb-0 text-bold text-center">
        Plan de ruta
    </h2>
    <h5 class="mb-4 text-center">
        {{cargo?.cargoFeature?.uploadDownload?.origin?.name}} -
        {{cargo?.cargoFeature.uploadDownload.destination[cargo?.cargoFeature.uploadDownload.destination.length -
        1]?.name}}
    </h5>
    <div class="itinerary-card" *ngFor="let itinerary of itineraries; let i=index">
        <h5 class="text-bold">
            <i class="far fa-circle cursor-pointer" *ngIf="selectedItinerary !== itinerary?.id"
                (click)="onSelectItinerary(itinerary)"></i>
            <i class="fas fa-check-circle" style="color: green;" *ngIf="selectedItinerary === itinerary?.id"></i>
            {{paramsDialog?.mode ==='edit'? itineraries.length===1 ? 'Itinerario Estandar' : (i+1)+'. '+itinerary.name :
            'Itinerario actual'}}
        </h5>
        <p class="m-0 pl-2"><b class="text-bold">Origen: </b>{{cargo?.cargoFeature?.uploadDownload?.origin?.name}}</p>
        <p class="m-0 pl-2"><b class="text-bold">Destino: </b>
            {{cargo?.cargoFeature.uploadDownload.destination[cargo?.cargoFeature.uploadDownload.destination.length
            -1]?.name}}
        </p>
        <p class="m-0 pl-2" *ngIf="!itinerary?.controlPoints?.length">
            <b class="text-bold">Puntos de control: </b>
            Sin puntos registrados
        </p>
        <p class="m-0 pl-2" *ngIf="!itinerary?.authorizedStops?.length">
            <b class="text-bold">Paradas autorizadas: </b>
            Sin paradas registradas
        </p>
        <mat-accordion class="m-1 mb-2 pl-2" *ngIf="itinerary?.controlPoints?.length">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="text-strong">
                        Puntos de control
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ol class="d-flex flex-wrap w-100">
                    <li class="col-md-4 col-sm-6 col-12" *ngFor="let point of itinerary?.controlPoints">
                        {{point?.name}}
                    </li>
                </ol>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion class="m-1 mb-2 pl-2" *ngIf="itinerary?.authorizedStops?.length">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="text-strong">
                        Paradas autorizadas
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ol class="d-flex flex-wrap w-100 pl-3">
                    <li class="col-md-4 col-sm-6 col-12" *ngFor="let stop of itinerary?.authorizedStops">
                        {{stop?.details?.name}}
                    </li>
                </ol>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="button-container" *ngIf="paramsDialog?.mode ==='edit' && routePlan">
        <button mat-raised-button class="button-width-auto bg-color-primary" color="primary" (click)="saveItinerary()">
            Guardar
        </button>
    </div>
</div>