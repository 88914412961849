import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GoogleService } from 'src/app/core/services/google.service';
import { LatLngLiteral } from '@agm/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizedSitesFormComponent } from '../sites/authorized-sites-form/authorized-sites-form.component';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { RouteItinerary } from 'src/app/core/interfaces/route-itinerary';
import { RouteControlPoint } from 'src/app/core/interfaces/route-control-point';
import { RouteReferenceAuthorizedPoint } from 'src/app/core/interfaces/route-reference-authorized-point';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PlanningRoute } from 'src/app/core/interfaces/planning-route';
import { CityLocationFormComponent } from './city-location-form/city-location-form.component';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RouteMessages } from 'src/app/core/messages/route-messages.enum';
import { RouteBasicCity } from 'src/app/core/interfaces/route-basic-city';
import { ConfirmRouteCreationComponent } from './confirm-route-creation/confirm-route-creation.component';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Global } from 'src/app/core/resources/global';
import { RoutesMapComponent } from '../routes-map/routes-map.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { Fmt } from 'src/app/core/messages/fmt';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-planning-form',
  templateUrl: './planning-form.component.html',
  styleUrls: ['./planning-form.component.scss']
})
export class PlanningFormComponent implements AfterViewInit {
  itineraries: RouteItinerary[] = [];
  mode: 'RUTE' | '1' | '2' | '3' | '4' = 'RUTE';
  form: FormGroup = new FormGroup({
    search: new FormControl('', [])
  })

  originControl: FormControl = new FormControl('', [Validators.required]);
  originSub: Subscription;
  originOptions: OptionsAutocomplete = { title: "Ciudad de origen", appearance: "outline", requireFullCity: true };
  originCity: RouteBasicCity = null;
  originValidate = '';

  destinationControl: FormControl = new FormControl('', [Validators.required]);
  destinationSub: Subscription;
  destinationOptions: OptionsAutocomplete = { title: "Ciudad de destino", appearance: "outline", requireFullCity: true };
  destinationCity: RouteBasicCity = null;
  destinationValidate = '';

  searchResult: Array<RouteAuthorizedPoint> = [];
  sites: Array<RouteAuthorizedPoint> = [];

  private renderer: google.maps.DirectionsRenderer = null;
  private params: any = {};
  mapOptions: OptionsAutocomplete = {
    clickMap: true,
    clickMapIcon: this.global.pathMarkerDestination
  };

  ngOnInit() {
    this.setSubscription();
    if (this.params.id) {
      this.originValidate = 'disable';
      this.destinationValidate = 'disable';
    }
  }

  setSubscription() {
    this.originSub = this.originControl.valueChanges.subscribe(city => {
      if (city && city.name && city.id && (!this.originCity || this.originCity.id !== city.id)) {
        this.originCity = city;
        this.updateFullLocations(0);
      } else if (this.originCity && this.originCity.id && !city || !city.name) {
        this.originCity = null;
        this.updateFullLocations(0);
      }
    })
    this.destinationSub = this.destinationControl.valueChanges.subscribe(city => {
      if (city && city.name && city.id && (!this.destinationCity || this.destinationCity.id !== city.id)) {
        this.destinationCity = city;
        this.updateFullLocations(this.locations.length + 1);
      } else if (this.destinationCity && this.destinationCity.id && !city || !city.name) {
        this.destinationCity = null;
        this.updateFullLocations(this.locations.length + 1);
      }
    })
  }

  ngAfterViewInit() {
    document.getElementById(this.mapComponent.mapId).style.height = (document.getElementById('section-complete-height').clientHeight - 30) + 'px';
    this.mapComponent.map.setOptions({
      styles: [
        {
          "featureType": "poi",
          "stylers": [
            { "visibility": "off" }
          ]
        }
      ],
      disableDefaultUI: true
    });
    this.mapComponent.map.setZoom(6);
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.mapComponent.map);

    if (!!this.params.id) {
      this.getRoutePlan(this.params.id)
    }
  }

  getRoutePlan(id) {
    this.spinner.show();
    this.planningRouteService
      .getRoute(id)
      .subscribe(
        (route: PlanningRoute) => {
          this.spinner.hide();
          if (route.origin && route.origin.name)
            this.originOptions = { title: "Ciudad de origen", appearance: "outline", initialValue: route.origin.name, requireFullCity: true };

          if (route.destination && route.destination.name)
            this.destinationOptions = { title: "Ciudad de destino", appearance: "outline", initialValue: route.destination.name, requireFullCity: true };

          this.itineraries = [];
          for (const itinerary of route.itineraries) {
            this.planningRouteService
              .getItinerary((itinerary as any).id)
              .subscribe(
                (itinerary: RouteItinerary) => {
                  this.itineraries.push(itinerary);
                }
              )
          }
        }, (error) => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          this.goBack();
        }
      )
  }

  @ViewChild('map', { static: false }) mapComponent: RoutesMapComponent;
  public drop = (e: CdkDragDrop<string[]>) => {
    moveItemInArray(this.locationNames, e.previousIndex, e.currentIndex);
    moveItemInArray(this.locations, e.previousIndex, e.currentIndex);
    this.updateFullLocations(e.currentIndex);
  }
  public locations = [];
  public locationNames: FormControl[] = [];

  public appendLocation = (value?: any) => {
    if (!!value) {
      this.locationNames.push(new FormControl(value.address.name));
      this.locations.push(new FormControl(value, [Validators.required]));
    } else {
      this.locationNames.push(new FormControl('', [Validators.required]));
      this.locations.push(new FormControl('', [Validators.required]));
    }
  }

  public deleteLocation = (i: number) => {
    this.locationNames = [
      ...this.locationNames.slice(0, i),
      ...this.locationNames.slice(i + 1)
    ];
    this.locations = [
      ...this.locations.slice(0, i),
      ...this.locations.slice(i + 1)
    ];
    this.updateFullLocations(i);
  }

  cleanMap = () => {
    this.mapComponent.clearRoutes();
  }

  constructor(
    private googleService: GoogleService,
    private planningRouteService: PlanningRouteService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackBarService,
    public global: Global,
    public spinner: NgxSpinnerService,
  ) {
    this.activatedRoute.params.subscribe((params) => this.params = params);
  }

  getFullRouteLocations(): google.maps.LatLngLiteral[] {
    const locations = [];
    if (this.originCity && this.originCity.location)
      locations[0] = this.originCity.location;
    else
      locations[0] = null;

    locations.push(...this.locations.map(v => (v.value).location));

    if (this.destinationCity && this.destinationCity.location)
      locations.push(this.destinationCity.location)
    else
      locations.push(null);
    return locations;
  }

  updateMap() {
    this.cleanMap();
    this.googleService.getRouteData(this.getFullRouteLocations(), 'DRIVING', true).then(
      (data: { cargoDistancy: number, cargoEstimatedTime: number, cargoRoute: google.maps.DirectionsResult }) => {

        for (const route in data.cargoRoute.routes) {
          const routeData = data.cargoRoute.routes[route];

          let duration: number = 0;
          let distance: number = 0;
          routeData.legs.forEach((leg) => {
            duration += (leg.duration.value);
            distance += (leg.distance.value);
          });

          const optionRenderer = this.mapComponent.printDirection(
            data.cargoRoute,
            {
              polylineOptions: {
                strokeColor: '#000000',
                strokeOpacity: 0.2
              },
              markerOptions: { opacity: 0 },
            }
          );
          optionRenderer.setRouteIndex(parseInt(route));
        }
        this.renderer = this.mapComponent.printDirection(
          data.cargoRoute,
          {
            polylineOptions: {
              strokeColor: '#02d7dc',
              strokeOpacity: 1,
              zIndex: 1000
            },
            markerOptions: { opacity: 0 },
          }
        );
      }
    )
  }

  public onLocation(e: any, i: number) {
    this.locationNames[i].setValue(e.address.name);
    this.locations[i].setValue(e);
    this.updateFullLocations(i);
  }

  public updateFullLocations(setCenterAt: number = 0, checkHasLocation: boolean = true) {
    const origin = this.originCity;
    const destination = this.destinationCity;

    // No generar spam de ventanas checkeando las locaciones
    if (checkHasLocation) {
      this.checkHasLocation(origin, 'origin');
      this.checkHasLocation(destination, 'destination');
    }
    this.checkExistence(origin, destination);

    const locations = this.getFullRouteLocations();
    this.updateMarkers(locations);
    this.mapComponent.map.setCenter(locations[setCenterAt]);
    this.updateMap();
  }

  private checkHasLocation(city, type: 'origin' | 'destination') {
    // Do not open multiple times same location
    if (city && city.id && !city.location) {
      const config = new MatDialogConfig();
      config.data = { city };
      config.maxHeight = ModalEnum.MAX_HEIGHT;
      config.width = ModalEnum.SMALL_WIDTH;
      config.maxWidth = ModalEnum.MAX_WIDTH;
      config.autoFocus = false;

      this.matDialog
        .open(CityLocationFormComponent, config)
        .afterClosed()
        .subscribe(
          (result) => {
            if (!!result) {
              this.planningRouteService
                .updateCity(result)
                .subscribe(
                  (r: RouteBasicCity) => {
                    if (r && r.location) {
                      this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_CITY);
                      if (type === 'origin') {
                        this.originCity['location'] = r.location;
                      } else {
                        this.destinationCity['location'] = r.location;
                      }
                      this.updateFullLocations(0, false);
                    } else {
                      this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_CITY, undefined, 'error');
                    }
                  }, (error) => {
                    this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_CITY, undefined, 'error');
                  }
                );
            } else {
              this.snackbarService.openSnackBar(RouteMessages.MUST_SELECT_CITY, undefined, 'error');
              if (type === 'origin') this.originOptions = { title: "Ciudad de origen", appearance: "outline", initialValue: "", requireFullCity: true };
              else this.destinationOptions = { title: "Ciudad de destino", appearance: "outline", initialValue: "", requireFullCity: true };
            }
          }
        );
    }
  }

  private checkExistence(origin, destination) {
    if (!!origin && !!destination) {
      this.planningRouteService
        .getRoutes(origin.name, destination.name)
        .subscribe((routes: PlanningRoute[]) => {
          if (!!routes && routes.length > 0 && !this.params.id) {
            const config = new MatDialogConfig();
            config.data = {
              title: `Ya existe una ruta con origen ${origin.name} y destino ${destination.name}`,
              description: `Seleccione confirmar si desea ser redirigido a la edición de esa ruta en específico, de lo contrario cambie sea el origen o el destino`
            };
            config.maxHeight = ModalEnum.MAX_HEIGHT;
            config.width = ModalEnum.SMALL_WIDTH;
            config.maxWidth = ModalEnum.MAX_WIDTH;
            config.autoFocus = false;

            this.matDialog
              .open(DialogComponent, config)
              .afterClosed()
              .subscribe(
                (result) => {
                  if (!!result && !!result.state) {
                    this.router.navigate(['routes', 'planning', 'form', routes[0].id])
                  }
                }
              );
          }
        });
    }
  }

  public updateMarkers(locations) {
    this.mapComponent.removeAllMarkers();
    const origin = locations[0];
    const stops = locations.slice(1, -1);
    const destination = locations[this.locations.length + 1];

    if (origin && origin.lat && origin.lng) {
      this.mapComponent.marker(
        origin.lat,
        origin.lng,
        'origin',
        undefined,
        { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_origin.svg', scaledSize: new google.maps.Size(36, 36) }
      );
    }

    if (stops.length > 0) {
      let counter = 1;
      for (const stop of stops) {
        if (stop && stop.lat && stop.lng) {
          this.mapComponent.marker(
            stop.lat,
            stop.lng,
            'destination',
            undefined,
            { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_stop.svg', scaledSize: new google.maps.Size(24, 24) },
            {
              color: '#FFFFFF',
              text: `${counter++}`,
              fontSize: '14px',
              fontWeight: 'bold'
            }
          );
        }
      }
    }

    if (destination && destination.lat && destination.lng) {
      this.mapComponent.marker(
        destination.lat,
        destination.lng,
        'destination',
        undefined,
        { url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_destination.svg', scaledSize: new google.maps.Size(36, 36) }
      );
    }

    if (this.sites && this.sites.length > 0) {
      for (const i in this.sites) {
        const site = this.sites[i];
        this._addSite(site);
      }
    }
  }

  setCurrentItinerary(id: string): void {
    for (const itinerary of this.itineraries) {
      if (itinerary.id == id) {
        this.borderSelectedCard(id);
        this.originCity = itinerary.originPoint;
        this.destinationCity = itinerary.destinationPoint;
        this.sites = itinerary.authorizedStops as any;
        this.locations = [];
        this.locationNames = [];
        if (itinerary.controlPoints && itinerary.controlPoints.length) {
          itinerary.controlPoints.forEach((controlPoint) => {
            const location = { location: controlPoint.location, address: { name: controlPoint.name } };
            this.appendLocation(location);
          });
        }
        const locations = this.getFullRouteLocations();
        this.updateMarkers(locations);
        this.mapComponent.map.setCenter(locations[0]);
        this.updateMap();
        break;
      }
    }
  }

  private borderSelectedCard(id: string): void {
    const cards = document.getElementsByClassName('itinerary-card');
    for (let i = 0; i < cards.length; i++) {
      (cards[i] as HTMLElement).style.border = '';
    }
    document.getElementById(id).style.border = '2px solid aqua';
  }

  getCurrentItinerary(): RouteItinerary {
    if (!this.renderer) {
      this.snackbarService.openSnackBar(RouteMessages.MUST_PICK_ITINERARY, "Aceptar", "error")
      return;
    }

    const origin = this.originControl.value;
    const destination = this.destinationControl.value;
    const route = this.renderer.getDirections().routes[0];

    const controlPoints: RouteControlPoint[] = [];
    const authorizedStops: RouteReferenceAuthorizedPoint[] = [];

    for (const id in this.sites) {
      const site = this.sites[id];
      if (!!site) {
        authorizedStops.push({
          id: site.id,
          fingerprint: {
            userId: this.authService
              .getUserSession()
              .information.document,
            userName: this.authService.getUserSession().information.name,
            date: DateManager.dateToString(new Date()),
          }
        })
      }
    }

    for (const i in this.locations) {
      const location = this.locations[i].value;
      const controlPoint: RouteControlPoint = {
        location: location.location,
        name: location.address.name,
        address: location.address.name,
        fingerprint: {
          userId: this.authService
            .getUserSession()
            .information.document,
          userName: this.authService.getUserSession().information.name,
          date: DateManager.dateToString(new Date()),
        }
      };
      controlPoints.push(controlPoint);
    }

    const itinerary = {
      name: `${origin.name} - ${destination.name}`,
      overviewPolylines: this.renderer.getDirections().routes[0].overview_polyline,
      estimatedTime: route.legs[0].duration.value,
      estimatedDistance: route.legs[0].distance.value,
      mustSleep: route.legs[0].duration.value > (14 * 60 * 60),
      originPoint: {
        id: origin.id,
        name: origin.name,
        location: origin.location
      },
      destinationPoint: {
        id: destination.id,
        name: destination.name,
        location: destination.location
      },
      authorizedStops,
      controlPoints
    };

    return itinerary;
  }

  goBack() {
    this.mode == 'RUTE' ? history.back() : this.siteButton('RUTE');
  }

  createRoute(id) {
    this.setCurrentItinerary(id);
    const itinerary = this.getCurrentItinerary();
    const sites = this.sites;
    if (!!itinerary) {
      itinerary.id = id;
      const config: MatDialogConfig = new MatDialogConfig();
      config.data = { itinerary, sites };
      config.maxHeight = ModalEnum.MAX_HEIGHT;
      config.width = ModalEnum.SMALL_WIDTH;
      config.maxWidth = ModalEnum.MAX_WIDTH;
      config.autoFocus = false;
      const dialog = this.matDialog.open(ConfirmRouteCreationComponent, config);
      dialog.afterClosed().subscribe(
        (result) => {
          if (result) this.selectItinerary(itinerary.id);
        });
    }
  }

  selectItinerary(itineraryId) {
    this.spinner.show();
    this.planningRouteService.selectItinerary(itineraryId).subscribe(
      (result: RouteItinerary) => {
        if (result && result.id) {
          this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_ITINERARY);
          let currentItineraries = [...this.itineraries];
          this.itineraries = [];
          for (const itinerary of currentItineraries) {
            this.planningRouteService
              .getItinerary((itinerary as any).id)
              .subscribe(
                (itinerary: RouteItinerary) => {
                  this.itineraries.push(itinerary);
                }
              )
          }
        }
        else this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_ITINERARY, undefined, 'error');
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(RouteMessages.ERROR_SELECT_ITINERARY, undefined, 'error');
      }
    );
  }

  public getDurationLabel(seconds: number): string {
    const durationMsg = [];
    const duration = DateManager.durationFormat(seconds, "seconds");
    if (duration.years > 0) durationMsg.push(`${duration.years} ${duration.years > 1 ? 'años' : 'año'}`);
    if (duration.months > 0) durationMsg.push(`${duration.months} ${duration.months > 1 ? 'meses' : 'mes'}`);
    if (duration.days > 0) durationMsg.push(`${duration.days} d`);
    if (duration.hours > 0) durationMsg.push(`${duration.hours} h`);
    if (duration.minutes > 0) durationMsg.push(`${duration.minutes} min`);

    return durationMsg.join(' ');
  }

  private addGasStation(position: LatLngLiteral, name: string): void {
    this.mapComponent.marker(
      position.lat,
      position.lng,
      "location",
      name,
      {
        url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_gas.svg',
        scaledSize: new google.maps.Size(24, 24)
      }
    );
  }

  private addParking(position: LatLngLiteral, name: string): void {
    this.mapComponent.marker(
      position.lat,
      position.lng,
      "location",
      name,
      {
        url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_parking.svg',
        scaledSize: new google.maps.Size(24, 24)
      }
    );
  }

  private addRestaurant(position: LatLngLiteral, name: string): void {
    this.mapComponent.marker(
      position.lat,
      position.lng,
      "location",
      name,
      {
        url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_restaurant.svg',
        scaledSize: new google.maps.Size(24, 24)
      }
    );
  }

  private addHotel(position: LatLngLiteral, name: string): void {
    this.mapComponent.marker(
      position.lat,
      position.lng,
      "location",
      name,
      {
        url: '/assets/svg/icons/tl_ico__pin_google_maps_trip_hotel.svg',
        scaledSize: new google.maps.Size(24, 24)
      }
    );
  }

  siteButton(site: 'RUTE' | '1' | '2' | '3' | '4') {
    site == this.mode ? this.mode = 'RUTE' : this.mode = site;
    this.form.get('search').setValue('');
    this.searchResult = [];
    for (let i = 0; i < document.getElementsByClassName('site-type-button').length; i++) {
      (document.getElementsByClassName('site-type-button')[i] as HTMLElement).style.background = '#FFFFFF';
    }
    if (this.mode != 'RUTE') {
      const type =
        site == '1' ? 'hotel' :
          site == '2' ? 'fuel' :
            site == '3' ? 'parking' :
              site == '4' ? 'restaurant' : undefined;
      document.getElementById(`${type}-button`).style.background = '#E3DCFF';
      this.search();
    }

  }

  search() {
    const name = this.form.get('search').value;
    this.spinner.show();
    this.planningRouteService
      .getAuthorizedPoints(name ? { active: "true", pointType: this.mode, stopName: name } : { active: 'true', pointType: this.mode })
      .subscribe(
        (points: Array<RouteAuthorizedPoint>) => {
          this.spinner.hide();
          !!points && points.length ? this.searchResult = points.slice(0, 10) : this.searchResult = [];
        },
        (error) => {
          this.spinner.hide();
          this.searchResult = [];
        }
      );
  }

  _addSite(point: RouteAuthorizedPoint) {
    switch (point.details.pointType.id) {
      case '1': this.addHotel(point.location, point.details.name); break;
      case '2': this.addGasStation(point.location, point.details.name); break;
      case '3': this.addParking(point.location, point.details.name); break;
      case '4': this.addRestaurant(point.location, point.details.name); break;
      default:
    }
  }

  addSite(index: number) {
    const point = this.searchResult[index];
    if (!this.sites) this.sites = [];
    this.sites[point.id] = point;
    this.sites.length++;
    this._addSite(point);
  }

  removeSite(index: number) {
    const point = this.searchResult[index];
    delete this.sites[point.id];
    this.sites.length--;
    this.updateFullLocations(0);
  }

  removeSiteFromCurrentSiteList(index: string) {
    delete this.sites[index];
    this.sites.length--;
    this.updateFullLocations(0);
  }

  createAuthorizedPoint() {
    const config = new MatDialogConfig();
    config.data = {
      type: this.mode
    }
    config.maxHeight = ModalEnum.MAX_HEIGHT;
    config.width = ModalEnum.LARGE_WIDTH;
    config.maxWidth = ModalEnum.MAX_WIDTH;
    config.autoFocus = false;
    this.matDialog.open(AuthorizedSitesFormComponent, config).afterClosed().subscribe(
      (result) => result && result.state && this.search()
    );
  }

  async createItinerary() {
    if (this.originValidate !== 'disable') this.originValidate = 'touched';
    if (this.destinationValidate !== 'disable') this.destinationValidate = 'touched';
    if (!this.getFullRouteLocations()[0]) {
      this.snackbarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'ciudad de origen'), undefined, 'alert');
      return;
    }
    if (!this.getFullRouteLocations()[1]) {
      this.snackbarService.openSnackBar(Fmt.string(FormMessages.MISSING_FIELD, 'ciudad de destino'), undefined, 'alert');
      return;
    }
    this.renderer.setRouteIndex(0);
    const itinerary = this.getCurrentItinerary();
    const title = await this.getTitle();
    if (title)
      itinerary.name = title;
    this.spinner.show();
    this.planningRouteService
      .createRouteItinerary(itinerary)
      .subscribe(
        (result: RouteItinerary) => {
          this.spinner.hide();
          if (result && result.id) {
            this.snackbarService.openSnackBar(RouteMessages.SUCCESS_SELECT_ITINERARY);
            this.itineraries.push(result);
          } else this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');

        }, (error) => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
        }
      );
  }

  private async getTitle(): Promise<string> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: `Añadir nombre`,
      description: "Si deseas puedes añadir el nombre del itinerario, si lo haces se mostrará ese nombre, sino se mostrará un nombre por defecto.",
      inputText: true,
      maxLength: 50,
      placeholder: 'Nombre del itinerario',
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.matDialog.open(DialogComponent, dialogConfig);
    const result = await dialogRef.afterClosed().toPromise();
    if (result && result.state && result.inputText)
      return result.inputText;
    return "";
  }

  ngOnDestroy() {
    if (this.originSub) this.originSub.unsubscribe();
    if (this.destinationSub) this.destinationSub.unsubscribe();
  }
}
