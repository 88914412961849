<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">
  <div class="col-md-12">
    <div class="row row-actions-header" *ngIf="!hiddenFields?.actionsForm">
      <div class="col-md-3 offset-md-9 text-right">
        <button class="btn button-width-auto" type="button" *ngIf="!edit && canEdit" (click)="toggleEdit(true)">
          <i class="fas fa-edit font-primary-dark"></i>
          <span class="font-primary-dark">
            Editar
          </span>
        </button>
        <button class="btn button-width-auto" type="button" *ngIf="edit" (click)="toggleEdit(false)">
          <i class="fas fa-edit font-primary-dark"></i>
          <span class="font-primary-dark">
            Cancelar
          </span>
        </button>
      </div>
    </div>
    <div class="row align-items-baseline">

      <div *ngIf="(form.get('cargoOwner').disabled && form.get('ministry').value) || (form?.get('idCompany')?.disabled && !form.get('ministry').value) && !hiddenFields?.companyId" class="col-md-3">
        <div class="field__card" *ngIf="!edit">
          <label class="field__card--label">
            Propietario del servicio
          </label>
          <span class="field__card--value">
            {{cargo?.cargoOwner?.documentNumber ? (cargo?.cargoOwner?.documentNumber | companyName) : cargo?.idCompany ? (cargo.idCompany | companyName) : '-' }}
          </span>
        </div>
      </div>
      <div class="col-md-3" *ngIf="form.get('cargoOwner').disabled && cargo?.ministry && !edit">
        <div class="field__card">
          <label class="field__card--label">
            Dirección
          </label>
          <span class="field__card--value">
            {{branchOfficeName? branchOfficeName : '-'}}
          </span>
        </div>
      </div>
      <div class="col-12" [ngClass]="{'p-0': form.get('ministry').value}" *ngIf="!form.get('cargoOwner').disabled || !form?.get('idCompany')?.disabled" >
        <div class="row" *ngIf="form.get('ministry').value; else ownerWithoutMinistry">
          <app-select-company class="col-md-6" [options]="branchOfficeOptions" [inputFormControl]="branchOfficeControl">
          </app-select-company>
          <div class="col-md-6" *ngIf="form.get('cargoOwner.name').value">
            <app-third-party-consignment [inputFormControl]="cargoOwnerGroup"
              [simpleAddress]="{title:'Request',companyId:form.get('cargoOwner.documentNumber')?.value}"
              (emitToParent)="onSelectAddress($event)"></app-third-party-consignment>
          </div>
        </div>
        <ng-template #ownerWithoutMinistry>
          <app-select-company [options]="companyOptions" [inputFormControl]="companyControl">
          </app-select-company>
        </ng-template>
      </div>
      <div class="col-md-3" *ngIf="form?.get('cargoOwner.name')?.value 
      && cargo?.ministry
      && form.get('cargoOwner').disabled && !edit && !hiddenFields?.branchOfficeCode">
        <div class="field__card">
          <label class="field__card--label">
            Código de sede
          </label>
          <span class="field__card--value">
            {{branchOfficeCode? branchOfficeCode : '-'}}
          </span>
        </div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="!hiddenFields?.consecutive">
      <ng-container>
        <div class="field__card">
          <label class="field__card--label">Consecutivo</label>
          <span class="field__card--value">
            {{ cargo.consecutive !=0 ? (cargo.consecutive) : '-' }}
          </span>
        </div>
      </ng-container>
    </div>
    <div class="col-md-3" *ngIf="!hiddenFields?.cargoState">
      <ng-container>
        <div class="field__card">
          <label class="field__card--label">Estado</label>
          <span class="field__card--value">
            {{ cargoState}}
          </span>
        </div>
      </ng-container>
    </div>
    <!-- Tiempo estimado -->
    <div class="col-md-3" *ngIf="!hiddenFields?.estimatedTime">
      <ng-container>
        <div class="field__card">
          <label class="field__card--label">Tiempo estimado</label>
          <span class="field__card--value">
            {{cargo?.estimatedTime ? (cargo?.estimatedTime | duration) : '-'}}
          </span>
        </div>
      </ng-container>
    </div>
    <!-- Tiempo real -->
    <div class="col-md-3" *ngIf="cargo?.realTime && !hiddenFields?.realTime">
      <ng-container>
        <div class="field__card">
          <label class="field__card--label">Tiempo real</label>
          <span class="field__card--value">
            {{cargo.realTime | duration}}
          </span>
        </div>
      </ng-container>
    </div>
    <!-- Distancia -->
    <div class="col-md-3" *ngIf="cargo?.realTime && !hiddenFields?.distancy">
      <ng-container>
        <div class="field__card">
          <label class="field__card--label">Distancia</label>
          <span class="field__card--value">
            {{cargo?.distancy ? (cargo?.distancy | mToKm) : '-'}}
          </span>
        </div>
      </ng-container>
    </div>
    <div class="row align-items-baseline" *ngIf="!hiddenFields?.riskProfile">
      <div class="col-md-3">
        <ng-container>
          <div class="field__card">
            <label class="field__card--label">Perfil de riesgo del servicio</label>
            <span class="field__card--value">
              {{cargo?.riskProfile?.name ? cargo?.riskProfile?.name : '-'}}
            </span>
          </div>
        </ng-container>
      </div>
      <!-- Documento remitente Nº -->
      <div class="col-md-3">
        <mat-form-field appearance="standard" *ngIf="!form.get('numberDocumentSender').disabled">
          <mat-label>Documento remitente</mat-label>
          <input matInput type="text" formControlName="numberDocumentSender">
        </mat-form-field>
        <ng-container *ngIf="form.get('numberDocumentSender').disabled">
          <div class="field__card">
            <label class="field__card--label">Documento remitente Nº</label>
            <span class="field__card--value">
              {{utils.isDefined(cargo.numberDocumentSender) ? cargo.numberDocumentSender : '-'}}
            </span>
          </div>
        </ng-container>
      </div>
      <!-- N° de Contenedor -->
      <div class="col-md-3" *ngIf="!isEscortedService">
        <mat-form-field appearance="standard" *ngIf="form.get('container').enabled; else readOnlyContainer">
          <mat-label>N° de Contenedor</mat-label>
          <input matInput type="text" formControlName="container" angularFormsMask="CCCCDDDDDDD" containerNumber>
          <mat-error *ngIf="form.get('container').errors?.pattern">{{
            utils.giveMessageError('INVALID_CONTAINER_GENERAL') }}</mat-error>
        </mat-form-field>
        <ng-template #readOnlyContainer>
          <div class="field__card">
            <label class="field__card--label">N° de Contenedor</label>
            <span class="field__card--value">
              {{cargo?.container ? cargo?.container : '-'}}
            </span>
          </div>
        </ng-template>
      </div>
      <!-- Fecha de expiracion del contenedor -->
      <div class="col-md-3"
        *ngIf="(['Importación','Nacional','Urbana'].includes(cargo?.cargoModel?.tripType?.name)) && !isEscortedService">
        <mat-form-field appearance="standard"
          *ngIf="!form.get('containerExpirationDate').disabled; else readOnlyContainerExpiration">
          <mat-label>Fecha de expiración de Contenedor<span class="asterisk">*</span></mat-label>
          <input matInput type="text" [formControl]="dateField" (click)="datePicker.open()" [matDatepicker]="datePicker"
            [matDatepickerFilter]="fromNowFilter" readonly>
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-error *ngIf="dateField.errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>

        <ng-template #readOnlyContainerExpiration>
          <div class="field__card">
            <label class="field__card--label">Fecha de expiración de Contenedor</label>
            <span class="field__card--value">
              {{utils.isDefined(cargo.containerExpirationDate) ? (cargo.containerExpirationDate) : '-'}}
            </span>
          </div>
        </ng-template>
      </div>
      <!-- Precinto -->
      <div class="col-md-3" *ngIf="!isEscortedService">
        <mat-form-field appearance="standard" *ngIf="!form.get('seal').disabled; else readOnlySeal">
          <mat-label>Precinto</mat-label>
          <input matInput type="text" formControlName="seal">
        </mat-form-field>
        <ng-template #readOnlySeal>
          <div class="field__card">
            <label class="field__card--label">Precinto</label>
            <span class="field__card--value">
              {{cargo?.seal ? cargo?.seal : '-'}}
            </span>
          </div>
        </ng-template>
      </div>

      <!-- Booking -->
      <div class="col-md-3" *ngIf="!isEscortedService">
        <mat-form-field appearance="standard" *ngIf="!form.get('booking').disabled; else readOnlyBooking">
          <mat-label>Booking</mat-label>
          <input matInput type="text" formControlName="booking">
        </mat-form-field>
        <ng-template #readOnlyBooking>
          <div class="field__card">
            <label class="field__card--label">Booking</label>
            <span class="field__card--value">
              {{cargo?.booking ? cargo?.booking : '-'}}
            </span>
          </div>
        </ng-template>
      </div>
      <div class="col-md-5 my-2" *ngIf="!isEscortedService">
        <ng-container *ngIf="form.get('ministry').enabled">
          <div class="d-flex justify-content-start align-items-center flex-wrap w-100 h-100">
            <label class="text-strong">¿Requiere manifiesto?&nbsp;&nbsp;</label>
            <mat-button-toggle-group formControlName="ministry" #group="matButtonToggleGroup" (change)="onToggleChange($event)">
              <mat-button-toggle [value]="true">Si</mat-button-toggle>
              <mat-button-toggle [value]="false">No</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </ng-container>
        <ng-container *ngIf="form.get('ministry').disabled">
          <div class="field__card">
            <label class="field__card--label">¿Requiere Manifiesto?</label>
            <span class="field__card--value">
              {{ cargo.ministry? 'Si' : 'No' }}
            </span>
          </div>
        </ng-container>
      </div>
      <div class="col-md-3" *ngIf="cargo.ministry && cargo.manifest">
        <ng-container>
          <div class="field__card">
            <label class="field__card--label">Manifiesto</label>
            <span class="field__card--value">
              {{ cargo.manifest }}
            </span>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
  <br>
  <!-- Acciones -->
  <div class="row" *ngIf="edit && !hiddenFields?.actionsForm">
    <div class="col-md-12 text-center">
      <mat-card-actions class="actions-card">
        <button mat-raised-button color="primary" class="button-width-auto" type="submit">
          Guardar
        </button>
      </mat-card-actions>
    </div>
  </div>
</form>