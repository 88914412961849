import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Permission } from 'src/app/core/resources/permission';
@Component({
  selector: 'app-manual-data-vehicle-input',
  templateUrl: './manual-data-vehicle-input.component.html',
  styleUrls: ['./manual-data-vehicle-input.component.scss']
})
export class ManualDataVehicleInputComponent implements OnInit {
  // Inputs
  @Input() type: 'alert' | 'manualButton';
  @Input() outServiceDbs: string;
  @Input() dataType: string;
  @Input() checkId: boolean;
  @Input() haveData: boolean;
  @Input() allowedToActions: boolean;
  @Input() additionalText: string;
  // Outputs
  @Output() emitServiceTrigger = new EventEmitter<string>();
  @Output() newSecurityStudy = new EventEmitter<boolean>();
  //Properties
  permission = Permission;
  constructor(
    public authService: AuthService,
    public permissionRole: PermissionRole
  ) { }

  ngOnInit() {
  }

  get canOverwriteVehicleInfo(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.administration.module,
      this.permission.administration.editFieldsRUNT
    );
  }

}
