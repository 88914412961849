<div class="summary">
  <div class="row">
    <div class="col-12 font-size-x2 text-interactive" (click)="showPeopleCards = !showPeopleCards">
      <i *ngIf="showPeopleCards" class="fas fa-caret-up" matTooltip="Ocultar"></i>
      <i *ngIf="!showPeopleCards" class="fas fa-caret-down" matTooltip="Mostrar"></i>
      <i class="fas fa-user font-size-x1" matTooltip="Personas Relacionadas"></i>
    </div>
  </div>
</div>

<div class="people-container" [ngClass]="{'people-container-hide': !showPeopleCards}">
  <div class="summary" *ngIf="driver">
    <mat-card>
      <mat-card-header class="flex-wrap align-items-center justify-content-center">
        <img mat-card-avatar *ngIf="!!driver.profilePictureUrl; else defaultImage" [src]="driver.profilePictureUrl" />
        <ng-template #defaultImage><img mat-card-avatar src="/assets/svg/icons/icon-profile.png" /></ng-template>
        <mat-card-title class="titles title-name" matTooltip="Nombre">{{ driver.information.name }}</mat-card-title>
        <mat-card-subtitle class="titles">Conductor</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content *ngIf="showPersonalInformation">
        <br>
        <div class="row">
          <div class="col-12 text-strong">
            <b>Información Básica</b>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" role="button" matTooltip="Tipo de documento">
            <i class="color-secondary-dark fas fa-address-card"></i>
            {{ driver.information?.documentTypeName }}
          </div>

          <div class="col-md-6" role="button" matTooltip="Número de documento">
            <i class="color-secondary-dark fas fa-hashtag"></i>
            {{ driver.information?.document }}
          </div>

          <div class="col-md-6" matTooltip="Teléfono">
            <i class="color-secondary-dark fas fa-mobile-retro"></i>
            {{ driver.phone | phoneNumber }}
          </div>

          <div class="text-strong col-md-6 text-interactive" role="button"
            (click)="vehicleManager.updateInformation(vehicle)" matTooltip="Ver detalles del vehículo">
            <i class="color-secondary-dark fas fa-truck"></i>
            {{vehicle?.id}}
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-12 text-strong">
            <b>GPS</b>
          </div>
        </div>

        <div class="row">
          <div class="text-strong col-md-6 text-interactive" role="button" (click)="openGPSSite(vehicle?.gps)"
            matTooltip="Ir a la página del GPS">
            <i class="color-secondary-dark fas fa-map-marker-alt"></i>
            {{vehicle?.gps?.gpsType}}
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 text-interactive" role="button" matTooltip="Usuario del GPS"
            (click)="copy(vehicle?.gps?.userGps)">
            <i class="color-secondary-dark fas fa-user"></i>
            {{ vehicle?.gps?.userGps? vehicle.gps.userGps : '-' }}
            <i class="fas fa-copy"></i>
          </div>

          <div class="col-md-6 text-interactive" role="button" matTooltip="Password del GPS"
            (click)="copy(vehicle?.gps?.passwordGps)">
            <i class="color-secondary-dark fas fa-unlock"></i>
            {{ vehicle?.gps?.passwordGps? vehicle.gps.passwordGps : '-' }}
            <i class="fas fa-copy"></i>
          </div>
        </div>
        <div class="row" *ngIf="hasSatrackGps">
          <div class="col-md-6 text-interactive" role="button" matTooltip="Tipo de GPS"
            (click)="copy(vehicle?.gps?.isPortableUnit)">
            <i class="color-secondary-dark fas fa-map-pin"></i>
            {{ vehicle?.gps?.isPortableUnit ? 'Unidad portable': 'Unidad integrada' }}
            <i class="fas fa-copy"></i>
          </div>

          <div class="col-md-6 text-interactive" role="button" matTooltip="ID del GPS"
            (click)="copy(vehicle?.gps?.gpsId)" *ngIf="vehicle?.gps?.isPortableUnit">
            <i class="color-secondary-dark fas fa-unlock"></i>
            {{ vehicle?.gps?.gpsId }}
            <i class="fas fa-copy"></i>
          </div>
        </div>
        <div class="row p-3">
             <!-- *ngIf="cargo?.secondDriver && !cargo?.activeSecondDriver" -->
          <button
            *ngIf="cargo?.secondDriver && !cargo?.secondDriverActive"
            permission-display 
            [module]="permission.cargo.module"
            [functionality]="permission.cargo.activateSecondDriver"
            mat-raised-button
            class="m-0 secondary-sharp summary__actions col-12 cursor-pointer"
            color="primary"
            (click)="getDriver()"
            >
              <div><span>Activar segundo conductor</span></div>
            </button>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>