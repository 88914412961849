

<div class="card-alert" *ngIf="type === 'alert' && checkId && !haveData">
    <div class="card-alert__icon">
        <i class="fas fa-exclamation-triangle"></i>
    </div>
    <div>
        La base de datos del {{ outServiceDbs}} está temporalmente fuera de servicio 
        <span *ngIf="additionalText">
         {{additionalText}}
        </span>
        <span *ngIf="allowedToActions">
            <span>
                , pero puedes
            </span>
            <span *ngIf="dataType !== 'arl' && dataType !=='eps'">
                ingresar la información manualmente <u class="text-bold cursor-pointer" (click)="emitServiceTrigger.emit(dataType)">aquí</u> o
            </span>
            <span *ngIf="dataType">
                lanzar un nuevo <u class="text-bold cursor-pointer" (click)="newSecurityStudy.emit(true)">estudio de seguridad</u>.
            </span>
        </span>
        
    </div>
</div>
<button 
    *ngIf="type === 'manualButton' && ((!checkId && authService?.getCompanySaaS()?.allowCreatingWithoutTruora && !haveData) || canOverwriteVehicleInfo)" 
    class="manual-info-button button-width-auto secondary-sharp" 
    mat-raised-button 
    color="primary"
    (click)="emitServiceTrigger.emit(dataType)">
    <span *ngIf="dataType && dataType ==='license'; else editData">
        <i class="fas fa-plus-circle"></i>
        Agregar licencia
    </span>
    <ng-template #editData>
        Editar datos 
        <i class="fas fa-pencil-alt"></i>
    </ng-template>
</button>