<div class="position-relative">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="enter-consecutives-container">
        <p class="title-operation" *ngIf="dialogParams?.title">{{dialogParams.title}}</p>
        <p class="subtitle-operation" *ngIf="dialogParams?.subtitle">{{dialogParams?.subtitle}}</p>
        <mat-form-field appearance="outline" class="my-2">
            <mat-label>{{dialogParams?.label ? dialogParams.label : 'Servicios'}}<span
                    class="asterisk">*</span></mat-label>
            <input massivePayments matInput type="text" placeholder="Digita los consecutivos de los servicios"
                [formControl]="consecutivesControl">
            <mat-error *ngIf="consecutivesControl?.errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <div class="button-container">
            <button mat-raised-button class="primary-secondary button-width-auto" color="primary"
                (click)="dialogRef.close()">
                Cancelar
            </button>
            <button mat-raised-button color="primary" class="button-width-auto bg-color-primary"
                (click)="processConsecutives()">
                {{dialogParams?.confirmButton ? dialogParams.confirmButton : 'Confirmar'}}
            </button>
        </div>
    </div>
</div>