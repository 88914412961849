import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManualCreationCargoService } from '../manual-creation-cargo/manual-creation-cargo.service';
import { Catalog } from 'src/app/core/interfaces/catalog';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { DatePipe } from '@angular/common';
import { Cargo, CargoRequest } from 'src/app/core/interfaces/cargo';
import { CalculatorMeasurementsComponent } from '../manual-creation-cargo/components/calculator-measurements/calculatorMeasurements.component';
import { MatButtonToggleChange, MatDatepickerInput, MatDatepickerInputEvent, MatDialog, MatDialogConfig, MatSelectChange } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { Utils } from 'src/app/core/resources/utils';
import { Tag } from 'src/app/core/interfaces/tag';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Destination } from 'src/app/core/interfaces/destination';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/core/resources/permission';
import { environmentTypes } from 'src/app/core/resources/typeEnvironment';

@Component({
  selector: 'app-create-cargo',
  templateUrl: './create-cargo.component.html',
  styleUrls: ['./create-cargo.component.scss']
})
export class CreateCargoComponent implements OnInit {
  permission = Permission;
  vehicleTypeControl: FormControl = new FormControl();
  bodyworkTypeControl: FormControl;
  form: FormGroup;
  tags: Tag[] = [];
  tagControl: FormControl;
  readonly tripTypes: { name: TRIP_TYPES }[] = [
    { name: TRIP_TYPES.IMPORT },
    { name: TRIP_TYPES.EXPORT },
    { name: TRIP_TYPES.NATIONAL },
    { name: TRIP_TYPES.URBAN },
    { name: TRIP_TYPES.LAST_MILE }
  ];
  tripTypesEnabled: { name: TRIP_TYPES }[];

  readonly loadDateOptions = { min: new Date() };
  optionsCompany = {
    title: 'Contratista del servicio',
    optionAll: false,
    appearance: 'outline'
  };
  originCityOptions = {
    title: 'Origen (Municipio o Ciudad)',
    appearance: 'outline'
  };
  destinationCityOptions = {
    title: 'Destino (Municipio o Ciudad)',
    appearance: 'outline'
  }
  optionsTypeVehicle: OptionsAutocomplete = {
    showAutocomplete: true
  };
  optionsBodyworkType: OptionsAutocomplete = { 
    title: 'Tipo de carrocería'}
  formValidate = '';

  endDateOptions: { min: Date }[];
  subscriptions: Subscription = new Subscription();
  environmentTypes: string[] = environmentTypes;
  constructor(
    private manualCreationCargoService: ManualCreationCargoService,
    private spinner: NgxSpinnerService,
    public datePipe: DatePipe,
    public dialogMaterial: MatDialog,
    public utils: Utils,
  ) { }

  ngOnInit() {
    this.initForm();
    this.subscribeCities();
    this.getTags();
    this.vehicleTypeControl.valueChanges.subscribe((vehicle: VehicleCargo) => {
      if (vehicle && vehicle.id) this.optionsBodyworkType['initialVehicleTypeId'] = vehicle.id;
      this.optionsBodyworkType['initialValue'] = '';
      this.optionsBodyworkType = { ...this.optionsBodyworkType };
      this.bodyworkTypeControl.setValue('');
    });
  }

  private initForm() {
    this.form = new FormGroup({
      company: new FormControl(null, [Validators.required]),
      ministry: new FormControl(true, Validators.required),
      doc: new FormControl(),
      tripType: new FormControl(null, [Validators.required]),
      origin: new FormControl(null, [Validators.required]),
      dateLoad: new FormControl(new Date(), [Validators.required]),
      destinations: new FormArray([
        new FormGroup({
          destination: new FormControl(null, [Validators.required]),
          downloadDate: new FormControl(),
        })
      ]),
      quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
      refrigerated: new FormControl(),
      zone: new FormControl('private'),
      quality: new FormControl('Seco'),
      unit: new FormControl('1'),
      observation: new FormControl(),
      observationDriver: new FormControl(),
      cost: new FormControl(null, [Validators.required, Validators.min(1)]),
      weight: new FormControl(null, Validators.min(1)),
      measurements: new FormControl(null, Validators.min(0.0001)),
      labels: new FormControl([])
    });
    this.tripTypesEnabled = this.utils.clone(this.tripTypes);
    this.tagControl = new FormControl('');
    this.vehicleTypeControl = new FormControl('', Validators.required);
    this.bodyworkTypeControl = new FormControl('', Validators.required);
    this.endDateOptions = [{
      min: new Date()
    }];
  }

  private subscribeCities() {
    const originSub = this.form.get('origin').valueChanges.subscribe(() => this.checkTripTypes());
    const destinationsSub = this.destinations.valueChanges.subscribe(() => this.checkTripTypes());
    this.subscriptions.add(originSub);
    this.subscriptions.add(destinationsSub);
  }

  private checkTripTypes() {
    const originCity: { id: string, name: string } = this.form.get('origin').value;
    const destinationCities: { id: string, name: string }[] = this.destinations.value.map(destination => destination.destination);
    if (!originCity || !originCity.name || !destinationCities.every(city => !!city && !!city.name)) {
      this.tripTypesEnabled = this.utils.clone(this.tripTypes);
      return;
    }
    if (destinationCities.every(city => city.name === originCity.name))
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.URBAN },
        { name: TRIP_TYPES.LAST_MILE }
      ];
    else
      this.tripTypesEnabled = [
        { name: TRIP_TYPES.IMPORT },
        { name: TRIP_TYPES.EXPORT },
        { name: TRIP_TYPES.NATIONAL }
      ];

    if (!this.tripTypesEnabled.includes(this.form.get('tripType').value))
      this.form.get('tripType').setValue(null);
  }

  private getTags(): void {
    this.manualCreationCargoService.getLabelTags().subscribe(
      (success) => {
        if (success && success.length) this.tags = success;
        else this.tags = [];
      }, () => {
        this.tags = [];
      }
    )
  }

  selectTag(event: MatSelectChange): void {
    const selectedTag: string = event.value;
    const labels: string[] = this.form.get('labels').value;
    if (!labels.includes(selectedTag)) {
      labels.push(selectedTag);
      this.form.patchValue({ labels });
    }
    this.tagControl.setValue(null);
  }
  clearTag(index: number): void {
    const labels: string[] = this.form.get('labels').value;
    if (labels.length > index) {
      labels.splice(index, 1);
      this.form.patchValue({ labels });
    }
  }

  get destinations(): FormArray {
    return this.form.get('destinations') as FormArray;
  }
  addDestination(): void {
    this.destinations.push(new FormGroup({
      destination: new FormControl(null, [Validators.required]),
      downloadDate: new FormControl(),
    }));
    this.endDateOptions.push({ min: new Date() });
    this.updateDestinationDates();
  }
  deleteDestination(index: number): void {
    this.destinations.removeAt(index);
    this.endDateOptions.splice(index, 1);
    this.updateDestinationDates();
  }
  updateDestinationDates() {
    let minDate: Date = new Date();
    if (this.form.get('dateLoad').value)
      minDate = new Date(this.form.get('dateLoad').value);
    this.destinations.controls.forEach((destination, index) => {
      this.endDateOptions[index].min = minDate;
      const downloadDate: Date = destination.get('downloadDate').value;
      if (downloadDate) {
        if (DateManager.isBefore(downloadDate, minDate))
          destination.get('downloadDate').setValue(minDate);
        else
          minDate = downloadDate;
      }
    })
  }

  openDialogCalculatorMeasurements() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialogMaterial.open(CalculatorMeasurementsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(totalSize => {
      if (totalSize)
        this.form.get('measurements').setValue(totalSize);
    });
  }

  createCargo() {
    this.form.markAllAsTouched();
    this.formValidate = 'touched';
    const dateLoad = this.datePipe.transform(this.form.get('dateLoad').value, 'yyyy-MM-dd HH:mm Z', 'UTC');
    const cargo: CargoRequest = {
      dateLoad,
      idCompany: this.form.get('company').value.companyId,
      ministry: this.form.get('ministry').value,
      numberDocumentSender: this.form.get('doc').value,
      labels: this.form.get('labels').value,
      cargoModel: {
        tripType: this.form.get('tripType').value
      },
      cargoFeature: {
        productType: {
          code: null,
          name: null,
          type: null,
          description: null
        },
        vehicleType: {
          name: this.vehicleTypeControl.value.name,
          quantity: 1,
          bodyWorkType: {
            name: this.bodyworkTypeControl.value.name,
          },
          quality: this.getValidWork(this.vehicleTypeControl.value.name, this.bodyworkTypeControl.value.name) ? this.form.get('quality').value : ''
        },
        uploadDownload: {
          origin: {
            name: this.form.get('origin').value.name,
            municipalityCode: this.form.get('origin').value.id,
            addresses: []
          },
          destination: []
        },
        cargoMeasure: {
          quantity: null,
          totalMeasurement: parseFloat(this.form.get('measurements').value),
          totalWeigth: parseInt(this.form.get('weight').value),
          amount: null,
          unit: this.form.get('weight').value && this.form.get('unit').value ? this.form.get('unit').value : null
        }
      },
      shippingCost: {
        totalCost: this.form.get('cost').value,
        freightCost: this.form.get('cost').value
      },
      observation: this.form.get('observation').value,
      observationDriver: this.form.get('observationDriver').value,
      zone: this.form.get('zone').value
    };
    const destinations: Destination[] = this.destinations.value.map((destination, i) => {
      return {
        id: `${i}`,
        name: destination.destination.name,
        municipalityCode: destination.destination.id,
        addresses: [],
        downloadDate: this.datePipe.transform(destination.downloadDate, 'yyyy-MM-dd HH:mm Z', 'UTC')
      }
    });
    cargo.cargoFeature.uploadDownload.destination = destinations;

    let cargoList: CargoRequest[] = [];
    for (let vehicle = 0; vehicle < parseInt(this.form.get('quantity').value); vehicle++) {
      cargoList.push(cargo)
    }
    let path = '/cargo/list/request';
    this.spinner.show();
    this.manualCreationCargoService.createRequestCargo(cargoList).subscribe(
      {
        next: (success: Cargo[]) => {
          this.spinner.hide();
          this.manualCreationCargoService.showResponseCreateCargo(
            'Tu solicitud de servicio ha sido creada exitosamente con los consecutivos',
            undefined,
            this.manualCreationCargoService.getConsecutive(success), path
          );
          this.initForm();
          this.cleanValues();
        },
        error: (error) => {
          this.spinner.hide();
          this.manualCreationCargoService.showResponseCreateCargo(
            'Ocurrió un error al crear la solicitud de servicio',
            error
          );
        },
      }
    );
  }
  private cleanValues() {
    this.optionsCompany['initialNit'] = '';
    this.optionsCompany = { ...this.optionsCompany };
    this.originCityOptions['initialValue'] = '';
    this.originCityOptions = { ...this.originCityOptions };
    this.destinationCityOptions['initialValue'] = '';
    this.destinationCityOptions = { ...this.destinationCityOptions };
    this.optionsTypeVehicle['initialValue'] = '';
    this.optionsTypeVehicle = { ...this.optionsTypeVehicle };
    //Clean errors

    this.initForm();
    this.form.get('dateLoad').markAsUntouched();
    this.form.get('tripType').markAsUntouched();
    this.form.get('cost').markAsUntouched();
    this.formValidate = 'untouched';
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  getValidWork(vehicleName: string, bodyworkName: string): boolean {
    if (vehicleName === 'TRACTOCAMION') return true;
    return !!(bodyworkName === 'FURGON' && (vehicleName === 'CAMIONETA' || vehicleName === 'CAMION'));
  }
}
