import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { RoutePointType } from 'src/app/core/interfaces/route-point-type';
import { DateManager } from 'src/app/core/managers/date.manager';
import { PlanningRoutesMessages } from 'src/app/core/messages/planning-routes-messages.enum';
import { AuthService } from 'src/app/core/services/authentication.service';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { RoutesMapComponent } from '../../routes-map/routes-map.component';
import { Patterns } from 'src/app/core/resources/patterns';
import { Utils } from 'src/app/core/resources/utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authorized-sites-form',
  templateUrl: './authorized-sites-form.component.html',
  styleUrls: ['./authorized-sites-form.component.scss']
})
export class AuthorizedSitesFormComponent implements OnInit {
  @ViewChild(RoutesMapComponent, { static: false }) mapComponent: RoutesMapComponent;
  types: RoutePointType[] = [];
  pointId: string;
  form: FormGroup = new FormGroup({
    cityId: new FormControl('', [Validators.required]),
    cityName: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required, Validators.minLength(8)]),
    phone: new FormControl('', [Validators.pattern(this.patterns.PHONE.source), Validators.minLength(7)]),
    address: new FormControl('', [Validators.required])
  });
  cityControl = new FormControl('', Validators.required);
  citySub: Subscription;
  cityOptions: OptionsAutocomplete = { title: "Ciudad" };
  cityValidate = '';
  private address: { address: any, location: google.maps.LatLngLiteral };
  mapOptions: OptionsAutocomplete = {
    clickMap: true,
    clickMapIcon: ''
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AuthorizedSitesFormComponent>,
    private planningRouteService: PlanningRouteService,
    private snackbarService: SnackBarService,
    private authService: AuthService,
    private patterns: Patterns,
    public utils: Utils,
    public spinner: NgxSpinnerService,
  ) {
    if (this.data) {
      if (this.data.pointId) this.pointId = this.data.pointId;
      if (this.data.city && this.data.city.id) this.form.get('cityId').setValue(this.data.city.id);
      if (this.data.city && this.data.city.name) this.form.get('cityName').setValue(this.data.city.name);
      if (this.data.type) this.form.get('type').setValue(this.data.type);
      if (this.data.details && this.data.details.name) this.form.get('name').setValue(this.data.details.name);
      if (this.data.details && this.data.details.contactPhone) this.form.get('phone').setValue(this.data.details.contactPhone);
      if (this.data.details && this.data.details.address) this.form.get('address').setValue(this.data.address);
    }

  }

  ngOnInit() {
    this.requestForPointTypes();
    this.setSubscription();
  }

  setSubscription() {
    this.citySub = this.cityControl.valueChanges.subscribe(city => {
      this.form.get('cityId').setValue(city && city.id ? city.id : '');
      this.form.get('cityName').setValue(city && city.name ? city.name : '');
    })
  }

  create() {
    this.form.markAllAsTouched();
    this.cityValidate = 'touched';
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('cityName'), 'ciudad')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('address'), 'dirección')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('type'), 'tipo de servicio')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('name'), 'nombre', 8)) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('phone'), 'teléfono')) return;
      else this.snackbarService.openSnackBar(PlanningRoutesMessages.INCOMPLETE_FORM, undefined, "error");
      return;
    }
    const point: RouteAuthorizedPoint = {
      active: true,
      fingerprint: {
        userId: this.authService
          .getUserSession()
          .information.document,
        userName: this.authService.getUserSession().information.name,
        date: DateManager.dateToString(new Date()),
      },
      city: {
        id: this.form.get('cityId').value,
        name: this.form.get('cityName').value
      },
      details: {
        contactPhone: this.form.get('phone').value,
        name: this.form.get('name').value,
        pointType: this.form.get('type').value
      },
      location: this.address.location,
      address: this.address.address.formatted_address
    }
    this.spinner.show();
    if (this.pointId) {
      point.id = this.pointId;
      this.planningRouteService.updateAuthorizedPoint(point).subscribe(
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(PlanningRoutesMessages.UPDATED_AUTHORIZED_POINT, undefined, "success");
          this.dialogRef.close({ state: true });
        },
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(PlanningRoutesMessages.HTTP_ERROR, undefined, "error");
        }
      )
    } else {
      this.planningRouteService.createAuthorizedPoint(point).subscribe(
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(PlanningRoutesMessages.CREATED_AUTHORIZED_POINT);
          this.dialogRef.close({ state: true });
        },
        () => {
          this.spinner.hide();
          this.snackbarService.openSnackBar(PlanningRoutesMessages.HTTP_ERROR, undefined, "error");
        }
      );
    }
  }

  onLocation(e: { address: any, location: google.maps.LatLngLiteral }, _: any) {
    this.address = e;
    this.form.get('address').setValue(e.address.formatted_address || e.address.name);
    this.mapComponent.removeAllMarkers();
    this.mapComponent.map.setCenter(e.location);
    this.mapComponent.map.setZoom(16);
    this.mapComponent.marker(e.location.lat, e.location.lng, "origin");
  }

  private requestForPointTypes() {
    this.planningRouteService
      .getPointTypes()
      .subscribe(
        (types: RoutePointType[]) => {
          if (types && types.length) this.types = types;
        }
      )
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
