import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, distinctUntilChanged, map } from 'rxjs/operators';
import { Company } from 'src/app/core/interfaces/company';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { MatAutocompleteSelectedEvent, MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { CostCenterFormComponent } from '../cost-center-form/cost-center-form.component';
import { Cargo } from 'src/app/core/interfaces/cargo';

@Component({
  selector: 'app-assign-cost-center',
  templateUrl: './assign-cost-center.component.html',
  styleUrls: ['./assign-cost-center.component.scss']
})
export class AssignCostCenterComponent implements OnInit {
  // Inputs
  @Input() company: Company;
  @Input() initialValue: CostCenter;
  @Input() cargo: Cargo;
  // Outputs
  @Output() emitResult: EventEmitter<{costCenter: CostCenter, created: boolean} | null> = new EventEmitter();
  public costCenterCtrl: FormControl = new FormControl('');
  public listGeneralCostCenter: Observable<CostCenter[]>;
  public costCenters: CostCenter[] = [];
  permission = Permission;
  constructor(
    public permissionRole: PermissionRole,
    public dialog: MatDialog
  ) { }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes){
      if (changes.initialValue && changes.initialValue.currentValue && this.initialValue) this.costCenterCtrl.setValue(this.initialValue);
      if (changes.company && changes.company.currentValue && this.company) {
        if (this.company.costCenter && this.company.costCenter.length) {
        this.costCenters = this.company.costCenter;
        this.costCenterCtrl.enable();
        if (this.cargo && this.cargo.shippingCost && this.cargo.shippingCost.billId) this.costCenterCtrl.disable();
        } else {
          this.costCenterCtrl.setValue('');
          this.costCenters = [];
          this.costCenterCtrl.disable();
        }
        this.createInstanceAutocomplete();
      }
    }
  }

  private createInstanceAutocomplete(): void {
      this.listGeneralCostCenter = this.costCenterCtrl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(value => {
          return this.costCenters.filter((option) => {
            return option.name.toLowerCase().indexOf(value.toString()) !== -1;
          });
        })
      );
  }

  public displayCostCenterSelected(costCenter: CostCenter): string {
    return costCenter ? costCenter.name || '' : '';
  }

  onCostCenterSelected($event: MatAutocompleteSelectedEvent){
    if ($event && $event.option && $event.option.value && $event.option.value.code) this.emitResult.emit({costCenter: $event.option.value, created: false});
  }

  openCreateCostCenter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      nitCompany: this.company.companyId,
      nameCompany: this.company.name
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CostCenterFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state && result.data) {
        this.costCenterCtrl.setValue(result.data);
        this.costCenters.push(result.data);
        if (this.costCenterCtrl.disabled) this.costCenterCtrl.enable();
        this.emitResult.emit({costCenter: result.data, created: true});
      }
    });
  }

  clearCostCenter(){
    this.costCenterCtrl.setValue('');
    this.emitResult.emit(null);
  }

  // GETTERS
  get canCreateCostCenter(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.createCostCenter
    );
  }

}
