<div class="upload">
  <!-- Title -->
  <mat-card-content>
    <label class="title-3">
      ¿A dónde vas a cargar?
    </label>
  </mat-card-content>
  <div class="row">
    <div class="col-md-12">
      <label class="text-strong">¿Es internacional?&nbsp;&nbsp;</label>
      <mat-button-toggle-group #group="matButtonToggleGroup"
        [formControl]="manualCreationCargoService.formOrigin.get('isInternational')">
        <mat-button-toggle [value]="true" (change)="onChangeInternational()">
          Si
        </mat-button-toggle>
        <mat-button-toggle [value]="false" (change)="onChangeInternational()">
          No
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <!-- Autocomplete City -->
  <mat-card-content class="mb-2">
    <span class="circle-title purple"></span>
      <app-autocomplete-city [inputFormControl]="cityControl" [options]="optionsCity" [validate]="validate"></app-autocomplete-city>
  </mat-card-content>
  <div
    *ngIf="(manualCreationCargoService.getCargoForm().get('ministry').value && (manualCreationCargoService.formOrigin.value.municipalityCode
    && manualCreationCargoService.formCargoOwner.value.documentNumber)||(manualCreationCargoService.formOrigin.value.country.code!=='CO')) || !manualCreationCargoService.getCargoForm().get('ministry').value">
    <div *ngFor="let address of manualCreationCargoService.formOriginAddresses.controls; let i = index"
      class="upload__autocomplete" #thirdPartyConsignmentList>
      <span class="upload__autocomplete-index">{{i + 1}}</span>
      <mdb-icon (click)="removeAddressField(i)" *ngIf="i > 0" class="icon-times" fas icon="times"></mdb-icon>
      <app-third-party-consignment [ngClass]="{'even': 0 === i % 2, 'odd': !(0 === i % 2)}" [title]="'Remitente'"
        [inputFormControl]="address"
        [options]=" {index: i, city: manualCreationCargoService.formOrigin.value, code: manualCreationCargoService.formOrigin.value.country.code}"
        (emitToParent)="onSelectThirdParty($event)"
        [isInternational]="!!manualCreationCargoService.formOrigin.get('isInternational').value"></app-third-party-consignment>
    </div>
    <!-- Btn add Address -->
    <mat-card-content class="text-right upload__actions">
      <button mat-button class="button-width-auto" (click)="addAddressForm()">
        <i class="fas fa-plus-circle"></i>
        <span>Agregar otra dirección</span>
      </button>
    </mat-card-content>
  </div>
  <mat-divider class="my-3"></mat-divider>
  <!-- Btn next -->
  <div class="button-container mt-3">
    <button mat-raised-button color="primary" class="bg-color-primary" (click)="nextStep()">Siguiente</button>
  </div>
</div>
