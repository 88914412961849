import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'pdfTitle'
})
export class PdfTitlePipe implements PipeTransform {

    constructor() { }
    transform(value) {
        switch (value) {
            case 'orderVehicle':
                return 'Orden de servicio';
            case 'consignment':
                return 'Remesa';
            case 'manifest':
                return 'Manifiesto';
            case 'routePlan':
                return "Plan de ruta";
            default:
                return value;
        }
    }

}