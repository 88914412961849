import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CargoItemTurnService } from './cargo-item-turn.service';
import { Permission } from 'src/app/core/resources/permission';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { FreightListService } from '../cargo-list-turn/cargo-list-turn.service';
import { AuthService } from 'src/app/core/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DetailVehicleComponent } from '../../administration/vehicles/detail-vehicle/detail-vehicle.component';
import { VehiclesService } from '../../administration/vehicles/list-vehicles.service';
import moment from 'moment';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { AvaliableCargoListComponent } from '../avaliable-cargo-list/avaliable-cargo-list.component';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { CargoTurnFormComponent } from '../cargo-turn-form/cargo-turn-form.component';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalEnum } from 'src/app/core/enums/modal.enum';

@Component({
  selector: 'app-cargo-item-turn',
  templateUrl: './cargo-item-turn.component.html',
  styleUrls: ['./cargo-item-turn.component.scss'],
})

export class CargoItemTurnComponent implements OnInit {
  @Input() filteredCargosTurns: any;
  @Input() enturnamiento: any;
  @Input() listPagination: any;
  @Output() applyFilter: EventEmitter<any> = new EventEmitter();
  @Output() refreshListTurn: EventEmitter<any> = new EventEmitter();
  public vehicle;
  permission = Permission;
  idUserActive = this.AuthService.getUserSession().information.document;;
  currentDay: moment.Moment;
  expirationDay: moment.Moment;
  diffInDays: any;
  constructor(
    public permissionRole: PermissionRole,
    public cargoListService: FreightListService,
    public AuthService: AuthService,
    public vehiclesService: VehiclesService,
    public dialog: MatDialog,
    public snackBarService: SnackBarService,
    public cargoItemTurnService: CargoItemTurnService,
    public spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getTurnExpirationDate();
  }

  getDetailVehicle() {
    if (!this.showVehicleDetail) return;
    this.vehiclesService.getVehicle(this.enturnamiento.licensePlate).subscribe(
      (success: any) => {
        if (success) {
          this.vehicle = success;
          this.openDetailVehicle();
        } else {
          this.snackBarService.openSnackBar("No hay detalles relacionados para mostrar de este vehiculo", undefined, "alert")
          this.vehicle = null;
        }
      },
      (error) => {
        this.vehicle = null;
      }
    );
  }

  public openDetailVehicle() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      vehicle: this.vehicle,
      close: true,
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.LARGE_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    const dialogRef = this.dialog.open(DetailVehicleComponent, dialogConfig);
  }

  getTurnExpirationDate() {
    this.currentDay = moment(moment().format("YYYY/MM/DD"), "YYYY/MM/DD");
    this.expirationDay = moment(
      this.enturnamiento.date,
      "YYYY/MM/DD"
    );
    this.diffInDays = this.expirationDay.diff(this.currentDay, "days");
  }

  AvaliableCargoList(licensePlate: string, numberOfTrips: number) {
    if (!this.filteredCargosTurns || (this.filteredCargosTurns && this.filteredCargosTurns.matches.length === 0)) return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      filteredCargosTurns: this.filteredCargosTurns,
      licensePlate: licensePlate,
      numberOfTrips: numberOfTrips
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(AvaliableCargoListComponent, dialogConfig);
  }

  removeTurn(idTurn: string, licensePlate: string, date: string, origin: string, destination: string) {
    const dialogConfig = new MatDialogConfig();
    const dateNew = moment(
      date,
      "YYYY/MM/DD"
    ).format("YYYY-MM-DD")
    dialogConfig.data = {
      title: `¿Está seguro que quiere eliminar el enturnamiento?`,
      dataList: [
        {
          data: [`Placa ${licensePlate}`, `Fecha: ${dateNew}`, `${origin} - ${destination ? destination : 'Sin especificar'}`]
        }
      ],
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.state) {
        const deleteObserver = {
          next: () => {
            this.refreshListTurn.emit(true);
            this.spinner.hide();
            this.snackBarService.openSnackBar(CargoMessages.SUCCESSFULL_TURN_DELETE, undefined, "success");
          },
          error: () => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          },
          complete: () => {

            // nada por ahora
          }
        };
        this.spinner.show();
        this.cargoItemTurnService.deleteTurn(idTurn).subscribe(deleteObserver);
      }
    });
  }

  editTurn(turn) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      turn: turn,
      type: 'edit'
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.MEDIUM_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const modalDialog = this.dialog.open(CargoTurnFormComponent, dialogConfig);
    modalDialog.afterClosed().subscribe(result => {
      if (result && result.state && result.type === 'edit') {
        const editObserver = {
          next: () => {
            this.refreshListTurn.emit(true);
            this.spinner.hide();
            this.snackBarService.openSnackBar(CargoMessages.SUCCESSFULL_TURN_EDIT, undefined, "success");
          },
          error: () => {
            this.spinner.hide();
            this.snackBarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
          },
          complete: () => {

            // nada por ahora
          }
        };
        this.spinner.show();
        this.cargoItemTurnService.editTurn(result.value).subscribe(editObserver);
      }
    })
  }

  get showVehicleDetail(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.readVehicleDetails);
  }

  get showAvailableLoads(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.viewAvailableLoads);
  }
}
