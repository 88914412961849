import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CargoManager } from 'src/app/core/managers/cargo.manager';
import { Filter } from 'src/app/core/models/filter';
import { AuthService } from 'src/app/core/services/authentication.service';
import { CargoTripAnomalyService } from 'src/app/modules/cargo/cargo-trip-anomaly/cargo-trip-anomaly.service';
import { environment } from "src/environments/environment";
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { Permission } from 'src/app/core/resources/permission';
import { Utils } from 'src/app/core/resources/utils';
import { Patterns } from 'src/app/core/resources/patterns';
import { FormMessages } from 'src/app/core/messages/form-messages.enum';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { BasicCompany } from 'src/app/core/interfaces/basicCompany';
import { Company } from 'src/app/core/interfaces/company';
import { DateManager } from 'src/app/core/managers/date.manager';
import { AdminUsersService } from 'src/app/modules/administration/admin-users/admin-users.service';
import { Role } from 'src/app/core/interfaces/role';
import { Global } from 'src/app/core/resources/global';
import { Tag } from 'src/app/core/interfaces/tag';
import { CompaniesService } from 'src/app/modules/administration/companies/list-companies.service';
import { CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { Subscription } from 'rxjs';
import { ServiceRequestStateEnum } from 'src/app/core/enums/serviceRequestState.enum';
import { TravelExpensesService } from 'src/app/core/services/travel-expenses.service';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { MatSelectChange } from '@angular/material';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
  providers: [CargoManager]
})

export class ListFilterComponent implements OnInit {
  @Input() filterBy: "cargo" | "company" | "vehicle" | "user" | "serviceRequests" = "cargo";
  @Input() options: Filter;
  @Input() typeList: string;
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  permission = Permission;
  staticFilters = {};
  filterPayment = "fastPay";
  filterCharges: '1' | '2' | '3' = '1';
  filterAdvanceState = new FormControl("requested");
  initialFilters = {};
  form: FormGroup;
  companySelected = new FormControl('');
  companiesControl: FormControl = new FormControl();
  filterAmountValue;
  incidents;
  originCity = new FormControl('');
  destinationCity = new FormControl('');
  dateLoad = new FormControl(null);
  endDate = new FormControl(null);
  creationDateInitial: FormControl = new FormControl();
  creationDateFinal: FormControl = new FormControl();
  tagControl: FormControl = new FormControl();
  showLateralFilter = false;
  cargoTypes = ["Importación", "Exportación", "Nacional", "Internacional", "Urbana", "Última milla"];
  cargoTabsLoadingRoutes = ["emptyContainers", "urbanRoutes", "lastMileRoutes", "nationalRoutes", "importRoutes", "exportRoutes", "internationalRoutes"];
  cargoTabsPayment = ['paymentAdvanceCargo', 'paymentExtraAdvanceCargo', 'paymentAdditionalCostsCargo', 'paymentBalanceCargo']
  optionsCompanyfilter: OptionsAutocomplete = {
    title: 'Compañía',
    optionAll: true,
  };
  optionsCompanies: OptionsAutocomplete = {
    title: 'Añadir Compañía',
    optionAll: false,
  };
  isOpen: boolean = false;
  optionsCityOrigin: OptionsAutocomplete = {
    title: 'Orígen',
  }
  optionsCityDestination: OptionsAutocomplete = {
    title: 'Destino',
  }
  validate: string = '';
  cityControlOrigin: FormControl = new FormControl();
  cityControlDestination: FormControl = new FormControl();
  companyRegisterList: BasicCompany[] = [];
  roles: Role[] = [];
  tags: Tag[] = [];
  userStates = [{ value: "true", name: 'Activo' }, { value: "false", name: 'Inactivo' }];
  isRootNit: boolean;
  optionsDocumentUser: OptionsAutocomplete = {
    title: 'N° doc conductor',
    showUserName: true,
    showSearchHint: false
  }
  documentDriverControl: FormControl = new FormControl();
  companySub: Subscription;
  companiesSub: Subscription;
  cityOriginSub: Subscription;
  cityDestinationSub: Subscription;
  documentDriverSub: Subscription;
  travelExpenseTypes: Array<CatalogItem> = [];

  constructor(
    public utils: Utils,
    private adminUsersService: AdminUsersService,
    private formBuilder: FormBuilder,
    public cargoManager: CargoManager,
    public authService: AuthService,
    private cdRef: ChangeDetectorRef,
    public patterns: Patterns,
    public snackBarService: SnackBarService,
    private cargoTripAnomalyService: CargoTripAnomalyService,
    private dateManager: DateManager,
    public global: Global,
    private companiesService: CompaniesService,
    private travelExpensesService: TravelExpensesService
  ) { }

  ngOnInit() {
    this.isRootNit = this.authService.userIsFromRootNit();
    this.getOptionsByMethods();
    this.initStaticFilters();
    this.initDynamicFilters();
  }

  ngAfterViewInit() {
    this.initDynamicFilters();
    this.getFromSessionStorage();
    this.setOptions();
    this.setSubscriptions();

    if (this.typeList != 'paymentTravelExpenses')
      this.search();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getOptionsByMethods() {
    if (this.filterBy === "user") {
      if (!this.roles.length) {
        this.adminUsersService.getRolesCompany(this.authService.getCompany().companyId).toPromise()
          .then((success: Role[]) => this.roles = success)
          .catch(() => this.roles = [])
          .finally(() => {
            this.roles.push(this.global.defaultRoles.webUserPendingActivate);
            this.roles = this.roles.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
          });
      }
    } else if (this.filterBy === "cargo") {
      //Aquí se usaría un servicio para obtener las tags
      this.companiesService.getLabelTags().subscribe(
        (success) => {
          if (success && success.length) this.tags = success;
        }, () => { }
      )
      if (this.typeList == 'paymentTravelExpenses') {
        this.travelExpensesService
          .getTypes()
          .subscribe((travelExpenseTypes) => {
            this.travelExpenseTypes = travelExpenseTypes;
            if (Array.isArray(this.travelExpenseTypes) && this.travelExpenseTypes[0])
              this.setStaticFilterAndSearch('travelExpensesType', travelExpenseTypes[0].name);
          },
            () => this.search());
      }
    }
  }

  setOptions() {
    if (this.isRootNit) {
      this.optionsCompanyfilter['initialNit'] = this.form && this.form.get('nit') && this.form.get('nit').value ? this.form.get('nit').value : '';
    }
    this.optionsCityOrigin['initialValue'] = this.form && this.form.get('origin') && this.form.get('origin').value ? this.form.get('origin').value : '';
    this.optionsCityOrigin = { ...this.optionsCityOrigin };
    this.optionsCityDestination['initialValue'] = this.form && this.form.get('destination') && this.form.get('destination').value ? this.form.get('destination').value : '';
    this.optionsCityDestination = { ...this.optionsCityDestination };
    this.optionsDocumentUser['initialValue'] = this.form && this.form.get('driver') && this.form.get('driver').value ? this.form.get('driver').value : '';
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  setSubscriptions() {
    this.companySub = this.companySelected.valueChanges.subscribe(() => {
      const company: Company = this.companySelected.value;
      if (!!company && !!company.companyId) {
        this.form.get('nit').setValue(company.companyId);
        this.form.get('exclusive') && this.form.get('exclusive').setValue(company.exclusive);
      } else {
        this.form.get('nit').setValue("");
        this.form.get('exclusive') && this.form.get('exclusive').setValue("");
      }
    });
    this.companiesSub = this.companiesControl.valueChanges
      .subscribe((value) => {
        if (value && value.companyId) {
          const companies: string[] = this.form.get('nits').value;
          if (companies.includes(value.companyId)) this.snackBarService.openSnackBar('Esta compañía ya está en la lista', undefined, 'alert');
          else if (companies.length >= 10) this.snackBarService.openSnackBar('Solo es posible filtrar por un máximo de 10 compañías simultaneamente', undefined, 'alert');
          else {
            this.form.get('nits').setValue([...companies, value.companyId]);
            this.optionsCompanies = {
              title: 'Añadir Compañía',
              optionAll: true,
              initialNit: ''
            }
          }
        }
      })

    this.cityOriginSub = this.cityControlOrigin.valueChanges
      .subscribe(value => this.form.get('origin').setValue(value && value.name ? value.name : ''))

    this.cityDestinationSub = this.cityControlDestination.valueChanges
      .subscribe(value => this.form.get('destination').setValue(value && value.name ? value.name : ''))
    if (this.filterBy !== 'serviceRequests')
      this.cargoTripAnomalyService.getTripAnomaly().subscribe((success: any) => {
        this.incidents = success.catalog;
      });

    this.documentDriverSub = this.documentDriverControl.valueChanges
      .subscribe(value => {
        if (value && value.information && value.information.document) this.form.get('driver').setValue(value.information.document);
      });
  }
  private getFromSessionStorage() {
    let dinamicFilters = sessionStorage.getItem("_filterListDynamic");
    if (dinamicFilters && dinamicFilters !== "null") {
      dinamicFilters.split("&").forEach((filter) => {
        if (filter && filter.split("=")[1] && filter.split("=")[1] !== "undefined")
          switch (filter.split("=")[0]) {
            case "dateLoad":
            case "endDate":
              this.initialFilters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]);
              this[filter.split("=")[0]] = new FormControl(
                DateManager.stringToDate(decodeURIComponent(filter.split("=")[1]), 'YYYY-MM-DD ZZ'));
              break;
            case "nits":
              this.initialFilters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]).split(',');
              break;
            case "labels":
              this.initialFilters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]).split(',');
              break;
            default:
              this.initialFilters[filter.split("=")[0]] = decodeURIComponent(filter.split("=")[1]);
              break;
          }
      })
    } else {
      this.initialFilters = {};
    }
    let staticFilters = sessionStorage.getItem("_filterListStatic");
    let options = sessionStorage.getItem("_currentOptions");

    if (options === JSON.stringify(this.options) && staticFilters && staticFilters !== null) {
      let storedFilters = this.parseQueryString(staticFilters);
      for (const key in this.staticFilters) {
        // Verifica si la llave está presente en storedFilters y si el valor no es nulo
        if (key in storedFilters && storedFilters[key] !== null && storedFilters[key] !== undefined) {
          // Establece el valor correspondiente en this.staticFilters
          if (this.options) {
            if (this.options.showFilterPaymentAdvanceCargo && storedFilters['advanceState'] === true && key === 'advanceState') this.staticFilters['requestedAdvance'] = '';
            else if (this.options.showFilterPaymentAdditionalCostsCargo && storedFilters['additionalCostPaid'] === true && key === 'additionalCostPaid') {
              this.staticFilters['additionalCost'] = '';
              this.staticFilters['states'] = '';
            }
            else if (this.options.showFilterPaymentBalanceCargo) {
              if (storedFilters['paymentType'] && storedFilters['fastPayment']) {
                this.filterPayment = "fastPay";
                this.staticFilters["paid"] = "";
                this.staticFilters["approval"] = "";
              } else if (storedFilters['paymentType']) {
                this.filterPayment = "pendingToPay";
                this.staticFilters["fastPayment"] = "";
                this.staticFilters["paid"] = "";
              } else {
                this.filterPayment = "paid";
                this.staticFilters["fastPayment"] = "";
                this.staticFilters["paymentType"] = "";
              }
            }
            else if (this.options.showFilterCharges) {
              if (storedFilters['cashed'] === false)
                this.filterCharges = '1'; //{ cashed: false, checked: '' };
              else if (storedFilters['checked'] === false)
                this.filterCharges = '2'; //{ cashed: true, checked: false };
              else
                this.filterCharges = '3'; //{ cashed: true, checked: true };
            }
          }
          if (!this.isTabLoadingRoutes)
            this.staticFilters[key] = storedFilters[key];
        }
      }
    }
    this.form.patchValue(this.initialFilters);
  }


  parseQueryString(queryString: string): { [key: string]: string | boolean | string[] } {
    const params = new URLSearchParams(queryString);
    const result: { [key: string]: string | boolean | string[] } = {};

    params.forEach((value, key) => {
      // Intenta convertir a boolean si es "true" o "false"
      if (value === 'true' || value === 'false') {
        result[key] = value === 'true';
      } else {
        // Divide por comas si es una lista
        const values = value.split(',');
        result[key] = values.length > 1 ? values : value;
      }
    });

    return result;
  }


  private cleanSessionStorage() {
    sessionStorage.setItem("_filterListDynamic", null);
  }
  private updateSessionStorage(filtersToSave: string, type: 'dynamic' | 'static') {
    sessionStorage.setItem(type === 'dynamic' ? "_filterListDynamic" : "_filterListStatic", filtersToSave ? filtersToSave : null);
    if (type === 'static') sessionStorage.setItem("_currentOptions", JSON.stringify(this.options));
  }

  //Filtros estáticos (siempre hay uno aplicado)
  public initStaticFilters() {
    if (this.filterBy === "cargo") {
      const includeStateFilter = !['assignedRequests', 'unassignedRequests'].includes(this.typeList);
      if (this.options.showFilterAprovedCargo) this.staticFilters = { approval: "all" };
      else if (this.options.showFilterAssignmentsCargo && includeStateFilter) this.staticFilters = { approval: "Pending approval" }
      else if (this.options.showFilterPaymentBalanceCargo) this.staticFilters = { fastPayment: true, paid: "", approval: "" };
      else if (this.options.showFilterPaymentAdvanceCargo) this.staticFilters = { requestedAdvance: true, advanceState: false };
      else if (this.options.showFilterPaymentAdditionalCostsCargo && includeStateFilter) this.staticFilters = { additionalCost: "Servicio cargue", additionalCostPaid: false, states: [CargoStateEnum.START_SERVICE, CargoStateEnum.END_SERVICE] };
      else if (this.options.showFilterDriver && includeStateFilter) this.staticFilters = { state: CargoStateEnum.ACCEPTED };
      else if (this.options.showFilterCharges) this.staticFilters = { approval: "all", cashed: false, checked: "", states: [CargoStateEnum.START_SERVICE, CargoStateEnum.END_SERVICE, CargoStateEnum.ACCEPTED] };
      else if (this.options.showFilterRaiting) this.staticFilters = { qualified: "" };
      else if (this.options.showFilterPaymentState) this.staticFilters = { paid: false };
      else if (this.options.showFilterCreationDateInitial && includeStateFilter) this.staticFilters = { journal: false, states: [CargoStateEnum.START_SERVICE, CargoStateEnum.END_SERVICE] };
      else if (this.options.showFilterOrderByTracking) this.staticFilters = { orderBy: 'oldFirst' };
      else this.staticFilters = {};

      let exceptionsToSetState = this.options.showFilterPaymentAdditionalCostsCargo || this.options.showFilterCharges || this.options.showFilterCreationDateInitial;
      this.staticFilters['state'] = exceptionsToSetState ? "" : this.getInitialCargoStateByTypelist(this.typeList) ? this.getInitialCargoStateByTypelist(this.typeList) : '';
      this.setFieldsByTab(this.typeList);
      if (!this.isRootNit) this.staticFilters['nit'] = this.authService.getUserSession().clientCompanyId;
    }
    else if (this.filterBy === "user") {
      if (this.options.showFilterStateUser) this.staticFilters = { type: "userClient" };
      if (!this.isRootNit) this.staticFilters['nit'] = this.authService.getUserSession().clientCompanyId;
    }
    else if (this.filterBy === "serviceRequests") {
      const filter = this.getInitialServiceRequestStateByTypelist(this.typeList);
      if (filter) {
        const key = Object.keys(filter)[0];
        this.staticFilters[key] = key === 'publish' ? false : filter[key];
      }
    }
    else {
      this.staticFilters = {};
    }
  }

  private getInitialCargoStateByTypelist(typeList: string): CargoStateEnum {
    let state: CargoStateEnum;
    switch (typeList) {
      case 'latestLoads':
        state = CargoStateEnum.CREATED;
        break;
      case 'scheduledLoads':
        state = CargoStateEnum.ACCEPTED;
        break;
      case 'loadsInNegotiation':
        state = null;
        break;
      case 'loadsFinished':
      case 'balance':
      case 'approvePayments':
      case 'paymentBalanceCargo':
        state = CargoStateEnum.END_SERVICE;
        break;
      case 'request':
        state = CargoStateEnum.REQUEST;
        break;
      case 'expired':
        state = CargoStateEnum.EXPIRED;
        break;
      case 'deleted':
        state = CargoStateEnum.DELETED;
        break;
      case 'paymentTravelExpenses':
        // state del viático
        state = 'true' as any as CargoStateEnum;
        break;
      default:
        if (!['assignedRequests', 'unassignedRequests'].includes(this.typeList)) {
          state = CargoStateEnum.START_SERVICE;
        }
        break;
    }
    return state;
  }

  private getInitialServiceRequestStateByTypelist(typeList: string): { [key: string]: ServiceRequestStateEnum } {
    let staticFilter: { [key: string]: ServiceRequestStateEnum } = {};
    switch (typeList) {
      case 'assignedServiceRequests':
        staticFilter = { requestState: ServiceRequestStateEnum.ACCEPTED };
        break;
      case 'notAssignedServiceRequests':
        staticFilter = { requestState: ServiceRequestStateEnum.REQUESTED };
        break;
      case 'notPublishedServiceRequests':
        staticFilter = { publish: ServiceRequestStateEnum.NOT_PUBLISHED };
        break;
      default:
        staticFilter = {};
        break;
    }
    return staticFilter;
  }


  private setFieldsByTab(tab: string) {
    if (this.isTabLoadingRoutes) {
      this.staticFilters['isEqualProductType'] = tab === "emptyContainers";
      this.staticFilters['productTypeName'] = "CONTENEDOR VACIO";
      if (tab === "nationalRoutes") this.staticFilters['tripTypesName'] = ["Nacional", "Importación", "Exportación"];
      else this.staticFilters['tripTypeName'] =
        tab === "urbanRoutes" ? "Urbana" :
          tab === "lastMileRoutes" ? "Última milla" :
            tab === "importRoutes" ? "Importación" :
              tab === "exportRoutes" ? "Exportación" :
                tab === "internationalRoutes" ? "Internacional" : "";
    } else if (this.isTabPayment) {
      if (tab === "paymentExtraAdvanceCargo") {
        this.staticFilters = { advanceState: true };
      }
      this.staticFilters['paymentType'] =
        tab === "paymentExtraAdvanceCargo" || tab === "paymentAdvanceCargo" ? "advance" :
          tab === "paymentAdditionalCostsCargo" ? "additionalCost" : "balance";
    }
  }
  get isTabLoadingRoutes(): boolean {
    return this.typeList && this.cargoTabsLoadingRoutes.includes(this.typeList);
  }
  get isTabPayment(): boolean {
    return this.typeList && this.cargoTabsPayment.includes(this.typeList);
  }
  get actualStaticFilters() {
    let filters = {}
    Object.keys(this.staticFilters).forEach(key => {
      let val = this.staticFilters[key];
      if (val !== null && val !== undefined && val !== "") {
        filters[key] = val;
      }
    })
    return filters;
  }
  //Métodos para editar filtros estáticos
  public setStaticFilterAndSearch(field: string, value) {
    this.staticFilters[field] = value;
    this.search();
  }
  public setFilterPayment(value) {
    this.filterPayment = value;
    switch (value) {
      case "fastPay":
        this.staticFilters["fastPayment"] = true;
        this.staticFilters["paid"] = "";
        this.staticFilters["approval"] = "";
        this.staticFilters["paymentType"] = "balance";
        break;
      case "pendingToPay":
        this.staticFilters["fastPayment"] = "";
        this.staticFilters["paid"] = "";
        this.staticFilters["approval"] = "Approved";
        this.staticFilters["paymentType"] = "balance";
        break;
      case "paid":
        this.staticFilters["fastPayment"] = "";
        this.staticFilters["paid"] = true;
        this.staticFilters["approval"] = "Approved";
        this.staticFilters["paymentType"] = "";
        break;
    }
    this.search();
  }
  public setAdvanceState(value) {
    this.staticFilters['advanceState'] = value === "" ? true : false;
    this.setStaticFilterAndSearch('requestedAdvance', value);
  }
  public setAdditionalCostPaid(value) {
    this.staticFilters['additionalCostPaid'] = value === "" ? true : false;
    this.staticFilters['states'] = value !== "" ? [CargoStateEnum.START_SERVICE, CargoStateEnum.END_SERVICE] : "";
    this.setStaticFilterAndSearch('additionalCost', value);
  }

  public setFilterDriver(value) {
    if (this.typeList === "scheduledLoads") {
      this.staticFilters['state'] = value ? CargoStateEnum.ACCEPTED : CargoStateEnum.CREATED;
      this.search();
    }
  }
  public setFilterCharges(value: '1' | '2' | '3') {
    this.filterCharges = value;
    let realValues = { cashed: null, checked: null };
    if (value === '1') realValues = { cashed: false, checked: '' };
    else if (value === '2') realValues = { cashed: true, checked: false };
    else realValues = { cashed: true, checked: true };

    this.staticFilters['cashed'] = realValues.cashed;
    this.setStaticFilterAndSearch('checked', realValues.checked);
  }
  public setUserType(type: 'user' | 'userClient') {
    this.staticFilters['type'] = type;
    this.form.get('role') && this.form.get('role').setValue('');
    this.form.get('state') && this.form.get('state').setValue('');
    this.search();
  }

  //Filtros dinámicos (opcionales y elegibles por el usuario)
  public initDynamicFilters() {
    this.form = this.formBuilder.group({});
    this.options.showFilterCompany && this.isRootNit && this.form.addControl('nit', new FormControl(''));
    if (this.filterBy === "cargo") {
      this.options.showFilterErrorManifest && this.form.addControl('errorManifest', new FormControl(''));
      this.options.showFilterMultipleCompanies && this.isRootNit && this.form.addControl('nits', new FormControl([]));
      this.options.showFilterCompany && this.isRootNit && this.form.addControl('exclusive', new FormControl(''));
      this.options.showfilterlicensePlate && this.form.addControl('licensePlate', new FormControl('',
        [Validators.minLength(5), Validators.maxLength(8), Validators.pattern(this.patterns.GET_REGEX('LICENSE_PLATES').source)]));
      this.options.showfilterEscortedLicensePlate && this.form.addControl('escortedLicensePlate', new FormControl('',
        [Validators.minLength(5), Validators.maxLength(8), Validators.pattern(this.patterns.GET_REGEX('LICENSE_PLATES').source)]));
      this.options.showFilterDate && this.form.addControl('dateLoad', new FormControl(''));
      this.options.showFilterDate && this.form.addControl('endDate', new FormControl(''));
      this.options.showFilterCreationDateInitial && this.form.addControl('creationDateInitial', new FormControl(''));
      this.options.showFilterCreationDateFinal && this.form.addControl('creationDateFinal', new FormControl(''));
      this.options.showFilterByTag && this.form.addControl('labels', new FormControl(''));
      this.options.showFilterCreationDateInitial && this.form.addControl('journal', new FormControl(''));
      this.options.showFilterLastPointExistance && this.form.addControl('lastPointExistance', new FormControl(''));
      this.options.showFilterLastPointLapse && this.form.addControl('lastPointLapse', new FormControl(''));
      this.options.showfilterIdCargo && this.form.addControl('consecutive', new FormControl('',
        [Validators.pattern(this.patterns.ONLY_NUMBERS.source), Validators.minLength(2), Validators.maxLength(10)]));
      this.options.showFilterContainer && this.form.addControl('container', new FormControl('',
        [Validators.pattern(this.patterns.CONTAINER_FORMAT_FULL.source), Validators.minLength(11), Validators.maxLength(11)]));
      this.options.showFilterCategory && this.form.addControl('tripCategory', new FormControl(''));
      this.options.showFilterPublicationZone && this.form.addControl('zone', new FormControl(''));
      this.options.showFilterNumberDocument && this.form.addControl('numberDocumentSender', new FormControl(''));
      this.options.showfilterManifestCargo && this.form.addControl('manifest', new FormControl(''));
      this.options.showfilterIdBill && this.form.addControl('billId', new FormControl(''));
      this.options.showFilterTripTypeName && this.form.addControl('tripTypeName', new FormControl(''));
      this.options.showFilterRegimeType && this.form.addControl('regimenType', new FormControl(''));
      this.options.showFilterFintech && this.form.addControl('fastPayment', new FormControl(''));
      this.options.showFilterConfirmedDriver && this.form.addControl('confirmedDriver', new FormControl(''));
      this.options.showFilterAmount && this.form.addControl('startAmount', new FormControl(''));
      this.options.showFilterAmount && this.form.addControl('endAmount', new FormControl(''));
      this.options.showFilterIncident && this.form.addControl('news', new FormControl(''));
      // Viáticos
      this.options.showFilterCargoConsecutive && this.form.addControl('cargoConsecutive', new FormControl(''));
      this.options.showFilterExpensesApproval && this.form.addControl('approval', new FormControl(''));
      this.options.showFilterExpensesPaid && this.form.addControl('paid', new FormControl(''));

      if (this.options.showFilterOriginDestination) {
        this.optionsCityOrigin = { title: 'Orígen', initialValue: '' };
        this.optionsCityDestination = { title: 'Destino', initialValue: '' };
        this.form.addControl('origin', new FormControl(''));
        this.form.addControl('destination', new FormControl(''));
        this.originCity = new FormControl('');
        this.destinationCity = new FormControl('');
      }
      if (this.options.showFilterNumberDocumentDriver) {
        this.optionsDocumentUser = { title: 'N° doc conductor', showUserName: true, initialValue: '', showSearchHint: false }
        this.optionsDocumentUser = { ...this.optionsDocumentUser };
        this.form.addControl('driver', new FormControl('', [Validators.pattern(this.patterns.ONLY_NUMBERS.source), Validators.minLength(2)]));
      }
      if (this.options.showFilterCompany) {
        this.companySelected.setValue('');
        this.optionsCompanyfilter = { title: '', optionAll: true, initialNit: '' };
      }
      if (this.options.showFilterMultipleCompanies) {
        this.companiesControl.setValue('');
      }
      if (this.options.showFilterAmount) this.filterAmountValue = null;
      if (this.options.showFilterDate) {
        this.dateLoad = new FormControl(null);
        this.endDate = new FormControl(null);
      }
      if (this.options.showFilterCreationDateInitial && this.options.showFilterCreationDateFinal) {
        this.creationDateInitial = new FormControl(null);
        this.creationDateFinal = new FormControl(null);
      }
    }
    else if (this.filterBy === "user") {
      this.options.showFilterStateUser && this.form.addControl('state', new FormControl(''));
      this.options.showFilterRole && this.form.addControl('role', new FormControl(''));
      this.options.showFilterDocument && this.form.addControl('document', new FormControl('', [Validators.maxLength(15)]));
      this.options.showFilterPhone && this.form.addControl('phone', new FormControl(''));
      this.options.showFilterEmail && this.form.addControl('email', new FormControl('', [Validators.maxLength(100), Validators.pattern(this.patterns.EMAIL.source)]));
    }
    else if (this.filterBy === "serviceRequests") {
      this.options.showfilterIdCargo && this.form.addControl('consecutive', new FormControl('',
        [Validators.pattern(this.patterns.ONLY_NUMBERS.source), Validators.minLength(2), Validators.maxLength(10)]));
    }

    if (this.options.showFilterCreationDateInitial || this.options.showFilterCreationDateFinal) {
      this.creationDateInitial.setValue(null);
      this.creationDateFinal.setValue(null);
      if (this.options.showFilterCreationDateInitial) {
        this.form.addControl('creationDateInitial', new FormControl(''));
        this.options.showFilterCreationDateInitial && this.form.addControl('journal', new FormControl(''));
      }
      if (this.options.showFilterCreationDateFinal)
        this.form.addControl('creationDateFinal', new FormControl(''));
    }
  }

  get actualFilters(): any {
    let filters = {}
    Object.keys(this.form.controls).forEach(key => {
      let val = this.form.get(key).value;
      if (val !== null && val !== undefined && val !== "") {
        filters[key] = val;
      }
    })
    return filters;
  }
  get someActualFilter(): boolean {
    return !!Object.keys(this.actualFilters).length;
  }

  get appliedFilters(): any {
    let filters = {}
    Object.keys(this.initialFilters).forEach(key => {
      let val = this.initialFilters[key];
      if (val !== null && val !== undefined && val !== "" && key !== "exclusive" && val.length !== 0
        && this.form.get(key)) {
        filters[key] = val;
      }
    })
    return filters;
  }
  get numberOfAppliedFilters(): number {
    return Object.keys(this.appliedFilters).length;
  }


  get allCompanies(): boolean {
    return this.authService.getUserSession().clientCompanyId === environment.rootNit;
  }
  public clearCompany(companyNit) {
    if (!this.form.get('nits').value.includes(companyNit)) return;
    const nitsArr = this.utils.clone(this.form.get('nits').value as string[]);
    const index = nitsArr.indexOf(companyNit);
    if (index === -1) return;
    nitsArr.splice(index, 1);
    this.form.get('nits').setValue(nitsArr.length ? nitsArr : '');
  }

  public setDate($event, type: string) {
    let newDate = DateManager.formatDate($event.value, null, 'YYYY-MM-DD ZZ');
    this.form.get(type === "load" ? "dateLoad" : "endDate").setValue(newDate);
  }
  get minDate() {
    if (!this.form.get('dateLoad') || !this.form.get('dateLoad').value) return null;
    return DateManager.stringToDate(this.form.get('dateLoad').value, 'YYYY-MM-DD ZZ');
  }

  get maxDate() {
    return !this.form.get('endDate') || !this.form.get('endDate').value ? null :
      DateManager.stringToDate(this.form.get('endDate').value, 'YYYY-MM-DD ZZ');
  }

  public onChangeCreationDate($event, type: 'creationDateInitial' | 'creationDateFinal') {
    let newDate = DateManager.dateToString($event.value, 'YYYY-MM-DD ZZ');
    this.form.get(type).setValue(newDate);
    if (type === "creationDateInitial") {
      this.creationDateFinal.setValue(null)
      this.form.get('creationDateFinal') && this.form.get('creationDateFinal').setValue('');
    }
  }

  get maxCreationDateInitial() {
    return new Date();
  }

  get minCreationDateFinal() {
    if (DateManager.isValidDate(this.creationDateInitial.value)) {
      return new Date(this.creationDateInitial.value);
    }
    return new Date();
  }

  get maxCreationDateFinal() {
    if (DateManager.isValidDate(this.creationDateInitial.value) &&
      DateManager.isBefore(DateManager.getLastMonthDay(this.creationDateInitial.value), new Date())) {
      return new Date(DateManager.getLastMonthDay(this.creationDateInitial.value));
    }
    return new Date();
  }

  public clearLastPointLapse($event) {
    if ($event.value === 'false') this.form.get('lastPointLapse').setValue('');
  }

  public selectTag($event) {
    this.tagControl.setValue('');
    if (!this.form.get('labels').value) this.form.get('labels').setValue([$event.value]);
    else if (!this.form.get('labels').value.includes($event.value)) this.form.get('labels').setValue([...this.form.get('labels').value, $event.value]);
  }
  public clearTag(tagName) {
    if (!this.form.get('labels').value.includes(tagName)) return;
    const tagArr = this.utils.clone(this.form.get('labels').value as string[]);
    const index = tagArr.indexOf(tagName);
    if (index === -1) return;
    tagArr.splice(index, 1);
    this.form.get('labels').setValue(tagArr.length ? tagArr : '');
  }

  public setAmount($event) {
    this.isFinite($event.value.start) ?
      this.form.get('startAmount').setValue($event.value.start) :
      this.form.get('startAmount').setValue('');
    this.isFinite($event.value.end) ?
      this.form.get('endAmount').setValue($event.value.end) :
      this.form.get('endAmount').setValue('');
  }
  public isFinite(n: number): boolean {
    return Number.isFinite(n);
  }
  public onSelectOriginDestination($event, type) {
    $event && $event.data && $event.data.name ?
      this.form.get(type).setValue($event.data.name) :
      this.form.get(type).setValue('');
  }


  //Funciones de aplicado/borrado de filtros
  public applyFilter() {
    if (this.form.valid) {
      this.closeLateralFilter();
      this.search();
    } else {
      //Cargo
      if (this.form.get('licensePlate') && this.utils.errorMessagesCustomized(this.form.get('licensePlate'), 'placa', 5, 8)) return;
      else if (this.form.get('escortedLicensePlate') && this.utils.errorMessagesCustomized(this.form.get('escortedLicensePlate'), 'placa escoltada', 5, 8)) return;
      else if (this.form.get('consecutive') && this.utils.errorMessagesCustomized(this.form.get('consecutive'), 'id del servicio', 2, 10)) return;
      else if (this.form.get('container') && this.utils.errorMessagesCustomized(this.form.get('container'), 'contenedor', 11, 11)) return;
      else if (this.form.get('driver') && this.utils.errorMessagesCustomized(this.form.get('driver'), 'documento conductor', 2)) return;
      //User
      else if (this.form.get('document') && this.utils.errorMessagesCustomized(this.form.get('document'), 'documento', null, 15)) return;
      else if (this.form.get('email') && this.utils.errorMessagesCustomized(this.form.get('email'), 'correo ', null, 100)) return;
      //General
      else this.snackBarService.openSnackBar(FormMessages.GENERAL_ERROR_DEFAULT, undefined, 'alert');
    }
  }

  public search() {
    let filtersToSessionStorage = "";
    Object.keys(this.form.controls).forEach(key => {
      let val = this.form.get(key).value;
      this.initialFilters[key] = val;
      if (val === null || val === undefined || val === "") {
        delete this.initialFilters[key];
      }
    })
    Object.keys(this.initialFilters).forEach((key) => {
      filtersToSessionStorage += "&" + key + "=" + encodeURIComponent(this.initialFilters[key]);
    });
    this.updateSessionStorage(filtersToSessionStorage, 'dynamic');
    let filtersToSearch = "";
    Object.keys(this.actualFilters).forEach((key) => {
      if (Array.isArray(this.actualFilters[key])) {
        if (this.actualFilters[key].length)
          filtersToSearch += "&" + key + "=" + encodeURIComponent(JSON.stringify(this.actualFilters[key]));
      } else
        filtersToSearch += "&" + key + "=" + encodeURIComponent(this.actualFilters[key]);
    });
    let staticfiltersToSessionStorage = "";
    Object.keys(this.actualStaticFilters).forEach((key) => {
      staticfiltersToSessionStorage += "&" + key + "=" + encodeURIComponent(this.actualStaticFilters[key]);
    });
    Object.keys(this.actualStaticFilters).forEach((key) => {
      if (Array.isArray(this.actualStaticFilters[key])) {
        if (this.actualStaticFilters[key].length)
          filtersToSearch += "&" + key + "=" + encodeURIComponent(JSON.stringify(this.actualStaticFilters[key]));
      } else
        filtersToSearch += "&" + key + "=" + encodeURIComponent(this.actualStaticFilters[key]);
    });
    this.updateSessionStorage(staticfiltersToSessionStorage, 'static');
    sessionStorage.setItem("_activeTab", this.typeList);
    this.refreshList.emit(filtersToSearch);
  }

  public cleanFilter(filter: string, index?: number) {
    switch (filter) {
      case "labels":
        if (this.appliedFilters.labels.length === 1) {
          delete this.initialFilters['labels'];
        }
        else if ((index || index === 0) && this.initialFilters['labels'] && this.initialFilters['labels'].length)
          this.initialFilters['labels'].splice(index, 1);
        break;
      case "nits":
        if (this.appliedFilters.nits.length === 1) {
          this.companiesControl.setValue('');
        }
        if ((index || index === 0) && this.initialFilters['nits'] && this.initialFilters['nits'].length)
          this.initialFilters['nits'].splice(index, 1);
        break;
      case "nit":
        this.form.get(filter).setValue('');
        this.form.get('exclusive') && this.form.get('exclusive').setValue('');
        this.initialFilters["exclusive"] = "";
        this.companySelected.setValue('');
        this.optionsCompanyfilter = {
          title: 'Compañía',
          optionAll: true,
          initialNit: ''
        }
        this.initialFilters[filter] = "";
        break;
      case "dateLoad":
      case "endDate":
      case "creationDateFinal":
        this[filter] = new FormControl(null);
        this.form.get(filter).setValue('');
        this.initialFilters[filter] = "";
        break;
      case "creationDateInitial":
        this.creationDateInitial.setValue(null);
        this.form.get(filter).setValue('');
        this.initialFilters[filter] = "";
        this.creationDateFinal.setValue(null);
        this.form.get('creationDateFinal') && this.form.get('creationDateFinal').setValue('')
        this.initialFilters['creationDateFinal'] = "";
        break;
      case "amount":
        this.form.get('startAmount').setValue('');
        this.initialFilters["startAmount"] = "";
        this.form.get('endAmount').setValue('');
        this.initialFilters["endAmount"] = "";
        this.filterAmountValue = null;
        this.initialFilters[filter] = "";
        break;
      case "origin":
        this.form.get(filter).setValue('');
        this.originCity = new FormControl('');
        this.optionsCityOrigin = {
          title: 'Orígen',
          initialValue: ''
        }
        this.initialFilters[filter] = "";
        break;
      case "destination":
        this.form.get(filter).setValue('');
        this.destinationCity = new FormControl('');
        this.optionsCityDestination = {
          title: 'Destino',
          initialValue: ''
        }
        this.initialFilters[filter] = "";
        break;
      case "driver":
        this.form.get(filter).setValue('');
        this.optionsDocumentUser = { title: 'N° doc conductor', showUserName: true, initialValue: '', showSearchHint: false }
        this.optionsDocumentUser = { ...this.optionsDocumentUser };
        this.initialFilters[filter] = "";
      default:
        this.form.get(filter).setValue('');
        this.initialFilters[filter] = "";
        break;
    }
    this.search();
  }

  public cleanAllFilters() {
    this.initDynamicFilters();
    this.initialFilters = {};
    this.cleanSessionStorage();
    this.search();
  }

  public openLateralFilter() {
    this.showLateralFilter = true;
    this.setOptions();
  }

  public closeLateralFilter() {
    this.showLateralFilter = false;
  }

  get scheduledLoadsTab(): boolean {
    return sessionStorage.getItem("_filterListStatic") && sessionStorage.getItem("_filterListStatic").includes('&state=Accepted');
  }

  get cargoStateEnum(): typeof CargoStateEnum {
    return CargoStateEnum;
  }

  get hasExternalFiltersFirstLine(): boolean {
    return [
      'showFilterAprovedCargo',
      'showFilterCharges',
      'showFilterDriver',
      'showFilterRaiting',
      'showFilterTypeUser'
    ].some(filter => this.options[filter]);
  }

  get hasExternalFiltersSecondLine(): boolean {
    return [
      'showFilterCharges',
      'showFilterPaymentBalanceCargo',
      'showFilterPaymentAdvanceCargo',
      'showFilterPaymentAdditionalCostsCargo',
      'showFilterPaymentState',
      'showFilterPaymentExpenses',
      'showFilterPublicationZone'
    ].some(filter => this.options[filter]);
  }

  ngOnDestroy() {
    this.cdRef.detach();
    if (this.companySub) this.companySub.unsubscribe();
    if (this.companiesSub) this.companiesSub.unsubscribe();
    if (this.cityOriginSub) this.cityOriginSub.unsubscribe();
    if (this.cityDestinationSub) this.cityDestinationSub.unsubscribe();
    if (this.documentDriverSub) this.documentDriverSub.unsubscribe();
  }
}
