import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Utils } from 'src/app/core/resources/utils';
import { Global } from 'src/app/core/resources/global';
import { Model } from 'src/app/core/interfaces/vehicle';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { VehicleType } from 'src/app/core/interfaces/vehicleType';
import { MatDatepickerInputEvent, MatSelectChange } from '@angular/material';
import { Patterns } from 'src/app/core/resources/patterns';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { AmountsCargoEnum } from 'src/app/core/enums/amountsCargo.enum';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DateManager } from 'src/app/core/managers/date.manager';

@Component({
  selector: 'app-person-vehicle',
  templateUrl: './person-vehicle.component.html',
  styleUrls: ['./person-vehicle.component.scss'],
  providers: [AuthService, ManualCreationCargoService, Model]
})
export class PersonVehicleComponent implements OnInit {
  @Input() title: 'Conductor' | 'Administrador' | 'Propietario';
  @Input() form: FormGroup;
  documenTypes: CatalogItem[];
  vehicleTypes: VehicleType[];
  expeditionDateId: FormControl = new FormControl('', Validators.required);
  optionsCity = {
    title: 'Ciudad',
    disabled: new BehaviorSubject<boolean>(false)
  }
  validate: string = '';
  cityControl: FormControl = new FormControl('', Validators.required);
  optionsDocumentUser: OptionsAutocomplete = {
    title: 'N° de identificación'
  }
  documentControl: FormControl = new FormControl('', Validators.required);
  today = new Date();
  formSub: Subscription;
  citySub: Subscription;
  documentSub: Subscription;
  documentControlSub: Subscription;
  expeditionDateSub: Subscription;
  constructor(
    public utils: Utils,
    private global: Global,
    private patterns: Patterns,
  ) {
    this.documenTypes = this.utils.clone(this.global.documenTypes);
  }

  /**
  * @description Initializes the optionsDocumentUser, deletes the documentType by title input, and execute setSubscription and setOptions methods
  */
  ngOnInit(): void {
    this.optionsDocumentUser.mustAcceptTerms = this.title === 'Conductor';
    this.optionsDocumentUser.typeUser = (this.title === 'Conductor' ? 'driver' : (this.title === 'Propietario' ? 'owner' : 'admin'));

    const indexDoc = { Conductor: '3', Propietario: '4', Administrador: '5' };
    const indexNit = this.documenTypes.findIndex((obj) => {
      return obj.id === indexDoc[this.title];
    });
    if (indexNit >= 0) {
      this.documenTypes.splice(indexNit, 1);
    }


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.form && changes.form.currentValue) {
      this.setSubscription();
      this.setOptions();
    }
  }
  /**
  * @description Creates some subscriptions to valueChanges of form controls and local variables, to update local variables and form controls
  */
  private setSubscription() {
    this.formSub = this.form.valueChanges.subscribe(() => {
      this.form.get('expeditionDateId') && this.form.get('expeditionDateId').disabled ? this.expeditionDateId.disable() : this.expeditionDateId.enable();
    });
    this.citySub = this.cityControl.valueChanges
      .subscribe(value => this.citySelected = value ? value : '');

    if (this.form.get('city')) {
      let $citySubscription = this.form.get('city').valueChanges
        .subscribe(value => {
          if (value) {
            this.optionsCity['initialValue'] = value;
            this.optionsCity = { ...this.optionsCity };
            $citySubscription.unsubscribe();
          }
        })

      this.documentSub = this.form.get('information.documentTypeId').valueChanges
        .subscribe(value => {
          if (this.title !== 'Conductor') {
            if (value === '3') {
              this.form.get('city').setValidators(Validators.required);
              this.cityControl.setValidators(Validators.required);
              this.validate = 'setValidators';
            } else {
              this.form.get('city').clearValidators();
              this.cityControl.clearValidators();
              this.validate = 'clearValidators';
            }
            this.form.get('city').updateValueAndValidity();
            this.cityControl.updateValueAndValidity();
          }
        })
    }
    this.documentControlSub = this.documentControl.valueChanges
      .subscribe(value => {
        if (value && value.information && (value.information.name || value.information.document)) {
          if (value.operationId) delete value.operationId;
          if (value.city) {
            this.optionsCity['initialValue'] = value.city;
            this.optionsCity = { ...this.optionsCity };
          }
          const documentTypeId = this.form.get('information.documentTypeId').value;
          const documentTypeName = this.form.get('information.documentTypeName').value;
          this.form.reset();
          const filteredValue = {
            ...value,
            information: {
              ...value.information,
              documentTypeId: value.information.documentTypeId ? value.information.documentTypeId : documentTypeId,
              documentTypeName: value.information.documentTypeName ? value.information.documentTypeName : documentTypeName,
            },
          };
          this.form.patchValue(filteredValue);
        } else {
          this.form.get('information.document').setValue(value && typeof value === 'string' ? value : '');
        }
      });

    let $documentSubscription = this.form.get('information.document').valueChanges
      .subscribe(value => {
        if (value) {
          this.setOptions();
          $documentSubscription.unsubscribe();
        }
      });
    if (this.form.controls.expeditionDateId) {
      this.expeditionDateSub = this.form.controls.expeditionDateId.valueChanges.subscribe((value) => {
        this.expeditionDateId.setValue(DateManager.stringToDate(value, 'YYYY-MM-DD'));
      });
    }
  }

  /**
  * @description Updates the documentUser's options
  */
  private setOptions() {
    this.optionsDocumentUser['initialValue'] = this.form && this.form.get('information.document') && this.form.get('information.document').value ? this.form.get('information.document').value : '';
    this.optionsDocumentUser['documentTypeId'] = this.form && this.form.get('information.documentTypeId') && this.form.get('information.documentTypeId').value ? this.form.get('information.documentTypeId').value : null;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  /**
  * @param {CatalogItem} documentType is the document type to check
  * @description If the documentType exists and has a name, returns the name otherwise returns an empty string
  */
  displayFnDocumentType(documentType: CatalogItem): string {
    return documentType ? documentType.name : '';
  }

  /**
  * @param {CatalogItem} documentType is the document type to check
  * @description If the documentType exists and has an id, returns the id otherwise returns undefined
  */
  returnFnDocumentType(documentType: CatalogItem): string | undefined {
    return documentType ? documentType.id : undefined;
  }

  /**
  * @param {MatSelectChange} $event is the event of the document type selected
  * @description Updates the form and its validations with the document type selected
  */
  onChangeDocumentType($event: MatSelectChange) {
    const documentType = this.documenTypes.filter((obj) => {
      return obj.id === $event.value;
    });
    this.form.get('information.documentTypeId').setValue(documentType[0].id);
    this.form.get('information.documentTypeName').setValue(documentType[0].name);
    if (this.form && this.form.get('information.documentTypeId') && this.form.get('information.documentTypeId').value && this.form.get('information.documentTypeId').value === '3') {
      this.form.get('phone').setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(12),
        Validators.pattern(this.patterns.CELLPHONEANDLANDLINE.source),
      ])
      this.form.get('information.document').setValidators([
        Validators.required,
        Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH),
        Validators.maxLength(AmountsCargoEnum.MAX_NIT_LENGTH),
      ])
    } else {
      this.form.get('phone').setValidators([
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(12),
        Validators.pattern(this.patterns.CELLPHONE.source),
      ])
      this.form.get('information.document').setValidators([
        Validators.required,
        Validators.minLength(AmountsCargoEnum.MIN_DOCUMENT_LENGTH),
        Validators.maxLength(AmountsCargoEnum.MAX_DOCUMENT_LENGTH),
      ])
    }
    this.form.updateValueAndValidity();
    this.optionsDocumentUser['documentTypeId'] = this.form.get('information.documentTypeId').value;
    delete this.optionsDocumentUser.initialValue;
    this.optionsDocumentUser = { ...this.optionsDocumentUser };
  }

  /**
  * @param {MatDatepickerInputEvent<Date>} $event is the event of the date selected
  * @description Updates the form's expeditionDateId with the date selected in format expected
  */
  onChangeDatePicker($event: MatDatepickerInputEvent<Date>) {
    this.form.get('expeditionDateId').setValue(DateManager.dateToString($event.value, 'YYYY-MM-DD'));
  }

  /**
  * @param {{id: string, name: string}} city is the event of the city selected
  * @description Updates the form's city and municipalityCode controls with the city selected
  */
  set citySelected(city: { id: string, name: string }) {
    this.form.get('city').setValue(city && city.name ? city.name : null);
    this.form.get('municipalityCode').setValue(city && city.id ? city.id : null);
  }

  /**
  * @param {string} $event is the address selected
  * @description Updates the form's address with the address selected
  */
  onSelectAddress($event: string) {
    if (this.utils.isDefined($event) && this.form.get('address')) {
      this.form.get('address').setValue($event);
    }
  }

  ngOnDestroy() {
    if (this.formSub) this.formSub.unsubscribe();
    if (this.citySub) this.citySub.unsubscribe();
    if (this.documentSub) this.documentSub.unsubscribe();
    if (this.documentControlSub) this.documentControlSub.unsubscribe();
    if (this.expeditionDateSub) this.expeditionDateSub.unsubscribe();
  }
}
