<div class="modal-body wrapper-modal main-container">
  <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
  <div class="title pt-3 pt-sm-0">Inspección preoperacional de vehículos</div>
  <form>
    <mat-card-content>
      <div class="md-form">
        <label class="label-field" *ngIf="data?.inspect?.id && data.inspect.approval">Aprobado por:</label>
        <label class="label-field" *ngIf="!data?.inspect?.id  || ( data?.inspect?.id && !data.inspect.approval)">Creado
          por:</label>
        <input type="text" class="form-control" value="{{userInformation}}" readonly>
      </div>
    </mat-card-content>
  </form>
  <div class="container-checklist">


    <div *ngIf="!isMotorcycle">
      <div class="opcion-decription">
        <div *ngFor="let o of options | keyvalue: originalOrder">{{o.key}}: {{o.value}}</div>
      </div>
      <h3 class="subtitle-inspection">Estructura</h3>
      <mat-card-content class="container-options">
        <div class="content-form" *ngFor="let element of vehicleStructure">
          <label class="label-field font">{{questions[element.question]}}</label>
          <mat-radio-group class="radio-group" [(ngModel)]="element.response">
            <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
              [value]="option.key" [disabled]="data.disabled">
              {{option.key}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card-content>
      <h3 class="subtitle-inspection">Carrocería</h3>
      <mat-card-content class="container-options">
        <div class="content-form" *ngFor="let element of bodyworkElements">
          <label class="label-field font">{{questions[element.question]}}</label>
          <mat-radio-group class="radio-group" [(ngModel)]="element.response">
            <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
              [value]="option.key" [disabled]="data.disabled">
              {{option.key}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card-content>

      <h3 class="subtitle-inspection">Elementos de protección personal</h3>
      <mat-card-content class="container-options">
        <div class="content-form" *ngFor=" let element of elementsProtection">
          <label class="label-field font">{{questions[element.name]}}</label>
          <br>
          Presente:
          <mat-button-toggle-group [(ngModel)]="element.available">
            <mat-button-toggle [value]="true" [disabled]="data.disabled">
              Si
            </mat-button-toggle>
            <mat-button-toggle [value]="false" [disabled]="data.disabled">
              No
            </mat-button-toggle>
          </mat-button-toggle-group>
          <br>
          Estado:
          <mat-radio-group class="radio-group" [(ngModel)]="element.status">
            <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
              [value]="option.key" [disabled]="data.disabled">
              {{option.key}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card-content>
    </div>
    <div *ngIf="isMotorcycle">
      <div class="opcion-decription">
        <div *ngFor="let o of motorcycleOptionsAlias | keyvalue: originalOrder">{{o.key}}: {{o.value}}</div>
      </div>
      <h3 class="subtitle-inspection">Condiciones del usuario</h3>
      <mat-card-content class="d-flex flex-column">
        <div class="d-flex flex-wrap" *ngFor="let element of elementsProtection">
          <label class="label-field font col-12 p-0">{{questions[element.name]}}</label>
          <div class="col-12 col-sm-6 pl-2">
            Estado:
            <mat-radio-group class="radio-group" [(ngModel)]="element.status">
              <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
                [value]="option.key" [disabled]="data.disabled">
                {{motorcycleOptions[option.key]}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12 col-sm-6 pl-2">
            Observaciones:
            <mat-form-field>
              <input matInput [(ngModel)]='element.observation' [disabled]="data.disabled">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <h3 class="subtitle-inspection">Verificación del sistema eléctrico</h3>
      <mat-card-content class="d-flex flex-column">
        <div class="d-flex flex-wrap" *ngFor=" let code of electricSystemQuestions">
          <label class="label-field font col-12 p-0">{{questions[code]}}</label>
          <div class="col-12 col-sm-6 pl-2">
            Estado:
            <mat-radio-group class="radio-group" *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
              [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].response">
              <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
                [value]="option.key" [disabled]="data.disabled">
                {{motorcycleOptions[option.key]}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12 col-sm-6 pl-2">
            Observaciones:
            <mat-form-field>
              <input matInput *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
                [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].observation"
                [disabled]="data.disabled">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <h3 class="subtitle-inspection">Estado mecánico</h3>
      <mat-card-content class="d-flex flex-column">
        <div class="d-flex flex-wrap" *ngFor=" let code of mechanicStatusQuestions">
          <label class="label-field font col-12 p-0">{{questions[code]}}</label>
          <div class="col-12 col-sm-6 pl-2">
            Estado:
            <mat-radio-group class="radio-group" *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
              [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].response">
              <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
                [value]="option.key" [disabled]="data.disabled">
                {{motorcycleOptions[option.key]}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12 col-sm-6 pl-2">
            Observaciones:
            <mat-form-field>
              <input matInput *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
                [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].observation"
                [disabled]="data.disabled">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>

      <h3 class="subtitle-inspection">Estado del funcionamiento del motor</h3>
      <mat-card-content class="d-flex flex-column">
        <div class="d-flex flex-wrap" *ngFor=" let code of motorStatusQuestions">
          <label class="label-field font col-12 p-0">{{questions[code]}}</label>
          <div class="col-12 col-sm-6 pl-2">
            Estado:
            <mat-radio-group class="radio-group" *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
              [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].response">
              <mat-radio-button *ngFor=" let option of options | keyvalue: originalOrder; let i=index"
                [value]="option.key" [disabled]="data.disabled">
                {{motorcycleOptions[option.key]}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12 col-sm-6 pl-2">
            Observaciones:
            <mat-form-field>
              <input matInput *ngIf="getQuestionIndex(code, 'vehicleStructure')!==-1"
                [(ngModel)]="vehicleStructure[getQuestionIndex(code, 'vehicleStructure')].observation"
                [disabled]="data.disabled">
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </div>

    <mat-card-content class="container-options">
      <div class="content-form">
        <label class="label-field font">¿Se encuentra la unidad en estado optimo?</label>
        <mat-button-toggle-group [(ngModel)]="optimal">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="content-form">
        <label class="label-field font">¿Hay olores presentes dentro de la unidad?</label>
        <mat-button-toggle-group [(ngModel)]="odors">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="content-form">
        <label class="label-field font">¿Hay riesgo de contaminación cruzada?</label>
        <mat-button-toggle-group [(ngModel)]="crossedContamination">
          <mat-button-toggle [value]="true" [disabled]="data.disabled">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false" [disabled]="data.disabled">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card-content>

    <form class="row form_container" [formGroup]="form">
      <mat-form-field class="col-md-6 cursor-pointer" appearance="standard">
        <mat-label>Fecha de mantenimiento correctivo<span class="asterisk">*</span></mat-label>
        <input readonly class="cursor-pointer" type="text" matInput [matDatepicker]="datePicker"
          formControlName="dateMaintenanceCorrective" (click)="datePicker.open()">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <mat-error *ngIf="form.get('dateMaintenanceCorrective').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6 cursor-pointer" appearance="standard">
        <mat-label>Fecha de mantenimiento preventivo<span class="asterisk">*</span></mat-label>
        <input readonly class="cursor-pointer" type="text" matInput [matDatepicker]="datePickerPreventive"
          formControlName="dateMaintenancePreventive" (click)="datePickerPreventive.open()">
        <mat-datepicker-toggle matSuffix [for]="datePickerPreventive"></mat-datepicker-toggle>
        <mat-datepicker #datePickerPreventive></mat-datepicker>
        <mat-error *ngIf="form.get('dateMaintenancePreventive').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-12 mt-2" appearance="outline">
        <mat-label>Observación<span class="asterisk">*</span></mat-label>
        <textarea matInput formControlName="observation"></textarea>
        <mat-error *ngIf="form.get('observation').errors?.required">
          {{ utils.giveMessageError('MANDATORY_FIELD') }}
        </mat-error>
        <mat-error *ngIf="form.get('observation').errors?.minlength">
          {{ utils.giveMessageError('MIN_LENGTH_NOT_REACHED', 'observación', minObservation) }}
        </mat-error>
      </mat-form-field>
    </form>
    <mat-card-content class="container-options">
      <div class="content-form" *ngIf="!data?.inspect?.id">
        <label class="label-field font">Actualizar foto del vehiculo</label>
        <br>
        <mat-button-toggle-group [(ngModel)]="updatePhoto">
          <mat-button-toggle [value]="true">
            Si
          </mat-button-toggle>
          <mat-button-toggle [value]="false">
            No
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card-content>
    <form class="row" [formGroup]="formPicture">
      <!-- Left lateral Picture-->
      <div *ngIf="updatePhoto || data?.inspect?.id" class="col-md-4">
        <input #inputLeftLateralPicture class="display-none" type="file"
          (change)="setInstanceFileStorage($event,'leftLateralPicture')">
        <mat-form-field appearance="standard" (click)="openWindowFile('leftLateralPicture')">
          <mat-label>Foto lateral Izquierda del Vehículo<span class="asterisk">*</span></mat-label>
          <input matInput type="text" class="field__form--input" readonly formControlName="leftLateralPicture">
          <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
          <mat-error *ngIf="formPicture.get('leftLateralPicture').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <!--Right lateral Picture-->
      <div *ngIf="updatePhoto || data?.inspect?.id" class="col-md-4">
        <input #inputRightLateralPicture class="display-none" type="file"
          (change)="setInstanceFileStorage($event,'rightLateralPicture')">
        <mat-form-field appearance="standard" (click)="openWindowFile('rightLateralPicture')">
          <mat-label>Foto lateral Derecha del Vehículo<span class="asterisk">*</span></mat-label>
          <input matInput type="text" class="field__form--input" readonly formControlName="rightLateralPicture">
          <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
          <mat-error *ngIf="formPicture.get('rightLateralPicture').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <!--Frontal Picture-->
      <div *ngIf="updatePhoto || data?.inspect?.id" class="col-md-4">
        <input #inputFrontalImage class="display-none" type="file"
          (change)="setInstanceFileStorage($event,'frontPicture')">
        <mat-form-field appearance="standard" (click)="openWindowFile('frontPicture')">
          <mat-label>Foto frontal del Vehículo<span class="asterisk">*</span></mat-label>
          <input matInput type="text" class="field__form--input" readonly formControlName="frontPicture">
          <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
          <mat-error *ngIf="formPicture.get('frontPicture').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <input #inputPreventativeMaintenance class="display-none" type="file"
          (change)="setInstanceFileStorage($event,'preventativeMaintenance')">
        <mat-form-field appearance="standard" (click)="openWindowFile('preventativeMaintenance')">
          <mat-label>Mantenimiento preventivo</mat-label>
          <input matInput type="text" class="field__form--input" readonly formControlName="preventativeMaintenance">
          <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
          <mat-error *ngIf="formPicture.get('preventativeMaintenance').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <input #inputCorrectiveMaintenance class="display-none" type="file"
          (change)="setInstanceFileStorage($event,'correctiveMaintenance')">
        <mat-form-field appearance="standard" (click)="openWindowFile('correctiveMaintenance')">
          <mat-label>Mantenimiento correctivo</mat-label>
          <input matInput type="text" class="field__form--input" readonly formControlName="correctiveMaintenance">
          <i class="fas fa-camera i-picture field__form--icon" for="upload-photo"></i>
          <mat-error *ngIf="formPicture.get('correctiveMaintenance').errors?.required">
            {{ utils.giveMessageError('MANDATORY_FIELD') }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <!-- Btn-->
  <mat-card-actions>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id" class="button-width-auto"
      (click)="downloadInspection()">
      Descargar
    </button>
    <button mat-raised-button color="primary" *ngIf="!data?.inspect?.id" class="button-width-auto"
      (click)="saveInspection()">
      Guardar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="primary-secondary button-width-auto" (click)="confirmRejection()">
      Rechazar
    </button>
    <button mat-raised-button color="primary" *ngIf="data?.inspect?.id && !data.inspect.approval"
      class="button-width-auto" (click)="approve(true)">
      Aprobar
    </button>
  </mat-card-actions>
</div>