import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserResolver } from './user.resolve';
import { isObservable, Observable } from 'rxjs';
import { User } from '../interfaces/user';
import { RoleService } from '../services/role.service';
import { NotificationRole, NotificationTypes } from '../enums/notification-types.enum';
import { MainComponent } from 'src/app/layout/main/main.component';
import { FirebaseApi } from '../classes/firebase-api';
import { Notification } from '../interfaces/notification';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from '../enums/modal.enum';
import { SnackBarService } from '../services/snackBar.service';
import { GpsNotificationComponent } from 'src/app/shared/gps-notification/gps-notification.component';
import { ServiceMessages } from '../messages/service-messages.enum';
import { Global } from '../resources/global';

//  this.subscribeToNotifications(
//    NotificationTypes.NEGOTIATION,
//    notification => this.createGeneralNotification(notification, '¡Negociación!')
//  );
//  this.subscribeToNotifications(
//    NotificationTypes.TRACKING,
//    notification => this.createGeneralNotification(notification, '¡Seguimiento!')
//  );
//  this.subscribeToNotifications(
//    NotificationTypes.CREATE_CARGO,
//    notification => this.createGeneralNotification(notification, '¡Carga creada!')
//  );
//  this.subscribeToNotifications(
//    NotificationTypes.USER_DEACTIVATE,
//    notification => this.createGeneralNotification(notification, '¡Usuario desactivado!')
//  );
//  this.subscribeToNotifications(
//    NotificationTypes.DEFAULT_ALERT,
//    notification => this.createPanicNotification(notification)
//  );

@Injectable({ providedIn: 'root' })
export class NotificationResolver implements Resolve<any> {

    constructor(
        private roleService: RoleService,
        private userResolver: UserResolver,
        private dialog: MatDialog,
        private snackBar: SnackBarService,
        private global: Global
    ) { }

    private subscribePanic() {
        this.subscribeToNotifications(
            NotificationTypes.PANIC,
            notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
            NotificationTypes.GPS_OFF,
            notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
            NotificationTypes.ANTENNA,
            notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
            NotificationTypes.VEHICLE_OFF,
            notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
            NotificationTypes.LOW_BATTERY,
            notification => this.createPanicNotification(notification)
        );

        this.subscribeToNotifications(
            NotificationTypes.OPENED_DOOR,
            notification => this.createPanicNotification(notification)
        );
    }

    private subscribeGeofence() {
        this.subscribeToNotifications(
            NotificationTypes.GEOFENCE,
            notification => this.createGeneralNotification(notification, '¡Notificación de geocerca!')
        );

        this.subscribeToNotifications(
            NotificationTypes.DELAY_BETWEEN_POINTS,
            notification => this.createGeneralNotification(notification, '¡Carga creada!')
        );
    }

    private _subscribeToNotifications() {
        const role = this.roleService.getRoleUser();
        if (role && Array.isArray(role.notificationTypes)) {
            const notifications = role.notificationTypes;

            if (notifications.includes(NotificationRole.GEOFENCE))
                this.subscribeGeofence();

            if (notifications.includes(NotificationRole.PANIC))
                this.subscribePanic();
        }

    }

    /**
   * @description Create a new type base notification watcher
   * @param {NotificationTypes} type: the type of notification based on NotificationTypes enum
   * @param {(Notification) => void} callback: some callback that recieves a notification as argument.
  */
    private subscribeToNotifications(type: NotificationTypes, callback?: (notification: Notification) => void): void {
        let isFirstTime = true;
        const last_notification = FirebaseApi.firestore.collection('LastNotification').doc(type);
        last_notification.onSnapshot((snapshot) => {
            if (isFirstTime) {
                isFirstTime = false;
                return;
            }
            const notification: Notification = snapshot.data() as Notification;
            MainComponent.listNotifications.push({
                data: {
                    type: notification.type,
                    cargoId: notification.cargoId,
                    message: notification.description,
                    consecutive: notification.consecutive,
                    extraInformation: notification.extraInformation,
                },
                from: '',
                priority: ''
            });
            !!callback ? callback(notification) : 0;
        });
    }

    /**
   * @description Creates a panic popup and generates a push notification to the browser.
   * @param {Notification} data: Notification stored in db
   * @returns {MatDialogRef}
   */
    private createPanicNotification(data: Notification) {
        const config = new MatDialogConfig();
        config.data = data;
        config.maxHeight = ModalEnum.MAX_HEIGHT;
        config.width = ModalEnum.LARGE_WIDTH;
        config.maxWidth = ModalEnum.MAX_WIDTH;
        config.autoFocus = false;
        config.disableClose = true

        const dialog = this.dialog.open(GpsNotificationComponent, config);
        this.createGeneralNotification(data, "¡Crítico!");
        return dialog;
    }

    /**
   * @description Create / try create a browser push notification
   * @param {Notification} data: Notification stored in db
   * @param {string} title: Title of the push notification
   * @returns {MatDialogRef}
  */
    private createGeneralNotification(data, title: string) {
        Notification.requestPermission()
            .then(() => {
                const img = this.global.pathImgLogoteclogi;
                const text = `Carga ${data.consecutive} - ${data.description}`;
                const notification = new Notification(title, { body: text, icon: img });
            })
            .catch((error) => {
                console.error(error);
                this.snackBar.openSnackBar(ServiceMessages.CANNOT_SEND_NOTIFICATIONS, undefined, 'alert')
            });
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Observable(
            (observer) => {
                const userResolver = this.userResolver.resolve(route, state);

                if (isObservable(userResolver)) {
                    (userResolver as Observable<User | void>)
                        .subscribe(
                            () => this._subscribeToNotifications(),
                            (err) => console.error(err),
                            () => {
                                observer.next(true);
                                observer.complete();
                            }
                        );
                } else if (userResolver instanceof Promise) {
                    userResolver
                        .then(() => this._subscribeToNotifications())
                        .catch((err) => console.error(err))
                        .finally(
                            () => {
                                observer.next(true);
                                observer.complete();
                            }
                        );
                } else {
                    this._subscribeToNotifications()
                    observer.next(true);
                    observer.complete();
                }
            }
        );

    }
}
