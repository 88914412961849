import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { BasicResponse } from 'src/app/core/interfaces/basicResponse';
import { Company, Model } from 'src/app/core/interfaces/company';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
import { Permission } from 'src/app/core/resources/permission';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CompaniesService } from '../list-companies.service';
import { Utils } from 'src/app/core/resources/utils';
import { CostCenterFormComponent } from '../cost-center-form/cost-center-form.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ModalEnum } from 'src/app/core/enums/modal.enum';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { AuthService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { IntegrationEnum } from 'src/app/core/enums/integration.enum';


@Component({
  selector: 'app-cost-center-list',
  templateUrl: './cost-center-list.component.html',
  styleUrls: ['./cost-center-list.component.scss']
})
export class CostCenterListComponent {

  permission = Permission;
  @Input() company: Company;
  @Input() costCenters: CostCenter[] = [];
  @Input() activeTab: string;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  private catalogGeneralCostCenter: CostCenter[] = [];
  public listGeneralCostCenter: Observable<CostCenter[]>;
  public costCenterCtrl: FormControl = new FormControl('', Validators.required);

  constructor(
    private companiesService: CompaniesService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private modelCompany: Model,
    public dialog: MatDialog,
    private authService: AuthService,
    public permissionRole: PermissionRole,
    public utils: Utils
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.activeTab && this.activeTab && this.activeTab === 'Centros de Costo') this.getCostCenters();
  }

  public getCostCenters(): void {
    this.spinner.show();
    this.companiesService.getCostCenters().toPromise()
      .then((success) => {
        this.catalogGeneralCostCenter = success;
        this.createInstanceAutocomplete();
      })
      .catch((error) => {
        this.catalogGeneralCostCenter = [];
      })
      .finally(() => this.spinner.hide());
  }

  private createInstanceAutocomplete(): void {
    if (this.catalogGeneralCostCenter && this.catalogGeneralCostCenter.length) {
      this.listGeneralCostCenter = this.costCenterCtrl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        map(value => {
          return this.catalogGeneralCostCenter.filter((option) => {
            return option.name.toLowerCase().indexOf(value.toString()) !== -1;
          });
        })
      );
    }

  }

  public displayCostCenterSelected(costCenter: CostCenter): string {
    return costCenter ? costCenter.name || '' : '';
  }

  public onSubmit(): void {
    if (this.costCenterCtrl.valid) {
      this.associateCostCenter(this.costCenterCtrl.value);
    } else {
      this.snackBarService.openSnackBar('Debes seleccionar un Centro de Costo', undefined, 'alert');
    }
  }

  private associateCostCenter(costCenter: CostCenter): void {
    this.spinner.show();
    this.companiesService.associateCostCenter(costCenter, this.company.companyId).toPromise()
      .then((success: BasicResponse) => {
        if (success.message === "Update" || success.message === "Actualizado") {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Centro de costo asociado correctamente');
          this.refreshList.emit();
        } else if (success.message) {
          this.spinner.hide();
          this.snackBarService.openSnackBar(success.message, undefined, 'error');
        } else {
          this.spinner.hide();
          this.snackBarService.openSnackBar('Ocurrió un error asociando el Centro de costo', undefined, 'error');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar('Ocurrió un error al enviar la información', undefined, 'error');
      });
  }

  openCreateCostCenter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      nitCompany: this.company.companyId,
      nameCompany: this.company.name
    };
    dialogConfig.maxHeight = ModalEnum.MAX_HEIGHT;
    dialogConfig.width = ModalEnum.EXTRA_SMALL_WIDTH;
    dialogConfig.maxWidth = ModalEnum.MAX_WIDTH;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(CostCenterFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.state) {
        this.refreshList.emit();
        this.getCostCenters();
      }
    });
  }

  get canCreateCostCenter(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.createCostCenter
    ) && !this.hasSiigoIntegration;
  }

  get canAssociateCostCenter(): boolean {
    return this.permissionRole.hasPermission(
      this.permission.payments.module,
      this.permission.payments.associateCostCenter
    ) && this.hasSiigoIntegration;
  }

  get hasSiigoIntegration(): boolean {
    return this.authService.getCompany().integrationCredentials &&
      this.authService.getCompany().integrationCredentials.length &&
      this.authService.getCompany().integrationCredentials.some(integration => integration && integration.integrationChannel &&
        integration.integrationChannel.toLowerCase() === IntegrationEnum.SIIGO.toLowerCase() && integration.state);
  }

}
