<div class="massive-payments-button-container">
  <div class="massive-payments-container">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="massive-payments-content" *ngIf="massivePaymentsList && massivePaymentsList.length">
      <div class="header-massive-payments">
        <p class="title-massive-payments">Estado de los servicios seleccionados</p>
        <p class="subtitle-massive-payments">Se ingresó el consecutivo de {{massivePaymentsList.length}} {{
          massivePaymentsList.length > 1 ? 'servicios' : 'servicio'}}. Al continuar solo se seleccionaran los
          habilitados para
          pago.</p>
      </div>
      <div *ngIf="loadsSelectedList && loadsSelectedList.length && activeTab === 0" class="button-container">
        <button mat-raised-button color="primary" class="button-width-auto" (click)="payMassivePayments()"
          [disabled]="!(loadsSelectedList && loadsSelectedList.length)"
          [ngClass]="{'disable': !(loadsSelectedList && loadsSelectedList.length), 'bg-color-primary': loadsSelectedList && loadsSelectedList.length}">
          Pagar servicios
        </button>
      </div>
      <mat-tab-group (selectedIndexChange)="onSelectTab($event)">
        <mat-tab *ngIf="massiveAvailableLoadsListShow && massiveAvailableLoadsListShow.length"
          [label]="getLoadLabel('Servicios habilitados para pago', massiveAvailableLoadsListShow.length)">
          <app-list-massive-payments-loads [massiveLoadsList]="massiveAvailableLoadsListShow" [typeList]="typeList"
            [activeSelected]="true" (loadsSelected)="loadsSelected($event)"></app-list-massive-payments-loads>
        </mat-tab>
        <mat-tab *ngIf="massiveUnavaliableLoadsListShow && massiveUnavaliableLoadsListShow.length"
          [label]="getLoadLabel('Servicios inhabilitados para pago', massiveUnavaliableLoadsListShow.length)">
          <app-list-massive-payments-loads [massiveLoadsList]="massiveUnavaliableLoadsListShow" [typeList]="typeList"
            [activeSelected]="false"></app-list-massive-payments-loads>
        </mat-tab>
        <mat-tab *ngIf="massiveNonExistentLoadsListShow && massiveNonExistentLoadsListShow.length"
          [label]="getLoadLabel('Servicios inexistentes', massiveNonExistentLoadsListShow.length)">
          <p class="subtitle-massive-payments non-existent-loads">Servicios que no están creados/registrados en el
            sistema:
            {{ getNonExistentLoadsList(massiveNonExistentLoadsListShow)}}</p>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>