<form class="modal-body wrapper-modal py-4 mx-2 mx-md-4 position-relative row" [formGroup]="form" (submit)="onSubmit()">
    <i matTooltip="Cerrar" class="fa-solid fa-xmark fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <h3 class="text-bold text-center col-12 px-3 mb-3">
        {{paramsDialog?.type==='license'? 'Licencia del vehículo' : 'Datos del vehículo'}}
    </h3>

    <!--Basic fields-->
    <div *ngIf="paramsDialog?.type==='vehicle'" class="d-flex flex-wrap">
        <app-type-vehicle class="col-lg-4 col-md-6 h-auto" [options]="optionsVehicleType"
            [inputFormControl]="vehicleTypeControl" [validate]="vehicleValidate">
        </app-type-vehicle>
        <app-vehicle-body-work-type class="col-lg-4 col-md-6" [inputFormControl]="bodyworkTypeControl"
            [validate]="bodyworkValidate" [options]="optionsBodyworkType"></app-vehicle-body-work-type>

        <!-- Registration date vehicle -->
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Fecha de matriculación</mat-label>
            <input matInput [matDatepickerFilter]="registrationDateFilter" [matDatepicker]="pickerRegistrationDate" [formControl]="form.get('registrationDate')"
                readonly (click)="pickerRegistrationDate.open()" class="cursor-pointer">
            <mat-datepicker-toggle matSuffix [for]="pickerRegistrationDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerRegistrationDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-lg-4 col-md-6"
            *ngFor="let field of global?.vehicleEditableFieldsAlias | keyvalue:originalOrder">
            <mat-label>{{field.value}}</mat-label>
            <input matInput type="text" [formControl]="form?.get(field.key)">
        </mat-form-field>
    </div>


    <!--Valid RTM-->
    <div *ngIf="paramsDialog?.type==='RTM'" class="d-flex flex-wrap w-100">
        <label class="col-12 text-bold">RTM</label>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Fecha de expiración</mat-label>
            <input matInput [matDatepicker]="pickerRTMexpiration" [formControl]="form.get('validRTM.expirationDate')"
                readonly (click)="pickerRTMexpiration.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerRTMexpiration"></mat-datepicker-toggle>
            <mat-datepicker #pickerRTMexpiration></mat-datepicker>
        </mat-form-field>
        <div class="col-lg-4 col-md-6">
            <label class="label-radio-button">¿Está activo?</label>
            <mat-radio-group [formControl]="form.get('validRTM.active')"
                class="d-flex justify-content-around flex-wrap">
                <mat-radio-button [value]="true">
                    Si
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    No
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>


    <!--Valid SOAT-->
    <div *ngIf="paramsDialog?.type==='SOAT'" class="d-flex flex-wrap">
        <label class="col-12 text-bold">SOAT</label>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6 mr-lg-2">
            <mat-label>Fecha de expiración</mat-label>
            <input matInput [matDatepicker]="pickerSOATexpiration" [formControl]="form.get('validSOAT.expirationDate')"
                readonly (click)="pickerSOATexpiration.open()">
            <mat-datepicker-toggle matSuffix [for]="pickerSOATexpiration"></mat-datepicker-toggle>
            <mat-datepicker #pickerSOATexpiration></mat-datepicker>
        </mat-form-field>
        <div class="col-lg-4 col-md-6">
            <label class="label-radio-button">¿Está activo?</label>
            <mat-radio-group [formControl]="form.get('validSOAT.active')"
                class="d-flex justify-content-around flex-wrap">
                <mat-radio-button [value]="true">
                    Si
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    No
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Compañía de seguros</mat-label>
            <input matInput type="text" [formControl]="form?.get('validSOAT.companyName')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Número de la compañía</mat-label>
            <input matInput type="text" [formControl]="form?.get('validSOAT.companyNumber')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Número del seguro</mat-label>
            <input matInput type="text" [formControl]="form?.get('validSOAT.number')">
        </mat-form-field>
    </div>

    <!--License-->
    <div *ngIf="paramsDialog?.type==='license'" class="d-flex flex-wrap">
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Categoría<span class="asterisk">*</span></mat-label>
            <input matInput type="text" [formControl]="form?.get('category')">
            <mat-error *ngIf="form?.get('category').errors?.maxlength || form?.get('category').errors?.minlength">
                La categoría debe corresponder a 2 caracteres
            </mat-error>
            <mat-error *ngIf="form?.get('category').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>N° de licencia<span class="asterisk">*</span></mat-label>
            <input matInput type="text" onlyNumbersInt [formControl]="form?.get('numberLicense')">
            <mat-error *ngIf="form?.get('numberLicense').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Restricciones</mat-label>
            <input matInput type="text" [formControl]="form?.get('restrictions')">
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Fecha de expedición<span class="asterisk">*</span></mat-label>
            <input matInput [matDatepicker]="licenseExpeditionPicker" [formControl]="form.get('expeditionDate')"
                readonly (click)="licenseExpeditionPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="licenseExpeditionPicker"></mat-datepicker-toggle>
            <mat-datepicker #licenseExpeditionPicker></mat-datepicker>
            <mat-error *ngIf="form?.get('expeditionDate').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-lg-4 col-md-6">
            <mat-label>Fecha de expiración<span class="asterisk">*</span></mat-label>
            <input matInput [matDatepicker]="licenseExpirationPicker" [formControl]="form.get('expirationDate')"
                readonly (click)="licenseExpirationPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="licenseExpirationPicker"></mat-datepicker-toggle>
            <mat-datepicker #licenseExpirationPicker></mat-datepicker>
            <mat-error *ngIf="form?.get('expirationDate').errors?.required">
                {{ utils.giveMessageError('MANDATORY_FIELD') }}
            </mat-error>
        </mat-form-field>
        <div class="col-lg-4 col-md-6">
            <label class="label-radio-button">¿Está activa?<span class="asterisk">*</span></label>
            <mat-radio-group [formControl]="form.get('active')" class="d-flex justify-content-around flex-wrap">
                <mat-radio-button [value]="true">
                    Si
                </mat-radio-button>
                <mat-radio-button [value]="false">
                    No
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="col-md-12 button-container">
        <button mat-raised-button color="primary" type="button" class="primary-secondary" (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-raised-button color="primary" type="submit" class="bg-color-primary">
            Guardar
        </button>
    </div>
</form>