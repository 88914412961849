// Angular
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

// Routing's
import { AdministrationRoutingModule } from "./administration-routing.module";

// Components
import { AdminUsersListComponent } from "./admin-users/admin-users-list/admin-users-list.component";
import { AdminUsersItemComponent } from "./admin-users/admin-users-item/admin-users-item.component";
import { SharedModule } from "src/app/core/shared.module";
import { UserFormComponent } from "src/app/modules/administration/admin-users/user-form/user-form.component";
import { FormLegalTermsComponent } from "./legal-terms/form-legal-terms/form-legal-terms.component";
import { ListTermsAndConditionsComponent } from "./legal-terms/list-terms-and-conditions/list-terms-and-conditions.component";
import { ItemLegalTermsComponent } from "./legal-terms/item-legal-terms/item-legal-terms.component";
import { ListVehiclesComponent } from "./vehicles/list-vehicles/list-vehicles.component";
import { ItemVehicleComponent } from "./vehicles/item-vehicle/item-vehicle.component";
import { DetailVehicleComponent } from "./vehicles/detail-vehicle/detail-vehicle.component";
import { VehiclesService } from "./vehicles/list-vehicles.service";
import { ListCompaniesComponent } from "./companies/list-companies/list-companies.component";
import { ItemCompanyComponent } from "./companies/item-company/item-company.component";
import { DetailCompanyComponent } from "./companies/form-company/form-company.component";
import { PersonVehicleComponent } from "./vehicles/person-vehicle/person-vehicle.component";
import { FormVehicleComponent } from "./vehicles/form-vehicle/form-vehicle.component";
import { ListRolesComponent } from "./roles/list-roles/list-roles.component";
import { FormRoleComponent } from "./roles/form-role/form-role.component";
import { ItemRoleComponent } from "./roles/item-role/item-role.component";
import { TrailerVehicleComponent } from "./vehicles/trailer-vehicle/trailer-vehicle.component";
import { CreateDriverComponent } from "./vehicles/create-driver/create-driver.component";
import { ModuleItemComponent } from "./module/module-item/module-item.component";
import { ModuleListComponent } from "./module/module-list/module-list.component";
import { ModuleFormComponent } from "./module/module-form/module-form.component";
import { FunctionalitiesFormComponent } from "./Functionalities/functionalities-form/functionalities-form.component";
import { FunctionalitiesListComponent } from "./Functionalities/functionalities-list/functionalities-list.component";
import { FunctionalitiesItemComponent } from "./Functionalities/functionalities-item/functionalities-item.component";
import { CityFormComponent } from "./city/city-form/city-form.component";
import { IcaFormComponent } from "./city/ica-form/ica-form.component";
import { CityItemComponent } from "./city/city-item/city-item.component";
import { CityListComponent } from "./city/city-list/city-list.component";
import { SubcityItemComponent } from "./city/subcity-item/subcity-item.component";
import { CostCenterListComponent } from "./companies/cost-center-list/cost-center-list.component";
import { CostCenterItemComponent } from "./companies/cost-center-item/cost-center-item.component";
import { filterCompaniesPipe } from "src/app/core/pipe/filterCompanies.pipe";
import { GpsVehicleComponent } from "./vehicles/gps-vehicle/gps-vehicle.component";
import { BankAccountComponent } from "./vehicles/bank-account/bank-account.component";
import { ContactPersonVehicleComponent } from "./vehicles/contact-person-vehicle/contact-person-vehicle.component";
import { VehicleDocumentsComponent } from "./vehicles/vehicle-documents/vehicle-documents.component";
import { VehicleContactsComponent } from "./vehicles/vehicle-contacts/vehicle-contacts.component";
import { UserDocumentsComponent } from "./admin-users/user-documents/user-documents.component";
import { VehicleTripsComponent } from "./vehicles/vehicle-trips/vehicle-trips.component";
import { InputAttachmentComponent } from "./vehicles/input-attachment/input-attachment.component";
import { CreateGpsVehicleComponent } from "./vehicles/create-gps-vehicle/create-gps-vehicle.component";
import { SummaryUserComponent } from "./admin-users/summary-user/summary-user.component";
import { SetAdministratorComponent } from "./vehicles/set-administrator/set-administrator.component";
import { DeleteGpsVehicleComponent } from "./vehicles/delete-gps-vehicle/delete-gps-vehicle.component";
import { DuplicatedPhoneComponent } from './vehicles/duplicated-phone/duplicated-phone.component';
import { ReceivableCreateComponent } from "./receivable/receivable-create/receivable-create.component";
import { ReceivableListComponent } from "./receivable/receivable-list/receivable-list.component";
import { ReceivableDetailComponent } from "./receivable/receivable-detail/receivable-detail.component";
import { ReceivableModificationsComponent } from "./receivable/receivable-modifications/receivable-modifications.component";
import { ReceivableModificationsDetailComponent } from "./receivable/receivable-modifications-detail/receivable-modifications-detail.component";
import { ReceivableFiltersComponent } from "./receivable/receivable-filters/receivable-filters.component";
import { FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppInterceptorService } from "src/app/core/interceptors/app.interceptor";
import { HistoryAndMetricsCompanyComponent } from './companies/history-and-metrics-company/history-and-metrics-company.component';
import { HistoryFilterComponent } from './companies/history-filter/history-filter.component';
import { HistoryAndMetricsCompanyDetailComponent } from './companies/history-and-metrics-company-detail/history-and-metrics-company-detail.component';
import { CompanyResourcesComponent } from './companies/company-resources/company-resources.component';
import { UserClientFormComponent } from "./admin-users/user-client-form/user-client-form.component";
import { EmailItemComponent } from './companies/email-item/email-item.component';
import { EmailListComponent } from './companies/email-list/email-list.component';
import { CostCenterFormComponent } from './companies/cost-center-form/cost-center-form.component';
import { AdditionalServicesConfigComponent } from './companies/additional-services-config/additional-services-config.component';
import { RequestTermsDialogComponent } from './request-terms-dialog/request-terms-dialog.component';
import { ChangePhoneDialogComponent } from './change-phone-dialog/change-phone-dialog.component';
import { ListTrailersComponent } from './trailers/list-trailers/list-trailers.component';
import { ItemTrailerComponent } from './trailers/item-trailer/item-trailer.component';
import { CreateTrailerComponent } from './trailers/create-trailer/create-trailer.component';
import { ManualAcceptComponent } from './request-terms-dialog/manual-accept/manual-accept.component';
import { LegalTermsComponent } from "./legal-terms/legal-terms.component";
import { ListPrivacyPolicyComponent } from './legal-terms/list-privacy-policy/list-privacy-policy.component';
import { ListPersonaDataComponent } from './legal-terms/list-persona-data/list-persona-data.component';
import { CompanyConfigComponent } from './companies/company-config/company-config.component';
import { TagsComponent } from './companies/tags/tags.component';
import { TagFormComponent } from './companies/tags/tag-form/tag-form.component';
import { UpdateVehicleFieldsComponent } from './vehicles/update-vehicle-fields/update-vehicle-fields.component';
import { ComplimentsComponent } from './companies/compliments/compliments.component';
import { ComplimentFormComponent } from './companies/compliment-form/compliment-form.component';
import { DocsStateComponent } from './admin-users/docs-state/docs-state.component';
import { MaintenanceListVehicleComponent } from './maintenance/maintenance-list-vehicle/maintenance-list-vehicle.component';
import { MaintenanceFilterComponent } from './maintenance/maintenance-filter/maintenance-filter.component';
import { MaintenanceItemVehicleComponent } from './maintenance/maintenance-item-vehicle/maintenance-item-vehicle.component';
import { MaintenanceListDetailComponent } from './maintenance/maintenance-list-detail/maintenance-list-detail.component';
import { MaintenanceItemDetailComponent } from './maintenance/maintenance-item-detail/maintenance-item-detail.component';
import { MaintenanceSummaryComponent } from './maintenance/maintenance-summary/maintenance-summary.component';
import { MaintenanceEvidencesComponent } from './maintenance/maintenance-evidences/maintenance-evidences.component';
import { CreateEditMaintenanceComponent } from './maintenance/create-edit-maintenance/create-edit-maintenance.component';
import { CreateEditFormMaintenanceComponent } from './maintenance/create-edit-form-maintenance/create-edit-form-maintenance.component';
@NgModule({
  imports: [SharedModule, AdministrationRoutingModule, FormsModule],
  declarations: [
    AdminUsersListComponent,
    AdminUsersItemComponent,
    UserFormComponent,
    UserClientFormComponent,
    ListTermsAndConditionsComponent,
    FormLegalTermsComponent,
    ItemLegalTermsComponent,
    ListVehiclesComponent,
    ItemVehicleComponent,
    DetailVehicleComponent,
    ListCompaniesComponent,
    ItemCompanyComponent,
    DetailCompanyComponent,
    PersonVehicleComponent,
    FormVehicleComponent,
    ListRolesComponent,
    FormRoleComponent,
    ItemRoleComponent,
    TrailerVehicleComponent,
    CreateDriverComponent,
    ModuleItemComponent,
    ModuleListComponent,
    ModuleFormComponent,
    FunctionalitiesFormComponent,
    FunctionalitiesListComponent,
    FunctionalitiesItemComponent,
    CityFormComponent,
    IcaFormComponent,
    CityItemComponent,
    CityListComponent,
    SubcityItemComponent,
    ModuleFormComponent,
    CostCenterListComponent,
    CostCenterItemComponent,
    filterCompaniesPipe,
    GpsVehicleComponent,
    CreateGpsVehicleComponent,
    BankAccountComponent,
    ContactPersonVehicleComponent,
    VehicleDocumentsComponent,
    VehicleContactsComponent,
    UserDocumentsComponent,
    VehicleTripsComponent,
    InputAttachmentComponent,
    SummaryUserComponent,
    SetAdministratorComponent,
    DeleteGpsVehicleComponent,
    DuplicatedPhoneComponent,
    ReceivableCreateComponent,
    ReceivableListComponent,
    ReceivableDetailComponent,
    ReceivableModificationsComponent,
    ReceivableModificationsDetailComponent,
    ReceivableFiltersComponent,
    HistoryAndMetricsCompanyComponent,
    HistoryFilterComponent,
    HistoryAndMetricsCompanyDetailComponent,
    CompanyResourcesComponent,
    EmailItemComponent,
    EmailListComponent,
    CostCenterFormComponent,
    AdditionalServicesConfigComponent,
    RequestTermsDialogComponent,
    ChangePhoneDialogComponent,
    ManualAcceptComponent,
    LegalTermsComponent,
    ListPrivacyPolicyComponent,
    ListPersonaDataComponent,
    CompanyConfigComponent,
    ListTrailersComponent,
    ItemTrailerComponent,
    CreateTrailerComponent,
    TagsComponent,
    TagFormComponent,
    UpdateVehicleFieldsComponent,
    ComplimentsComponent,
    ComplimentFormComponent,
    DocsStateComponent,
    MaintenanceListVehicleComponent,
    MaintenanceFilterComponent,
    MaintenanceItemVehicleComponent,
    MaintenanceListDetailComponent,
    MaintenanceItemDetailComponent,
    MaintenanceSummaryComponent,
    MaintenanceEvidencesComponent,
    CreateEditMaintenanceComponent,
    CreateEditFormMaintenanceComponent
  ],
  providers: [VehiclesService, { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorService, multi: true }],
  entryComponents: [
    CreateDriverComponent,
    TrailerVehicleComponent,
    GpsVehicleComponent,
    CreateGpsVehicleComponent,
    SetAdministratorComponent,
    DeleteGpsVehicleComponent,
    UpdateVehicleFieldsComponent,
    DuplicatedPhoneComponent,
    ReceivableCreateComponent,
    ReceivableModificationsComponent,
    IcaFormComponent,
    ReceivableModificationsDetailComponent,
    CostCenterFormComponent,
    RequestTermsDialogComponent,
    ChangePhoneDialogComponent,
    ManualAcceptComponent,
    TagFormComponent,
    ComplimentFormComponent,
    MaintenanceEvidencesComponent,
    CreateEditMaintenanceComponent
  ],
  exports: [
    AdminUsersListComponent,
    AdminUsersItemComponent,
    UserFormComponent,
    UserClientFormComponent,
    ListTermsAndConditionsComponent,
    FormLegalTermsComponent,
    ItemLegalTermsComponent,
    ListVehiclesComponent,
    ItemVehicleComponent,
    DetailVehicleComponent,
    ListCompaniesComponent,
    ItemCompanyComponent,
    DetailCompanyComponent,
    PersonVehicleComponent,
    FormVehicleComponent,
    ListRolesComponent,
    FormRoleComponent,
    ItemRoleComponent,
    TrailerVehicleComponent,
    CreateDriverComponent,
    FunctionalitiesFormComponent,
    FunctionalitiesListComponent,
    FunctionalitiesItemComponent,
    GpsVehicleComponent,
    CreateGpsVehicleComponent,
    DeleteGpsVehicleComponent,
    HistoryAndMetricsCompanyComponent,
    LegalTermsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdministrationModule { }
