<div class="main-container">
    <i matTooltip="Cerrar" class="fas fa-times fa-2xl close-icon-actions" (click)="dialogRef.close()"></i>
    <div class="cards-container">
        <mat-card-header class="title">
            {{title}}
        </mat-card-header>
        <mat-card-content class="content" *ngIf="!licenses?.length">
            <div class="card" *ngFor="let cargo of cargos">
                {{cargo.licensePlate}}
            </div>
        </mat-card-content>
        <mat-card-content class="content pb-4" *ngIf="licenses.length">
            <div *ngFor="let license of licenses" class="container-card"
                [ngClass]="{'color-red': license.isLicenseExpired}">
                <div class="detail">
                    <div>
                        <span class="title-item">Categoria: </span>
                        <div>{{license.category}}
                        </div>
                    </div>
                    <div>
                        <span class="title-item">Numero licencia: </span>
                        <div>{{license.numberLicense}}
                        </div>
                    </div>
                    <div *ngIf="license.restrictions">
                        <span class="title-item">Restricción: </span>
                        <div>{{license.restrictions}}
                        </div>
                    </div>
                    <div>
                        <span class="title-item">Fecha de expedición: </span>
                        <div>{{license.expeditionDate | date:'fullDate'}}
                        </div>
                    </div>
                    <div>
                        <span class="title-item">Fecha de expiración: </span>
                        <div>{{license.expirationDate | date:'fullDate'}}
                        </div>
                    </div>
                    <div>
                        <span class="title-item">Estado: </span>
                        <div>{{license.active? 'Activo': 'Inactivo'}}
                        </div>
                    </div>
                </div>

                <div class="d-flex mt-3 justify-content-center align-items-end div-actions" *ngIf="canOverwriteInfo">
                    <button class="button-width-auto secondary-sharp" mat-raised-button color="primary"
                        (click)="editLicense(license)">
                        Editar datos <i class="fas fa-pencil-alt"></i>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </div>
</div>