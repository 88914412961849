import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatButtonToggleChange } from '@angular/material';
import { Observable } from 'rxjs';
import { orderBy } from "lodash";
import { VehiclesService } from '../../vehicles/list-vehicles.service';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { Utils } from 'src/app/core/resources/utils';
import { Patterns } from 'src/app/core/resources/patterns';
import { Permission } from 'src/app/core/resources/permission';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Trailer } from 'src/app/core/interfaces/trailer';
import { LicensePlateComponent } from 'src/app/shared/license-plate/license-plate.component';
import { BodyWorkType } from 'src/app/core/models/body-work-type.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-trailer',
  templateUrl: './create-trailer.component.html',
  styleUrls: ['./create-trailer.component.scss']
})
export class CreateTrailerComponent implements OnInit {
  permission = Permission;
  form: FormGroup = new FormGroup(
    {
      id: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      emptyWeight: new FormControl('', [Validators.required]),
      axles: new FormControl(null, [Validators.required]),
      licensePlate: new FormControl(''),
      hasAdministrator: new FormControl(false, [Validators.required]),
      configuration: new FormGroup({
        code: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required])
      }),
      brand: new FormGroup({
        code: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required])
      }),
      bodywork: new FormGroup({
        code: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required])
      }),
      owner: new FormGroup(
        {
          document: new FormControl('', [Validators.required]),
          documentTypeId: new FormControl('', [Validators.required]),
          documentTypeName: new FormControl('', [Validators.required]),
          name: new FormControl('', [Validators.required]),
          address: new FormControl(''),
          municipalityCode: new FormControl(''),
        }
      ),
      administrator: new FormGroup(
        {
          document: new FormControl(''),
          documentTypeId: new FormControl(''),
          documentTypeName: new FormControl(''),
          name: new FormControl(''),
          address: new FormControl(''),
          municipalityCode: new FormControl(''),
        }
      ),
      propertyCard: new FormControl('', [Validators.required]),
      picture: new FormControl('', [Validators.required])
    }
  );

  bodyWorkCode: Array<string> = ["71", "72", "73", "74", "81", "82", "83", "84", "85"];
  listBodyWork: CatalogItem[] = [];
  listTrailerBrand: CatalogItem[] = [];
  filterTrailerBrand: Observable<CatalogItem[]>;
  filterBodyWork: Observable<CatalogItem[]>;
  filterConfiguration: Observable<any[]>;
  filterConfigurationLoading = false;
  vehicleType: 24 | 41 = 41;
  trailerDescription: string = '';
  @ViewChild('trailerLicensePlate', { static: true }) licensePlate: LicensePlateComponent;
  @ViewChild('vehicleLicensePlate', { static: true }) vehicleLicensePlate: LicensePlateComponent;

  constructor(
    private vehicleService: VehiclesService,
    private patterns: Patterns,
    private snackbarService: SnackBarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public utils: Utils,
    private spinner: NgxSpinnerService
  ) {
    this.getTrailerBrand();

    const id = this.activatedRoute.snapshot.params.id;
    if (!!id) {
      this.loadTrailer(id);
    }
  }

  loadTrailer(id: string): void {
    this.vehicleService.getTrailerList(`?id=${id}`).subscribe(
      (trailers: Trailer[]) => {
        if (trailers instanceof Array) {
          const trailer = trailers[0];
          this.form.patchValue(trailer);
          if (trailer && trailer.configuration && trailer.configuration.description) this.trailerDescription = trailer.configuration.description;
          this.adminValidations(this.form.get('hasAdministrator').value);
          this.licensePlate.setValueLicensePlate(trailer.id);
          this.vehicleLicensePlate.setValueLicensePlate(trailer.licensePlate);
          this.onSelectConfiguration({ option: { value: this.form.get('configuration').value } } as any);
        }
      }
    )
  }

  ngOnInit() {
    this.filterConfiguration = this.form.get("axles").valueChanges.pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((value) => {
        if (typeof value === "number" && value > 0) {
          this.filterConfigurationLoading = true;
          return this.getVehicleConfig(value)
            .toPromise()
            .then((response) => {
              return response;
            })
            .finally(() => {
              this.filterConfigurationLoading = false;
              this.trailerDescription && this.form.get('configuration.description').setValue(this.trailerDescription);
            });
        } else {
          return [];
        }
      })
    );
    this.filterTrailerBrand = this.form
      .get("brand.description")
      .valueChanges.pipe(
        startWith(""),
        distinctUntilChanged(),
        map((value) => {
          return this.filterCatalog(value || "", this.listTrailerBrand);
        })
      );
  }

  private filterCatalog(value, list): CatalogItem[] {
    if (value) {
      const filterValue =
        typeof value === "string"
          ? value.toLowerCase()
          : value.name.toLowerCase();
      return list.filter((option) => {
        return option.name.toLowerCase().indexOf(filterValue) !== -1;
      });
    } else {
      return list;
    }
  }

  getTrailerBrand() {
    this.vehicleService.getTrailerBrand().subscribe((data) => {
      this.listTrailerBrand = data.catalog;
    });
  }

  getVehicleConfig(axles): Observable<any[]> {
    return this.vehicleService.getVehicleConfig(axles);
  }

  onSelectConfiguration($event: MatAutocompleteSelectedEvent) {
    this.setCatalogValue('configuration', $event);
    this.getBodyWork($event.option.value.code);
  }

  getBodyWork(bodyWorkCode: string) {
    this.vehicleType = 41;
    for (const code of bodyWorkCode) {
      if (bodyWorkCode === code) {
        this.vehicleType = 24;
        break;
      }
    }

    //servicio que me trae las marcas y las carrocerias
    this.vehicleService.getBodyWork(this.vehicleType).subscribe(
      (success: BodyWorkType[]) => {
        if (success) {
          this.listBodyWork = orderBy(success, ["name"]);
        }
      },
      (error) => { },
      () => {
        this.filterBodyWork = this.form
          .get("bodywork.description")
          .valueChanges.pipe(
            startWith(""),
            distinctUntilChanged(),
            map((value) => {
              return this.filterCatalog(value || "", this.listBodyWork);
            })
          );
      }
    );
  }

  get isDisable(): boolean {
    if (
      this.form.get("id").value &&
      this.patterns.GET_REGEX('TRAILER_PLATES').test(this.form.get("id").value)
    ) {
      this.form.get("propertyCard").enable();
      this.form.get("picture").enable();
      return false;
    } else {
      this.form.get("propertyCard").disable();
      this.form.get("picture").disable();
      return true;
    }
  }

  setCatalogValue(
    nameControl: string,
    $event: MatAutocompleteSelectedEvent
  ) {
    this.form.get(`${nameControl}.code`).setValue($event.option.value.code || $event.option.value.id);
    this.form.get(`${nameControl}.description`).setValue($event.option.value.name);
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      if (this.utils.errorMessagesCustomized(this.form.get('id'), 'placa del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('axles'), 'ejes')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('model'), 'modelo')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('emptyWeight'), 'peso vacío')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('configuration.code'), 'clase')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('bodywork.code'), 'carrocería')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('brand.code'), 'marca')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.documentTypeName'), 'tipo de identificación del propietario del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.document'), 'número de identificación del propietario del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('owner.name'), 'nombre del propietario del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('administrator.documentTypeName'), 'tipo de identificación del administrador del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('administrator.document'), 'número de identificación del administrador del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('administrator.name'), 'nombre del administrador del tráiler')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('propertyCard'), 'foto de la tarjeta de propiedad')) return;
      else if (this.utils.errorMessagesCustomized(this.form.get('picture'), 'foto del tráiler')) return;
    }
    this.createTrailer();
  }

  createTrailer(){
    let form = this.form.value;
    if (!this.form.get('hasAdministrator').value) {
      const { administrator, ...newForm } = this.form.value;
      form = newForm;
    }
    this.spinner.show();
    this.vehicleService.createTrailer(form).subscribe({
      next: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.REFERENCES_SUCCESS_EDIT, "Aceptar", "success");
        this.router.navigate(['administration', 'trailers', 'detail', this.form.get('id').value]);
      },
      error: () => {
        this.spinner.hide();
        this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, "Aceptar", "error");
      }
    })
  }

  onChangeIsSameAdmin($event: MatButtonToggleChange) {
    this.adminValidations($event.value);
    this.clearOwnerForm();
  }

  adminValidations(haveAdmin: boolean) {
    if (haveAdmin){
      this.adminControls.enable();
      this.adminControls.get('documentTypeId').setValidators(Validators.required);
      this.adminControls.get('documentTypeName').setValidators(Validators.required);
      this.adminControls.get('document').setValidators(Validators.required);
      this.adminControls.get('name').setValidators(Validators.required);
    } else {
      this.adminControls.disable();
      this.adminControls.clearValidators();
    }
    this.adminControls.updateValueAndValidity();
  }

  clearOwnerForm() {
    this.form
      .get("administrator")
      .patchValue({
        document: "",
        documentTypeId: "",
        documentTypeName: "",
        name: "",
        address: "",
        municipalityCode: ""
      });
  }

  get ownerControls() {
    return (this.form as FormGroup).get("owner");
  }

  get adminControls() {
    return (this.form as FormGroup).get("administrator");
  }
}
