import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { Global } from 'src/app/core/resources/global';
import PlaceResult = google.maps.places.PlaceResult;
import * as _ from 'lodash';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { Utils } from 'src/app/core/resources/utils';
import { City } from 'src/app/core/interfaces/city';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Company } from 'src/app/core/interfaces/company';
import { AuthService } from 'src/app/core/services/authentication.service';
import { UploadLocationComponent } from '../upload-location/upload-location.component';
import { DownloadLocationComponent } from '../download-location/download-location.component';
import { ThirdPartyConsignmentComponent } from '../third-party-consignment/third-party-consignment.component';
import { MatDialog } from '@angular/material';
import { BranchOffice } from 'src/app/core/interfaces/branch-offices';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { StandardMapComponent } from 'src/app/shared/standard-map/standard-map.component';
import { CargoResources } from '../../resources/cargo';
import { Permission } from 'src/app/core/resources/permission';
import { CompanyService } from 'src/app/core/services/company.service';
import { UploadDownloadMapService } from './uploadDownloadMap.service';
import { DateManager } from 'src/app/core/managers/date.manager';
import { Router } from '@angular/router';
import { InsuranceInformation } from 'src/app/core/interfaces/insuranceInformation';
import { CostCenter } from 'src/app/core/interfaces/costCenter';
declare var google: any;

@Component({
  selector: 'app-upload-download-map-freight',
  templateUrl: './upload-download-map.component.html',
  styleUrls: ['./upload-download-map.component.scss'],
  providers: [Global]
})

export class UploadDownloadMapComponent implements OnInit {

  permission = Permission;
  viewActive: string;
  showLocationsLoad = false;
  activeLocation: { typeLocation?, indexAddress?, indexDestination?} = {};
  panelOpenState = true;
  listCities: City[];
  cleanCity: boolean = false;
  @ViewChild(StandardMapComponent, { static: false }) standardMap: StandardMapComponent;
  @ViewChild(UploadLocationComponent, { static: false }) uploadLocationComponent: UploadLocationComponent;
  @ViewChild(DownloadLocationComponent, { static: false }) downloadLocationComponent: DownloadLocationComponent;
  @ViewChild(ThirdPartyConsignmentComponent, { static: false }) thirdPartyConsignmentComponent: ThirdPartyConsignmentComponent;
  cargoOwner: { documentType, documentNumber, branchOffice };
  numberDocumentSenderForm: string;
  locationSelected: { indexAddress, indexDestination };
  mapOptions: OptionsAutocomplete = {};
  listKeyMarkersOrigin: Array<string> = [];
  listKeyMarkersDestination: Array<string> = [];
  index: number;
  indexDestination: number;
  keyMarker: string = '';
  city: string = '';
  allowCreatingWithoutTruora: boolean = false;
  policy: InsuranceInformation;
  policyExpirated: boolean = false;
  costCenterSetted: CostCenter;
  emptyCostCenter: CostCenter = {
    id: 0,
    code: '',
    name: '',
    active: false,
  }
  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public utils: Utils,
    private global: Global,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private cargoResources: CargoResources,
    private companyService: CompanyService,
    public uploadDownloadMapService: UploadDownloadMapService,
    private router: Router
  ) {
  }



  ngOnInit() {
    this.infoTruora();
    this.setCostCenter();
    if (history && history.state && history.state.type) this.setViewActive(history.state.type);
    else this.setViewActive('upload');
  }

  setCostCenter(){
    let isDefinedCostCenter = this.manualCreationCargoService && this.manualCreationCargoService.cargoForm && this.manualCreationCargoService.cargoForm.get('costCenter') && this.manualCreationCargoService.cargoForm.get('costCenter').value && this.manualCreationCargoService.cargoForm.get('costCenter').value.code;
    if (isDefinedCostCenter) this.costCenterSetted = this.manualCreationCargoService.cargoForm.get('costCenter').value;
    else this.costCenterSetted = this.emptyCostCenter;
  }

  private infoTruora() {
    const holderCompany = this.companyService.holderCompany;
    if (holderCompany && holderCompany.companyId)
      this.allowCreatingWithoutTruora = !!(holderCompany && holderCompany.allowCreatingWithoutTruora);
    if (this.allowCreatingWithoutTruora) this.manualCreationCargoService.cargoForm.get('ministry').setValue(false);
  }

  setViewActive(value: string) {
    this.viewActive = value;
  }

  onSelectOwner(event) {
    if ('companyId' in event) {
      if (event && this.manualCreationCargoService && this.manualCreationCargoService.getOwnerSelected() && event.companyId !== this.manualCreationCargoService.getOwnerSelected().companyId) this.refreshCompaniesList(null);
      this.manualCreationCargoService.setOwnerSelected(event);
      this.setInfoPolicy(event);
    }
    if (event && event.options && event.options.clearListener) {
      this.mapOptionsConfig({
        clickMap: false,
        onlyListener: false,
        removeClickMapListener: true
      })
    } else {
      this.mapOptionsConfig({
        clickMap: true,
        onlyListener: true
      })
    }
    if (!(event as Company).branchOffices) {
      this.manualCreationCargoService.setBranchOfficeSelected(null);
      this.manualCreationCargoService.setSubBranchOfficeSelected(null);
    }
    if (event && event.data && (event.data.focusInput || event.data.refreshMarkers)) {
      this.activeLocation.typeLocation = 'cargoOwner';
      if (event.data.refreshMarkers) {
        this.standardMap.setCenterMap(event.data.location && event.data.location.lat ? event.data.location.lat : null, event.data.location && event.data.location.lng ? event.data.location.lng : null, 16)
        this.saveAddressSelected(event.data);
      }
    } else {
      this.activeLocation.typeLocation = null;
    }
  }

  setInfoPolicy(company: Company) {
    const companySaaS = this.authService && this.authService.getCompanySaaS();
    this.policy = this.uploadDownloadMapService.getApplyPolicyByCompany()
      ? company && company.insuranceInformation && company.insuranceInformation.policyNumber
        ? company.insuranceInformation
        : null
      : companySaaS && companySaaS.insuranceInformation && companySaaS.insuranceInformation.policyNumber
        ? companySaaS.insuranceInformation
        : null
    this.policyExpirated = this.uploadDownloadMapService.getApplyPolicyByCompany()
      ? company && company.insuranceInformation && company.insuranceInformation.expirationDate
        ? DateManager.dateDiff(company.insuranceInformation.expirationDate, 'DD/MM/YYYY', DateManager.setStartOfDay(new Date()), null, 'days') <= 0
        : false
      : companySaaS && companySaaS.insuranceInformation && companySaaS.insuranceInformation.expirationDate
        ? DateManager.dateDiff(companySaaS.insuranceInformation.expirationDate, 'DD/MM/YYYY', DateManager.setStartOfDay(new Date()), null, 'days') <= 0
        : null
    this.uploadDownloadMapService.setPolicyCompany(this.policy);

  }

  public onSelectBranch($event: BranchOffice): void {
    this.manualCreationCargoService.setBranchOfficeSelected($event);
    this.manualCreationCargoService.setSubBranchOfficeSelected(null);
  }

  public onSelectSubBranch($event: BranchOffice): void {
    this.manualCreationCargoService.setSubBranchOfficeSelected($event);
  }

  set addressOriginMapSelected($event: any) {
    if (this.manualCreationCargoService.getCargoForm().get('ministry').value && !this.manualCreationCargoService.formOrigin.get('isInternational').value) {
      (this.uploadLocationComponent.thirdPartyConsignmentList as QueryList<ThirdPartyConsignmentComponent>).toArray()
      [this.locationSelected.indexAddress].addressSelected = { address: $event.address, location: $event.location };
    } else {
      this.manualCreationCargoService.getFormOriginAddress(this.locationSelected.indexAddress).patchValue({
        address: $event.address,
        location: $event.location
      });
    }
  }

  public get ownerSelected(): Company {
    return this.manualCreationCargoService.getOwnerSelected();
  }

  public get branchOfficeSelected(): BranchOffice {
    return this.manualCreationCargoService.getBranchOfficeSelected();
  }

  set addressDestinationMapSelected($event: any) {
    const index = (this.downloadLocationComponent.thirdPartyConsignmentList as QueryList<ThirdPartyConsignmentComponent>).toArray()
      .findIndex((a) => {
        return a.options.index === this.locationSelected.indexAddress &&
          a.options.indexDestination === this.locationSelected.indexDestination;
      });
    if (this.manualCreationCargoService.getCargoForm().get('ministry').value &&
      !this.manualCreationCargoService.getFormDestination(this.locationSelected.indexDestination).get('isInternational').value) {
      if (index > -1) {
        (this.downloadLocationComponent.thirdPartyConsignmentList as QueryList<ThirdPartyConsignmentComponent>).toArray()
        [index].addressSelected = { address: $event.address, location: $event.location };
      }
    } else {
      this.manualCreationCargoService.getFormDestinationAddresses(this.locationSelected.indexDestination)
        .at(this.locationSelected.indexAddress).patchValue(
          $event
        );
    }
  }

  onLoadMap($event) {
    if (this.activeLocation && this.activeLocation.typeLocation) this.transformCoordinatesToAddress($event.latLng.lat(), $event.latLng.lng());
    this.mapOptionsConfig({
      removeClickMapListener: true,
      clickMap: false
    })
  }

  transformCoordinatesToAddress(lat: number, lng: number) {
    let geocoderService = new google.maps.Geocoder;
    let latlng = { lat: lat, lng: lng };
    this.spinner.show();
    geocoderService.geocode({ 'location': latlng }, (results, status) => {
      if (results.length) {
        this.spinner.hide();
        const data = this.setAddressDataToSave(results[0].formatted_address, lat, lng, this.activeLocation ? this.activeLocation.typeLocation : null, this.activeLocation ? this.activeLocation.indexAddress : null, this.activeLocation ? this.activeLocation.indexDestination : null)
        if (this.activeLocation.typeLocation !== 'cargoOwner') {
          let isOrigin = !(!!this.activeLocation.indexDestination || this.activeLocation.indexDestination === 0);
          this.standardMap.createInfoWindow(this.keyMarker, this.createContentInfoWindow(isOrigin, results[0].formatted_address, this.activeLocation ? Number(this.activeLocation.indexAddress) : null, this.city, this.city, this.activeLocation ? Number(this.activeLocation.indexDestination) : null), this.standardMap.markers[this.keyMarker])
        }
        this.saveAddressSelected(data);
      } else {
        this.spinner.hide();
      }
    });
  }

  addressRemovedOrigin(index: number) {
    if (index && this.standardMap) {
      var originAddresses = [];
      this.manualCreationCargoService.formOriginAddresses.controls.forEach((address, index) => {
        if (address && address.get('location') && address.get('location.lat') && address.get('location.lat').value && address.get('location.lng') && address.get('location.lng').value) {
          let originName = this.manualCreationCargoService.getFormOrigin().controls.name.value;
          originAddresses.push({
            index,
            addressName: address.get('address').value,
            originName: originName,
            key: `indexAddress:${index}`,
            lat: address.get('location.lat').value,
            lng: address.get('location.lng').value,
          })
        }
      })
      this.listKeyMarkersOrigin = this.utils.clone(this.standardMap.listMarkersKey);
      this.mapOptionsConfig({
        removeClickMapListener: true,
        clickMap: false
      })
      if (this.listKeyMarkersOrigin && this.listKeyMarkersOrigin.length) {
        this.listKeyMarkersOrigin.forEach(key => {
          if (!key.includes(`indexDestination`)) this.standardMap.removeMarker(key);
        })
        this.listKeyMarkersOrigin = [];
      }
      if (originAddresses && originAddresses.length) {
        originAddresses.forEach(address => {
          if (address && address.key && address.lat && address.lng) this.standardMap.createMarker(address.key, address.lat, address.lng, this.createContentInfoWindow(true, address.addressName, address.index, address.originName), address.index === 0 ? this.global.pathMarkerOrigin : this.global.pathLocationPin, address.index ? (address.index + 1).toString() : null)
        })
      }
    }
  }

  removeAllMarkersOrigin(remove: boolean) {
    if (remove && this.standardMap) {
      this.listKeyMarkersOrigin = this.utils.clone(this.standardMap.listMarkersKey);
      this.mapOptionsConfig({
        removeClickMapListener: true,
        clickMap: false
      })
      if (this.listKeyMarkersOrigin && this.listKeyMarkersOrigin.length) {
        this.listKeyMarkersOrigin.forEach(key => {
          if (!key.includes(`indexDestination`)) this.standardMap.removeMarker(key);
        })
        this.listKeyMarkersOrigin = [];
      }
    }
  }

  removeOneMarkerOneDestination(indexes) {
    if (indexes && (indexes.index || indexes.index === 0) && (indexes.indexDestination || indexes.indexDestination === 0) && this.standardMap) {
      var destinationAddresses = [];
      this.manualCreationCargoService.formDestinations.controls.forEach((destination, indexDestination) => {
        if (destination && destination.get('addresses') && destination.get('addresses').value && destination.get('addresses').value.length && indexes.indexDestination === indexDestination) {
          destination.get('addresses').value.forEach((address, index) => {
            if (address && address.location && address.location.lat && address.location.lng) {
              destinationAddresses.push({
                index,
                indexDestination,
                destination: destination.get('name').value,
                addressName: address.address,
                key: `indexAddress:${index}&indexDestination:${indexDestination}`,
                lat: address.location.lat,
                lng: address.location.lng,
              })
            }
          });
        }
      })
      this.listKeyMarkersDestination = this.utils.clone(this.standardMap.listMarkersKey);
      this.mapOptionsConfig({
        removeClickMapListener: true,
        clickMap: false
      })
      if (this.listKeyMarkersDestination && this.listKeyMarkersDestination.length) {
        this.listKeyMarkersDestination.forEach(key => {
          if (key.includes(`indexDestination:${indexes.indexDestination}`)) this.standardMap.removeMarker(key);
        })
        this.listKeyMarkersDestination = [];
      }
      if (destinationAddresses && destinationAddresses.length) {
        destinationAddresses.forEach(address => {
          if (address && address.key && address.lat && address.lng) this.standardMap.createMarker(address.key, address.lat, address.lng, this.createContentInfoWindow(false, address.addressName, address.index, null, address.destination, address.indexDestination), address.index === 0 ? this.global.pathMarkerOrigin : this.global.pathLocationPin, address.index ? (address.index + 1).toString() : null)
        })
      }

    }
  }

  removeAllMarkersOneDestination(indexDestination: number) {
    if (indexDestination || indexDestination === 0) {
      this.listKeyMarkersDestination = this.utils.clone(this.standardMap.listMarkersKey);
      this.mapOptionsConfig({
        removeClickMapListener: true,
        clickMap: false
      })
      if (this.listKeyMarkersDestination && this.listKeyMarkersDestination.length) {
        this.listKeyMarkersDestination.forEach(key => {
          if (key.includes(`indexDestination:${indexDestination}`)) this.standardMap.removeMarker(key);
        })
        this.listKeyMarkersDestination = [];
      }
    }
  }
  removeAllMarkersAllDestinations(remove: boolean) {
    if (remove && this.standardMap) {
      this.listKeyMarkersDestination = this.utils.clone(this.standardMap.listMarkersKey);
      this.mapOptionsConfig({
        removeClickMapListener: true,
        clickMap: false
      })
      if (this.listKeyMarkersDestination && this.listKeyMarkersDestination.length) {
        this.listKeyMarkersDestination.forEach(key => {
          if (key.includes('indexDestination:')) this.standardMap.removeMarker(key);
        })
        this.listKeyMarkersDestination = [];
      }
    }
  }

  private createContentInfoWindow(isOrigin: boolean, address: string, index: number, origin?: string, destination?: string, indexDestination?: number) {
    let content = '';
    if (isOrigin) {
      content = `
      <b>Origen:</b> ${origin ? origin : '-'}<br/>
      <b>Direccion n° ${index + 1}:</b> ${address ? address : '-'}<br/>`
    } else {
      content = `
      <b>Destino n° ${indexDestination + 1}:</b> ${destination ? destination : '-'}<br/>
      <b>Dirección n° ${index + 1}:</b> ${address ? address : '-'}<br/>`
    }
    return content;
  }

  receiveFromChildren($event: any) {
    this.city = $event && $event.options && $event.options.city && $event.options.city.name ? $event.options.city.name : '';
    this.index = $event && $event.options && ($event.options.index || $event.options.index === 0) ? $event.options.index : null;
    this.indexDestination = $event && $event.options && ($event.options.indexDestination || $event.options.indexDestination === 0) ? $event.options.indexDestination : null;
    this.activeLocation.indexAddress = this.index;
    this.activeLocation.indexDestination = this.indexDestination;
    let isOrigin = !(!!this.indexDestination || this.indexDestination === 0);
    this.keyMarker = this.index || this.index === 0 ? `indexAddress:${this.index}` : '';
    this.keyMarker += this.indexDestination || this.indexDestination === 0 ? `&indexDestination:${this.indexDestination}` : '';
    let labelIcon = this.index ? (this.index + 1).toString() : null;
    this.locationSelected = {
      indexAddress: this.index || this.index === 0 ? this.index : null,
      indexDestination: this.indexDestination || this.indexDestination === 0 ? this.indexDestination : null
    };
    if ($event && $event.data && $event.data.viewActive) {
      this.viewActive = $event.data.viewActive;
    }
    if ($event && $event.data && $event.data.refreshForm) {
      const cloneValue = this.utils.clone(this.manualCreationCargoService.cargoForm.value);
      this.manualCreationCargoService.refreshCargoForm(cloneValue);
    }
    //Actualizar o crear marker desde el autocomplete
    if ($event && $event.data && $event.data.refreshMarkers && $event.data.location && $event.data.location.lat && $event.data.location.lng) {
      this.activeLocation.typeLocation = 'address';
      this.standardMap.removeMapListener();
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, this.createContentInfoWindow(isOrigin, $event.data.address, this.index, $event.options.city.name, $event.options.city.name, !isOrigin ? this.indexDestination : null), this.index === 0 ? this.global.pathMarkerOrigin : this.global.pathLocationPin, this.index ? labelIcon : null);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      const data = this.setAddressDataToSave($event.data.address, $event.data.location.lat, $event.data.location.lng, this.activeLocation ? this.activeLocation.typeLocation : null, $event.options.index, $event && $event.options && $event.options.indexDestination ? $event.options.indexDestination : null);
      this.saveAddressSelected(data);
    }
    //Actualizar o crear marker desde el click del mapa
    if ($event && $event.data && $event.data.focusInput) {
      if ($event.options && $event.options.clearListener) {
        this.mapOptionsConfig({
          removeClickMapListener: true,
          removeMarker: true,
          clickMap: false,
          onlyListener: false
        })
      } else {
        this.mapOptionsConfig({
          keyMarkerListener: this.keyMarker ? this.keyMarker : null,
          removeClickMapListener: false,
          removeMarker: false,
          clickMapIcon: $event && $event.options && this.index === 0 ? this.global.pathMarkerOrigin : this.global.pathLocationPin,
          clickMap: true,
          labelIcon: labelIcon,
          onlyListener: false
        })
      }
      this.activeLocation.typeLocation = 'address';
    }

    //Actualizar o crear marker si es un remitente o destinatario
    if ($event && $event.data && $event.data.thirdPartyConsignment && $event.data.thirdPartyConsignment.documentNumber && $event.data.location.lat && $event.data.location.lng) {
      this.standardMap.removeMapListener();
      this.standardMap.removeMarker(this.keyMarker);
      this.standardMap.createMarker(this.keyMarker, $event.data.location.lat, $event.data.location.lng, this.createContentInfoWindow(isOrigin, $event.data.address, this.index, $event.options.city.name, $event.options.city.name, !isOrigin ? this.indexDestination : null), this.index === 0 ? this.global.pathMarkerOrigin : this.global.pathLocationPin, this.index ? labelIcon : null);
      this.standardMap.setCenterMap($event.data.location.lat, $event.data.location.lng, 16);
      const data = this.setAddressDataToSave($event.data.address, $event.data.location.lat, $event.data.location.lng, this.activeLocation ? this.activeLocation.typeLocation : null, $event.options.index, $event && $event.options && ($event.options.indexDestination || $event.options.indexDestination === 0) ? $event.options.indexDestination : null);
      this.activeLocation.typeLocation = 'address';
      this.saveAddressSelected(data);
    }
  }

  mapOptionsConfig(options: OptionsAutocomplete) {
    this.mapOptions = { ...this.mapOptions, ...options };
  }

  setAddressDataToSave(address: string, lat: number, lng: number, typeLocation: string, indexAddress: number, indexDestination?: number) {
    const data = {
      address: address,
      location: {
        lat,
        lng
      },
      typeLocation: typeLocation,
      indexAddress: indexAddress,
      indexDestination: indexDestination
    };
    return data;
  }

  saveAddressSelected(data) {
    if (this.activeLocation.typeLocation === 'cargoOwner') {
      this.thirdPartyConsignmentComponent.addressSelected = { address: data.address, location: data.location };
      this.activeLocation.typeLocation = null;
    } else if (this.activeLocation.typeLocation === 'address') {
      if (
        this.utils.isDefined(this.locationSelected) &&
        this.utils.isDefined(this.locationSelected.indexAddress) &&
        !this.utils.isDefined(this.locationSelected.indexDestination)
      ) {
        this.addressOriginMapSelected = data;
        this.activeLocation.typeLocation = null;
      } else if (
        this.utils.isDefined(this.locationSelected) &&
        this.utils.isDefined(this.locationSelected.indexAddress) &&
        this.utils.isDefined(this.locationSelected.indexDestination)
      ) {
        this.addressDestinationMapSelected = data;
        this.activeLocation.typeLocation = null;
      }
    }
  }


  onChangeManifestOption() {
    this.manualCreationCargoService.formOrigin.get('name').setValue('');
    let initialValue = this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.origin);
    let initialValueDestination = this.utils.clone(this.cargoResources.cargoMock.cargoFeature.uploadDownload.destination);

    const basicAddress = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses[0]);
    const basicAddressDestination = this.utils.clone(this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination[0].addresses[0]);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [];
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination.forEach(destination => {
      destination.addresses = []
    });


    this.manualCreationCargoService.formOrigin.patchValue(initialValue);
    this.manualCreationCargoService.formDestinations.patchValue(initialValueDestination);

    let cloneValue = this.utils.clone(this.manualCreationCargoService.cargoForm.value);
    this.manualCreationCargoService.refreshCargoForm(cloneValue);
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.origin.addresses = [basicAddress];
    this.manualCreationCargoService.cargoMock.cargoFeature.uploadDownload.destination.forEach(destination => {
      destination.addresses = [basicAddressDestination]
    });
    this.manualCreationCargoService.formDestinations.patchValue(initialValueDestination);
    this.manualCreationCargoService.formOrigin.patchValue(initialValue);
    cloneValue = this.utils.clone(this.manualCreationCargoService.cargoForm.value);
    this.manualCreationCargoService.refreshCargoForm(cloneValue);
    this.removeAllMarkersOrigin(true);
    this.removeAllMarkersAllDestinations(true);
  }

  redirectToCompany() {
    let companyId = this.uploadDownloadMapService.getApplyPolicyByCompany() ? this.manualCreationCargoService.getOwnerSelected().companyId : this.authService.getCompanySaaS().companyId;
    this.router.navigate(['/administration/companies/detail', companyId], { state: { redirection: true } });
  }

  refreshCompaniesList(costCenterResponse: {costCenter: CostCenter, created: boolean} | null){
    let costCenterSelected = costCenterResponse && costCenterResponse.costCenter && costCenterResponse.costCenter.code ? costCenterResponse.costCenter: null;
    if (costCenterResponse && costCenterResponse.created) this.thirdPartyConsignmentComponent.getCompanies(true);
    if (this.utils.isDefined(costCenterSelected) && this.utils.isDefined(costCenterSelected.code)) {
      this.manualCreationCargoService.cargoForm.get('costCenter').setValue(costCenterSelected);
      this.costCenterSetted = costCenterSelected;
    }
    else {
      this.costCenterSetted = this.emptyCostCenter
      this.manualCreationCargoService.cargoForm.get('costCenter').setValue(this.emptyCostCenter);
    }
  }

}
