import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthorizedSitesFormComponent } from './authorized-sites-form/authorized-sites-form.component';
import { PlanningRouteService } from 'src/app/core/services/planning-route.service';
import { RouteAuthorizedPoint } from 'src/app/core/interfaces/route-authorized-point';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { PlanningRoutesMessages } from 'src/app/core/messages/planning-routes-messages.enum';
import { RouteAuthorizedPointSearchParam } from 'src/app/core/interfaces/route-authorized-point-search-param';
import { FormControl } from '@angular/forms';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  public services = [
    { id: 1, name: 'Hospedaje' },
    { id: 2, name: 'Estación de servicio' },
    { id: 3, name: 'Parqueadero' },
    { id: 4, name: 'Restaurante' },
  ];
  public sites: RouteAuthorizedPoint[] = [];
  public filters: any[] = [];

  cityControl = new FormControl();
  citySub: Subscription;
  cityOptions: OptionsAutocomplete = { title: "Ciudad", appearance: "outline" };
  public sort_order = 0;

  constructor(
    private matDialog: MatDialog,
    private planningRouteService: PlanningRouteService,
    private snackbarService: SnackBarService
  ) { }

  ngOnInit() {
    this.loadList();
    this.setSubscription();
  }

  setSubscription() {
    this.citySub = this.cityControl.valueChanges.subscribe(city => {
      if (city && city.name) this.loadList({ cityId: city.id, cityName: city.name });
    })
  }

  onFilterType(pointType: any) {
    this.loadList({ pointType });
  }

  public create() {
    this.matDialog.open(AuthorizedSitesFormComponent);
  }

  public edit(site: any) {
    const config = new MatDialogConfig();
    config.data = site;
    this.matDialog.open(AuthorizedSitesFormComponent, config);
  }

  public deactive(site: any) {
    this.planningRouteService.deactivateAuthorizedPoint(site.id).subscribe(
      () => {
        this.snackbarService.openSnackBar(PlanningRoutesMessages.DEACTIVE_AUTHORIZED_POINT, "Aceptar", "success");
      },
      () => {
        this.snackbarService.openSnackBar(PlanningRoutesMessages.HTTP_ERROR, "Aceptar", "error");
      }
    );
  }

  private loadList(filters?: RouteAuthorizedPointSearchParam) {
    this
      .planningRouteService
      .getAuthorizedPoints(filters || { active: "true" })
      .subscribe(
        (result: RouteAuthorizedPoint[]) => {
          !!result ? this.sites = result : this.sites = [];
        }
      )
  }

  ngOnDestroy() {
    if (this.citySub) this.citySub.unsubscribe();
  }
}
