export enum PDFTypes {
    ORDER_VEHICLE = 'orderVehicle',
    CONSIGNMENT = 'consignment',
    CONSIGNMENT_URBAN = 'consignment_urban',
    MANIFEST = 'manifest',
    MANIFEST_URBAN = 'manifest_urban',
    VEHICLE_INSPECTION = 'vehicleInspection',
    CONTAINER_INSPECTION = 'containerInspection',
    PLANNING_ROUTE = 'routePlan',
}
