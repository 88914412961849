import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Vehicle } from 'src/app/core/interfaces/vehicle';
import { Endpoints } from 'src/app/core/resources/endpoints';
import { Utils } from 'src/app/core/resources/utils';
import { environment } from 'src/environments/environment';
import { ManualCreationCargoService } from '../../cargo/manual-creation-cargo/manual-creation-cargo.service';
import { VehicleCargo } from 'src/app/core/interfaces/vehicle-cargo';
import { VehiclesService } from '../vehicles/list-vehicles.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { ServiceMessages } from 'src/app/core/messages/service-messages.enum';
import { Evidence, MaintenanceDetail } from 'src/app/core/interfaces/maintenance-detail';
import { CatalogItem } from 'src/app/core/interfaces/catalogItem';
import { DateManager } from 'src/app/core/managers/date.manager';
@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  vehicle: Vehicle;
  vehicleTypes: VehicleCargo[] = [];
  loadingRoute: string = '';
  params: string = '';
  constructor(
    private utils: Utils,
    private endpointResources: Endpoints,
    private http: HttpClient,
    private manualCreationCargoService: ManualCreationCargoService,
    private vehicleService: VehiclesService,
    private spinner: NgxSpinnerService,
    private snackbarService: SnackBarService
  ) { }

  public setVehicle(vehicle: Vehicle): void {
    this.vehicle = vehicle;
  }

  public clearVehicle(): void {
    this.vehicle = null;
  }

  public getListVehiclesDetail(paramsFilter: string, pageKey: number, pageSize: number): Observable<Vehicle[]> {
    let params = this.utils.getParamsFormatted(paramsFilter, pageKey, pageSize);
    return this.http.get<Vehicle[]>(`${environment.urlServerTeclogi}${this.endpointResources.getVehicles}`,
      { params });
  }

  public getListMaintenanceVehicle(paramsFilter: string, pageKey: number, pageSize: number) {
    let params = this.utils.getParamsFormatted(paramsFilter, pageKey, pageSize);
    return this.http.get(`${environment.urlServerTeclogi}${this.endpointResources.maintenance}`,
      { params });
  }

  public deleteMaintenanceVehicle(id: string) {
    return this.http.delete(`${environment.urlServerTeclogi}${this.endpointResources.maintenance}/${id}`);
  }

  public getMaintenanceTypes() {
    return this.http.get<{ catalog: CatalogItem[] }>(`${environment.urlServerTeclogi}${this.endpointResources.typeMaintenances}`);
  }

  public getMaintenanceRecords() {
    return this.http.get<{ catalog: CatalogItem[] }>(`${environment.urlServerTeclogi}${this.endpointResources.maintenanceRecords}`);
  }

  public createMaintenance(maintenance: MaintenanceDetail | { evidence: Evidence[] }) {
    return this.http.post(`${environment.urlServerTeclogi}${this.endpointResources.maintenance}`, maintenance);
  }

  public updateMaintenanceVehicle(maintenanceId: string, maintenance: MaintenanceDetail | { evidence: Evidence[] }) {
    return this.http.put(`${environment.urlServerTeclogi}${this.endpointResources.maintenance}/${maintenanceId}`, maintenance);
  }

  public async getVehicleTypes() {
    const data = await this.manualCreationCargoService.getVehicleType().toPromise();
    this.vehicleTypes = data.catalog ? data.catalog : this.manualCreationCargoService.listVehicles;
  }

  getVehicle(id: string): Promise<Vehicle> {
    if (this.vehicle && this.vehicle.id === id) return Promise.resolve(this.vehicle);
    else {
      return new Promise((resolve, reject) => {
        const vehicleObserver = {
          next: async (vehicle: Vehicle) => {
            this.spinner.hide();
            if (vehicle) {
              if (this.vehicleTypes && this.vehicleTypes.length) {
                vehicle.typeVehiclePath = this.getNameIconVehicle(vehicle);
              } else {
                await this.getVehicleTypes();
                vehicle.typeVehiclePath = this.getNameIconVehicle(vehicle);
              }
              this.setVehicle(vehicle);
              resolve(vehicle);
            } else {
              this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
              reject(null);
            }
          },
          error: () => {
            this.spinner.hide();
            this.vehicle = null;
            this.snackbarService.openSnackBar(ServiceMessages.GENERAL_HTTP_ERROR, undefined, 'error');
            reject(null);
          }
        };
        this.spinner.show();
        this.vehicleService.getVehicle(id).subscribe(vehicleObserver);
      });
    }
  }

  getLoadingRoute(): string {
    return this.loadingRoute;
  }

  setLoadingRoute(route: string, params?: string): void {
    this.loadingRoute = route;
    this.params = params;
  }

  // GETTERS
  public getNameIconVehicle(vehicle: Vehicle) {
    let iconPath: string = '/assets/svg/icons/icon-camion.svg';
    if (
      this.utils.getNestedValue(vehicle, 'vehicleType.name')
      && this.vehicleTypes
      && this.vehicleTypes.length
      && this.vehicleTypes.every(vehicleType => !!vehicleType.name)) {
      let vehicleTypeFilter = this.vehicleTypes.find(vehicleType => vehicleType.name.toLowerCase() === vehicle.vehicleType.name.toLowerCase());
      if (vehicleTypeFilter && vehicleTypeFilter.name) iconPath = '/assets/svg/icons/icon-' + vehicleTypeFilter.name.toLowerCase() + '.svg';
    }
    return iconPath;
  }


  getCalculateMaintenancePeriod(expirationDate: string): number {
    const expirationDateFormatted = DateManager.setStartOfDay(DateManager.stringToDate(expirationDate, 'YYYY-MM-DDTHH:mm:ssT'));
    return DateManager.dateDiff(expirationDateFormatted, null, DateManager.setStartOfDay(new Date()));
  }

}
